import "./PamphletDetailsCard.css";
const PamphletDetailsCard = (props) => {
  // console.log(props.referCodeList);
  return (
    <>
      <div className="pamphlet_details_card_mai_div">
        <div className="pamphlet_details_card_child_div1">
          <div className="sub_title_child">Size&nbsp; -</div>
          <div className="sub_title_child">Side&nbsp; -</div>
          <div className="sub_title_child">Quantity&nbsp; -</div>
          <div className="sub_title_child">Amount&nbsp; -</div>
        </div>

        <div className="pamphlet_details_card_child_div2">
          <div className="sub_title_child">{props.size}</div>
          <div className="sub_title_child">
            {props.side === true ? "Double Side" : "Single Side"}
          </div>
          <div className="sub_title_child">{props.quantity}</div>
          <div className="sub_title_child">{props.amount}</div>
        </div>
      </div>
    </>
  );
};
export default PamphletDetailsCard;
