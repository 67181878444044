import "./VideoPreviewPage.css";
const VideoPreviewPage = (props) => {
  // console.log(props.response);
  const array = props.response && props.response.qr_code_url;
  // console.log(array);

  const businessName = array.businessName;
  const description = array.description;
  const videoTitle = array.videoTitle;
  const videoUrl = array.videoUrl;
  //   const videoUrl = "https://www.youtube.com/embed/Rh3tobg7hEo";

  return (
    <>
      <div className="multi_url_preview_main_div">
        <div className="multi_url_preview_sub_div">
          <div
            style={{ height: "430px", marginTop: "-28rem" }}
            className="green_background"
          ></div>
          <div
            style={{ color: "#FFF", marginTop: "-16rem" }}
            className="multi_url_business_name"
          >
            {businessName}
          </div>
          <div
            style={{ color: "#fff", fontSize: "14px", marginTop: "1rem" }}
            className="video_description"
          >
            {description}
          </div>

          <div className="multi_urls_tabs_card">
            <div className="social_media_tabs_card_sub_div">
              <div style={{ marginTop: "1rem" }} className="sub_title">
                {videoTitle}
              </div>
              <iframe
                className="video_frame"
                width="300"
                height="200"
                src={videoUrl}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VideoPreviewPage;
