import "./SocialMediaPreviewPage.css";
import {
  facebookLogo,
  instaLogo,
  linkedInLogo,
  twitterLogo,
  youTubeLogo,
} from "../../../../../assets";

const SocialMediaPreviewPage = (props) => {
  // console.log(props.response);
  console.log(typeof props.response?.qr_code_url);
  const data = JSON.parse(props.response?.qr_code_url);

  // console.log(data);
  const businessName = data.businessName;

  const instagramLink = data.instagramLink;
  const facebookLink = data.facebookLink;
  const linkedInLink = data.linkedInLink;
  const twitterLink = data.twitterLink;
  const youTubeLink = data.youTubeLink;

  return (
    <>
      <div className="multi_url_preview_main_div">
        <div className="multi_url_preview_sub_div">
          {/* <div className="green_background"></div> */}
          <div
            style={{ marginTop: "-6rem", color: "#fff" }}
            className="multi_url_business_name"
          >
            Follow Us On Social Media
          </div>

          <div className="multi_urls_tabs_card">
            <div className="social_media_tabs_card_sub_div">
              <div
                style={{ color: "black" }}
                className="multi_url_business_name"
              >
                {businessName}
              </div>
              <div className="social_media_btns_wrapper">
                {instagramLink !== "" && (
                  <button className="insta_btn">
                    <span>
                      <img
                        className="preview_logo"
                        src={instaLogo}
                        alt="instaLogo"
                      />
                    </span>
                    <a href={instagramLink}>Instagram</a>
                  </button>
                )}
                {facebookLink !== "" && (
                  <button className="fb_btn">
                    <span>
                      <img
                        className="preview_logo"
                        src={facebookLogo}
                        alt="instaLogo"
                      />
                    </span>
                    <a href={facebookLink}>Facebook</a>
                  </button>
                )}
                {twitterLink !== "" && (
                  <button className="linkedin_btn">
                    <span>
                      <img
                        className="preview_logo"
                        src={twitterLogo}
                        alt="instaLogo"
                      />
                    </span>
                    <a href={twitterLink}>Twitter</a>
                  </button>
                )}
                {linkedInLink !== "" && (
                  <button className="twitter_btn">
                    <span>
                      <img
                        className="preview_logo"
                        src={linkedInLogo}
                        alt="instaLogo"
                      />
                    </span>
                    <a href={linkedInLink}>LinkedIn</a>
                  </button>
                )}
                {youTubeLink !== "" && (
                  <button className="youtube_btn">
                    <span>
                      <img
                        className="preview_logo"
                        src={youTubeLogo}
                        alt="instaLogo"
                      />
                    </span>
                    <a href={youTubeLink}>YouTube</a>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SocialMediaPreviewPage;
