import React from "react";
import "./HeroSec.css";
import img from "./heroImg.png";
import hero from "./_hero.png";
import newHeroImg from "./new_hero_img.png";
import hero2 from "./Hero2.png";
import arrow from "./arrow_forward.svg";
import Phone from "./phone.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { adsHeroBanner } from "../../../../../assets";

function HeroSec() {
  const history = useHistory();
  return (
    <div
      className={
        window.innerWidth < 1000
          ? "advertiser-hero-main-div-mobile"
          : "advertiser-hero-main-div"
      }
      id="HeroSec"
    >
      <div
        className={
          window.innerWidth > 1000
            ? "ads__text__component "
            : "ads__text__component text_white"
        }
      >
        Enhancing Your <br /> Business with <br />
        <span className="dark_green_text">
          Direct Mail Marketing, <br />
        </span>{" "}
        One Mail at a Time
        {/* <h2
          className={
            window.innerWidth > 1000 ? "black_text" : "heroSec_sub_text"
          }
        >
          Empower your Marketing with our Direct Mail Marketing Solution
        </h2> */}
        <div className="hero_btn m_t_2">
          <button
            className={
              window.innerWidth > 1000 ? "hero_btn_desk" : "hero_btn_mobile"
            }
            onClick={() => {
              history.push("/register");
            }}
          >
            Get started <img src={arrow} alt="arrow"></img>
          </button>
        </div>
      </div>
      {window.innerWidth > 1000 && (
        <div className="hero__sec_img">
          <img src={newHeroImg} alt="hero" />
        </div>
      )}
      {/* <div className="bottom_text">
        <span>“</span>With our cutting-edge technology & customized <br />{" "}
        advertising we're revolutionizing the industry & helping our
        <br /> clients see real results<span>”</span>
      </div> */}
    </div>
  );
}

export default HeroSec;
