import React from "react";
import "./Button.css";

const Button = (props) => {
  const classes =
    props.isChecked === false || props.isClicked === false
      ? `ads_custom_btn_disabled not-allowed  ${
          props.className !== undefined ? props.className : " "
        }`
      : `ads_custom_btn pointer ${
          props.className !== undefined ? props.className : " "
        } `;

  const btnClasses = "ads_custom_btn_text " + props.btnTxtClassName;

  return (
    <button
      onClick={props.onClick}
      className={classes}
      style={props.style}
      type={props.type ? props.type : "submit"}
    >
      <div className={btnClasses}>
        <span className={props.btnTextClasses ? props.btnTextClasses : ""}>
          {props.name}
        </span>
      </div>
    </button>
  );
};

export default Button;
