import { Chip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RxArrowRight } from "react-icons/rx";
import { Link, useHistory } from "react-router-dom";
import { arrowRight, infoIcon, successGIF } from "../../assets";
import { API_URL } from "../../constant/apiURL";
import { fetchCampaignData } from "../../store/campaign-slice";
import CampaignLayout from "../CampaignLayout/CampaignLayout";
import SideBarButton from "../DashboardLayout/DashboardHeader/SideBarButtons/SideBarButton";
import HorizontalDivider from "../UI/Dividers/HorizontalDivider";
import "./PaymentSuccess.css";
let isInitial = true;
const PaymentSuccess = () => {
  const createdCampaignId = localStorage.getItem("createdCampaignId");
  const paidAmount = localStorage.getItem("amountPaid");

  const [campaign, setCampaign] = useState(null);
  // console.log(campaign);
  const [pamphlet, setPamphlet] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  // console.log(isPaid);
  const [isCampaignVisible, setIsCampaignVisible] = useState(false);
  const [amountPaid, setamountPaid] = useState();
  const tax = 5;
  let payableTaxAmount = +30 * tax * 0.01;

  let amountInclTax = campaign?.total_amount + payableTaxAmount;
  const selectedBudget = campaign?.budget_amount;
  const isCouponApplicable =
    campaign?.coupon?.coupon_code === "ABCDE" ? true : false;

  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const token = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();

  useEffect(() => {
    if (createdCampaignId && paidAmount) {
      fetch(API_URL + "campaign/deduct-payment/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          campaign_id: +createdCampaignId,
          amount_paid: +paidAmount,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json().then((data) => {
              // console.log(data);
              setIsCampaignVisible(true);
              setIsPaid(data.is_paid);
              setamountPaid(data?.amount_paid);
              // console.log(data.is_paid, "data.is_paid");
              // console.log(data);
              // localStorage.removeItem("amountPaid");
            });
          } else {
            return res.json().then((data) => {
              setIsCampaignVisible(true);
              // console.log(data);
              let errorMsg = "Something went wrong!";
              for (let key in data) {
                errorMsg = data[key];
              }
              throw new Error(errorMsg);
            });
          }
        })
        .catch((err) => {
          console.log(err.message);
          alert(err.message);
        });
    }
  }, [token, createdCampaignId]);

  useEffect(() => {
    const _campaign = campaigns.find(
      (campaign) => campaign.id === parseInt(createdCampaignId)
    );
    if (_campaign) {
      setCampaign(_campaign);
    } else if (isInitial) {
      isInitial = false;
      dispatch(fetchCampaignData(token));
    }
    // if (_campaign) {
    //   (async () => {
    //     const resPam = await fetch(
    //       API_URL + `campaign/pamphlet-detail/${_campaign.pamphlet_id?.id}/`,
    //       {
    //         headers: {
    //           Authorization: `Token ${token}`,
    //         },
    //       }
    //     );
    //     const dataPam = await resPam.json();
    //     setPamphlet(dataPam);
    //   })();
    // }
  }, [campaigns, dispatch, token, createdCampaignId]);

  let discount;
  if (campaign?.coupon?.lumpsum_discount === 0) {
    const discountPercent = campaign?.coupon?.discount;

    const discountAmount = ((selectedBudget * discountPercent) / 100).toFixed(
      0
    );
    discount = discountAmount;
  } else {
    if (selectedBudget > campaign?.coupon?.lumpsum_discount) {
      discount = campaign?.coupon?.lumpsum_discount;
    }
  }

  return (
    <CampaignLayout>
      <div className="PaymentSuccess_main_container">
        <div>
          <img
            className="PaymentSuccess_gif"
            src={successGIF}
            alt="success.gif"
          ></img>
        </div>
        <div className="campaign_detail_title">
          Campaign Created Successfully !!
        </div>
        <div className=" campaign_detail_subtitle">
          You can track your campaign from your dashboard
        </div>
        {campaign?.is_part_paid && (
          <div className="campaign_not_working_wrapper m_t_1">
            <div className="make_payment_text_msg">
              <span className="info_icon_div">
                <img src={infoIcon} alt="infoIcon" />
              </span>
              Your campaign is not running, Kindly make full payment to start
              your campaign
            </div>
          </div>
        )}
        <Link to="/dashboard">
          <button className="g_t_dash_btn">
            Go to Dashboard{" "}
            <span>
              <RxArrowRight />
            </span>
          </button>
        </Link>
        <div className="campaign_detail_table">
          <div className="campaign_detail_table_head">
            <div className="campaign_detail_table_title truncate">
              {campaign?.name}
            </div>{" "}
            <div className="campaign_detail_table_date opaque">
              {campaign?.created_on.substring(0, 10)}
            </div>
          </div>
          {/* <HorizontalDivider style={{ height: "2px" }} /> */}
          <div className="campaign_detail_table_content_container">
            {/* <div>
              <div style={{ width: "100%" }} className="body_text_xs">
                Pamphlet details
              </div>
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="firstChild">Size: </div>
                <div className="lastChild">{campaign?.pamphlet_id?.size}</div>
              </div>
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="firstChild">Side: </div>
                <div className="lastChild">
                  {campaign?.pamphlet_id?.is_duplex
                    ? "Double Side"
                    : "Single Side"}
                </div>
              </div>
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="firstChild">File 1:</div>
                <div className="lastChild truncate">{fileName1}</div>
              </div>
              {campaign?.pamphlet_id?.is_duplex && (
                <div
                  style={{ width: "100%" }}
                  className="campaign_detail_table_content_text"
                >
                  <div className="firstChild">File:</div>
                  <div className="lastChild truncate">{fileName2}</div>
                </div>
              )}
            </div> */}
            <div id="second2nd_div">
              <div
                style={{ width: "100%" }}
                className="body_text_xs green_text"
              >
                Payment details
              </div>
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="payment_firstChild">Price </div>
                <div className="payment_lastChild">
                  ₹{campaign?.budget_amount}
                </div>
              </div>
              {!isCouponApplicable && selectedBudget && (
                <div className="campaign_detail_table_content_text">
                  <div
                    style={{ fontWeight: "600" }}
                    className="payment_list_firstChild "
                  >
                    Discount
                  </div>
                  <div className="payment_lastChild">₹{discount}</div>
                </div>
              )}
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="payment_firstChild sub_title">GST(5%) </div>
                <div className="payment_lastChild">₹{payableTaxAmount}</div>
              </div>
              <br />
              <HorizontalDivider />
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="payment_firstChild sub_title">Total </div>
                <div className="payment_lastChild">₹{amountInclTax}</div>
              </div>

              <HorizontalDivider style={{ marginTop: "1rem" }} />
              {!isPaid && (
                <div
                  style={{ width: "100%" }}
                  className="campaign_detail_table_content_text"
                >
                  <div className="payment_firstChild sub_title">
                    Partial Payment{" "}
                  </div>
                  <div className="payment_lastChild">₹{amountPaid}</div>
                </div>
              )}
              {!isPaid && (
                <div
                  style={{ width: "100%" }}
                  className="campaign_detail_table_content_text"
                >
                  <div className="payment_firstChild sub_title">
                    Remaining Payment{" "}
                  </div>
                  <div className="payment_lastChild">
                    ₹{amountInclTax - amountPaid}
                  </div>
                </div>
              )}
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div
                  className="payment_firstChild"
                  style={{ fontWeight: "600" }}
                >
                  Payment status:
                </div>
                <div
                  className="payment_lastChild"
                  style={{
                    fontWeight: "600",
                    color: !isPaid ? "#e59400" : "#31B963",
                  }}
                >
                  {!isPaid ? "Partially Paid" : "Paid"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CampaignLayout>
  );
};

export default PaymentSuccess;
