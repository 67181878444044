import { useEffect } from "react";
import {
  adjustIcon,
  benefitsIcon,
  check,
  diamondSymbolIcon,
  rightArrowNew,
} from "../../../assets";
import { API_URL } from "../../../constant/apiURL";
import { useState } from "react";
import "./BusinessCheckboxQtyCard.css";
import HztlBarDotted from "../HztlBarDotted/HztlBarDotted";

const BusinessCheckboxQtyCard = (props) => {
  // checkbox adtype
  const [checkboxStates, setCheckboxStates] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);

  const [miniQtyError, setMiniQtyError] = useState(false);

  const handleCheckboxChange = (index) => {
    const newCheckboxStates = [...checkboxStates];
    newCheckboxStates[index] = !newCheckboxStates[index];
    if (index === 0 && newCheckboxStates[1]) {
      newCheckboxStates[1] = false;
    } else if (index === 1 && newCheckboxStates[0]) {
      newCheckboxStates[0] = false;
    }

    setCheckboxStates(newCheckboxStates);
  };

  useEffect(() => {
    if (
      props.card2 &&
      props.selectedPlan2 &&
      !props.selectedPlan3 &&
      !props.selectedPlan4
    ) {
      props.setQtyAdTypesCard2([...checkboxStates]);
      props.setQtyAdTypesCard3([false, false, false, false, false]);
      props.setQtyAdTypesCard4([false, false, false, false, false]);
    }
    if (
      props.card3 &&
      !props.selectedPlan2 &&
      props.selectedPlan3 &&
      !props.selectedPlan4
    ) {
      props.setQtyAdTypesCard2([false, false, false, false, false]);
      props.setQtyAdTypesCard3([...checkboxStates]);
      props.setQtyAdTypesCard4([false, false, false, false, false]);
    }
    if (
      props.card4 &&
      !props.selectedPlan2 &&
      !props.selectedPlan3 &&
      props.selectedPlan4
    ) {
      props.setQtyAdTypesCard2([false, false, false, false, false]);
      props.setQtyAdTypesCard3([false, false, false, false, false]);
      props.setQtyAdTypesCard4([...checkboxStates]);
    }
  }, [
    ...checkboxStates,
    props.selectedPlan2,
    props.selectedPlan3,
    props.selectedPlan4,
  ]);

  // states for bids
  const [pamphletBid, setPamphletBid] = useState();
  const [footerBid, setFooterBid] = useState();
  const [displayAdBid, setDisplayAdBid] = useState();
  const [labelAdBid, setLabelAdBid] = useState();
  const [qrCodeBid, setQrCodeBid] = useState();

  const [expanded, setExpanded] = useState(false);

  const toggleText = () => {
    setExpanded(!expanded);
  };

  let pamphB;
  let footerB;
  let displayAdB;
  let labelAdB;
  let qrB;

  let pamphC;
  let footerC;
  let displayAdC;
  let labelAdC;
  let qrC;

  const DA590 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Double" &&
    props.pammphletQlty === "90GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "DA590";

  const SA590 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Single" &&
    props.pammphletQlty === "90GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "SA590";

  const DA490 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Double" &&
    props.pammphletQlty === "90GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "DA490";

  const SA490 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Single" &&
    props.pammphletQlty === "90GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "SA490";

  const DA5130 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Double" &&
    props.pammphletQlty === "130GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "DA5130";

  const SA5130 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Single" &&
    props.pammphletQlty === "130GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "SA5130";

  const DA4130 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Double" &&
    props.pammphletQlty === "130GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "DA4130";

  const SA4130 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Single" &&
    props.pammphletQlty === "130GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "SA4130";

  const CM1 = props.footerSize === "1cm" && "CM1";
  const CM2 = props.footerSize === "2cm" && "CM2";

  const l1 = props.displayAdSize === "l1" && "l1";
  const l2 = props.displayAdSize === "l2" && "l2";

  const S1 = props.labelAdSize === "1" && "S1";
  const S2 = props.labelAdSize === "2" && "S2";

  const QRDA5 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Double" &&
    checkboxStates[1] &&
    "QRDA5";

  const QRSA5 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Single" &&
    checkboxStates[1] &&
    "QRSA5";

  const QRDA4 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Double" &&
    checkboxStates[1] &&
    "QRDA4";

  const QRSA4 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Single" &&
    checkboxStates[1] &&
    "QRSA4";

  const codeOfPamph =
    DA590 || SA590 || DA490 || SA490 || DA5130 || SA5130 || DA4130 || SA4130;

  const codeOfFooter = CM1 || CM2;
  const codeOfDisplayAd = l1 || l2;
  const codeOfLabelAd = S1 || S2;
  const codeOfQrPamph = QRDA5 || QRSA5 || QRDA4 || QRSA4;

  // ad types apis of bid
  useEffect(() => {
    // pamphlet bid list
    fetch(API_URL + "bids/pamphlet-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setPamphletBid(data))
      .catch((error) => console.log("Error Fetching Data", error));

    // footer bid list
    fetch(API_URL + "bids/footer-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())

      .then((data) => setFooterBid(data))
      .catch((error) => console.log("Error Fetching Data", error));

    // Display Ad Bid List
    fetch(API_URL + "bids/display-ad-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setDisplayAdBid(data))
      .catch((error) => console.log("Error Fetching Data", error));

    // Label Ad Bid List
    fetch(API_URL + "bids/label-ad-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setLabelAdBid(data))
      .catch((error) => console.log("Error Fetching Data", error));

    fetch(API_URL + "bids/qr-code-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setQrCodeBid(data))
      .catch((error) => console.log("Error Fetching Data", error));
  }, []);

  // functions to get bid and constant
  function pamphletBidHandler() {
    for (let i = 0; i < pamphletBid?.length; i++) {
      if (codeOfPamph === pamphletBid[i]?.code) {
        pamphB = pamphletBid[i]?.bid;

        pamphC = pamphletBid[i]?.constant;
      }
    }
  }
  pamphletBidHandler();

  function footerBidHandler() {
    for (let i = 0; i < footerBid?.length; i++) {
      if (codeOfFooter === footerBid[i]?.code) {
        footerB = footerBid[i]?.bid;
        footerC = footerBid[i]?.constant;
      }
    }
  }
  footerBidHandler();

  function displayAdBidHandler() {
    for (let i = 0; i < displayAdBid?.length; i++) {
      if (codeOfDisplayAd === displayAdBid[i]?.code) {
        displayAdB = displayAdBid[i]?.bid;
        displayAdC = displayAdBid[i]?.constant;
      }
    }
  }
  displayAdBidHandler();

  function labelAdBidHandler() {
    for (let i = 0; i < labelAdBid?.length; i++) {
      if (codeOfLabelAd === labelAdBid[i]?.code) {
        labelAdB = labelAdBid[i]?.bid;

        labelAdC = labelAdBid[i]?.constant;
      }
    }
  }
  labelAdBidHandler();

  function qrPamphBidHandler() {
    for (let i = 0; i < qrCodeBid?.length; i++) {
      if (codeOfQrPamph === qrCodeBid[i]?.code) {
        qrB = qrCodeBid[i]?.bid;

        qrC = qrCodeBid[i]?.constant;
      }
    }
  }
  qrPamphBidHandler();

  const [qtyOfPamph2, setQtyOfPamph2] = useState(25000);
  const [qtyOfFtr2, setQtyOfFtr2] = useState(25000);
  const [qtyOfDisp2, setQtyOfDisp2] = useState(25000);
  const [qtyOfLbl2, setQtyOfLbl2] = useState(25000);

  const [qtyOfPamph3, setQtyOfPamph3] = useState(50000);
  const [qtyOfFtr3, setQtyOfFtr3] = useState(50000);
  const [qtyOfDisp3, setQtyOfDisp3] = useState(50000);
  const [qtyOfLbl3, setQtyOfLbl3] = useState(50000);

  const [qtyOfPamph4, setQtyOfPamph4] = useState(100000);
  const [qtyOfFtr4, setQtyOfFtr4] = useState(100000);
  const [qtyOfDisp4, setQtyOfDisp4] = useState(100000);
  const [qtyOfLbl4, setQtyOfLbl4] = useState(100000);

  let qtyOfPamph;
  let qtyOfFtr;
  let qtyOfDisp;
  let qtyOfLbl;

  let pamphAmnt = 0;
  let ftrAmnt = 0;
  let dispAmnt = 0;
  let LblAmnt = 0;

  let totalBudget;

  if (props.card2) {
    qtyOfPamph = (checkboxStates[0] || checkboxStates[1]) && qtyOfPamph2;
    qtyOfFtr = checkboxStates[2] && qtyOfFtr2;
    qtyOfDisp = checkboxStates[3] && qtyOfDisp2;
    qtyOfLbl = checkboxStates[4] && qtyOfLbl2;

    pamphAmnt =
      (checkboxStates[0] && qtyOfPamph * pamphB) ||
      (checkboxStates[1] && qtyOfPamph * (pamphB + qrB));
    ftrAmnt = qtyOfFtr * footerB;
    dispAmnt = qtyOfDisp * displayAdB;
    LblAmnt = qtyOfLbl * labelAdB;

    totalBudget = pamphAmnt + ftrAmnt + dispAmnt + LblAmnt;
  }

  if (props.card3) {
    qtyOfPamph = (checkboxStates[0] || checkboxStates[1]) && qtyOfPamph3;
    qtyOfFtr = checkboxStates[2] && qtyOfFtr3;
    qtyOfDisp = checkboxStates[3] && qtyOfDisp3;
    qtyOfLbl = checkboxStates[4] && qtyOfLbl3;

    pamphAmnt =
      (checkboxStates[0] && qtyOfPamph * pamphB) ||
      (checkboxStates[1] && qtyOfPamph * (pamphB + qrB));
    ftrAmnt = qtyOfFtr * footerB;
    dispAmnt = qtyOfDisp * displayAdB;
    LblAmnt = qtyOfLbl * labelAdB;

    totalBudget = pamphAmnt + ftrAmnt + dispAmnt + LblAmnt;
  }

  if (props.card4) {
    qtyOfPamph = (checkboxStates[0] || checkboxStates[1]) && qtyOfPamph4;
    qtyOfFtr = checkboxStates[2] && qtyOfFtr4;
    qtyOfDisp = checkboxStates[3] && qtyOfDisp4;
    qtyOfLbl = checkboxStates[4] && qtyOfLbl4;

    pamphAmnt =
      (checkboxStates[0] && qtyOfPamph * pamphB) ||
      (checkboxStates[1] && qtyOfPamph * (pamphB + qrB));
    ftrAmnt = qtyOfFtr * footerB;
    dispAmnt = qtyOfDisp * displayAdB;
    LblAmnt = qtyOfLbl * labelAdB;

    totalBudget = pamphAmnt + ftrAmnt + dispAmnt + LblAmnt;
  }

  useEffect(() => {
    if (
      (checkboxStates[0] || checkboxStates[1]) &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setPamphQty(qtyOfPamph);
    }
    if (
      checkboxStates[1] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setCodeOfPamph(codeOfPamph);
    }

    if (
      checkboxStates[2] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setFtrQty(qtyOfFtr);
    }

    if (
      checkboxStates[3] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setDispQty(qtyOfDisp);
    }

    if (
      checkboxStates[4] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setLabelQty(qtyOfLbl);
    }

    if (
      (checkboxStates[0] ||
        checkboxStates[1] ||
        checkboxStates[2] ||
        checkboxStates[3] ||
        checkboxStates[4]) &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setQtyBasedBgt(totalBudget);
    }
  });

  const checkboxLabels = [
    props.pammphletSize === "A4" && props.pammphletSide === "Double"
      ? "Half Fold DS + QR"
      : "Standard",
    props.pammphletSize === "A4" && props.pammphletSide === "Double"
      ? "Tri-Fold DS + QR"
      : props.pammphletSide === "Double"
      ? "Standard DS + QR"
      : "Standard + QR",
    props.footerSize === "1cm" ? "Footer" : "Footer + QR",
    "Display Ad",
    "Front Page",
  ];

  return (
    <>
      <div
        className={
          props.darkTheme
            ? "new__business_budget_card_main_div_dark"
            : props.selectedPlan
            ? "new_business_budget_card_main_div_selected"
            : "new_business_budget_card_main_div"
        }
      >
        <div className="new_budget_card_sub_div">
          <div className="budget_detail_wrapper">
            {props.isVoucher && (
              <div className="voucher_card_label_div">
                <div className="f_s_14 text-center voucher_plan_label">
                  {props.isLocation
                    ? "Location-Focused Voucher Plan"
                    : "Standard Voucher Plan"}
                </div>
              </div>
            )}
            <div className="months_plan_wrapper">
              <div className="month_plan">
                <img
                  style={{ width: "100%" }}
                  src={props.monthPlan}
                  alt="Months"
                />
              </div>
            </div>
            <div className="amount_detail_wrapper">
              <div className="budget_name_text f_s_18 f_w_500">Budget</div>
              <div className="budget_amount_txt">
                ₹{totalBudget > 0 ? totalBudget : 0}
              </div>
            </div>
            <HztlBarDotted style={{ margin: "0.5rem 0rem" }} />
            <div className="quantity_detail_wrapper f_s_16 f_w_500">
              {checkboxStates[0] && (
                <div className="qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp;
                    {props.pammphletSize === "A4" &&
                    props.pammphletSide === "Double"
                      ? "Half-Fold DS + QR :"
                      : "Standard :"}
                  </div>
                  <div className="rec_cus_qty_wrapper ">
                    {!props.isCustomInput && qtyOfPamph}
                    &nbsp;&nbsp; {!props.isCustomInput && "Qty"}
                    {props.isCustomInput && (
                      <input
                        pattern="^[0-9]*$"
                        className={
                          miniQtyError
                            ? "cstm_qty_input_error "
                            : "cstm_qty_input"
                        }
                        placeholder="Enter Quantity"
                        type="text"
                        value={qtyOfPamph}
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          if (e.target.value < 2000) {
                            setMiniQtyError(true);
                            setQtyOfPamph2(+e.target.value);
                          } else {
                            setMiniQtyError(false);
                          }
                          if (props.card2) {
                            setQtyOfPamph2(+e.target.value);
                          }
                          if (props.card3) {
                            setQtyOfPamph3(+e.target.value);
                          }
                          if (props.card4) {
                            setQtyOfPamph4(+e.target.value);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {checkboxStates[1] && (
                <div className=" qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper ">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp;
                    {props.pammphletSize === "A4" &&
                    props.pammphletSide === "Double"
                      ? "Tri-Fold DS + QR :"
                      : props.pammphletSide === "Double"
                      ? "Standard DS + QR :"
                      : "Standard + QR :"}
                  </div>
                  <div className="rec_cus_qty_wrapper ">
                    {!props.isCustomInput && qtyOfPamph}
                    &nbsp;&nbsp; {!props.isCustomInput && "Qty"}
                    {props.isCustomInput && (
                      <input
                        pattern="^[0-9]*$"
                        className={
                          miniQtyError
                            ? "cstm_qty_input_error "
                            : "cstm_qty_input"
                        }
                        placeholder="Enter Quantity"
                        type="text"
                        value={qtyOfPamph}
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          if (e.target.value < 2000) {
                            setMiniQtyError(true);
                          } else {
                            setMiniQtyError(false);
                          }
                          if (props.card2) {
                            setQtyOfPamph2(+e.target.value);
                          }
                          if (props.card3) {
                            setQtyOfPamph3(+e.target.value);
                          }
                          if (props.card4) {
                            setQtyOfPamph4(+e.target.value);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {checkboxStates[2] && (
                <div className=" qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper  ">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp;{" "}
                    {props.footerSize === "1cm" ? "Footer :" : "Footer + QR :"}
                  </div>
                  <div className="rec_cus_qty_wrapper">
                    {!props.isCustomInput && qtyOfFtr}
                    &nbsp;&nbsp; {!props.isCustomInput && "Qty"}
                    {props.isCustomInput && (
                      <input
                        pattern="^[0-9]*$"
                        className="cstm_qty_input"
                        placeholder="Enter Quantity"
                        type="text"
                        value={qtyOfFtr}
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          if (e.target.value < 2000) {
                            setMiniQtyError(true);
                          } else {
                            setMiniQtyError(false);
                          }
                          if (props.card2) {
                            setQtyOfFtr2(+e.target.value);
                          }
                          if (props.card3) {
                            setQtyOfFtr3(+e.target.value);
                          }
                          if (props.card4) {
                            setQtyOfFtr4(+e.target.value);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {checkboxStates[3] && (
                <div className=" qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp; Display Ad :{" "}
                  </div>
                  <div className="rec_cus_qty_wrapper">
                    {!props.isCustomInput && qtyOfDisp}
                    &nbsp;&nbsp; {!props.isCustomInput && "Qty"}
                    {props.isCustomInput && (
                      <input
                        pattern="^[0-9]*$"
                        className="cstm_qty_input"
                        placeholder="Enter Quantity"
                        type="text"
                        value={qtyOfDisp}
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          if (e.target.value < 2000) {
                            setMiniQtyError(true);
                          } else {
                            setMiniQtyError(false);
                          }
                          if (props.card2) {
                            setQtyOfDisp2(+e.target.value);
                          }
                          if (props.card3) {
                            setQtyOfDisp3(+e.target.value);
                          }
                          if (props.card4) {
                            setQtyOfDisp4(+e.target.value);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {checkboxStates[4] && (
                <div className="f_s_15 qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp; Front Page :{" "}
                  </div>
                  <div className="rec_cus_qty_wrapper">
                    {!props.isCustomInput && qtyOfLbl}
                    &nbsp;&nbsp; {!props.isCustomInput && "Qty"}
                    {props.isCustomInput && (
                      <input
                        pattern="^[0-9]*$"
                        className="cstm_qty_input"
                        placeholder="Enter Quantity"
                        type="text"
                        value={qtyOfLbl}
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          if (e.target.value < 2000) {
                            setMiniQtyError(true);
                          } else {
                            setMiniQtyError(false);
                          }
                          if (props.card2) {
                            setQtyOfLbl2(+e.target.value);
                          }
                          if (props.card3) {
                            setQtyOfLbl3(+e.target.value);
                          }
                          if (props.card4) {
                            setQtyOfLbl4(+e.target.value);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            {!props.isCustomInput && (
              <div className="customize_budget_btn_wrapper">
                <button
                  className={
                    props.darkTheme
                      ? "customize_btn_dark_theme"
                      : "customize_btn"
                  }
                  onClick={props.ctmQtyOnClick}
                >
                  Customize Quantity &nbsp;
                  <span>
                    <img src={adjustIcon} alt="adjustIcon" />
                  </span>
                </button>
              </div>
            )}
            {props.isCustomInput && (
              <div className="customize_budget_btn_wrapper">
                <button
                  className={
                    props.darkTheme
                      ? "customize_btn_dark_theme"
                      : "customize_btn"
                  }
                  onClick={() => {
                    if (!miniQtyError) {
                      props.saveQtyOnClick();
                    }
                  }}
                >
                  Save Quantity
                </button>
              </div>
            )}
          </div>
          {/* <hr style={{ height: "2px", margin: "10px 0px" }} /> */}
          <HztlBarDotted style={{ margin: "1rem 0rem" }} />
          <div className="benefits_wrapper">
            <div className="benefits_sub_wrapper">
              <p className="f_s_16 f_w_500">
                {" "}
                <span>
                  <img src={rightArrowNew} alt="rightArrowNew" />
                </span>
                &nbsp; Choose an Ad Type to Display Quantity.
              </p>
              <div className="benefits_list m_t_0_5">
                {checkboxStates.map((isChecked, index) => (
                  <div key={index} className="adtype_labl_chbx_wrapper">
                    <label className="f_s_16 f_w_500">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      &nbsp;&nbsp;
                      {checkboxLabels[index]}
                    </label>
                  </div>
                ))}
              </div>

              <div className="choose_plan_btn_wrapper">
                <button
                  className={
                    props.selectedPlan || props.comingSoon
                      ? "choose_plan_btn_selected"
                      : "choose_plan_btn"
                  }
                  onClick={() => {
                    if (!miniQtyError) {
                      props.chooseOnClick();
                    }
                  }}
                >
                  {props.selectedPlan && !props.comingSoon ? (
                    <>
                      <span className="f_s_16 f_w_500 white_text">
                        Selected
                      </span>{" "}
                      &nbsp;
                      <img className="" src={check} alt="check" />
                    </>
                  ) : props.comingSoon ? (
                    "Coming Soon"
                  ) : (
                    "Choose Plan"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BusinessCheckboxQtyCard;
