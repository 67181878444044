import React from "react";

import "./CardWrapper.css";

const CardWrapper = (props) => {
  let classes = `custom_card_comp ${props.className}`;
  return (
    <div className={classes} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default CardWrapper;
