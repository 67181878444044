import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import "./ToggleBusinessPlan.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../../store/campaign-slice";
import { useEffect } from "react";

const ToggleBusinessPlan = (props) => {
  const dispatch = useDispatch();
  // const [campaignType, setCampaignType] = useState("Quantity");
  const [selectedSize, setSelectedSize] = useState("A5");
  const [selectedSide, setSelectedSide] = useState("Single");
  const [selectedQuality, setSelectedQuality] = useState("130GSM");
  const [footerSize, setFooterSize] = useState("1cm");

  const [pamphletQty, setPamphletQty] = useState(props.quantityArr?.[0]);

  let inputObj = {
    // campaignType: campaignType,
    pamphletSize: selectedSize,
    pamphletSide: selectedSide,
    pamphletQuality: selectedQuality,
    pamphletQty: pamphletQty,
    footerSize: footerSize,
    displayAdSize: "l1",
    labelAdSize: "1",
  };
  useEffect(() => {
    dispatch(campaignActions.setAdTypeData({ adTypeData: inputObj }));
  }, [
    selectedSize,
    selectedSide,
    selectedQuality,
    pamphletQty,
    footerSize,
    // campaignType,
  ]);

  // const handleSizeChange = (event, size) => {
  //   setSelectedSize(size);
  // };

  // const handleSideChange = (event, side) => {
  //   setSelectedSide(side);
  // };

  // const footerSizeChange = (event, size) => {
  //   setFooterSize(size);
  // };

  // const handleQualityChange = (event, quality) => {
  //   setSelectedQuality(quality);
  // };
  return (
    <>
      <div className="business_toggle_btn_card_main_div">
        {/* <div className="business_toggle_sub_div_footer ">
          <div className="size_toggle_wrapper">
            <div className="sub_title_child m_t_0_5">Campaign Type</div>
            <ToggleButtonGroup
              color="primary"
              value={campaignType}
              exclusive
              // onChange={handleQualityChange}
              aria-label="Platform"
              sx={{
                // width: "180px",
                color: "#43ba56",
                border: "2px solid rgba(217, 217, 217, 1)",
                borderRadius: "30px",
                padding: "3px",
                margin: "0.5rem",
              }}
              className="business_toggle_btn_grp"
            >
              <ToggleButton
                className={
                  campaignType === "Quantity"
                    ? "selected-toggle"
                    : "unselected-toggle"
                }
                value="Quantity"
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "none",
                  // backgroundColor: "rgba(243, 243, 243, 1)",
                }}
                onClick={() => {
                  setCampaignType("Quantity");
                }}
              >
                Quantity
              </ToggleButton>

              <ToggleButton
                className={
                  campaignType === "Budget"
                    ? "selected-toggle"
                    : "unselected-toggle"
                }
                value="Budget"
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "none",
                  // backgroundColor: "rgba(243, 243, 243, 1)",
                }}
                onClick={() => {
                  setCampaignType("Budget");
                }}
              >
                Budget
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div> */}
        <div className="business_toggle_sub_div1">
          <div className="sub_title text_flex_start">Pamphlet Details</div>
          <div className="business_size_side_toggle_wrapper">
            <div className="size_toggle_wrapper m_t_1">
              <div className="sub_title_child">Pamphlet Size</div>
              <ToggleButtonGroup
                value={selectedSize}
                exclusive
                // onChange={handleSizeChange}
                aria-label="Platform"
                sx={{
                  // width: "180px",
                  color: "#43ba56",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "30px",
                  padding: "3px",
                  margin: "0.5rem",
                }}
                className="business_toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    selectedSize === "A5"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="A5"
                  sx={{
                    fontWeight: 700,
                    fontSize: "15px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedSize("A5");
                  }}
                >
                  A5
                </ToggleButton>
                <ToggleButton
                  className={
                    selectedSize === "A4"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="A4"
                  sx={{
                    fontWeight: 700,
                    fontSize: "15px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedSize("A4");
                    setSelectedSide("Double");
                  }}
                >
                  A4
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div className="size_toggle_wrapper m_t_1">
              <div className="sub_title_child">Printing Side</div>
              <ToggleButtonGroup
                color="primary"
                value={selectedSide}
                exclusive
                // onChange={handleSideChange}
                aria-label="Platform"
                sx={{
                  // width: "180px",
                  color: "#43ba56",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "30px",
                  padding: "3px",
                  margin: "0.5rem",
                }}
                className="business_toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    selectedSide === "Single"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="Single"
                  sx={{
                    width: 120,
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    if (selectedSize === "A5") {
                      setSelectedSide("Single");
                    }
                  }}
                >
                  Single
                </ToggleButton>
                <ToggleButton
                  className={
                    selectedSide === "Double"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="Double"
                  sx={{
                    width: 120,
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedSide("Double");
                  }}
                >
                  Double
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            {/* <div className="size_toggle_wrapper m_t_1 m_b_1">
              <div className="sub_title_child">Paper Quality</div>
              <ToggleButtonGroup
                color="primary"
                value={selectedQuality}
                exclusive
                // onChange={handleQualityChange}
                aria-label="Platform"
                sx={{
                  // width: "180px",
                  color: "#43ba56",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "8px",
                  padding: "3px",
                  margin: "0.5rem",
                }}
                className="business_toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    selectedQuality === "90GSM"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="90GSM"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedQuality("90GSM");
                  }}
                >
                  90 GSM
                </ToggleButton>
                <ToggleButton
                  className={
                    selectedQuality === "130GSM"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="130GSM"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedQuality("130GSM");
                  }}
                >
                  130GSM
                </ToggleButton>
              </ToggleButtonGroup>
            </div> */}
          </div>
        </div>

        {props.isFooter && (
          <div className="business_toggle_sub_div_footer ">
            <div className="sub_title text_flex_start">Footer Details</div>

            <div className="size_toggle_wrapper">
              <div className="sub_title_child m_t_0_5">Size</div>
              <ToggleButtonGroup
                color="primary"
                value={footerSize}
                exclusive
                // onChange={handleQualityChange}
                aria-label="Platform"
                sx={{
                  // width: "180px",
                  color: "#43ba56",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "30px",
                  padding: "3px",
                  margin: "0.5rem",
                }}
                className="business_toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    footerSize === "1cm"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="1CM"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setFooterSize("1cm");
                  }}
                >
                  1CM
                </ToggleButton>
                <ToggleButton
                  className={
                    footerSize === "2cm"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="2CM"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setFooterSize("2cm");
                  }}
                >
                  2CM
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ToggleBusinessPlan;
