import React, { useState } from "react";
import CardWrapper from "../CardWrapper/CardWrapper";

import ElevatedCard from "../ElevatedCard/ElevatedCard";
import Input from "../Inputs/Input";
import "./CustomBudgetCard.css";
import filledCheck from "./filledCheck.svg";
import check from "./check.svg";

const CustomBudgetCard = (props) => {
  return (
    <ElevatedCard
      onClick={props.onClick}
      className={`custom_budget_card_main_div ${
        props.isChecked ? "elevated_card_selected" : ""
      }`}
    >
      <div className="custom_budget_card_main_div_div1" onClick={props.onClick}>
        <div className="sub_title_distribution">
          {/* Enter your budget for  */}
          Pamphlet Quantity
        </div>
      </div>
      <div className="custom_budget_card_main_div_div2">
        <CardWrapper className="buget_card_info" onClick={props.onClick}>
          {/* <div className="buget_card_info_div1">
            <div className="sub_title txt_center">Pamphlet quantity</div>
            <div className="large_num step_2_card_number">
              {props.qty !== null ? props.qty : 0}
            </div>
          </div> */}
          <div className="buget_card_info_div2">
            <div className="sub_title_price">Price : ₹ &nbsp;{props.price}</div>
            <div className="sub_title_side">
              {props.size} - {props.side}
            </div>
          </div>
          <div className="buget_card_info_div3">
            <div className="img_flex">
              {props.isChecked ? <img src={check} alt="check"></img> : ""}
            </div>
          </div>
        </CardWrapper>
        <div className="buget_card_input_div">
          <div className="w_100">
            <Input
              inputClasses="cust-qty-input"
              label=""
              placeholder="0 0 0 0"
              className=""
              onClick={props.onClick}
              onChange={props.setPrice}
              value={props.qty ? props.qty : undefined}
              type="number"
            />
          </div>
          <div className="w_100 input_status_div">
            <div className="w_90 body_title text_red">
              {props.qtyAlert && <>Quantity must be above {props.minQty}</>}
              {props.maxQtyReached && (
                <>Quantity must be below {props.maxQty}</>
              )}
            </div>
            <div className="filled_check_img">
              {/* <img src={filledCheck} alt="filledCheck"></img> */}
            </div>
          </div>
        </div>
      </div>
    </ElevatedCard>
  );
};

export default CustomBudgetCard;
