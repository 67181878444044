import { useState } from "react";
import { API_URL } from "../../../../../constant/apiURL";
import "./BusinessPreviewPage.css";
import {
  callIcon,
  mailIcon,
  portalIcon,
  locationIcon,
  clockIcon,
  instaLogo,
  facebookLogo,
} from "../../../../../assets";
import demoLogo from "./demo_logo.svg";
const BusinessPreviewPage = (props) => {
  const isLiveUrl = API_URL === "https://service.ewayadz.com/api/";

  const isLivePath = isLiveUrl
    ? "https://service.ewayadz.com/"
    : "http://192.168.0.48:7070/";
  const path = isLivePath + "media/";
  // console.log(path, "path");

  // console.log(props.response);
  const array2 = props.response && JSON.parse(props.response?.qr_code_url);
  // console.log(array2, "array2");
  const businessName = array2.businessName;
  const description = array2.description;
  const phoneNo = array2.phoneNo;
  const email = array2.email;
  const storeAddress = array2.storeAddress;
  const openingTime = array2.openingTime;
  const closingTime = array2.closingTime;
  const website = array2.website;

  const logo = path + props.response?.qr_code_business_images;
  //   console.log(array2);
  // console.log(logo, "logo");
  //   const array = array2?.urls;

  return (
    <>
      <div className="multi_url_preview_main_div">
        <div className="multi_url_preview_sub_div">
          {/* <div
            style={{ marginTop: "-1rem" }}
            className="green_background"
          ></div> */}
          <div style={{ marginTop: "1rem" }}>
            <img
              className="multi_url_logo_preview"
              src={logo}
              alt="ImagePreview"
            />
          </div>
          <div className="multi_url_business_name">{businessName}</div>
          <div
            style={{ width: "300px" }}
            className="business_description_preview_guide_text"
          >
            {description}
          </div>
          <div className="multi_urls_tabs_card">
            <div className="multi_urls_tabs_card_sub_div">
              <div className="multi_urls_tabs_card_sub_div_child">
                <div className="contact_details_text ">Contact Details</div>
                <div
                  style={{ marginTop: "0.5rem" }}
                  className="sub_title_child pointer"
                  onClick={() => {
                    const toPhone = `tel:${phoneNo}`;

                    // Use window.location to navigate to the mailto URL.
                    window.location.href = toPhone;
                  }}
                >
                  <span>
                    <img src={callIcon} alt="callIcon" />
                  </span>
                  &nbsp;&nbsp;&nbsp; {phoneNo}
                </div>
                {email && (
                  <div
                    className="sub_title_child pointer"
                    onClick={() => {
                      const mailtoURL = `mailto:${email}`;

                      // Use window.location to navigate to the mailto URL.
                      window.location.href = mailtoURL;
                    }}
                  >
                    <span>
                      <img src={mailIcon} alt="mailIcon" />
                    </span>
                    &nbsp;&nbsp; {email}
                  </div>
                )}
                {website && (
                  <>
                    <div
                      style={{ marginTop: "1rem" }}
                      className="contact_details_text "
                    >
                      Website
                    </div>
                    <div
                      style={{ marginTop: "0.5rem" }}
                      className="sub_title_child pointer"
                      onClick={() => {
                        window.location.href = website;
                      }}
                    >
                      <span>
                        <img src={portalIcon} alt="portalIcon" />
                      </span>
                      &nbsp;&nbsp;&nbsp;{website}
                    </div>
                  </>
                )}
                <div
                  style={{ marginTop: "1rem" }}
                  className="contact_details_text "
                >
                  Address
                </div>
                <div
                  style={{ marginTop: "0.5rem" }}
                  className="sub_title_child"
                >
                  <span>
                    <img src={locationIcon} alt="locationIcon" />
                  </span>
                  &nbsp;&nbsp;&nbsp;{storeAddress}
                </div>
                {openingTime && closingTime && (
                  <>
                    <div
                      style={{ marginTop: "1rem" }}
                      className="contact_details_text "
                    >
                      Opening Hours
                    </div>
                    <div
                      style={{ marginTop: "0.5rem" }}
                      className="sub_title_child"
                    >
                      <span>
                        <img src={clockIcon} alt="locationIcon" />
                      </span>
                      {openingTime} to {closingTime}
                    </div>
                  </>
                )}
                {(array2?.url1 || array2?.url2) && (
                  <>
                    <div
                      style={{ marginTop: "1rem" }}
                      className="contact_details_text "
                    >
                      Follow Us On
                    </div>
                    <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      {array2?.url1 && (
                        <img
                          className="pointer"
                          src={instaLogo}
                          alt="instaLogo"
                          style={{ marginRight: "1rem" }}
                          onClick={() => {
                            window.location.href = array2?.url1;
                          }}
                        />
                      )}
                      {array2?.url2 && (
                        <img
                          className="pointer"
                          src={facebookLogo}
                          alt="facebookLogo"
                          onClick={() => {
                            window.location.href = array2?.url2;
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BusinessPreviewPage;
