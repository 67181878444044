import { arrowBack } from "../../../assets";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import "./BasicPlanNewDetail.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import CampaignInput from "../CampaignInput/CampaignInput";
import PamphletFileInput from "../PamphletFileInput/PamphletFileInput";
import CampaignReview from "../CampaignReview/CampaignReview";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { API_URL } from "../../../constant/apiURL";
import { useState } from "react";
import { campaignActions } from "../../../store/campaign-slice";
import { Grid } from "@mui/material";
import PaymentModel from "../../../components/Accounts/PaymentModel";
import QrCodeCreater from "../../NewFlowOfCampaign/QrCodeCreater/QrCodeCreater";
import CreateQrCode from "../../NewFlowOfCampaign/CreateQrCode/CreateQrCode";

const BasicPlanNewDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const paymentModalRef = React.useRef();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);
  const clientId = useSelector((state) => state.auth.clientId);
  const campaignDetails = useSelector(
    (state) => state.campaigns.campaignDetails
  );
  const [campaignId, setCampaignId] = useState();

  const pamphlet_id = location?.state.pamphlet_id;

  const roundedQty = Math.round(location.state.qty);
  const recBgtQty = location?.state?.recBgtQty;

  const budget = location?.state?.budget;

  const [locationList, setLocationList] = useState();
  let pinCodesCSV =
    locationList &&
    locationList.map((l) => l.pin_code !== "All" && l.pin_code).join(" ,");

  // validations
  const [campaignDetailsError, setCampaignDetailsError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [pamphletFileError, setPamphletFileError] = useState(false);

  //qr code data
  const [qrCodeId, setQrCodeId] = useState();
  const [qrFile, setQrFile] = useState();
  const [isQrVisible, setIsQrVisible] = useState(false);
  const [qrCodeCreatedCard, setQrCodeCreatedCard] = useState(false);
  const [selectedCardName, setSelectedCardName] = useState(null);
  const [qrCodeId2, setQrCodeId2] = useState(null);

  const [url, setUrl] = useState(false);
  const [isCheckedUrlEmpty, setIsCheckedUrlEmpty] = useState(false);

  const [pamphletFile1, setPamphletFile1] = useState("");
  const [pamphletFile2, setPamphletFile2] = useState("");

  const [sizeOfPamphlet, setSizeOfPamphlet] = useState();
  const [isDuplex, setIsDuplex] = useState();
  const [pamphletType, setPamphletType] = useState("");
  const side = isDuplex ? "Double Side" : "Single Side";
  const [activeStep, setActiveStep] = React.useState(1);

  // states for coupon code
  const [coupon, setCoupon] = useState("");

  const [couponId, setCouponId] = useState();
  const [couponError, setCouponError] = useState(false);
  const [couponMsg, setCouponMsg] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  // amount calculation
  let totalAmountWithDiscount = budget - discount;

  const tax = 5;
  let payableTaxAmount = +totalAmountWithDiscount * tax * 0.01;
  // let totalAmountToPay = Math.floor(+props.budget + +payableTaxAmount);

  let total = budget - discount + payableTaxAmount;

  const handleNext = () => {
    if (activeStep === 1) {
      if (
        campaignDetails.campaignName === "" ||
        campaignDetails.startOn === "" ||
        campaignDetails.industry === ""
      ) {
        setCampaignDetailsError(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (isDuplex && activeStep === 3 && selectedOption === "yes") {
      if (activeStep === 3 && pamphletFile1 === "" && pamphletFile2 === "") {
        setPamphletFileError(true);
      }
      if (
        activeStep === 3 &&
        pamphletFile1 !== "" &&
        pamphletFile2 !== "" &&
        selectedOption === "yes"
      ) {
        updatePamphlet();
        setPamphletFileError(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (activeStep === 3 && pamphletFile1 === "" && selectedOption === "yes") {
      setPamphletFileError(true);
    }
    if (
      activeStep === 3 &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      selectedOption === "yes"
    ) {
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === 3 && selectedOption === "no") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === 2) {
      if (!qrCodeCreatedCard) {
        setIsQrVisible(true);
      }

      if (qrCodeCreatedCard) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        updateQrCodeHandler();
      }
    }

    if (activeStep === 4) {
      if (qrCodeCreatedCard && campaignId && isCouponApplied) {
        updateCampaignApi();
      }
      if (!qrCodeCreatedCard && !campaignId) {
        submitCampaignCreate();
      }
      submitHandler();
    }
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // pamphlet detail api
  useEffect(() => {
    // Pamphlet Detail api
    fetch(API_URL + `campaign/pamphlet-detail/${pamphlet_id && pamphlet_id}/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSizeOfPamphlet(data.size);
        setIsDuplex(data.is_duplex);
        setPamphletType(data.pamphlet_type);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // location list api
    fetch(API_URL + "campaign/locations-list/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLocationList(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // pamphlet update api
  const updatePamphlet = () => {
    const formData = new FormData();
    formData.append("pamphlet_file1", pamphletFile1 ? pamphletFile1 : "");
    if (isDuplex) {
      formData.append("pamphlet_file2", pamphletFile2 ? pamphletFile2 : "");
    }
    formData.append("pamphlet_type", pamphletType);
    formData.append("client", clientId);
    formData.append("is_duplex", isDuplex);

    fetch(`${API_URL}campaign/pamphlet-update/${pamphlet_id && pamphlet_id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // update qr code api
  const updateQrCodeHandler = () => {
    const formData = new FormData();
    formData.append("file", qrFile);
    fetch(API_URL + `campaign/qr-code-update/${qrCodeId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        // setIsLoading(false);

        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            // console.log("pamphlet updated");
            // history.goBack();
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // campaign create api
  const submitCampaignCreate = (id) => {
    setQrCodeId(id);
    const formData = new FormData();
    formData.append("name", campaignDetails.campaignName);
    formData.append("start_on", campaignDetails.startOn);
    formData.append("sub_industry", campaignDetails.industry);
    formData.append("campaign_type", "quantity");
    formData.append("pamphlet_id", pamphlet_id && pamphlet_id);
    formData.append("amount", isCouponApplied ? budget - discount : budget);
    formData.append("budget_amount", budget);
    formData.append("qty_for_pamphlet", roundedQty);
    formData.append("pin_codes", pinCodesCSV);
    formData.append("coupon", coupon?.toUpperCase());
    if (id) {
      formData.append("qr_code_id", id);
    }
    fetch(API_URL + "campaign/create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setCampaignId(data.id);
            dispatch(campaignActions.setCampaignId({ campaignId: data.id }));
            dispatch(campaignActions.updateCampaignList({ campaign: data }));
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // Update Campaign api
  const updateCampaignApi = () => {
    const formData = new FormData();
    formData.append("coupon", couponId);
    formData.append("budget_amount", budget);
    formData.append("amount", budget - discount);
    formData.append("campaign_type", "quantity");
    fetch(API_URL + `campaign/update/${campaignId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            setCampaignId(data.id);
            dispatch(campaignActions.setCampaignId({ campaignId: data.id }));
            dispatch(campaignActions.updateCampaignList({ campaign: data }));
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // coupon code api
  const applyCoupon = async () => {
    // console.log(coupon)
    const formData = new FormData();
    formData.append("coupon_code", coupon?.toUpperCase());
    formData.append("quantity", roundedQty);
    const res = await fetch(API_URL + "campaign/apply-coupen-code/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    });
    // console.log(res);
    if (res.ok) {
      const data = await res.json();
      // console.log(data);
      setCouponId(data.id);
      if (data.lumpsum_discount === 0) {
        const discountPercent = data.discount;

        const discountAmount = ((+budget * discountPercent) / 100).toFixed(0);
        setDiscount(discountAmount);
      } else {
        if (+budget > data.lumpsum_discount) {
          setDiscount(data.lumpsum_discount);
        }
      }
    }
    if (res.ok) {
      setCouponMsg("Coupon Code Applied Successfully");
      setIsCouponApplied(true);
      setCouponError(false);
    } else if (!res.ok) {
      setCouponError(true);
      setIsCouponApplied(false);
      setCouponMsg("Coupon Code Invalid");
    }
    // console.log(couponMsg);
  };

  const submitHandler = (event) => {
    paymentModalRef.current.openModal();
  };

  const steps = [
    {
      label: `Plan ${sizeOfPamphlet} ${side} Pamphlet`,
    },
    {
      label: "Create Your Pamphlet Campaign",
    },
    {
      label: "QR Code (optional)",
    },
    {
      label: "Pamphlet Design File",
    },

    {
      label: "Your Campaign Details",
    },
  ];
  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />
      {!isQrVisible && (
        <>
          <button
            className="back_btn_basic_plan"
            onClick={() => {
              history.goBack();
            }}
          >
            <span>
              <img src={arrowBack} alt="arrowLeft" />
            </span>
            &nbsp; Back
          </button>
          <div className="basic_plan_detail_main_div">
            <div className="basic_plan_detail_sub_div">
              <Box className="stepper_main_div">
                <Stepper
                  className="stepper_sub_div"
                  activeStep={activeStep}
                  orientation="vertical"
                >
                  {steps.map((step, index) => (
                    <Step
                      key={index}
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "rgba(49, 185, 99, 1)", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                          {
                            color: "rgba(49, 185, 99, 0.7)", // Just text label (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "rgba(49, 185, 99, 1)", // circle color (ACTIVE)
                          fontSize: 20,
                        },
                        "& .MuiStepLabel-root .MuiStepIcon-root .Mui-active": {
                          radius: 76,
                        },
                        // "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                        //   color: "common.white", // Just text label (ACTIVE)
                        // },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fontSize: 17, // circle's number (ACTIVE)
                        },
                      }}
                    >
                      <StepLabel>{step.label}</StepLabel>
                      <StepContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        {activeStep === 1 && (
                          <CampaignInput
                            campaignDetailsError={campaignDetailsError}
                          />
                        )}
                        {activeStep === 2 && (
                          <>
                            {/* {!qrCodeCreatedCard && ( */}
                            {!qrCodeCreatedCard && (
                              <div className="qr_code_text_stepper">
                                When this QR is scanned, potential customers can
                                be directed to your website, social media
                                accounts, or even a promotional video.
                              </div>
                            )}

                            {qrCodeCreatedCard && (
                              <div className="payment_summary_main_div">
                                <div className="payment_summary_sub_div">
                                  <div className="plan_details_text m_l_2">
                                    QR Code Created
                                  </div>
                                  <div className="qr_code_card">
                                    <CreateQrCode
                                      setQrFile={setQrFile}
                                      setUrl={setUrl}
                                      campaignId={campaignId && campaignId}
                                      isCheckedUrlEmpty={isCheckedUrlEmpty}
                                      selectedCardName={selectedCardName}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* )} */}
                          </>
                        )}
                        {activeStep === 3 && (
                          <PamphletFileInput
                            setPamphletFile1={setPamphletFile1}
                            setPamphletFile2={setPamphletFile2}
                            isDuplex={isDuplex}
                            setSelectedOption={setSelectedOption}
                            pamphletFileError={pamphletFileError}
                            pamphletId={pamphlet_id}
                          />
                        )}

                        {activeStep === 4 && (
                          <CampaignReview
                            campaignDetails={campaignDetails}
                            sizeOfPamphlet={sizeOfPamphlet}
                            side={side}
                            pamphQty={roundedQty}
                            budget={budget}
                            pamphletFile1={pamphletFile1}
                            pamphletFile2={pamphletFile2}
                            selectedOption={selectedOption}
                            isDuplex={isDuplex}
                            // for qr code
                            qrCodeCreatedCard={qrCodeCreatedCard}
                            setUrl={setUrl}
                            campaignId={campaignId}
                            isCheckedUrlEmpty={isCheckedUrlEmpty}
                            selectedCardName={selectedCardName}
                            //for coupon code
                            applyCoupon={applyCoupon}
                            setCoupon={setCoupon}
                            coupon={coupon}
                            couponError={couponError}
                            couponMsg={couponMsg}
                            discount={discount}
                            setDiscount={setDiscount}
                            setIsCouponApplied={setIsCouponApplied}
                            isCouponApplied={isCouponApplied}
                          />
                        )}
                        <Box sx={{ mt: 2, flex: "1 0 auto" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {activeStep === 2 && !qrCodeCreatedCard && (
                              <Button
                                disabled={index === 0}
                                onClick={handleSkip}
                                sx={{
                                  mt: 1,
                                  ml: 1,
                                  backgroundColor: "#eee",
                                  color: "rgb(0,0,0)",
                                  borderRadius: "44px",
                                  "&:hover": {
                                    backgroundColor: "#eee",
                                    color: "rgb(0,0,0)",
                                  },
                                }}
                              >
                                Skip
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              // color="primary" // Set the color to 'primary'
                              sx={{
                                mt: 1,
                                ml: 1,
                                backgroundColor: "#199647",
                                borderRadius: "44px",
                                "&:hover": {
                                  backgroundColor: "#199647",
                                },
                              }}
                            >
                              {activeStep === 2 && !qrCodeCreatedCard
                                ? "Create Qr Code"
                                : "Continue"}
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Grid item>
                <PaymentModel
                  budget={budget}
                  payableTaxAmount={payableTaxAmount}
                  ref={paymentModalRef}
                  amount={total}
                  discount={isCouponApplied ? discount : 0}
                  createdCampaignId={campaignId}
                  isCreated={false}
                  campaignId={null}
                />
              </Grid>
            </div>
          </div>
        </>
      )}
      {isQrVisible && (
        <QrCodeCreater
          qrFile={qrFile}
          setIsQrVisible={setIsQrVisible}
          setQrCodeCreatedCard={setQrCodeCreatedCard}
          campaignId={campaignId}
          setSelectedCardName={setSelectedCardName}
          setQrCodeId2={setQrCodeId2}
          submitCampaignCreate={submitCampaignCreate}
        />
      )}
    </>
  );
};
export default BasicPlanNewDetail;
