import { Password } from "@mui/icons-material";
import { Avatar, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AuthLayout from "../../components/AuthComponents/AuthLayout";
import classes from "../../components/AuthComponents/AuthLayout.module.css";
import { API_URL } from "../../constant/apiURL";
// import { authActions } from "../../store/auth-slice";

function ChangePassword() {
  const oldPasswordInpRef = useRef();
  const password1InpRef = useRef();
  const password2InpRef = useRef();

  const history = useHistory();

  // const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();

    const enteredOldOtp = oldPasswordInpRef.current.value;
    const password = password1InpRef.current.value;
    const password2 = password2InpRef.current.value;

    setIsLoading(true);

    fetch(API_URL + "accounts/change-password/", {
      method: "PUT",
      body: JSON.stringify({
        old_password: enteredOldOtp,
        password: password,
        password2: password2,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        return res.json().then((data) => {
          // console.log(data);
          let successMsg = "Successfully updated your password ";
          //   for (let key in data) {
          //     successMsg = data[key];
          //     console.log(key + ":" + data[key]);
          //   }
          alert(successMsg);
          history.replace("/");
        });
      } else {
        // console.log(res);
        return res.json().then((data) => {
          // console.log(data);
          let errorMsg = "Authentication Failed!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(key + ":" + data[key]);
          }
          alert(errorMsg);
        });
      }
    });
  };

  return (
    <>
      <AuthLayout>
        <Grid align="center" mb={3}>
          <Avatar className={classes.titleIcon}>
            <Password />
          </Avatar>
          <Typography variant="h5">Change Password</Typography>
        </Grid>
        <form onSubmit={submitHandler}>
          <TextField
            inputRef={oldPasswordInpRef}
            sx={{ mb: 3 }}
            label="Old password"
            type="text"
            variant="outlined"
            placeholder="Enter OTP"
            fullWidth
            required
          />
          <TextField
            inputRef={password1InpRef}
            sx={{ mb: 3 }}
            label="Password"
            type="password"
            variant="outlined"
            placeholder="Enter new Password"
            fullWidth
            required
          />
          <TextField
            inputRef={password2InpRef}
            sx={{ mb: 3 }}
            label="Confirm Password"
            type="password"
            variant="outlined"
            placeholder="Enter new Password"
            fullWidth
            required
          />
          <Button
            disabled={isLoading}
            sx={{ mb: 5 }}
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
          >
            {isLoading ? "Loading..." : "Verify"}
          </Button>
        </form>
      </AuthLayout>
    </>
  );
}

export default ChangePassword;
