import { arrowBack } from "../../../assets";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import CampaignInput from "../CampaignInput/CampaignInput";
import PamphletFileInput from "../PamphletFileInput/PamphletFileInput";
import CampaignReview from "../CampaignReview/CampaignReview";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { API_URL } from "../../../constant/apiURL";
import { useState } from "react";
import { campaignActions } from "../../../store/campaign-slice";
import { Grid } from "@mui/material";
import PaymentModel from "../../../components/Accounts/PaymentModel";
import QrCodeCreater from "../../NewFlowOfCampaign/QrCodeCreater/QrCodeCreater";
import CreateQrCode from "../../NewFlowOfCampaign/CreateQrCode/CreateQrCode";

const BusinessPlanPageDetails = () => {
  const history = useHistory();
  const location = useLocation();

  const paymentModalRef = React.useRef();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);
  const clientId = useSelector((state) => state.auth.clientId);
  const campaignDetails = useSelector(
    (state) => state.campaigns.campaignDetails
  );
  const [campaignId, setCampaignId] = useState();

  const pamphletId = useSelector((state) => state.campaigns.pamphletId);
  const footerId = useSelector((state) => state.campaigns.footerId);
  const displayAdId = useSelector((state) => state.campaigns.displayAdId);
  const labelAdId = useSelector((state) => state.campaigns.labelAdId);

  const [voucherId, setVoucherId] = useState();

  // ad type data

  let isDuplex = location?.state?.isDuplex;
  let pamphSize = location?.state?.pamphSize;
  let codeOfPamph = location?.state?.codeOfPamph;
  const side = isDuplex ? "Double Side" : "Single Side";
  let pamphQlty = location?.state?.pamphQlty;
  let ftrSize = location?.state?.ftrSize;
  let displayAdSize = location?.state?.displayAdSize;
  let labelAdSize = location?.state?.labelAdSize;
  let isLocation = location?.state?.isLocation;
  let isVoucher = location?.state?.isVoucher;
  let isQr = location?.state?.isQr;
  let campaignType = location?.state?.campaignType;

  // quantity of ad types useLocation
  let pamphQty = location?.state?.pamphQty;
  let ftrQty = location?.state?.ftrQty;
  let dispQty = location?.state?.dispQty;
  let labelQty = location?.state?.labelQty;

  // budget
  let customBgt = location?.state?.customBgt;
  let recBgt = location?.state?.recBgt;
  let isCtmBgt = location?.state?.isCtmBgt;
  const budget = isCtmBgt ? customBgt : recBgt;

  const [locationList, setLocationList] = useState();

  let pinCodesCSV =
    locationList &&
    locationList.map((l) => l.pin_code !== "All" && l.pin_code).join(" ,");

  let pinCodesArray = campaignDetails?.pinCodes?.map((l) => l.pin_code);
  let selectedPinCodesCsv = pinCodesArray?.join(",");

  // validations
  const [campaignDetailsError, setCampaignDetailsError] = useState(false);
  const [voucherDetailsError, setVoucherDetailsError] = useState(false);
  const [locationDetailsError, setLocationDetailsError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [pamphletFileError, setPamphletFileError] = useState(false);
  const [isContactNoValid, setIsContactNoValid] = useState(false);

  //qr code data
  const [qrCodeId, setQrCodeId] = useState();
  const [qrFile, setQrFile] = useState();
  const [isQrVisible, setIsQrVisible] = useState(false);
  const [qrCodeCreatedCard, setQrCodeCreatedCard] = useState(false);
  const [selectedCardName, setSelectedCardName] = useState(null);
  const [qrCodeId2, setQrCodeId2] = useState(null);

  const [url, setUrl] = useState(false);
  const [isCheckedUrlEmpty, setIsCheckedUrlEmpty] = useState(false);

  const [pamphletFile1, setPamphletFile1] = useState("");

  const [pamphletFile2, setPamphletFile2] = useState("");
  const [footerFile, setFooterFile] = useState("");
  const [displayAdFile, setDisplayAdFile] = useState("");
  const [labelAdFile, setLabelAdFile] = useState("");

  const [voucherFile, setVoucherFile] = useState("");

  const [activeStep, setActiveStep] = React.useState(0);

  // states for coupon code
  const [coupon, setCoupon] = useState("");
  const [couponId, setCouponId] = useState();
  const [couponError, setCouponError] = useState(false);
  const [couponMsg, setCouponMsg] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  // amount calculation
  let totalAmountWithDiscount = budget - discount;

  const tax = 5;
  let payableTaxAmount = +totalAmountWithDiscount * tax * 0.01;
  // let totalAmountToPay = Math.floor(+props.budget + +payableTaxAmount);

  let total = budget - discount + payableTaxAmount;

  const handleNext = () => {
    if (activeStep === 0 && !isVoucher && !isLocation) {
      if (
        campaignDetails.campaignName === "" ||
        campaignDetails.startOn === "" ||
        campaignDetails.industry === ""
      ) {
        setCampaignDetailsError(true);
      } else {
        setActiveStep((prevActiveStep) =>
          isQr ? prevActiveStep + 1 : prevActiveStep + 2
        );
      }
    }
    if (activeStep === 0 && isVoucher && !isLocation) {
      if (
        campaignDetails.campaignName === "" ||
        campaignDetails.startOn === "" ||
        campaignDetails.industry === "" ||
        campaignDetails.voucherName === "" ||
        campaignDetails.tagLine === "" ||
        campaignDetails.voucherFile === "" ||
        campaignDetails.contact === null
      ) {
        setVoucherDetailsError(true);
      } else {
        submitVoucherApi();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 0 && isVoucher && isLocation) {
      if (
        campaignDetails.campaignName === "" ||
        campaignDetails.startOn === "" ||
        campaignDetails.industry === "" ||
        campaignDetails.voucherName === "" ||
        campaignDetails.tagLine === "" ||
        campaignDetails.voucherFile === "" ||
        campaignDetails.contact === null ||
        campaignDetails.pinCodes === null
      ) {
        setLocationDetailsError(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        submitVoucherApi();
      }
    }

    if (activeStep === 1) {
      if (!qrCodeCreatedCard) {
        setIsQrVisible(true);
      }

      if (qrCodeCreatedCard) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        updateQrCodeHandler();
      }
    }

    // for update pamphlet duplex
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile2 !== "" &&
      pamphletFile1 !== "" &&
      pamphletId &&
      !footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for update pamphlet single
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      pamphletId &&
      !footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // pamphlet file duplex error

    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile2 !== "" &&
      pamphletFile1 !== "" &&
      pamphletId &&
      !footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // pamphlet file single error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 === "" &&
      pamphletId &&
      !footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for update footer
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      footerFile !== "" &&
      !pamphletId &&
      footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      updateFooter();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for footer error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      footerFile === "" &&
      !pamphletId &&
      footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for display Ad update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      displayAdFile !== "" &&
      !pamphletId &&
      !footerId &&
      displayAdId &&
      !labelAdId
    ) {
      updateDisplayAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for display ad error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      displayAdFile === "" &&
      !pamphletId &&
      !footerId &&
      displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for update labelAd
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      labelAdFile !== "" &&
      !pamphletId &&
      !footerId &&
      !displayAdId &&
      labelAdId
    ) {
      updateLabelAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for labelAd error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      labelAdFile === "" &&
      !pamphletId &&
      !footerId &&
      !displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for pamphlet duplex and footer update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 !== "" &&
      pamphletFile2 !== "" &&
      footerFile !== "" &&
      pamphletId &&
      footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      updatePamphlet();
      updateFooter();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for pamphlet duplex and footer error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 === "" &&
      pamphletFile2 === "" &&
      footerFile === "" &&
      pamphletId &&
      footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for pamphlet single and footer update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      footerFile !== "" &&
      pamphletId &&
      footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      updatePamphlet();
      updateFooter();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for pamphlet single and footer error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 === "" &&
      footerFile === "" &&
      pamphletId &&
      footerId &&
      !displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for update displayAd & label ad
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      labelAdFile !== "" &&
      displayAdFile !== "" &&
      !pamphletId &&
      !footerId &&
      displayAdId &&
      labelAdId
    ) {
      updateDisplayAd();
      updateLabelAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for displayAd & label ad error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      labelAdFile === "" &&
      displayAdFile === "" &&
      !pamphletId &&
      !footerId &&
      displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for footer & displayAd update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      footerFile !== "" &&
      displayAdFile !== "" &&
      !pamphletId &&
      footerId &&
      displayAdId &&
      !labelAdId
    ) {
      updateFooter();
      updateDisplayAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for footer & displayAd error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      footerFile === "" &&
      displayAdFile === "" &&
      !pamphletId &&
      footerId &&
      displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for pamphlet duplex & label Ad update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 !== "" &&
      pamphletFile2 !== "" &&
      labelAdFile !== "" &&
      pamphletId &&
      !footerId &&
      !displayAdId &&
      labelAdId
    ) {
      updatePamphlet();
      updateLabelAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for pamphlet duplex & label Ad error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 === "" &&
      pamphletFile2 === "" &&
      labelAdFile === "" &&
      pamphletId &&
      !footerId &&
      !displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for pamphlet single & label Ad update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      labelAdFile !== "" &&
      pamphletId &&
      !footerId &&
      !displayAdId &&
      labelAdId
    ) {
      updatePamphlet();
      updateLabelAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for pamphlet single & label Ad error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 === "" &&
      labelAdFile === "" &&
      pamphletId &&
      !footerId &&
      !displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for pamphlet single and display Ad update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      displayAdFile !== "" &&
      pamphletId &&
      !footerId &&
      displayAdId &&
      !labelAdId
    ) {
      updatePamphlet();
      updateDisplayAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for pamphlet single and display Ad update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 === "" &&
      displayAdFile === "" &&
      pamphletId &&
      !footerId &&
      displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for pamphlet duplex and display Ad update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 !== "" &&
      pamphletFile2 !== "" &&
      displayAdFile !== "" &&
      pamphletId &&
      !footerId &&
      displayAdId &&
      !labelAdId
    ) {
      updatePamphlet();
      updateDisplayAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for pamphlet duplex and display Ad error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 === "" &&
      pamphletFile2 === "" &&
      displayAdFile === "" &&
      pamphletId &&
      !footerId &&
      displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for footer and label ad update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      labelAdFile !== "" &&
      footerFile !== "" &&
      !pamphletId &&
      footerId &&
      !displayAdId &&
      labelAdId
    ) {
      updateFooter();
      updateLabelAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for footer and label ad update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      labelAdFile === "" &&
      footerFile === "" &&
      !pamphletId &&
      footerId &&
      !displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for footer , display Ad & pamphlet duplex update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 !== "" &&
      pamphletFile2 !== "" &&
      displayAdFile !== "" &&
      footerFile !== "" &&
      pamphletId &&
      footerId &&
      displayAdId &&
      !labelAdId
    ) {
      updateFooter();
      updateDisplayAd();
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for footer , display Ad & pamphlet duplex error
    if (
      activeStep === 2 &&
      selectedOption !== "yes" &&
      isDuplex &&
      pamphletFile1 === "" &&
      pamphletFile2 === "" &&
      displayAdFile === "" &&
      footerFile === "" &&
      pamphletId &&
      footerId &&
      displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for footer , display Ad & pamphlet single update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      displayAdFile !== "" &&
      footerFile !== "" &&
      pamphletId &&
      footerId &&
      displayAdId &&
      !labelAdId
    ) {
      updateFooter();
      updateDisplayAd();
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for footer , display Ad & pamphlet single error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 === "" &&
      displayAdFile === "" &&
      footerFile === "" &&
      pamphletId &&
      footerId &&
      displayAdId &&
      !labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for labelAd , display Ad & pamphlet duplex update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 !== "" &&
      pamphletFile2 !== "" &&
      displayAdFile !== "" &&
      labelAdFile !== "" &&
      pamphletId &&
      !footerId &&
      displayAdId &&
      labelAdId
    ) {
      updateLabelAd();
      updateDisplayAd();
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for labelAd , display Ad & pamphlet duplex error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 === "" &&
      pamphletFile2 === "" &&
      displayAdFile === "" &&
      labelAdFile === "" &&
      pamphletId &&
      !footerId &&
      displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for labelAd , display Ad & pamphlet single update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      displayAdFile !== "" &&
      labelAdFile !== "" &&
      pamphletId &&
      !footerId &&
      displayAdId &&
      labelAdId
    ) {
      updateLabelAd();
      updateDisplayAd();
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for labelAd , display Ad & pamphlet single error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 === "" &&
      displayAdFile === "" &&
      labelAdFile === "" &&
      pamphletId &&
      !footerId &&
      displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for labelAd ,  footer & pamphlet duplex update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 !== "" &&
      pamphletFile2 !== "" &&
      footerFile !== "" &&
      labelAdFile !== "" &&
      pamphletId &&
      footerId &&
      !displayAdId &&
      labelAdId
    ) {
      updateLabelAd();
      updateFooter();
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for labelAd , footer & pamphlet duplex error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 === "" &&
      pamphletFile2 === "" &&
      footerFile === "" &&
      labelAdFile === "" &&
      pamphletId &&
      footerId &&
      !displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for labelAd ,footer & pamphlet single update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      footerFile !== "" &&
      labelAdFile !== "" &&
      pamphletId &&
      footerId &&
      !displayAdId &&
      labelAdId
    ) {
      updateLabelAd();
      updateFooter();
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for labelAd ,footer & pamphlet single error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 === "" &&
      footerFile === "" &&
      labelAdFile === "" &&
      pamphletId &&
      footerId &&
      !displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for labelAd ,footer & displayAd  update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      displayAdFile !== "" &&
      footerFile !== "" &&
      labelAdFile !== "" &&
      !pamphletId &&
      footerId &&
      displayAdId &&
      labelAdId
    ) {
      updateLabelAd();
      updateFooter();
      updateDisplayAd();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for labelAd ,footer & displayAd  error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      displayAdFile === "" &&
      footerFile === "" &&
      labelAdFile === "" &&
      !pamphletId &&
      footerId &&
      displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for labelAd ,footer, displayAd & pamphlet Duplex  update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 !== "" &&
      pamphletFile2 !== "" &&
      displayAdFile !== "" &&
      footerFile !== "" &&
      labelAdFile !== "" &&
      pamphletId &&
      footerId &&
      displayAdId &&
      labelAdId
    ) {
      updateLabelAd();
      updateFooter();
      updateDisplayAd();
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for labelAd ,footer, displayAd & pamphlet Duplex error
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      isDuplex &&
      pamphletFile1 === "" &&
      pamphletFile2 === "" &&
      displayAdFile === "" &&
      footerFile === "" &&
      labelAdFile === "" &&
      pamphletId &&
      footerId &&
      displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    // for labelAd ,footer, displayAd & pamphlet single update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 !== "" &&
      displayAdFile !== "" &&
      footerFile !== "" &&
      labelAdFile !== "" &&
      pamphletId &&
      footerId &&
      displayAdId &&
      labelAdId
    ) {
      updateLabelAd();
      updateFooter();
      updateDisplayAd();
      updatePamphlet();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // for labelAd ,footer, displayAd & pamphlet single update
    if (
      activeStep === 2 &&
      selectedOption === "yes" &&
      !isDuplex &&
      pamphletFile1 === "" &&
      displayAdFile === "" &&
      footerFile === "" &&
      labelAdFile === "" &&
      pamphletId &&
      footerId &&
      displayAdId &&
      labelAdId
    ) {
      setPamphletFileError(true);
    }

    if (activeStep === 2 && selectedOption === "no") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === 3) {
      if (qrCodeCreatedCard && campaignId && isCouponApplied) {
        updateCampaignApi();
      }
      if (!qrCodeCreatedCard && !campaignId) {
        submitCampaignCreate();
      }
      submitHandler();
      dispatch(campaignActions.setPamphletId({ pamphletId: null }));
      dispatch(campaignActions.setFooterId({ footerId: null }));
      dispatch(campaignActions.setDisplayAdId({ displayAdId: null }));
      dispatch(campaignActions.setLabelAdId({ labelAdId: null }));
    }
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // pamphlet detail api
  useEffect(() => {
    // Pamphlet Detail api

    // location list api
    fetch(API_URL + "campaign/locations-list/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLocationList(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // pamphlet update api
  const updatePamphlet = () => {
    const formData = new FormData();
    formData.append("pamphlet_file1", pamphletFile1 ? pamphletFile1 : "");
    if (isDuplex) {
      formData.append("pamphlet_file2", pamphletFile2 ? pamphletFile2 : "");
    }
    formData.append("pamphlet_type", "UP");
    formData.append("client", clientId);
    formData.append("is_duplex", isDuplex);

    fetch(`${API_URL}campaign/pamphlet-update/${pamphletId && pamphletId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // footer update api
  const updateFooter = () => {
    const formData = new FormData();
    formData.append("footer_file", footerFile ? footerFile : "");
    formData.append("client", clientId);
    fetch(`${API_URL}campaign/footer-update/${footerId && footerId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // displayAd update api
  const updateDisplayAd = () => {
    const formData = new FormData();
    formData.append("display_ad_file", displayAdFile ? displayAdFile : "");
    formData.append("client", clientId);
    fetch(
      `${API_URL}campaign/display-ad-update/${displayAdId && displayAdId}/`,
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // displayAd update api
  const updateLabelAd = () => {
    const formData = new FormData();
    formData.append("file", labelAdFile ? labelAdFile : "");
    formData.append("client", clientId);
    fetch(`${API_URL}campaign/label-ad-update/${labelAdId && labelAdId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // update qr code api
  const updateQrCodeHandler = () => {
    const formData = new FormData();
    formData.append("file", qrFile);
    fetch(API_URL + `campaign/qr-code-update/${qrCodeId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        // setIsLoading(false);

        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            // console.log("pamphlet updated");
            // history.goBack();
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const submitVoucherApi = (e) => {
    const formData = new FormData();
    formData.append("voucher_name", campaignDetails?.voucherName);
    formData.append("tag_line", campaignDetails?.tagLine);
    formData.append("contact_number", campaignDetails?.contact);
    formData.append("image", voucherFile);

    fetch(API_URL + "campaign/voucher-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        // setIsLoading(false);

        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setVoucherId(data.id);
            // dispatch(campaignActions.setVoucherId({ voucherId: data.id }));
            // console.log("got data.id  " + data.id);
            // idOFVoucher.push({
            //   voucherId: data.id,
            // });
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            // console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // campaign create api
  const submitCampaignCreate = (id) => {
    setQrCodeId(id);
    const formData = new FormData();
    formData.append("name", campaignDetails.campaignName);
    formData.append("start_on", campaignDetails.startOn);
    formData.append("sub_industry", campaignDetails.industry);
    formData.append(
      "campaign_type",
      campaignType === "Budget" ? "budget" : "quantity"
    );

    formData.append("amount", isCouponApplied ? budget - discount : budget);
    formData.append("budget_amount", budget);
    if (pamphletId) {
      formData.append("pamphlet_id", pamphletId);
    }
    if (footerId) {
      formData.append("footer_id", footerId);
    }
    if (displayAdId) {
      formData.append("display_ad_id", displayAdId);
    }
    if (labelAdId) {
      formData.append("label_ad_id", labelAdId);
    }
    if (voucherId) {
      formData.append("voucher_id", voucherId);
    }

    if (pamphletId) {
      formData.append("qty_for_pamphlet", pamphQty);
    }
    if (footerId) {
      formData.append("qty_for_footer", ftrQty);
    }
    if (displayAdId) {
      formData.append("qty_for_display_ad", dispQty);
    }
    if (labelAdId) {
      formData.append("qty_for_label_ad", labelQty);
    }
    formData.append(
      "pin_codes",
      isLocation ? selectedPinCodesCsv : pinCodesCSV
    );
    formData.append("coupon", coupon?.toUpperCase());
    if (id) {
      formData.append("qr_code_id", id);
    }
    fetch(API_URL + "campaign/create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setCampaignId(data.id);
            dispatch(campaignActions.setCampaignId({ campaignId: data.id }));
            dispatch(campaignActions.updateCampaignList({ campaign: data }));
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // Update Campaign api
  const updateCampaignApi = () => {
    const formData = new FormData();
    formData.append("coupon", couponId);
    formData.append("amount", budget - discount);
    formData.append("budget_amount", budget);
    formData.append("campaign_type", "budget");

    fetch(API_URL + `campaign/update/${campaignId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            setCampaignId(data.id);
            dispatch(campaignActions.setCampaignId({ campaignId: data.id }));
            dispatch(campaignActions.updateCampaignList({ campaign: data }));
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // coupon code api
  const applyCoupon = async () => {
    // console.log(coupon)
    const formData = new FormData();
    formData.append("coupon_code", coupon?.toUpperCase());
    // formData.append("quantity", roundedQty);
    const res = await fetch(API_URL + "campaign/apply-coupen-code/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    });
    // console.log(res);
    if (res.ok) {
      const data = await res.json();
      // console.log(res);
      setCouponId(data.id);
      if (data.lumpsum_discount === 0) {
        const discountPercent = data.discount;

        const discountAmount = ((+budget * discountPercent) / 100).toFixed(0);
        setDiscount(discountAmount);
      } else {
        if (+budget > data.lumpsum_discount) {
          setDiscount(data.lumpsum_discount);
        }
      }
    }
    if (res.ok) {
      setCouponMsg("Coupon Code Applied Successfully");
      setIsCouponApplied(true);
      setCouponError(false);
    } else if (!res.ok) {
      setCouponError(true);
      setIsCouponApplied(false);
      setCouponMsg("Coupon Code Invalid");
    }
    // console.log(couponMsg);
  };

  const submitHandler = (event) => {
    paymentModalRef.current.openModal();
  };

  // let isPamphlet =
  //   pamphletId && footerId && !displayAdId && "Footer + Pamphlet";
  // let isDisplayAd =
  //   pamphletId && footerId && displayAdId && "Footer + Pamphlet + DisplayAd";
  // let isFooter = footerId && !pamphletId && !displayAdId && "Footer";

  // let firstStepToShow = isPamphlet || isDisplayAd || isFooter;

  const steps = [
    {
      label: `Create Campaign`,
    },
    {
      label: isQr ? "QR Code" : "QR Code Not Available",
    },
    {
      label: "File Upload",
    },
    {
      label: "Your Campaign Details",
    },
  ];
  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />
      {!isQrVisible && (
        <>
          <button
            className="back_btn_basic_plan"
            onClick={() => {
              history.goBack();
              dispatch(campaignActions.setPamphletId({ pamphletId: null }));
              dispatch(campaignActions.setFooterId({ footerId: null }));
              dispatch(campaignActions.setDisplayAdId({ displayAdId: null }));
              dispatch(campaignActions.setLabelAdId({ labelAdId: null }));
            }}
          >
            <span>
              <img src={arrowBack} alt="arrowLeft" />
            </span>
            &nbsp; Back
          </button>
          <div className="basic_plan_detail_main_div">
            <div className="basic_plan_detail_sub_div">
              <Box className="stepper_main_div">
                <Stepper
                  className="stepper_sub_div"
                  activeStep={activeStep}
                  orientation="vertical"
                >
                  {steps.map((step, index) => (
                    <Step
                      key={index}
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "rgba(49, 185, 99, 1)", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                          {
                            color: "rgba(49, 185, 99, 0.7)", // Just text label (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "rgba(49, 185, 99, 1)", // circle color (ACTIVE)
                          fontSize: 20,
                        },
                        "& .MuiStepLabel-root .MuiStepIcon-root .Mui-active": {
                          radius: 76,
                        },
                        // "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                        //   color: "common.white", // Just text label (ACTIVE)
                        // },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fontSize: 17, // circle's number (ACTIVE)
                        },
                      }}
                    >
                      <StepLabel>{step.label}</StepLabel>
                      <StepContent
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        {activeStep === 0 && (
                          <CampaignInput
                            campaignDetailsError={campaignDetailsError}
                            voucherDetailsError={voucherDetailsError}
                            locationDetailsError={locationDetailsError}
                            isContactNoValid={isContactNoValid}
                            isVoucher={isVoucher}
                            isLocation={isLocation}
                            locationList={locationList}
                            setVoucherFile={setVoucherFile}
                          />
                        )}
                        {activeStep === 1 && (
                          <>
                            {/* {!qrCodeCreatedCard && ( */}
                            {!qrCodeCreatedCard && (
                              <div className="qr_code_text_stepper">
                                When this QR is scanned, potential customers can
                                be directed to your website, social media
                                accounts, or even a promotional video.
                              </div>
                            )}

                            {qrCodeCreatedCard && (
                              <div className="payment_summary_main_div">
                                <div className="payment_summary_sub_div">
                                  <div className="plan_details_text m_l_2">
                                    QR Code Created
                                  </div>
                                  <div className="qr_code_card">
                                    <CreateQrCode
                                      setQrFile={setQrFile}
                                      setUrl={setUrl}
                                      campaignId={campaignId && campaignId}
                                      isCheckedUrlEmpty={isCheckedUrlEmpty}
                                      selectedCardName={selectedCardName}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* )} */}
                          </>
                        )}
                        {activeStep === 2 && (
                          <PamphletFileInput
                            isQr={isQr}
                            setPamphletFile1={setPamphletFile1}
                            setPamphletFile2={setPamphletFile2}
                            setDisplayAdFile={setDisplayAdFile}
                            setFooterFile={setFooterFile}
                            setLabelAdFile={setLabelAdFile}
                            isDuplex={isDuplex}
                            setSelectedOption={setSelectedOption}
                            pamphletFileError={pamphletFileError}
                            pamphletId={pamphletId}
                            displayAdId={displayAdId}
                            labelAdId={labelAdId}
                            footerId={footerId}
                          />
                        )}

                        {activeStep === 3 && (
                          <CampaignReview
                            // ad type id`s`
                            pamphletId={pamphletId}
                            displayAdId={displayAdId}
                            labelAdId={labelAdId}
                            footerId={footerId}
                            // ad type data
                            campaignDetails={campaignDetails}
                            sizeOfPamphlet={pamphSize}
                            side={side}
                            pamphQlty={pamphQlty}
                            ftrSize={ftrSize}
                            displayAdSize={displayAdSize}
                            labelAdSize={labelAdSize}
                            // quantities & budget of ad types
                            pamphQty={pamphQty}
                            ftrQty={ftrQty}
                            dispQty={dispQty}
                            labelQty={labelQty}
                            budget={budget}
                            selectedOption={selectedOption}
                            pamphletFile1={pamphletFile1}
                            pamphletFile2={pamphletFile2}
                            footerFile={footerFile}
                            displayAdFile={displayAdFile}
                            labelAdFile={labelAdFile}
                            voucherFile={voucherFile}
                            isDuplex={isDuplex}
                            // for qr code
                            qrCodeCreatedCard={qrCodeCreatedCard}
                            setUrl={setUrl}
                            campaignId={campaignId}
                            isCheckedUrlEmpty={isCheckedUrlEmpty}
                            selectedCardName={selectedCardName}
                            //for coupon code
                            applyCoupon={applyCoupon}
                            setCoupon={setCoupon}
                            coupon={coupon}
                            couponError={couponError}
                            couponMsg={couponMsg}
                            discount={discount}
                            setDiscount={setDiscount}
                            setIsCouponApplied={setIsCouponApplied}
                            isCouponApplied={isCouponApplied}
                          />
                        )}
                        <Box sx={{ mt: 2, flex: "1 0 auto" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {activeStep === 1 && !qrCodeCreatedCard && (
                              <Button
                                disabled={index === 0}
                                onClick={handleSkip}
                                sx={{
                                  mt: 1,
                                  ml: 1,
                                  backgroundColor: "#eee",
                                  color: "rgb(0,0,0)",
                                  display: isQr ? "none" : "",
                                  borderRadius: "44px",
                                  "&:hover": {
                                    backgroundColor: "#eee",
                                    color: "rgb(0,0,0)",
                                  },
                                }}
                              >
                                Skip
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              // color="primary" // Set the color to 'primary'
                              sx={{
                                mt: 1,
                                ml: 1,
                                backgroundColor: "#199647",
                                borderRadius: "44px",
                                "&:hover": {
                                  backgroundColor: "#199647",
                                },
                              }}
                            >
                              {activeStep === 1 && !qrCodeCreatedCard
                                ? "Create Qr Code"
                                : "Continue"}
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Grid item>
                <PaymentModel
                  budget={budget}
                  payableTaxAmount={payableTaxAmount}
                  ref={paymentModalRef}
                  amount={total}
                  discount={isCouponApplied ? discount : 0}
                  createdCampaignId={campaignId}
                  isCreated={false}
                  campaignId={null}
                />
              </Grid>
            </div>
          </div>
        </>
      )}
      {isQrVisible && (
        <QrCodeCreater
          qrFile={qrFile}
          codeOfPamph={codeOfPamph}
          setIsQrVisible={setIsQrVisible}
          setQrCodeCreatedCard={setQrCodeCreatedCard}
          campaignId={campaignId}
          setSelectedCardName={setSelectedCardName}
          setQrCodeId2={setQrCodeId2}
          submitCampaignCreate={submitCampaignCreate}
        />
      )}
    </>
  );
};
export default BusinessPlanPageDetails;
