import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import noImage from "./noImage.svg";
import previewIcon from "./share.png";
import "./CampaignModal.css";
import "../../../pages/Campaign/CampaignCreate.css";

const CampaignModal = (props) => {
  const [preview, setPreview] = useState("none");
  const frontIsAvl = props.frontImg != null;
  const [frontIsPDFFile, setFrontIsPDFFile] = useState(false);
  const [backIsPDFFile, setBackIsPDFFile] = useState(false);
  // console.log("preview  " + preview);

  useEffect(() => {
    if (props.frontImg) {
      setPreview("front");
    }
    if (props.backImg) {
      setPreview("back");
    }
    if (props.frontImg) {
      if (props.backImg) setPreview("back");
    }
    if (props.backImg) {
      if (props.frontImg) setPreview("front");
    }
    if (props.frontImgInput || props.frontImg) {
      if (
        props.frontImgInput?.type === "application/pdf" ||
        props.frontImg?.endsWith(".pdf")
      ) {
        setFrontIsPDFFile(true);
      }
    }
    if (props.backImgInput) {
      if (
        props.backImgInput?.type === "application/pdf" ||
        props.backImg.endsWith(".pdf")
      ) {
        setBackIsPDFFile(true);
      }
    }
  }, [
    frontIsAvl,
    props.backImg,
    props.backImgInput,
    props.frontImg,
    props.frontImgInput,
  ]);

  return (
    <div
      className={`w_100 flex align_flex_start justify_flex_end preview_btn_grp_container ${props.className}`}
      style={{ backgroundColor: "" }}
    >
      <div
        className="w_80 flex align_center flex_column justify_center campaign_modal_sub_div"
        style={{
          backgroundColor: "#dbdbdb",
          width: "310px",
          height: "431px",
          boxShadow: "-2px 3px 10px 2px rgb(0 0 0 / 30%)",
        }}
      >
        <div className="flex align_center flex_column justify_center ">
          {preview === "none" && (
            <>
              <div>
                <img src={noImage} alt="noImage" />
              </div>
              <div className="w_100 sub_title opaque text_center">
                Attach file to see preview of Paper Billboard
              </div>
            </>
          )}
          {preview === "front" && props.frontImg !== null && (
            <>
              {!frontIsPDFFile ? (
                <img
                  className="preview_modal_img_"
                  src={props.frontImg}
                  alt="pamphlet"
                />
              ) : (
                <div>
                  <a
                    className="previewLink"
                    href={props.frontImg}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="previewIcon"
                      src={previewIcon}
                      alt="previewIcon"
                    ></img>
                    <span style={{ marginTop: "20px" }}>
                      Show Front Preview
                    </span>
                  </a>
                </div>
              )}
            </>
          )}
          {preview === "back" && props.backImg != null && (
            <>
              {!backIsPDFFile ? (
                <img
                  src={props.backImg}
                  alt="templateOne"
                  className="preview_modal_img_"
                />
              ) : (
                <div>
                  <a
                    className="previewLink"
                    href={props.backImg}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="previewIcon"
                      src={previewIcon}
                      alt="previewIcon"
                    ></img>
                    <span style={{ marginTop: "20px" }}>Show Back Preview</span>
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="buttons-grp">
        {(props.isDuplex || props.isDuplexForPlanCode) && (
          <>
            <div className="buttons-grp-item1">
              <button
                onClick={() => {
                  setPreview((state) => (state = "front"));
                }}
                className={
                  preview === "front"
                    ? "selected_preview_btn"
                    : "non_selected_preview_btn"
                }
                type="button"
              >
                <span className="body_title  ">Front side</span>
              </button>
            </div>
            <div className="buttons-grp-item2">
              <button
                onClick={() => setPreview((state) => (state = "back"))}
                className={
                  preview === "back"
                    ? "selected_preview_btn"
                    : "non_selected_preview_btn"
                }
                type="button"
              >
                <span className="body_title "> Back side</span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CampaignModal;
