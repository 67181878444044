import React from "react";
import { Link } from "react-router-dom";

import "./PrivacyPolicy.css";

import ewayAdz from "../LandingPage_3/components/Nav/Ewayadz.png";
function PrivacyPolicy() {
  return (
    <div>
      <div className="TandC-navbar">
        <div>
          <a className="navbar-brand" href="/">
            <div className="nav-c1">
              <img
                style={{ width: "6.99em" }}
                src={ewayAdz}
                alt="ewayadz"
              ></img>
            </div>
          </a>
        </div>
        <div style={{ flex: "1" }}></div>
        <div>
          <Link to="/">
            <button className="nav-button">Back To Home</button>
          </Link>
        </div>
      </div>
      <div className="TandC-body">
        <h1 align="center">Privacy Policy Eway Print</h1>
        <div>
          <p>
            Privacy Policy Eway Print&#39;s mobile application/s/ website/ any
            other electronic medium of communication (“Communication Medium”) is
            for the users of Eway Print.
          </p>
          <p>
            The terms We, Us, Eway Print or Our, wherever used refer to Eway
            Print, which expression shall mean and include its affiliates,
            successors, and permitted assignments. We are committed to protect
            and respect your privacy.
          </p>
          <p>
            This Policy constitutes a legal agreement between You, as the user
            of the Communication Medium, and Eway Print, as the owner of the
            Communication Medium. This Policy has been drafted in compliance
            with the applicable data protection rules and regulations of India,
            European Union’s (EU’s) General Data Protection Regulation (GDPR)
            &amp; The California Consumer Privacy Act (CCPA) and shall by
            inference include and comply with any amendments thereto unless
            expressly excluded.
          </p>
          <p>
            BY VOLUNTARILY PROVIDING US WITH THE INFORMATION REQUESTED, YOU ARE
            CONSENTING TO ITS PROVISION (INCLUDING ANY INFORMATION THAT MAY
            QUALIFY AS PERSONALLY IDENTIFIABLE INFORMATION
          </p>
        </div>
        <div>
          <h3>General</h3>
          <p>
            We respect the privacy of our customers, users, and employees. But
            as we are a business and offer a digital service platform for the
            educational sector, it means that we need to collect and process
            certain personal data relating to our customers, users, and
            employees.  It’s our responsibility to process personal data in
            accordance with this privacy policy and applicable privacy laws.
          </p>
          <p>
            We are committed to processing personal data in compliance with this
            privacy policy and applicable laws. This privacy policy contains
            information about what personal data we collect, what are the key
            principles of processing the data, and what rights you have relating
            to your personal data. Through our digital service, we also operate
            from time to time as a data processor for our customers (e.g.
            educational organization or institution), which means that we
            process personal data based on contracts on behalf of our customers
            in accordance with their instructions. Regarding this personal data
            collection and use, we advise you to read our customers’ privacy
            policies as well. The privacy policy would be updated from time to
            time associated with the development of our business operations or
            changes in applicable laws, so please read visit this privacy policy
            from time to time.
          </p>
        </div>
        <div>
          <h3>Purpose of Data Collection</h3>
          <p>
            Personal data is collected and used for managing the entire customer
            relationship and providing our digital service. This may include for
            instance user authentication and invoicing. The legal basis for this
            processing is a contract between Eway Print and the customer.
          </p>
        </div>
        <div>
          <h3>Marketing;</h3>
          <p>
            We also collect and use personal data for marketing purposes,
            including direct marketing and contacting potential customers. We
            may also use personal data to target or retarget advertising in
            digital media platforms, such as social media and search engines.
            The legal basis for this processing is our legitimate interest. A
            person can however at any point prohibit direct marketing by sending
            email to support@ewayprint.com. We do not sell or rent personal data
            to third parties for marketing purposes.
          </p>
        </div>
        <div>
          <h3>Customer communications:</h3>
          <p>
            We also collect and use personal data for customer communication
            purposes, including handling of support requests and customer
            feedback as well as notifying users about the service. The legal
            basis for this processing is fulfilling contractual obligations as
            well as our legitimate interest.
          </p>
        </div>
        <div>
          <h3>Analytics and business development:</h3>
          <p>
            We also use anonymized data for statistical and research purposes.
            This is mainly data that is generated during the use of our service.
            This anonymized data may be transferred to third parties, such as
            educational organizations and institutions for the purpose of
            educational analysis and development. This may be done in the form
            of reports provided to our customers. We also use personal data as
            well as the anonymized data generated from the use of our service to
            develop our own digital service. The legal basis for this processing
            is mainly our legitimate interest. Human resources management.
            Personal data relating to employees and job applicants are mainly
            collected and used for human resources management purposes, payment
            of salaries, fulfilling other rights and obligations relating to
            employment contracts, meeting legal requirements relating to
            employment as well as evaluating and selecting candidates for open
            positions. The legal basis for this processing may be fulfilling a
            contract between Eway Print and the employee, consent (job
            applicants) as well as fulfilling legal obligations relating to
            employment.
          </p>
        </div>
        <div>
          <h3>Sources of Data collection</h3>
          <p>
            The personal data we collect and use is mainly provided by the
            person herself. Another important source is the data created and
            generated during the use of our digital service. Regarding potential
            organizational customers, we could also get information from public
            sources, such as LinkedIn. We also collect website use and visitor
            information with Google Analytics for analyzing our website use,
            developing it further, and for targeting relevant marketing content
            for our customers and website visitors.
          </p>
          <p>
            <i>
              <strong>
                Data we collect and process relating to our customers:
              </strong>
            </i>
             Name of the organization, person’s name, business contact details,
            and similar data.
          </p>
          <p>
            <strong>
              <i>Data we collect and process relating to teachers: </i>
            </strong>
            Name, school or educational institution, email address, and similar
            data.
          </p>
          <p>
            <strong>
              <i>Data we collect and process relating to students:</i>
            </strong>
             Data is divided into personal data and research data. Personal data
            is data that teachers need to generate reports. It contains for
            instance user login, name of the person, and date of birth. Research
            data contains data that is generated when the student uses the
            service (e.g. what assignments the student did as well as the
            results) and classification data relating to the student. Research
            data contains for instance age in months, gender and country.
            Research data alone is anonymous data.
          </p>
          <p>
            <strong>
              <i>Data we collect and store relating to potential customers:</i>
            </strong>
             Name, email, employer, job title, and similar data.
          </p>
          <p>
            <strong>
              <i>Data we collect and store relating to employees:</i>
            </strong>
             Name and contact details, social security number, email, and
            details required for payment of salaries and withholding taxes. Note
            that our customers may use the service for storing and processing
            their and their users’ personal data, so in those situations, we
            operate as their data processor based on contracts. In such a
            situation, we process personal data only on behalf of the specific
            customer based on their instructions and only as long as we have a
            valid contract.
          </p>
        </div>
        <div>
          <h3>Information</h3>
          <p>
            A contract regarding the use of our service can also be made with an
            educational institution and its representative, who is authorized to
            make contracts on behalf of the specific organization. This person
            must be at least 18 years old and in a relevant position in the
            organization.
          </p>
        </div>
        <div>
          <h3>Personal Data</h3>
          <p>
            Personal data is mainly stored in electronic format and people
            within our organization have access to personal data.
          </p>
          <p>
            We may also use third-party services providers for data storage
            (e.g. cloud storage), digital marketing, and processing of personal
            data. In these situations, we make sure we have a written contract
            with the services provider with minimum data processing provisions
            and also otherwise that the confidentiality of personal data is
            secured and data is otherwise processed and transferred lawfully.
          </p>
          <p>
            We may also disclose or transfer personal data to fulfill legal
            obligations or when a legal authority requires a disclosure. We may
            also disclose personal data if we are a party of a business sale,
            such as a merger or an acquisition.
          </p>
          <p>
            We follow The European Union’s (EU’s) General Data Protection
            Regulation (GDPR), California Consumer Privacy Act (CCPA)  We may
            also transfer to third parties (mainly customers and educational
            institutions) anonymized or statistical data, from which it is not
            possible to identify a person. If this kind of data is no longer
            considered personal data, we may provide the data to third parties
            also for other purposes than those described in this privacy policy.
            If you live in California, you have certain rights to request
            information. Users outside of the United States should note that we
            transfer data to India and other areas outside of the European
            Economic Area.
          </p>
          <p>
            If you are a California resident, you have the right to request
            certain details about what personal information we share with third
            parties for those third parties’ direct marketing purposes. To
            submit your request, send an email to support@ewayprint.com
          </p>
        </div>
        <div>
          <h3>Duration for Data Storage</h3>
          <p>
            We will not store personal data for a longer period than is
            necessary for its purpose or required by contract or law. The
            storage times for personal data may vary based on its purpose and
            the situation. Data may also be deleted after a contract ends with
            the customer.
          </p>
        </div>
        <div>
          <h3>Storage &amp; Security</h3>
          <p>
            Personal data is stored and secured in accordance with general
            industry standards and practices. We consider and keep personal data
            confidential. Subcontractors that we use for processing personal
            data are selected also based on their data security measures. For
            our own systems and data storage, we use only well-established
            services providers and robust software tools. Access to personal
            data is also protected with user-specific logins, passwords, and
            user rights. Our premises are also safe and secure.
          </p>
        </div>
        <div>
          <h3>Need for Personal Data</h3>
          <p>
            If you as a customer, user or employee do not provide us some of
            your personal data or allow processing of it, it is very likely that
            we cannot serve and fulfill the purpose of our business. At least
            entirely. At a minimum, we need to process personal data relating to
            employment and for the provision of our service to customers with
            whom we have a contract.
          </p>
        </div>
        <div>
          <h3>Cookies</h3>
          <p>
            We use cookies on our website to provide the best possible user
            experience for our website visitors. Cookies are small text files
            that are placed on a web user’s computer and are designed to hold a
            modest amount of data particular to a user and a website. Cookies
            give us information how users use our website. We may use cookies to
            develop our website and services, analyze website use as well as
            target and optimize marketing efforts. If you do not wish to receive
            cookies, you may set your web browser to disable them. Please note
            that most browsers accept cookies automatically. If you disable
            cookies, you should understand that our services or our website may
            not function correctly.
          </p>
        </div>
        <div>
          <h3>Rights - Personal Data</h3>
          <p>
            <strong>Withdraw your consent: </strong> If we process personal data
            based on your consent, you can at anytime withdraw your consent by
            notifying us by sending email to support@ewayprint.com
          </p>
          <p>
            <strong>Access to data:</strong> You have the right to have
            confirmed if we are processing your personal data and also to know
            what data we have about you. In addition, you have the right to some
            supplemental information described in the law about the processing
            activities. For access requests, please contact us as instructed in
            the next section.
          </p>
          <p>
            <strong>Right to have errors corrected:</strong> You have the right
            to request that we correct any inaccurate or outdated personal data
            we have about you.
          </p>
          <p>
            <strong>Right to prohibit direct marketing: </strong>You have the
            right to request that your personal data is not processed for direct
            marketing purposes by sending us an email to support@ewayprint.com
          </p>
          <p>
            <strong>Right to object processing:</strong> If we process your
            personal data based on public interest or our legitimate interest,
            you have the right to object to the processing of your data, to the
            extent that there is no such significant other reason that would
            override your rights or the processing is not necessary for handling
            legal claims. Please notice that in this situation we may not be
            able to serve you anymore.
          </p>
          <p>
            <strong>Right to restrict processing:</strong> In certain
            situations, you have the right to require that we restrict the
            processing of your personal data.
          </p>
          <p>
            Right to data portability: If we process your personal data based on
            your consent or fulfilling of a contract, you have the right to
            require the transfer of the data you have provided to us to another
            services provider in a commonly used electronic format.
          </p>
        </div>
        <div>
          <h3>International data transfers</h3>
          <p>
            Our website is primarily operated and managed on servers located and
            operated within India. Eway Print might also host data on
            international servers, hence your Personal Data may also be stored
            in third-party data servers located in other countries.
          </p>
        </div>
        <div>
          <h3>Rights</h3>
          <p>
            You can execute and use your rights by contacting us by sending an
            email to support@ewayprint.com. In such a case, we ask you to
            provide us your name, contact details, phone number as well as
            something that we can use to verify your identity, such as a written
            and signed (and scanned) request.
          </p>
        </div>
        <div>
          <h3>Cancellation Policy</h3>
          <p>
            As per out cancellation policy, Once the order is printed it cannot
            be cancelled.
          </p>
        </div>
        <div>
          <h3>Refund Policy</h3>
          <p>Refund will be provided within 5 to 7 working days.</p>
        </div>
        <div>
          <h3>Children’s Data</h3>
          <p>
            As a general policy, our company does not engage in the collection,
            processing, storage, use, dissemination, and transfer of Personal
            Data of children.
          </p>
        </div>
        <div>
          <h3>Update – Privacy Policy</h3>
          <p>
            We may make updates to this privacy policy when our operations
            change or develop. Also, changes in the law may make it necessary to
            update this privacy policy. The changes become valid once we have
            published them in the form of an updated privacy policy. Therefore,
            please visit this page and read this privacy policy from time to
            time.
          </p>
        </div>
        <div>
          <h3>Contact</h3>
          <p>Contact details in privacy matters:</p>
          <p>Eway Print</p>
          <p>www.ewayprint.com</p>
          <p>Email: support@ewayprint.com</p>
          <p>
            Our contact person in privacy matters is ____________. You can reach
            him/her by sending an email to the address provided above.
          </p>
          <p>
            Address: UPPER GROUND, OFFICE NO U 71, CLOVER HILLS PLAZA, NIBM
            ROAD, KONDHWA KHURD, Pune, Pune, Maharashtra, 411048
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
