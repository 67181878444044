import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CampaignLayout from "../../../components/CampaignLayout/CampaignLayout";
// import Button from "../../../components/UI/GreenBtn/Button";
import Input from "../../../components/UI/Inputs/Input";
import SelectDropdown from "../../../components/UI/SelectDropdown/SelectDropdown";
import { API_URL } from "../../../constant/apiURL";

import "./BusinessInfo.css";
import Button from "../../../components/UI/GreenBtn/Button";

const BusinessInfo = () => {
  const token = useSelector((state) => state.auth.token);

  let history = useHistory();

  const [selectedIndustry, setSelectedIndustry] = useState(0);
  const [businessName, setBusinessName] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const getSelected = (item) => {
    setSelectedIndustry(item);
  };
  const handleBusinessName = (event) => {
    setBusinessName(event.target.value);
  };
  const handleSubmit = () => {
    localStorage.setItem("selectedIndustry", `${selectedIndustry}`);
    let formData = new FormData();
    // formData.append("name", businessName);
    formData.append("industry", selectedIndustry);
    formData.append("pin_code", "411048");
    formData.append("user", +localStorage.getItem("userId"));

    if (token) {
      setIsLoading(true);
      (async () => {
        const res = await fetch(
          API_URL + `clients/update/${localStorage.getItem("clientId")}/`,
          {
            method: "PUT",
            headers: {
              Authorization: `Token ${token}`,
            },
            body: formData,
          }
        );
        if (res.ok) {
          setIsLoading(false);
          history.replace("/ads/campaign/create/new");
        }
      })();
    }
  };

  useEffect(() => {
    if (token) {
      (async () => {
        // Fetching Industry List
        const resInd = await fetch(API_URL + "industries/list/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const dataInd = await resInd.json();

        setIndustryList(dataInd);
      })();
    }
  }, [token]);

  return (
    <CampaignLayout>
      {/* <div className="content__div_login "> */}
      <div className="business_info_main_container">
        {/* <div className="business_info_1st_title" style={{ marginTop: "24px" }}>
          What is your business name?
        </div>
        <Input
          placeholder="Enter your business name"
          className="m_top_2 m_bottom_2"
          onChange={handleBusinessName}
        /> */}
        <SelectDropdown
          optionsArray={industryList}
          getSelected={getSelected}
          label="Industry"
          className="m_bottom_2"
        />
        <div className="w_100 flex justify_flex_end">
          <Button
            name={isLoading ? "Loading..." : "Next"}
            className="next_btn"
            style={{ width: "120px" }}
            onClick={() => {
              handleSubmit();
            }}
          ></Button>
        </div>
      </div>
      {/* </div> */}
    </CampaignLayout>
  );
};

export default BusinessInfo;
