import React from "react";

import ewayAdz from "../LandingPage_3/components/Nav/Ewayadz.png";
import "./TandC.css";

function TandC() {
  return (
    <div className="TandC-main-div">
      <div className="TandC-navbar">
        <div>
          <a className="navbar-brand" href="/">
            <div className="nav-c1">
              <img
                style={{ width: "6.99em" }}
                src={ewayAdz}
                alt="ewayadz"
              ></img>
            </div>
          </a>
        </div>
        <div style={{ flex: "1" }}></div>
        {/* <div>
          <a href="/">
            <button className="nav-button">Back To Home</button>
          </a>
        </div> */}
      </div>
      <div className="TandC-body">
        <div align="center">
          <h1 className="h1"> Terms &amp; Conditions Eway Print</h1>
        </div>
        <div>
          <p>
            Welcome to our website. If you continue to browse and use this
            website, you are agreeing to comply with and be bound by the
            following terms and conditions of use, which together with our
            privacy policy govern Eway Print’s relationship with you with this
            website. These terms will take effect from the date on which you
            first use the website. If you disagree with any part of these terms
            and conditions, please do not use our website. The term ‘Eway Print’
            or ‘us’ or ‘we’ refers to the owner of the website. The term ‘you’
            refers to the user or viewer of our website.
          </p>
        </div>
        <div>
          <h3>
            The use of this website is subject to the following terms of use:
          </h3>
          <div>
            <ul type="circle">
              <li>
                The content of the pages of this website is for your general
                information and use only. It is subject to change without
                notice.
              </li>
              <li>
                This website uses cookies to monitor browsing preferences. If
                you do allow cookies to be used, some personal information may
                be stored by us for use by third parties. Refer to our privacy
                policy for more details.
              </li>
              <li>
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timelines, performance,
                completeness, or suitability of the information and materials
                found or offered on this website for any particular purpose. You
                acknowledge that such information and materials may contain
                inaccuracies or errors and we expressly exclude liability for
                any such inaccuracies or errors to the fullest extent permitted
                by law.
              </li>
              <li>
                Your use of any information or materials on this website is
                entirely at your own risk, for which we shall not be liable. It
                shall be your own responsibility to ensure that any products,
                services, or information available through this website meet
                your specific requirements.
              </li>
              <li>
                This website contains material that is owned by or licensed to
                us. This material includes, but is not limited to, the design,
                content, layout, look, appearance and graphics. Reproduction is
                prohibited other than in accordance with the copyright notice,
                which forms part of these terms and conditions. Most of the
                material featured on this website is subject to copyright
                protection.
              </li>
              <li>
                All trademarks reproduced on this website that are not the
                property of, or licensed to, the operator are acknowledged on
                the website.
              </li>
              <li>
                Unauthorized use of this website may give rise to a claim for
                damages and/or be a criminal offense.
              </li>
              <li>
                From time to time this website may also include links to other
                websites. These links are provided for your convenience to
                provide further information. They do not signify that we endorse
                the website(s). We have no responsibility for the content of the
                linked website(s).
              </li>
              <li>
                Your use of this website and any dispute arising out of such use
                of the website is subject to the laws of the Government of
                India.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 align="left">Contract</h3>

          <p>
            When you use our website and services you agree to all of these
            terms. Your use of our websites and services is also subject to our
            cookie policy and privacy policy, which covers how we collect, use,
            share, and store your personal information.
          </p>
          <p>
            You agree that by registering, accessing or using our services
            (described below), you are agreeing to enter into a legally binding
            contract with Eway Print (even if you are using our Services on
            behalf of a company). If you do not agree to this contract
            (“Contract” or “User Agreement”), do not register or access any of
            our Services. If you wish to terminate this contract, at any time
            you can do so by closing your account and no longer accessing or
            using our Services.
          </p>
        </div>
        <div>
          <h3>Services</h3>
          <p>
            This Contract applies to Eway Print&#39;s Customers, Team members,
            and participants. Registered users of our Services are “Members” and
            unregistered users are “Visitors”. You are entering into this
            Contract with Eway Print (also referred to as “we” and “us”). Eway
            Print will be the controller of your personal data provided to, or
            collected by or for, or processed in connection with our Services.
          </p>
        </div>
        <div>
          <h3>This Contract applies to Members and Visitors.</h3>
          <p>
            As a Visitor, Customer or Member of our Services, the collection,
            use and sharing of your personal data is subject to this privacy
            policy (which includes our cookie policy and other documents
            referenced in this Privacy Policy) and updates.
          </p>
        </div>
        <div>
          <h3>Members and Visitors</h3>
          <p>
            When you register and join the Eway Print community or register to
            receive free printouts, webinars, email or newsletter, updates, you
            become a Member. If you have chosen not to register for our
            Services, you may access certain features as a “Visitor.”
          </p>
        </div>

        <div>
          <h3>OBLIGATIONS</h3>
          <h3>Service Eligibility</h3>
          <p>
            Here are some promises that you make to us in this Contract: You’re
            eligible to enter into this contract and you are at least our
            “Minimum Age.” The Services are not for use by anyone under the age
            of 18. To use the Services, you agree that: (1) you must be the
            “Minimum Age” (described below) or older; (2) you will only have one
            Eway Print account, which must be in your real name. Creating an
            account with false information is a violation of our terms,
            including accounts registered on behalf of others or persons under
            the age of 18. “Minimum Age” means 18 years old.  Your Account You
            will keep your password a secret. You will not share an account with
            anyone else and will follow our rules and the law. Members are
            account holders. You agree to: (1) use a strong password and keep it
            confidential; (2) not transfer any part of your account (e.g.,
            connections) and (3) follow the law and our list of Dos and Don’ts
            and professional community policies. You are responsible for
            anything that happens through your account unless you close it or
            report misuse. As between you and others (including your employer),
            your account belongs to you.
          </p>
        </div>
        <div>
          <p>Free bonus are to be use within 60 days.</p>
          <p>Free balance is not transferable.</p>
          <p>Free bonus cannot be claimed under any condition.</p>
          <p>
            Free balance is subject to certain condition like area should be
            within delivery range.
          </p>
        </div>

        <div>
          <h3>Delivery</h3>
          <p>
            For free and paid delivery, proper and complete address is must. If
            proper address is not given, then it won’t be delivered. Printout
            won’t be delivered on public places like college, library, school,
            hostel etc. And common addresses where only landmarks or area name
            is given.
          </p>
        </div>
        <div>
          <h3>User will receive ads</h3>
          <p>
            Any product or service bought by the ads, EwayPrint is not
            responsible. Children under age 18 then no name and personal data
            should be added. Parents and guardians should use their name.
          </p>
        </div>
        <div>
          <h3>Notices and Messages</h3>
          <p>
            You’re okay with us providing notices and messages to you through
            our websites, webinars, emails and contact information. If your
            contact information is out of date, you may miss out on important
            notices. You agree that we will provide notices and messages to you
            in the following ways: (1) within the Service, or (2) sent to the
            contact information you provided us (e.g., email, mobile number,
            physical address). You agree to keep your contact information up to
            date.
          </p>
        </div>
        <div>
          <h3>Sharing</h3>
          <p>
            When you share information on our Services, others can see, copy and
            use that information. Our Services allow messaging and sharing of
            information in many ways, such as your profile, articles, group
            posts, links to news articles, and messages. Information and content
            that you share or post may be seen by other Members, Visitors or
            others (including off of the Services). We are not obligated to
            publish any information or content on our Service and can remove it
            with or without notice.
          </p>
        </div>
        <div>
          <h3>You agree that:</h3>
          <div>
            <ul>
              <li>
                not harass other users, students, attendees, support agents, or
                visitors;
              </li>
              <li>
                not create an atmosphere of disharmony, hostility within the
                groups
              </li>
              <li>Not upload and print copyrighted material</li>
              <li>Not upload and print illegal or pornographic material</li>
              <li>
                You would be liable for uploading images, documents,
                presentations, or any digital format for using our printing
                services
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>Payment Terms and Refund Policy</h3>
          <p>
            Refunds shall be done in accordance with the terms and conditions of
            the refund policy of the respective program.
          </p>
        </div>
        <div>
          <h3>Copyright and Trademark Policy</h3>
          <p>
            We respect the intellectual property rights of individuals and
            organizaitons and expect our users to do the same when using the
            Services. We reserve the right to suspend, disable, or terminate the
            Accounts of users who infringe or are charged with infringing the
            copyrights, trademarks, or other intellectual property rights of
            others.
          </p>
        </div>
        <div>
          <h3>Force Majeure</h3>
          <p>
            Neither of us shall be liable to the other for any delay or failure
            in performance under these Terms, other than payment obligations,
            arising out of a cause beyond its control and without its fault or
            negligence. Such causes may include, but are not limited to fires,
            floods, earthquakes, strikes, unavailability of necessary utilities,
            blackouts, acts of God, acts of declared or undeclared war, acts of
            regulatory agencies, or national disasters.
          </p>
          <p>By using the Services, you agree to our Privacy Policy.</p>
          <p>Contact</p>
          <p>Contact details in privacy matters:</p>
          <p>Eway Print</p>
          <p>www.ewayprint.com</p>
          <p>Email: support@ewayprint.com</p>
          <p>
            Our contact person in privacy matters and Terms &amp; conditions is
            __________. You can reach him/her by sending an email to the address
            provided above.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TandC;
