import { Ellipse, corporateFare } from "../../../assets";

const dataArray2 = [
  {
    logo: corporateFare,
    icon: Ellipse,
    text: "Suitable for service providers and vendors",
  },
  {
    icon: Ellipse,
    text: "Target new customers",
  },
  {
    icon: Ellipse,
    text: "Expand the client base in specific locations",
  },
  {
    icon: Ellipse,
    text: "Exclusive vouchers of your brand",
  },
];
export default dataArray2;
