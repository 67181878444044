import { useState } from "react";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import PlanSelectionCard from "../../../components/UI/PlanSelectionCard/PlanSelectionCard";
import "./PlanSelectionPage.css";
import dataArray1 from "./dataArray1";
import dataArray2 from "./dataArray2";
import Button from "../../../components/UI/GreenBtn/Button";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const PlanSelectionPage = () => {
  const history = useHistory();

  const [selectedPlan1, setSelectedPlan1] = useState(false);
  const [selectedPlan2, setSelectedPlan2] = useState(false);

  const cardSelectHandler1 = () => {
    setSelectedPlan1(true);
    setSelectedPlan2(false);
  };
  const cardSelectHandler2 = () => {
    setSelectedPlan1(false);
    setSelectedPlan2(true);
  };
  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />

      <div className="plan_selection_page_main_div">
        <div className="plan_selection_page_sub_div">
          <div className="sub_title text_center">
            Which type of campaign would you like to create?
          </div>
          <div className="plan_selection_cards_wrapper">
            <div className="plan_selection_card1_wrapper">
              <PlanSelectionCard
                dataArray={dataArray1}
                onClick={() => {
                  cardSelectHandler1();
                }}
                isSelected={selectedPlan1}
                planName="Basic Campaign"
                btnName="Select Goal"
              />
            </div>
            <div className="plan_selection_card2_wrapper">
              <PlanSelectionCard
                dataArray={dataArray2}
                onClick={() => {
                  cardSelectHandler2();
                }}
                isSelected={selectedPlan2}
                planName="Business Campaign"
                btnName="Select Goal"
                // comingSoon={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_fixed_div">
        <ButtonNew
          className="new_back_btn"
          name="Back"
          onClick={() => {
            history.goBack();
          }}
        />
        <ButtonNew
          className="new_continue_btn"
          name="Continue"
          onClick={() => {
            if (selectedPlan1) {
              history.push("/dashboard/basic-plan");
            }
            if (selectedPlan2) {
              history.push("/dashboard/business-plans-page");
            }
          }}
        />
      </div>
    </>
  );
};
export default PlanSelectionPage;
