import { benefitsIcon } from "../../../assets";

const benefitsListBusinessPlans = {
  plan1: {
    icon: benefitsIcon,
    list: [
      // "Boosts brand recall value",
      "QR Code allows to easily interact with your ad.",
      "Cost-effective way to reach audience",
    ],
  },
  plan2: {
    icon: benefitsIcon,
    list: [
      // "Get all benefits of footer Ad",
      "Pamphlets & footers combine for a dual-channel marketing approach",
      "Pamphlets are tangible and can leave a personal touch.",
    ],
  },

  plan3: {
    icon: benefitsIcon,
    list: [
      // "Get all benefits of Pamphlet + Footer Ads",
      "Pamphlets, footers & Display Adcombine for a triple-channelmarketing approach",
      "Display ads help in brand recognition and maintaining a consistent presence",
    ],
  },

  // plan4: {
  //   icon: benefitsIcon,
  //   list: ["", "", "", ""],
  // },
};
export default benefitsListBusinessPlans;
