import * as React from "react";

import "./QtyCard.css";
import { useState } from "react";
import { useEffect } from "react";

const QtyCard = (props) => {
  const [selectedQty, setSelectedQty] = useState(props.quantityArr[0]);
  const [isCustomQty, setIsCustomQty] = useState(false);
  const [isGetPrice, setIsGetPrice] = useState(false);
  const [cusQtyInput, setCusQtyInput] = useState(null);

  const handleSelect = (option) => {
    setSelectedQty(option);
  };

  useEffect(() => {
    props.setPamphletQty(isCustomQty && isGetPrice ? cusQtyInput : selectedQty);
  }, [isCustomQty, isGetPrice, cusQtyInput, selectedQty]);
  return (
    <>
      <div className="toggle_btn_card_main_div m_t_1">
        <div className="toggle_btn_card_sub_div1">
          <div className="sub_title">Choose Pamphlet Quantity</div>
          <div className="dropdown_wrapper_bgt_qty">
            <select
              value={selectedQty}
              onChange={(e) => handleSelect(e.target.value)}
              className="dropdown_select sub_title_child"
            >
              {props.quantityArr?.map((qty, index) => (
                <option className="sub_title_child" key={index} value={qty}>
                  {qty}
                </option>
              ))}
            </select>
          </div>
          {/* <div
            className="sub_title_child"
            style={{
              textAlign: "center",
              marginTop: "0.5rem",
              fontSize: "20px",
            }}
          >
            or
          </div> */}

          {/* {!isCustomQty && (
            <div className="customize_budget_btn_wrapper">
              <button
                className="enter_ctm_qty_btn"
                onClick={() => {
                  setIsCustomQty(true);
                }}
              >
                Enter Custom Quantity
              </button>
            </div>
          )} */}

          {/* {isCustomQty && (
            <div className="custom_input_btn_new_wrapper">
              <div className="custom_input_btn_sub_new_wrapper">
                <div className="custom_input_wrapper_new">
                  <input
                    className={
                      props.darkTheme
                        ? "ctm_bgt_input_dark_theme"
                        : "ctm_bgt_input_new"
                    }
                    type="number"
                    // value={cusQtyInput}
                    onChange={(e) => {
                      setCusQtyInput(e.target.value);
                    }}
                    placeholder="Enter Your Quantity"
                  />
                </div>
                <div>
                  <button
                    className={
                      props.darkTheme
                        ? "bgt_save_btn_dark_theme"
                        : "bgt_save_btn_new"
                    }
                    onClick={() => {
                      setIsGetPrice(true);
                    }}
                  >
                    Get Price
                  </button>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};
export default QtyCard;
