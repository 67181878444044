import { useEffect, useState } from "react";
import { benefitsIcon, check, redInfoIcon } from "../../../../assets";
import "./BudgetCard2.css";
import benefitsListBasicPlans from "../../BasicPlansPage/benefitsList";

const BudgetCard2 = (props) => {
  let distributionRate = 3;

  const budget = props.quantity * props.amountToMultiply;

  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedQty, setSelectedQty] = useState(null);

  const [isCustomQty, setIsCustomQty] = useState(false);
  const [isGetPrice, setIsGetPrice] = useState(false);
  const [cusQtyInput, setCusQtyInput] = useState(null);
  const [custQtyError, setCustQtyError] = useState(false);
  const [maxQtyError, setMaxQtyError] = useState(false);

  const amounts = [
    { value: 1000 * distributionRate, quantity: "1000" },
    { value: 2000 * distributionRate, quantity: "2000" },
    { value: 3000 * distributionRate, quantity: "3000" },
    { value: 5000 * distributionRate, quantity: "5000" },
    { value: 10000 * distributionRate, quantity: "10000" },
    { value: 15000 * distributionRate, quantity: "15000" },
  ];

  useEffect(() => {
    setSelectedAmount(amounts[0].value); // Set the first amount as the default selected value
    setSelectedQty(amounts[0].quantity);
  }, []);

  useEffect(() => {
    props.setPrePrintedQty(
      isCustomQty && isGetPrice ? cusQtyInput : selectedQty
    );
    props.setPrePrintedBgt(
      isGetPrice && isCustomQty
        ? cusQtyInput * distributionRate
        : selectedAmount
    );
  }, [isCustomQty, isGetPrice, cusQtyInput, selectedQty, selectedAmount]);

  return (
    <>
      <div
        className={
          props.darkTheme
            ? "new_budget_card_main_div_dark"
            : "new_budget_card_main_div"
        }
      >
        <div className="new_budget_card_sub_div">
          <div className="budget_detail_wrapper">
            <div className="size_side_detail_wrapper">
              <div
                className={
                  !props.isPrePrinted
                    ? props.size === "A5"
                      ? "size_side_text"
                      : "size_side_text_a4"
                    : "size_side_text_budget_card2"
                }
              >
                {!props.isPrePrinted
                  ? `${props.size}-${props.side}`
                  : "Pamphlet Distribution Plan"}
              </div>
            </div>
            <div className="amount_detail_wrapper">
              <div className="budget_name_text">Budget</div>
              <div className="budget_amount_txt">
                {!props.isPrePrinted
                  ? `₹${budget}`
                  : isGetPrice
                  ? `₹${cusQtyInput * distributionRate}`
                  : `₹${selectedAmount}`}
              </div>
            </div>

            <div className="quantity_detail_wrapper_budget_card2">
              <div className="sub_title_child">
                Pamphlet Quantity : &nbsp;
                <span className="_sub_title">
                  {!props.isPrePrinted
                    ? props.quantity
                    : isCustomQty && isGetPrice
                    ? cusQtyInput
                    : selectedQty}
                </span>
              </div>
            </div>
          </div>
          <hr style={{ height: "2px", margin: "20px 0px" }} />

          <div className="benefits_wrapper">
            {!props.isPrePrinted && (
              <div className="benefits_sub_wrapper">
                {!props.isBenefits && (
                  <>
                    <div className="f_w_600">Price Breakdown</div>
                    <div className="pricing_div m_t_1">
                      <div className="price_distribution_text_wrapper">
                        <div className="sub_title_child">Pricing Cost</div>
                        <div className="sub_title_child">Distribution Cost</div>
                      </div>
                      <div className="price_distribution_amount_wrapper">
                        <div className="sub_title_child">{budget * 0.6}</div>
                        <div className="sub_title_child">{budget * 0.4}</div>
                      </div>
                    </div>
                  </>
                )}
                {props.isBenefits && (
                  <>
                    <div className="f_w_600">Benefits</div>
                    <div className="benefits_list m_t_1">
                      {benefitsListBasicPlans.plan1.list?.map((benefit, i) => {
                        return (
                          <div className="benefits_icon_text_wrapper" key={i}>
                            <img
                              className="m_r_5"
                              src={benefitsIcon}
                              alt="icon"
                            />
                            <div>{benefit}</div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="choose_plan_btn_wrapper">
                  <button
                    className={
                      props.selectedPlan1
                        ? "choose_plan_btn_selected"
                        : "choose_plan_btn"
                    }
                    onClick={props.chooseOnClick1}
                  >
                    {props.selectedPlan1 ? (
                      <img className="" src={check} alt="check" />
                    ) : (
                      "Confirm Plan"
                    )}
                  </button>
                </div>
              </div>
            )}

            {props.isPrePrinted && (
              <div className="benefits_sub_wrapper">
                <div>
                  <div className="sub_title_child m_b_1">
                    Choose Quantity To Distribute
                  </div>
                  {amounts.map((amount, index) => (
                    <label key={index} className="sub_title_child">
                      <input
                        type="radio"
                        value={amount.value}
                        checked={selectedQty === amount.quantity}
                        onChange={(event) => {
                          setSelectedQty(amount.quantity);
                          setSelectedAmount(event.target.value);
                        }}
                      />
                      &nbsp;&nbsp;
                      {amount.quantity} &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp;-&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; ₹{amount.value}
                    </label>
                  ))}
                </div>

                {!isCustomQty && (
                  <div className="customize_budget_btn_wrapper">
                    <button
                      className="enter_ctm_qty_btn_card"
                      onClick={() => {
                        setIsCustomQty(true);
                      }}
                    >
                      Enter Custom Quantity
                    </button>
                  </div>
                )}

                {isCustomQty && (
                  <>
                    <div className="custom_input_btn_new_wrapper">
                      <div className="custom_input_btn_sub_new_wrapper_card">
                        <div className="custom_input_wrapper_new">
                          <input
                            className={
                              props.darkTheme
                                ? "ctm_bgt_input_dark_theme"
                                : "ctm_bgt_input_new"
                            }
                            type="number"
                            // value={cusQtyInput}
                            onChange={(e) => {
                              setCusQtyInput(e.target.value);
                              if (e.target.value < 500) {
                                setCustQtyError(true);
                                setIsGetPrice(false);
                              } else {
                                setCustQtyError(false);
                              }

                              if (e.target.value > 100000) {
                                setMaxQtyError(true);
                                setIsGetPrice(false);
                              } else {
                                setMaxQtyError(false);
                              }
                            }}
                          />
                        </div>
                        <div>
                          <button
                            className={
                              props.darkTheme
                                ? "bgt_save_btn_dark_theme"
                                : "bgt_save_btn_new"
                            }
                            onClick={() => {
                              if (
                                !custQtyError &&
                                cusQtyInput >= 500 &&
                                cusQtyInput < 100000
                              ) {
                                setIsGetPrice(true);
                              }
                            }}
                          >
                            Get Price
                          </button>
                        </div>
                      </div>
                    </div>
                    {custQtyError && (
                      <div className="error_text m_t_0_5">
                        Please Enter Quantity Above 500
                      </div>
                    )}
                    {maxQtyError && (
                      <div className="error_text m_t_0_5">
                        Please Enter Quantity Below 100000
                      </div>
                    )}
                  </>
                )}
                <div className="choose_plan_btn_wrapper m_t_1">
                  <button
                    className={
                      props.selectedPlan2
                        ? "choose_plan_btn_selected"
                        : "choose_plan_btn"
                    }
                    onClick={props.chooseOnClick2}
                  >
                    {props.selectedPlan2 ? (
                      <img className="" src={check} alt="check" />
                    ) : (
                      "Confirm Plan"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {props.confirmPlanError && (
        <div className="error_text error_text_info_icon_wrapper">
          <span>
            <img src={redInfoIcon} alt="redInfoIcon" />
          </span>
          &nbsp; Please confirm your plan to continue
        </div>
      )} */}
    </>
  );
};
export default BudgetCard2;
