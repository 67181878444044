import "./ClientSite.css";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constant/apiURL";
import { useSelector } from "react-redux";
// import * as React from 'react';
import { useState } from "react";
import MultiUrlPreviewPage from "../../pages/NewFlowOfCampaign/QrCodeCreater/QrCodePreviewPages/MultiUrlPreviewPage/MultiUrlPreviewPage";
import BusinessPreviewPage from "../../pages/NewFlowOfCampaign/QrCodeCreater/QrCodePreviewPages/BusinessPreviewPage/BusinessPreviewPage";
import SocialMediaPreviewPage from "../../pages/NewFlowOfCampaign/QrCodeCreater/QrCodePreviewPages/SocialMediaPreviewPage/SocialMediaPreviewPage";
import VideoPreviewPage from "../../pages/NewFlowOfCampaign/QrCodeCreater/QrCodePreviewPages/VideoPreviewPage/VideoPreviewPage";
import ProductPreviewPage from "../../pages/NewFlowOfCampaign/QrCodeCreater/QrCodePreviewPages/ProductPreviewPage/ProductPreviewPage";
const ClientSite = () => {
  const { campaignId } = useParams();
  const [response, setResponse] = useState(null);
  const [parseData, setParseData] = useState(null);
  const [urlType, setUrlType] = useState(null);
  // console.log(campaignId);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    // let currentUrl = window.location.href;

    // if (currentUrl === "https://ewayprint.com/ads/client-site/319") {
    //   currentUrl = "https://ads.ewayprint.com/client-site/319";
    //   window.location.href = currentUrl;
    // }
    // if (currentUrl === "https://ewayprint.com/ads/client-site/326") {
    //   currentUrl = "https://ads.ewayprint.com/client-site/326";
    //   window.location.href = currentUrl;
    // }
    const callApi = async () => {
      // apiCall
      let formData = new FormData();
      formData.append("id", campaignId);

      const res = await fetch(API_URL + `campaign/qr-code-counted/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });

      if (res.ok) {
        const data = await res.json();
        setResponse(data);
        setUrlType(data.qr_code_url_type);

        if (
          data?.qr_code_url_type === "location" ||
          data?.qr_code_url_type === "website"
        ) {
          if (typeof data?.qr_code_url === "string") {
            setParseData(JSON.parse(data?.qr_code_url));
          } else {
            setParseData(data?.qr_code_url);
          }
        }
        // window.location.href = data["qr_code_url"];
      } else {
        console.log("no response");
      }
    };

    callApi();
    // After getting response
    // window.location.href = res.url
  }, [campaignId]);

  if (urlType === "website") {
    window.location.href = parseData?.url;
    // console.log(parseData?.url);
  }

  if (urlType === "location") {
    window.location.href = parseData?.url;
  }
  return (
    <div className="loader_wrapper">
      {urlType === "multiple_links" && (
        <MultiUrlPreviewPage response={response && response} />
      )}
      {urlType === "business_page" && (
        <BusinessPreviewPage response={response && response} />
      )}
      {urlType === "s_m_account" && (
        <SocialMediaPreviewPage response={response && response} />
      )}
      {urlType === "product_display" && (
        <ProductPreviewPage response={response && response} />
      )}
      {urlType === "video" && (
        <VideoPreviewPage response={response && response} />
      )}
    </div>
  );
};

export default ClientSite;
