import "./ProductDisplay.css";
import { useEffect, useRef, useState } from "react";
import { imagesColor } from "../../../../../assets/UrlTypeColorIcons";
import PopUp from "../../../../../components/UI/PopUp/PopUp";
import MultipleFilePicker from "../../../../../components/UI/MultipleFilePicker/MultipleFilePicker";

const ProductDisplay = (props) => {
  const [dragging, setDragging] = useState(false);

  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [products, setProducts] = useState([]);
  const [productDetails, setProductDetails] = useState({
    phoneNo: "",
    actualPrice: "",
    discountedPrice: "",
    productName: "",
    storeAddress: "",
    productDetails: "",
    productImg: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  const handleAddProduct = () => {
    setProducts([...products, productDetails]);
    setProductDetails({
      phoneNo: "",
      actualPrice: "",
      discountedPrice: "",
      productName: "",
      storeAddress: "",
      productDetails: "",
      productImg: "",
    });
    setFiles([]);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    setDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles([...files, ...droppedFiles]);
    setProductDetails({
      productImg: [...files, ...droppedFiles],
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
    setProductDetails({
      productImg: [...files, ...selectedFiles],
    });
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // useEffect(() => {
  //   props.setProductDisplayDetails(productDisplayDetails);
  // }, [productDisplayDetails]);

  function togglePopup() {
    props.setIsProductDetailsEmpty(!props.isProductDetailsEmpty);
  }

  return (
    <>
      <div className="social_media_main_details">
        <div className="multiple_url_details_sub_div">
          <div className="sub_title url_type_heading">
            <span className="url_heading_icon">
              <img src={imagesColor} alt="videoColor" />
            </span>
            Product Display
          </div>
          {/* <div
            style={{
              color: "#31b963",
              fontSize: "40px",
              textAlign: "center",
              marginTop: "2rem",
            }}
            className="sub_title"
          >
            Coming Soon
          </div> */}

          <div className="prdct_form_wrapper">
            <form>
              <div className="prdct_lbl_inpt_wrapper">
                <label className="sub_title_child">Name:</label>
                <input
                  className="prdct_input inpt_height "
                  type="text"
                  name="productName"
                  value={productDetails.productName}
                  onChange={handleInputChange}
                  placeholder="Enter product Name"
                  required
                />
              </div>
              <br />

              <div className="two_prdct_inputs_wrapper">
                <div className="prdct_lbl_inpt_wrapper2">
                  <label className="sub_title_child">Original Price:</label>
                  <input
                    className="inpt_height text-center "
                    type="number"
                    name="actualPrice"
                    value={productDetails.actualPrice}
                    onChange={handleInputChange}
                    placeholder="Enter price"
                    required
                  />
                </div>
                <div className="prdct_lbl_inpt_wrapper2">
                  <label className="sub_title_child">Discounted Price:</label>
                  <input
                    className="inpt_height text-center "
                    type="number"
                    name="discountedPrice"
                    value={productDetails.discountedPrice}
                    onChange={handleInputChange}
                    placeholder="Enter discounted price"
                    required
                  />
                </div>
              </div>
              <br />

              <div className="prdct_lbl_number_wrapper">
                <label className="sub_title_child">Phone No:</label>
                <input
                  className="prdct_input inpt_height text-center "
                  type="number"
                  name="phoneNo"
                  value={productDetails.phoneNo}
                  onChange={handleInputChange}
                  placeholder="Enter phone number"
                  required
                />
              </div>
              <br />

              <div className="prdct_lbl_inpt_wrapper">
                <label className="sub_title_child">Product Details:</label>
                <textarea
                  className="prdct_input "
                  name="productDetails"
                  value={productDetails.productDetails}
                  onChange={handleInputChange}
                  placeholder="Enter product details"
                  rows={4}
                  required
                />
              </div>
              <br />

              <div className="prdct_lbl_inpt_wrapper">
                <label className="sub_title_child">Store Address:</label>
                <textarea
                  className="prdct_input "
                  name="storeAddress"
                  value={productDetails.storeAddress}
                  onChange={handleInputChange}
                  placeholder="Enter store address"
                  rows={4}
                  required
                />
              </div>
              <div className="prdct_img_wrapper">
                <div className="sub_title_child m_b_0_5">Product Images</div>
                <div>
                  <div
                    onClick={handleClick}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    style={{
                      border: `2px ${
                        dragging ? "dashed #3498db" : "dashed #ccc"
                      }`,
                      backgroundColor: dragging ? "#f0f0f0" : "",
                      padding: "20px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Drag and drop files here or click to select files
                  </div>

                  <input
                    type="file"
                    name="productImg"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    required
                  />
                </div>

                {files.length > 0 && (
                  <div className="m_t_1">
                    <div>Selected Files:</div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="add_prdct_btn_wrapper">
                <button
                  className="add_prdct_btn m_t_1"
                  onClick={handleAddProduct}
                >
                  + Add Product
                </button>
              </div>
            </form>

            <div>
              <h2>Product List</h2>
              <ul>
                {products.map((product, index) => (
                  <li key={index}>
                    {product.productName} - ${product.discountedPrice}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {props.isProductDetailsEmpty && (
            <PopUp onClose={togglePopup}>
              <>
                <div className="txt_center error_text p-3">
                  *All Fields are required{" "}
                </div>
              </>
            </PopUp>
          )}
        </div>
      </div>
    </>
  );
};
export default ProductDisplay;
