import { Ellipse, storeFront } from "../../../assets";

const dataArray1 = [
  {
    logo: storeFront,
    icon: Ellipse,
    text: "Suitable for small and large retail stores",
  },
  {
    icon: Ellipse,
    text: "Reach a wider audience",
  },
  {
    icon: Ellipse,
    text: "Increase brand awareness",
  },
  {
    icon: Ellipse,
    text: "Distribute pamphlets at random locations",
  },
];
export default dataArray1;
