/* eslint-disable no-const-assign */
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import ContactUs from "./components/ContactUs/ContactUs";
import ContactUsAds from "./components/AdvertiserPage/ContactUs/ContactUs";
import Hero from "./components/homePage/Hero/Hero";
import HowItWorks from "./components/homePage/HowItWorks/HowItWorks";
import Promoter from "./components/homePage/Promoter/Promoter";
import WhoCanUse from "./components/homePage/WhoCanUse/WhoCanUse";
import "./LandingPage_3.css";
import HeroSec from "./components/AdvertiserPage/HeroSec/HeroSec";
import Git from "./components/ContactUsPage/GetInTouchSection/Git";
import Testimonials from "./components/homePage/Testimonials/Testimonials";
import Nav from "./components/Nav/Nav";
import { useLocation } from "react-router-dom";
import AboutUsAdvertiser from "./components/AdvertiserPage/AboutUsAdvertizer/AboutUsAdvertiser";
import FAQ from "./components/homePage/FAQ/FAQ";
import EsteemedClients from "./components/homePage/EsteemedClients/EsteemedClients";
import AppDownloadsCard from "./components/AdvertiserPage/AboutUsAdvertizer/AppDownloadsCard/AppDownloadsCard";
import QrCodeSection from "./components/AdvertiserPage/QrCodeSection/QrCodeSection";
import EwayprintAppDetail from "./components/homePage/EwayprintAppDetail/EwayprintAppDetail";

function LandingPage_3(props) {
  const [page, setPage] = useState(props.selected);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [count, setCount] = useState(1);
  // const [MainPage, setMainPage] = useState("ewayprint");
  const location = useLocation();
  const ads = location.pathname.startsWith("/");
  const { ref: refSec, inView: isVisible } = useInView();

  useEffect(() => {
    if (isVisible && count < 2) {
      setTimeout(() => {
        setButtonPopup(true);
        setCount((state) => state + 1);
      }, 7000);
    }
  }, [ads, isVisible, count]);
  return (
    <div className="ads_landing_page_main_div">
      {/* <Popup trigger={buttonPopup} setButtonPopup={setButtonPopup}>
        <div style={{ overflow: "auto" }}>
          <Event1 />
        </div>
      </Popup> */}
      {/* <Popup trigger={buttonPopup} setButtonPopup={setButtonPopup}>
        <h3 className="popup-heading" align="center">
          Contact Us{" "}
        </h3>
        <div style={{ overflow: "auto" }}>
          <FormSec />
        </div>
      </Popup> */}
      {/* <WhatsAppFloatIcon></WhatsAppFloatIcon> */}
      <Nav page={page} setPage={setPage} />
      {/* <Nav page={page} setPage={setPage} /> */}
      {page === "home" && (
        <>
          <Hero />
          <WhoCanUse />
          {/* <HowItWorks /> */}
          <div className="downloads_main_div_wrapper">
            <AppDownloadsCard home={true} />
          </div>

          <Testimonials />
          <Promoter />
          {/* <AboutUs /> */}
        </>
      )}
      {page === "advertiser" && (
        <>
          {/* <Popup
            id="popUp123"
            trigger={buttonPopup}
            setButtonPopup={setButtonPopup}
          >
            <h3 className="popup-heading" align="center">
              Contact Us{" "}
            </h3>
            <div style={{ overflow: "auto" }}>
              <FormSec />
            </div>
          </Popup> */}
          {/* <WhatsAppFloatIcon></WhatsAppFloatIcon> */}

          <HeroSec />
          <EsteemedClients />
          <AboutUsAdvertiser />
          <QrCodeSection />
          <HowItWorks />
          <FAQ />
          <ContactUsAds />
        </>
      )}
      {page === "contactUs" && (
        <>
          <Git style={{ marginBottom: "5%" }} />
          {/* <FormSec /> */}
        </>
      )}
      {page === "Terms&Conditions" && <></>}

      <ContactUs forward={refSec} page={page} setPage={setPage} />
    </div>
  );
}

export default LandingPage_3;
