/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import "./WhatsAppFloatIcon.css";
import whatsapp from "./whatsapp.gif";
import { useLocation } from "react-router-dom";

function WhatsAppFloatIcon() {
  const location = useLocation();
  const ads = location.pathname.startsWith("/ads");
  var number = ads ? "+917770010066" : "+919021410511";

  /* Storing user's device details in a variable*/
  let details = navigator.userAgent;

  /* Creating a regular expression 
  containing some mobile devices keywords 
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(details);

  // if (isMobileDevice) {
  //   console.log("You are using a Mobile Device");
  // } else {
  //   console.log("You are using Desktop");
  // }
  return (
    <div className="whatsapp-float-icon ">
      {!isMobileDevice && (
        <a
          href={`https://web.whatsapp.com/send?phone=${number}&amp;text=Hi`}
          target="_blank"
        >
          <img src={whatsapp} alt="whatsapp"></img>
        </a>
      )}
      {isMobileDevice && (
        <a href={`whatsapp://send?phone=$number${number}`}>
          <img src={whatsapp} alt="whatsapp"></img>
        </a>
      )}
    </div>
  );
}

export default WhatsAppFloatIcon;
