import logo from "./../Group 46.png";
import playStoreIcon from "./../play store.svg";
import downloadIcon from "./../download_for_offline (1).svg";
import printConnect from "./../print_connect.svg";
import "./AppDownloadsCard.css";
const AppDownloadsCard = (props) => {
  return (
    <div className="downloads_main_div">
      <div className="downloads_child_div1">
        <img src={logo} alt="logo"></img>
      </div>

      {!props.home && (
        <div className="downloads_child_div2">
          <div className="downloads_num">14,000+</div>
          <div className="downloads_num__text">
            <span style={{ marginRight: "0.5rem" }} className="download_icon">
              <img src={downloadIcon} alt="downloadIcon" />
            </span>
            App downloads
          </div>
        </div>
      )}

      {props.home && (
        <div className="downloads_child_div3">
          <div className="downloads_num">11,20,800+</div>
          <div className="downloads_num__text">
            <span style={{ marginRight: "0.5rem" }} className="download_icon">
              <img src={printConnect} alt="printConnect" />
            </span>
            Pages Delivered
          </div>
        </div>
      )}

      <div className="downloads_child_div1">
        <div className="available_on_text">Available on </div>
        <div className="play_store_text">
          <span>
            <img src={playStoreIcon} alt="playStoreIcon" />
          </span>
          Play Store
        </div>
      </div>
    </div>
  );
};
export default AppDownloadsCard;
