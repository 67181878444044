import React from "react";
import pdf from "./example.pdf";

const PDFReader = () => {
  var link = `https://docs.google.com/gview?url=pdf.pdf&embedded=true`;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <object
        // data="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf"
        data={pdf}
        width="100"
        height="141"
      ></object> */}
      <a href={pdf} target="_blank" rel="noreferrer">
        Read more
      </a>
    </div>
  );
};

export default PDFReader;
