import { useEffect } from "react";
import {
  benefitsIcon,
  check,
  cstmzBgtIcon,
  diamondSymbolIcon,
  rightArrowNew,
} from "../../../assets";
import { API_URL } from "../../../constant/apiURL";
import { useState } from "react";
import "./BusinessCheckboxCard.css";
import HztlBarDotted from "../HztlBarDotted/HztlBarDotted";

const BusinessCheckboxCard = (props) => {
  // checkbox adtype
  const [checkboxStates, setCheckboxStates] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleCheckboxChange = (index) => {
    const newCheckboxStates = [...checkboxStates];
    newCheckboxStates[index] = !newCheckboxStates[index];
    if (index === 0 && newCheckboxStates[1]) {
      newCheckboxStates[1] = false;
    } else if (index === 1 && newCheckboxStates[0]) {
      newCheckboxStates[0] = false;
    }

    setCheckboxStates(newCheckboxStates);
  };

  useEffect(() => {
    if (
      props.card2 &&
      props.selectedPlan2 &&
      !props.selectedPlan3 &&
      !props.selectedPlan4
    ) {
      props.setAdTypesCard2([...checkboxStates]);
      props.setAdTypesCard3([false, false, false, false, false]);
      props.setAdTypesCard4([false, false, false, false, false]);
    }
    if (
      props.card3 &&
      !props.selectedPlan2 &&
      props.selectedPlan3 &&
      !props.selectedPlan4
    ) {
      props.setAdTypesCard2([false, false, false, false, false]);
      props.setAdTypesCard3([...checkboxStates]);
      props.setAdTypesCard4([false, false, false, false, false]);
    }
    if (
      props.card4 &&
      !props.selectedPlan2 &&
      !props.selectedPlan3 &&
      props.selectedPlan4
    ) {
      props.setAdTypesCard2([false, false, false, false, false]);
      props.setAdTypesCard3([false, false, false, false, false]);
      props.setAdTypesCard4([...checkboxStates]);
    }
  }, [
    ...checkboxStates,
    props.selectedPlan2,
    props.selectedPlan3,
    props.selectedPlan4,
  ]);

  // states for bids
  const [pamphletBid, setPamphletBid] = useState();
  const [footerBid, setFooterBid] = useState();
  const [displayAdBid, setDisplayAdBid] = useState();
  const [labelAdBid, setLabelAdBid] = useState();
  const [qrCodeBid, setQrCodeBid] = useState();

  const [expanded, setExpanded] = useState(false);

  const toggleText = () => {
    setExpanded(!expanded);
  };

  let pamphB;
  let footerB;
  let displayAdB;
  let labelAdB;
  let qrB;

  let pamphC;
  let footerC;
  let displayAdC;
  let labelAdC;
  let qrC;

  const DA590 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Double" &&
    props.pammphletQlty === "90GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "DA590";

  const SA590 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Single" &&
    props.pammphletQlty === "90GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "SA590";

  const DA490 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Double" &&
    props.pammphletQlty === "90GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "DA490";

  const SA490 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Single" &&
    props.pammphletQlty === "90GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "SA490";

  const DA5130 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Double" &&
    props.pammphletQlty === "130GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "DA5130";

  const SA5130 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Single" &&
    props.pammphletQlty === "130GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "SA5130";

  const DA4130 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Double" &&
    props.pammphletQlty === "130GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "DA4130";

  const SA4130 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Single" &&
    props.pammphletQlty === "130GSM" &&
    (checkboxStates[0] || checkboxStates[1]) &&
    "SA4130";

  const CM1 = props.footerSize === "1cm" && "CM1";
  const CM2 = props.footerSize === "2cm" && "CM2";

  const l1 = props.displayAdSize === "l1" && "l1";
  const l2 = props.displayAdSize === "l2" && "l2";

  const S1 = props.labelAdSize === "1" && "S1";
  const S2 = props.labelAdSize === "2" && "S2";

  const QRDA5 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Double" &&
    checkboxStates[1] &&
    "QRDA5";

  const QRSA5 =
    props.pammphletSize === "A5" &&
    props.pammphletSide === "Single" &&
    checkboxStates[1] &&
    "QRSA5";

  const QRDA4 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Double" &&
    checkboxStates[1] &&
    "QRDA4";

  const QRSA4 =
    props.pammphletSize === "A4" &&
    props.pammphletSide === "Single" &&
    checkboxStates[1] &&
    "QRSA4";

  const codeOfPamph =
    DA590 || SA590 || DA490 || SA490 || DA5130 || SA5130 || DA4130 || SA4130;

  const codeOfFooter = CM1 || CM2;
  const codeOfDisplayAd = l1 || l2;
  const codeOfLabelAd = S1 || S2;
  const codeOfQrPamph = QRDA5 || QRSA5 || QRDA4 || QRSA4;

  const overAllBudget =
    props.isCtmBgt && props.saveBgt
      ? props.customBgt
      : props.selectedRecomendedBgt;

  // ad types apis of bid
  useEffect(() => {
    // pamphlet bid list
    fetch(API_URL + "bids/pamphlet-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setPamphletBid(data))
      .catch((error) => console.log("Error Fetching Data", error));

    // footer bid list
    fetch(API_URL + "bids/footer-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())

      .then((data) => setFooterBid(data))
      .catch((error) => console.log("Error Fetching Data", error));

    // Display Ad Bid List
    fetch(API_URL + "bids/display-ad-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setDisplayAdBid(data))
      .catch((error) => console.log("Error Fetching Data", error));

    // Label Ad Bid List
    fetch(API_URL + "bids/label-ad-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setLabelAdBid(data))
      .catch((error) => console.log("Error Fetching Data", error));

    fetch(API_URL + "bids/qr-code-bid-list/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setQrCodeBid(data))
      .catch((error) => console.log("Error Fetching Data", error));
  }, []);

  // functions to get bid and constant
  function pamphletBidHandler() {
    for (let i = 0; i < pamphletBid?.length; i++) {
      if (codeOfPamph === pamphletBid[i]?.code) {
        pamphB = pamphletBid[i]?.bid;

        pamphC = pamphletBid[i]?.constant;
      }
    }
  }
  pamphletBidHandler();

  function footerBidHandler() {
    for (let i = 0; i < footerBid?.length; i++) {
      if (codeOfFooter === footerBid[i]?.code) {
        footerB = footerBid[i]?.bid;
        footerC = footerBid[i]?.constant;
      }
    }
  }
  footerBidHandler();

  function displayAdBidHandler() {
    for (let i = 0; i < displayAdBid?.length; i++) {
      if (codeOfDisplayAd === displayAdBid[i]?.code) {
        displayAdB = displayAdBid[i]?.bid;
        displayAdC = displayAdBid[i]?.constant;
      }
    }
  }
  displayAdBidHandler();

  function labelAdBidHandler() {
    for (let i = 0; i < labelAdBid?.length; i++) {
      if (codeOfLabelAd === labelAdBid[i]?.code) {
        labelAdB = labelAdBid[i]?.bid;

        labelAdC = labelAdBid[i]?.constant;
      }
    }
  }
  labelAdBidHandler();

  function qrPamphBidHandler() {
    for (let i = 0; i < qrCodeBid?.length; i++) {
      if (codeOfQrPamph === qrCodeBid[i]?.code) {
        qrB = qrCodeBid[i]?.bid;

        qrC = qrCodeBid[i]?.constant;
      }
    }
  }
  qrPamphBidHandler();

  let lcmFactor;

  if (
    checkboxStates[0] &&
    !checkboxStates[1] &&
    checkboxStates[2] &&
    checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = pamphC + footerC + displayAdC + labelAdC;
  }

  if (
    !checkboxStates[0] &&
    checkboxStates[1] &&
    checkboxStates[2] &&
    checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = qrC + pamphC + footerC + displayAdC + labelAdC;
  }

  if (
    !checkboxStates[0] &&
    !checkboxStates[1] &&
    checkboxStates[2] &&
    checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = footerC + displayAdC + labelAdC;
  }

  if (
    checkboxStates[0] &&
    !checkboxStates[1] &&
    !checkboxStates[2] &&
    checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = pamphC + displayAdC + labelAdC;
  }
  if (
    !checkboxStates[0] &&
    checkboxStates[1] &&
    !checkboxStates[2] &&
    checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = qrC + pamphC + displayAdC + labelAdC;
  }

  if (
    checkboxStates[0] &&
    !checkboxStates[1] &&
    checkboxStates[2] &&
    !checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = pamphC + footerC + labelAdC;
  }
  if (
    !checkboxStates[0] &&
    checkboxStates[1] &&
    checkboxStates[2] &&
    !checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = qrC + pamphC + footerC + labelAdC;
  }

  if (
    checkboxStates[0] &&
    !checkboxStates[1] &&
    checkboxStates[2] &&
    checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = pamphC + footerC + displayAdC;
  }
  if (
    !checkboxStates[0] &&
    checkboxStates[1] &&
    checkboxStates[2] &&
    checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = qrC + pamphC + footerC + displayAdC;
  }

  if (
    checkboxStates[0] &&
    !checkboxStates[1] &&
    checkboxStates[2] &&
    !checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = pamphC + footerC;
  }
  if (
    !checkboxStates[0] &&
    checkboxStates[1] &&
    checkboxStates[2] &&
    !checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = qrC + pamphC + footerC;
  }

  if (
    !checkboxStates[0] &&
    !checkboxStates[1] &&
    checkboxStates[2] &&
    checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = footerC + displayAdC;
  }

  if (
    checkboxStates[0] &&
    !checkboxStates[1] &&
    !checkboxStates[2] &&
    !checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = pamphC + labelAdC;
  }
  if (
    !checkboxStates[0] &&
    checkboxStates[1] &&
    !checkboxStates[2] &&
    !checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = qrC + pamphC + labelAdC;
  }

  if (
    !checkboxStates[0] &&
    !checkboxStates[1] &&
    !checkboxStates[2] &&
    checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = displayAdC + labelAdC;
  }

  if (
    checkboxStates[0] &&
    !checkboxStates[1] &&
    !checkboxStates[2] &&
    checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = pamphC + displayAdC;
  }
  if (
    !checkboxStates[0] &&
    checkboxStates[1] &&
    !checkboxStates[2] &&
    checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = qrC + pamphC + displayAdC;
  }

  if (
    !checkboxStates[0] &&
    !checkboxStates[1] &&
    checkboxStates[2] &&
    !checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = footerC + labelAdC;
  }

  if (
    checkboxStates[0] &&
    !checkboxStates[1] &&
    !checkboxStates[2] &&
    !checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = pamphC;
  }
  if (
    !checkboxStates[0] &&
    checkboxStates[1] &&
    !checkboxStates[2] &&
    !checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = qrC + pamphC;
  }

  if (
    !checkboxStates[0] &&
    !checkboxStates[1] &&
    checkboxStates[2] &&
    !checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = footerC;
  }

  if (
    !checkboxStates[0] &&
    !checkboxStates[1] &&
    !checkboxStates[2] &&
    checkboxStates[3] &&
    !checkboxStates[4]
  ) {
    lcmFactor = displayAdC;
  }

  if (
    !checkboxStates[0] &&
    !checkboxStates[1] &&
    !checkboxStates[2] &&
    !checkboxStates[3] &&
    checkboxStates[4]
  ) {
    lcmFactor = labelAdC;
  }

  const amountForPamph = (overAllBudget * pamphC) / lcmFactor;
  const amountForFooter = (overAllBudget * footerC) / lcmFactor;
  const amountForDisplayAd = (overAllBudget * displayAdC) / lcmFactor;
  const amountLabelAd = (overAllBudget * labelAdC) / lcmFactor;
  const amountForQrPamph = (overAllBudget * (pamphC + qrC)) / lcmFactor;

  let qtyForPamph = Math.round(amountForPamph / pamphB);
  let qtyForFtr = Math.round(amountForFooter / footerB);
  let qtyForDsplAd = Math.round(amountForDisplayAd / displayAdB);
  let qtyForLblAd = Math.round(amountLabelAd / labelAdB);
  let qtyForQrPamph = Math.round(amountForQrPamph / (pamphB + qrB));

  useEffect(() => {
    if (
      checkboxStates[0] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setPamphQty(qtyForPamph);
    }
    if (
      checkboxStates[1] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setPamphQty(qtyForQrPamph);
      props.setCodeOfPamph(codeOfPamph);
    }

    if (
      checkboxStates[2] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setFtrQty(qtyForFtr);
    }

    if (
      checkboxStates[3] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setDispQty(qtyForDsplAd);
    }

    if (
      checkboxStates[4] &&
      (props.selectedPlan2 || props.selectedPlan3 || props.selectedPlan4)
    ) {
      props.setLabelQty(qtyForLblAd);
    }
  });

  const checkboxLabels = [
    props.pammphletSize === "A4" && props.pammphletSide === "Double"
      ? "Half Fold DS + QR"
      : "Standard",
    props.pammphletSize === "A4" && props.pammphletSide === "Double"
      ? "Tri-Fold DS + QR"
      : props.pammphletSide === "Double"
      ? "Standard DS + QR"
      : "Standard + QR",
    props.footerSize === "1cm" ? "Footer" : "Footer + QR",
    "Display Ad",
    "Front Page",
  ];
  return (
    <>
      <div
        className={
          props.darkTheme
            ? "new__business_budget_card_main_div_dark"
            : props.selectedPlan
            ? "new_business_budget_card_main_div_selected"
            : "new_business_budget_card_main_div"
        }
      >
        <div className="new_budget_card_sub_div">
          <div className="budget_detail_wrapper">
            {props.isVoucher && (
              <div className="voucher_card_label_div">
                <div className="f_s_14 text-center voucher_plan_label">
                  {props.isLocation
                    ? "Location-Focused Voucher Plan"
                    : "Standard Voucher Plan"}
                </div>
              </div>
            )}
            <div className="months_plan_wrapper">
              <div className="month_plan">
                <img
                  style={{ width: "100%" }}
                  src={props.monthPlan}
                  alt="Months"
                />
              </div>
            </div>
            <div className="amount_detail_wrapper">
              <div className="budget_name_text f_s_18 f_w_500">Budget</div>
              <div className="budget_amount_txt">₹{overAllBudget}</div>
            </div>
            <HztlBarDotted style={{ margin: "0.5rem 0rem" }} />
            <div className="quantity_detail_wrapper f_s_16 f_w_500">
              {checkboxStates[0] && (
                <div className="  qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper  ">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp;
                    {props.pammphletSize === "A4" &&
                    props.pammphletSide === "Double"
                      ? "Half Fold DS + QR :"
                      : "Standard :"}
                  </div>{" "}
                  <div className="rec_cus_qty_wrapper">
                    {checkboxStates[0] && qtyForPamph} Qty
                  </div>
                </div>
              )}
              {checkboxStates[1] && (
                <div className="  qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper  ">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp;
                    {props.pammphletSize === "A4" &&
                    props.pammphletSide === "Double"
                      ? "Tri-Fold DS + QR :"
                      : props.pammphletSide === "Double"
                      ? "Standard DS + QR :"
                      : "Standard + QR :"}
                  </div>{" "}
                  <div className="rec_cus_qty_wrapper">
                    {checkboxStates[1] && qtyForQrPamph} Qty
                  </div>
                </div>
              )}
              {checkboxStates[2] && (
                <div className="  qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper  ">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp;
                    {props.footerSize === "1cm" ? "Footer :" : "Footer + QR :"}
                  </div>{" "}
                  <div className="rec_cus_qty_wrapper">{qtyForFtr} Qty</div>
                </div>
              )}
              {checkboxStates[3] && (
                <div className=" qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper  ">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp; Display Ad :
                  </div>{" "}
                  <div className="rec_cus_qty_wrapper">{qtyForDsplAd} Qty</div>
                </div>
              )}
              {checkboxStates[4] && (
                <div className="  qty_labl_input_wrapper">
                  <div className="qty_lbl_wrapper  ">
                    <img
                      className="diamond_symbl_icon"
                      src={diamondSymbolIcon}
                      alt="diamondSymbolIcon"
                    />
                    &nbsp; Front Page :
                  </div>

                  <div className="rec_cus_qty_wrapper">{qtyForLblAd} Qty</div>
                </div>
              )}
            </div>
            {!props.isCustomInput && (
              <div className="customize_budget_btn_wrapper">
                <button
                  className={
                    props.darkTheme
                      ? "customize_btn_dark_theme"
                      : "customize_btn"
                  }
                  onClick={props.ctmBgtOnClick}
                >
                  Customize Budget &nbsp;
                  <span>
                    <img
                      width="18px"
                      height="20px"
                      src={cstmzBgtIcon}
                      alt="cstmzBgtIcon"
                    />
                  </span>
                </button>
              </div>
            )}
            {props.isCustomInput && (
              <div className="custom_input_btn_wrapper">
                <div className="custom_input_btn_sub_wrapper">
                  <div className="custom_input_wrapper">
                    <input
                      pattern="^[0-9]*$"
                      className={
                        props.darkTheme
                          ? "ctm_bgt_input_dark_theme"
                          : "ctm_bgt_input"
                      }
                      type="text"
                      value={props.customBgt}
                      onChange={props.onChange}
                    />
                    {props.minBgtError && (
                      <div className="error_text_child2">
                        Please enter budet above 2000
                      </div>
                    )}
                    {props.maxBgtError && (
                      <div className="error_text_child2">
                        Please enter budet below 500000
                      </div>
                    )}
                  </div>
                  <div>
                    <button
                      className={
                        props.darkTheme
                          ? "bgt_save_btn_dark_theme"
                          : "bgt_save_btn"
                      }
                      onClick={props.saveBgtOnClick}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="reset_btn_wrapper">
                  <button
                    className={
                      props.darkTheme ? "reset_btn_dark_theme" : "reset_btn"
                    }
                    onClick={props.resetOnClick}
                  >
                    Reset Budget
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* <hr style={{ height: "2px", margin: "10px 0px" }} /> */}

          <HztlBarDotted style={{ margin: "1rem 0rem" }} />
          <div className="benefits_wrapper">
            <div className="benefits_sub_wrapper">
              <p className="f_s_16 f_w_500">
                {" "}
                <span>
                  <img src={rightArrowNew} alt="rightArrowNew" />
                </span>
                &nbsp; Choose an Ad Type to Display Quantity.
              </p>
              <div className="benefits_list m_t_0_5">
                {checkboxStates.map((isChecked, index) => (
                  <div key={index} className="adtype_labl_chbx_wrapper">
                    <label className="f_s_16 f_w_500">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      &nbsp;&nbsp;
                      {checkboxLabels[index]}
                    </label>
                  </div>
                ))}
              </div>

              <div className="choose_plan_btn_wrapper">
                <button
                  className={
                    props.selectedPlan || props.comingSoon
                      ? "choose_plan_btn_selected"
                      : "choose_plan_btn"
                  }
                  onClick={props.chooseOnClick}
                >
                  {props.selectedPlan && !props.comingSoon ? (
                    <>
                      <span className="f_s_16 f_w_500 white_text">
                        Selected
                      </span>
                      &nbsp;
                      <img className="" src={check} alt="check" />
                    </>
                  ) : props.comingSoon ? (
                    "Coming Soon"
                  ) : (
                    "Choose Plan"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BusinessCheckboxCard;
