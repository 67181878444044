import "./PlanCodeReviewCard.css";
import greenCheck from "./Tick-icon.svg";

const PlanCodeReviewCard = (props) => {
  return (
    <>
      <div className="plan_code_review_main_div">
        <div className="plan_code_review_child1">
          <div className="sub_title">Pamphlet Quantity</div>
          <div style={{ textAlign: "center" }} className="sub_title">
            {props.quantity}
          </div>
        </div>

        <div className="plan_code_review_child2">
          <div className="sub_title">
            Price &nbsp;- ₹{props.amount}{" "}
            <span className="total_amount_using_code">
              &nbsp;₹{props.totalAmountUsingCode}
            </span>
          </div>
          <div className="sub_title" style={{ marginTop: "1rem" }}>
            <div className="discount_text">
              {props.discount}% Discount Applied
            </div>
          </div>
        </div>

        <div className="plan_code_review_child3">
          <div className="sub_title">
            {props.sizeUsingCode}&nbsp;-&nbsp;
            {props.sideUsingCode === true ? "Double Side" : "Single Side"}
          </div>
        </div>
      </div>
    </>
  );
};
export default PlanCodeReviewCard;
