import { useEffect, useRef, useState } from "react";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import { infoIcon, expandArrow2 } from "../../../assets";
import { checkCircle, checkCircleWhite } from "../../../assets";
import HorizontalDivider from "../../../components/UI/Dividers/HorizontalDivider";
import Button from "../../../components/UI/GreenBtn/Button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FileInput from "../../../components/UI/FileInput/FileInput";
import { handleBreakpoints } from "@mui/system";
import { Grid } from "@mui/material";
import Input from "../../../components/UI/Inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../constant/apiURL";
import SelectDropdown from "../../../components/UI/SelectDropdown/SelectDropdown";
import { campaignActions } from "../../../store/campaign-slice";
import Multiselect from "multiselect-react-dropdown";
import "./ReachNewAudience.css";
import CreateQrCode from "../CreateQrCode/CreateQrCode";
import FileInputForVoucher from "../../../components/UI/FileInputForVoucher/FileInputForVoucher";
import useHttp from "../../../Hooks/useHttp";
import PopUp from "../../../components/UI/PopUp/PopUp";
import infoIcon2 from "./info.svg";
const ReachNewAudience = (props) => {
  const token = useSelector((state) => state.auth.token);
  const clientId = useSelector((state) => state.auth.clientId);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const multiSelectRef = useRef();
  const [listOfPinCodes, setListOfPinCodes] = useState([]);
  const qrCodeId = useSelector((state) => state.campaigns.qrCodeId);
  const { id = 0 } = useParams();

  const [fileName, setFileName] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState(0);
  const [industryList, setIndustryList] = useState("");

  const [inputData, setInputData] = useState({
    campaignName: "",
    startOn: "",
    pamphlet: "",
    footer: "",
    displayAd: "",
    voucherName: "",
    offer: "",
    contactDetail: "",
    generateVoucherCode: "",
    attachedFile: null,
    fileName: "",
    pinCodes: null,
    industry: null,
    createdVoucherId: null,
  });
  // states for validation
  const [isCampaignNameEmpty, setIsCampaignNameEmpty] = useState(false);
  const [isStartOnEmpty, setIsStartOnEmpty] = useState(false);
  const [isIndustryEmpty, setIsIndustryEmpty] = useState(false);
  const [isVoucherNameEmpty, setIsVoucherNameEmpty] = useState(false);
  const [isOfferEmpty, setIsOfferEmpty] = useState(false);
  const [isContactDetailEmpty, setIsContactDetailEmpty] = useState(false);
  const [isContactValid, setIsContactValid] = useState(false);
  const [isFileAttached, setIsFileAttached] = useState(false);
  const [isPinCodeEmpty, setIsPinCodeEmpty] = useState(false);
  const [gotDataId, setGotDataId] = useState(false);
  const [selectAdType, setSelectAdType] = useState(false);

  //states for qr code
  const [campaignId, setCampaignId] = useState("");
  const [qrCreated, setQrCreate] = useState();
  // console.log(qrCreated);
  const [isChecked, setIsChecked] = useState(false);
  const [url, setUrl] = useState(false);

  // popup function
  const [showPopup, setShowPopup] = useState(false);
  function togglePopup() {
    setShowPopup(!showPopup);
  }

  //Date select Validation
  const [minDate, setMinDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 5);
    return date.toISOString().slice(0, 10);
  });

  const setData = (dataKey, dataVal) => {
    setInputData((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };
  // console.log(inputData.pinCodes);

  const getSelected = (item) => {
    props.setIndustry(item);
    setData("industry", item);
  };

  const fileReadHandler = (e) => {
    setFileName(e.target.files[0].name);
    setData("attachedFile", e.target.files[0]);
  };
  const getSelectedPinCode = (item) => {
    props.setPinCodes(item);
    setData("pinCodes", item);
  };

  // const submitCampaignCreate = () => {
  //   fetch(API_URL + "campaign/create/", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //     body: JSON.stringify({
  //       name: inputData.campaignName,
  //       sub_industry: selectedIndustry,
  //       pin_codes: "411048",
  //     }),
  //   }).then((res) => {
  //     if (res.ok) {
  //       return res.json().then((data) => {
  //         console.log(data);
  //       });
  //     }
  //   });
  // };
  useEffect(() => {
    (async () => {
      // Fetching PinCode List
      const pinCodes = await fetch(API_URL + "campaign/locations-list/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      const pinCodesOfLocations = await pinCodes.json();

      setListOfPinCodes(pinCodesOfLocations);

      // console.log(pinCodesOfLocations);
    })();
  }, []);
  let pinCodesList = [];
  pinCodesList.push(listOfPinCodes);

  useEffect(() => {
    if (token) {
      (async () => {
        // Fetching Industry List
        const resInd = await fetch(API_URL + "industries/list/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const dataInd = await resInd.json();
        // console.log(dataInd);
        setIndustryList(dataInd);
      })();
    }
  }, [token]);
  useEffect(() => {
    if (gotDataId) {
      // console.log(gotDataId);
    }
  }, [gotDataId]);

  const submitVoucherDetails = (e) => {
    const formData = new FormData();
    formData.append("voucher_name", inputData.voucherName);
    formData.append("tag_line", inputData.offer);
    formData.append("contact_number", inputData.contactDetail);
    formData.append("image", inputData.attachedFile);

    fetch(API_URL + "campaign/voucher-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        // setIsLoading(false);

        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            props.setVoucherId(data.id);
            setData("createdVoucherId", data.id);
            dispatch(campaignActions.setVoucherId({ voucherId: data.id }));
            // console.log("got data.id  " + data.id);
            // idOFVoucher.push({
            //   voucherId: data.id,
            // });
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            // console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  // console.log(idOFVoucher);
  const [
    locationList: data,
    locationError: error,
    isLocationLoading: isLoading,
  ] = useHttp(API_URL + "campaign/locations-list/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  let pinCodesCSV =
    locationList &&
    locationList.map((l) => l.pin_code !== "All" && l.pin_code).join(" ,");

  const submitQrCodeCampaign = (id) => {
    const formData = new FormData();
    formData.append("name", inputData.campaignName);
    formData.append("start_on", inputData.startOn);
    formData.append("sub_industry", inputData.industry);
    formData.append("qr_code_id", qrCodeId || id);
    formData.append("pin_codes", pinCodesCSV);
    fetch(API_URL + "campaign/create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        // setIsLoading(false);

        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setCampaignId(data.id);
            dispatch(campaignActions.setCampaignId({ campaignId: data.id }));
            dispatch(campaignActions.updateCampaignList({ campaign: data }));
            // console.log("got data.id  " + data.id);
            // idOFVoucher.push({
            //   voucherId: data.id,
            // });
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />
      <div className="brand_recall_main_div ">
        <div className="brand_recall_main__sub_container">
          <div
            style={{ marginTop: "1rem" }}
            className="create_campaign heading"
            onClick={() => {}}
          >
            Create Your Pamphlet Campaign
          </div>
          <div className=" m_t_1 m_b_1 campaign_name_div ">
            <div className=" " id="Campaign_name_Input">
              <div className="campaign_name_wrapper">
                <div className="sub_title">Campaign Name</div>
              </div>
              <Input
                // onChange={(e) => {
                //   props.setCampaignName(e.target.value);
                //   if (e.target.value.length === 0) {
                //     setIsCampaignNameEmpty(true);
                //   } else {
                //     setIsCampaignNameEmpty(false);
                //   }
                // }}
                onChange={(e) => setData("campaignName", e.target.value)}
                placeholder="Enter Campaign Name"
              ></Input>
            </div>
            {isCampaignNameEmpty && (
              <p style={{ color: "red" }}>please enter campaign name</p>
            )}
          </div>

          <div className="date_picker_div">
            <div className="campaign_name_wrapper">
              <div className="sub_title">Distribution Start Date</div>
            </div>
            <input
              onChange={(e) => {
                setData("startOn", e.target.value);
              }}
              type="date"
              name="date"
              min={minDate}
              id=""
              className="date_picker"
            />
            {isStartOnEmpty && (
              <p style={{ color: "red" }}>please select date</p>
            )}
          </div>

          <div className="industry_wrapper">
            <div className="industry  ">
              <SelectDropdown
                optionsArray={industryList}
                getSelected={getSelected}
                label="Select Your Business Category"
                className="m_b_1"
              />
              {isIndustryEmpty && (
                <p style={{ color: "red" }}>please select industry</p>
              )}
            </div>
          </div>
          {/* <div className="ad_types_card ">
            <div className="sub_title ad_types">
              Ad types
              <span className="info_span">
                <img src={infoIcon} alt="info" />
              </span>
            </div>
            <div
              className="choose_ad_type"
              // onClick={() => console.log(props.isChecked)}
            >
              Choose Atleast one Ad type
            </div>
            <div className="checkbox_group">
              <div className="checkbox_div1">
                <input
                  type="checkbox"
                  value="pamphlet"
                  id="pamphlet"
                  checked={props.isChecked.pamphletIsChecked}
                  onChange={(e) => {
                    props.setIsChecked((state) => ({
                      ...state,
                      pamphletIsChecked: e.target.checked,
                    }));
                  }}
                />
                &nbsp;&nbsp;
                <label htmlFor="pamphlet">Pamphlet</label>
              </div>

              <div className="checkbox_div2">
                <input
                  type="checkbox"
                  value="footer"
                  id="footer"
                  checked={props.isChecked.footerIsChecked}
                  onChange={(e) => {
                    props.setIsChecked((state) => ({
                      ...state,
                      footerIsChecked: e.target.checked,
                    }));
                  }}
                />
                &nbsp;&nbsp;
                <label htmlFor="footer">Footer</label>
              </div>

              <div className="checkbox_div2">
                <input
                  type="checkbox"
                  value="display_ad"
                  checked={props.isChecked.displayAdIsChecked}
                  id="display_ad"
                  onChange={(e) => {
                    props.setIsChecked((state) => ({
                      ...state,
                      displayAdIsChecked: e.target.checked,
                    }));
                  }}
                />
                &nbsp;&nbsp;
                <label htmlFor="display_ad">Display Ad</label>
              </div>
              {selectAdType ? (
                <p style={{ color: "red" }}>Please Select Atleast 1 Ad Type</p>
              ) : null}
            </div>
          </div> */}
          <div className="voucher_details_main_container ">
            <div className="voucher_details sub_title">
              Voucher Details
              {/* <span className="info_span">
                <img src={infoIcon} alt="info" />
              </span> */}
            </div>
            <div className="inputs_main_div">
              <Grid item xs={12}>
                <div className="w_100 m_t_1 m_b_2">
                  <div className="w_100" id="Campaign_name_Input">
                    <Input
                      onChange={(e) => setData("voucherName", e.target.value)}
                      label="Voucher Name"
                      placeholder="Enter Voucher Name"
                    ></Input>
                    {isVoucherNameEmpty && (
                      <p style={{ color: "red" }}>please enter voucher name</p>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="w_100 m_t_1 m_b_2">
                  <div className="w_100" id="Campaign_name_Input">
                    <Input
                      onChange={(e) => setData("offer", e.target.value)}
                      label="Tag Line (Offer)"
                      placeholder="Enter Offer"
                    ></Input>
                    {isOfferEmpty && (
                      <p style={{ color: "red" }}>please enter offer</p>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="w_100 m_t_1 m_b_2">
                  <div className="w_100" id="Campaign_name_Input">
                    <Input
                      onChange={(e) => {
                        setData("contactDetail", e.target.value);
                        if (
                          e.target.value.length < 10 ||
                          e.target.value.length > 10
                        ) {
                          setIsContactValid(true);
                        } else {
                          setIsContactValid(false);
                        }
                      }}
                      label="Contact Detail"
                      placeholder="Enter Contact Detail"
                      maxLength="10"
                      pattern="^[0-9]*$"
                      type="number"
                    ></Input>
                    {isContactValid && (
                      <p style={{ color: "red" }}>
                        Phone Number must contain 10 digits
                      </p>
                    )}
                  </div>

                  {isContactDetailEmpty ? (
                    <p style={{ color: "red" }}>
                      Contact Number must not be empty
                    </p>
                  ) : null}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="w_100 m_t_b_1">
                  <div className="w_100 body_title">Attach Voucher</div>
                  <div className="w_100 m_t_1">
                    <FileInputForVoucher
                      fileName={fileName}
                      onChange={fileReadHandler}
                      setFileName={setData}
                      isBrandRecall={location.state}
                    />
                    {isFileAttached && (
                      <p style={{ color: "red" }}>please attach file first</p>
                    )}
                  </div>
                </div>
              </Grid>
            </div>
          </div>
          <div className="pincodes_card">
            <div className="pincodes_card_main_div">
              <div className="location_text_wrapper">
                <div className="location_text create_heading sub_title">
                  Location
                </div>
              </div>
              <div className="location_paragraph_wrapper">
                <div className="location_paragraph">
                  Advertise in selected pincodes
                </div>
              </div>
              <div className="pincodes_dropdown">
                <Multiselect
                  options={pinCodesList[0]}
                  displayValue="pin_code"
                  onSelect={getSelectedPinCode}
                  ref={multiSelectRef}
                  selectedValues={inputData.pinCodes}
                />
                {isPinCodeEmpty && (
                  <p style={{ color: "red" }}>please select pin code</p>
                )}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "2rem" }} className="qr_checkbox_wrapper">
            <input
              type="checkbox"
              value="showQrCard"
              id="showQrCard"
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
            />
            &nbsp;&nbsp;
            <label htmlFor="showQrCard" className="sub_title">
              Create QR code (optional)
            </label>
            <div>
              When this QR is scanned, potential customers can be directed to
              your website, social media accounts, or even a promotional video.
            </div>
          </div>

          {isChecked && (
            <div className="qr_code_card ">
              <CreateQrCode
                setUrl={setUrl}
                campaignId={campaignId}
                setQrCreate={setQrCreate}
                submitQrCodeCampaign={submitQrCodeCampaign}
              />
            </div>
          )}
          <div style={{ marginBottom: "10rem" }}></div>
          {/* <div className="qr_code_main_div ">
            <div className="create_heading sub_title">Create QR Code</div>
            <div className="qr_code_details">
              QR codes are a great way to encourage people to visit your
              website, Social media page or contact you via whatsApp. Just
              Create a QR code and attach it to your ads. It's that easy!
            </div>

            <div className="create_qr_code_button">
              {props.qrCreated ? (
                <div className="qr_created_div">
                  <div className="qr_created">
                    QR Code Created &nbsp;
                    <img
                      className="checkCircleWhite"
                      src={checkCircleWhite}
                      alt="checkCircleWhite"
                    ></img>
                  </div>
                </div>
              ) : null}
              <button
                onClick={() =>
                  history.push({
                    pathname: "/ads/campaign/create/new/create-qr-code",
                    state: location.state,
                  })
                }
                className="edit_qr_code_button"
              >
                Create Qr Code
                <span>
                  <img src={expandArrow2} alt="expand arrow"></img>
                </span>
              </button>
            </div>
          </div> */}
          <div className="wrapper ">
            <div className="divider_brand_recall">
              <HorizontalDivider />
            </div>
            <div className="button_wrapper">
              <Button
                className="white"
                onClick={() => history.push("/ads/campaign/create/new")}
                btnTxtClassName=" sub_title text_black"
                name="Back"
              />
              <Button
                type="submit"
                name="Next"
                onClick={() => {
                  if (qrCreated) {
                    togglePopup();
                  } else {
                    if (inputData.campaignName === "") {
                      setIsCampaignNameEmpty(true);
                    }
                    if (inputData.startOn === "") {
                      setIsStartOnEmpty(true);
                    }
                    if (inputData.industry === null) {
                      setIsIndustryEmpty(true);
                    }
                    if (inputData.voucherName === "") {
                      setIsVoucherNameEmpty(true);
                    }
                    if (inputData.offer === "") {
                      setIsOfferEmpty(true);
                    }
                    if (inputData.contactDetail === "") {
                      setIsContactDetailEmpty(true);
                    }
                    if (inputData.attachedFile === null) {
                      setIsFileAttached(true);
                    }
                    if (inputData.pinCodes === null) {
                      setIsPinCodeEmpty(true);
                    }

                    // console.log(inputData);
                    let isValid =
                      inputData.campaignName !== "" &&
                      inputData.startOn !== "" &&
                      // inputData.footer !== "" &&
                      // inputData.displayAd !== "" &&
                      inputData.attachedFile !== null &&
                      inputData.pinCodes !== null &&
                      inputData.voucherName !== "" &&
                      inputData.offer !== "" &&
                      inputData.industry !== null &&
                      inputData.contactDetail !== "";
                    if (isValid) {
                      history.replace({
                        pathname: "/ads/campaign/create/new/stepper",
                        state: {
                          ...location.state,
                          ...inputData,
                          ...props.isChecked,
                        },
                      });
                      // console.log("state pushed");
                      submitVoucherDetails();
                    }
                    if (
                      !props.isChecked.pamphletIsChecked &&
                      !props.isChecked.footerIsChecked &&
                      !props.isChecked.displayAdIsChecked
                    ) {
                      setSelectAdType(true);
                    }
                  }
                }}
              />
            </div>
          </div>
          {showPopup && (
            <PopUp onClose={togglePopup}>
              <div className="info_icon_wrapper_campaign_modal">
                <img src={infoIcon2} alt="infoIcon2" />
              </div>

              <div style={{ textAlign: "center" }} className="sub_title">
                Add QR Code in your pamphlet and come back to create your
                campaign
              </div>
              {/* <div className="sub_text_campaign_modal">
                You can Resume creating your campaign from campaign list
              </div> */}
              <hr></hr>
              <div
                className="save_add_btn_campaign_modal"
                onClick={() => {
                  // submitQrCodeCampaign();
                  history.push("/ads/campaign-list");
                }}
              >
                Save QR code & Continue Later
              </div>
              <p
                style={{ textAlign: "center", fontSize: "15px" }}
                className="sub_title_child"
              >
                Choosing this option will save your campaign and allow you to
                add QR code in your pamphlet. You can resume creating campaign
                from campaign list.
              </p>
              <hr></hr>
              <div
                className="continue_btn_campaign_modal"
                onClick={() => {
                  togglePopup();
                  if (inputData.campaignName === "") {
                    setIsCampaignNameEmpty(true);
                  }
                  if (inputData.startOn === "") {
                    setIsStartOnEmpty(true);
                  }
                  if (inputData.industry === null) {
                    setIsIndustryEmpty(true);
                  }
                  // console.log(inputData);
                  let isValid =
                    inputData.campaignName !== "" &&
                    inputData.startOn !== "" &&
                    // inputData.footer !== "" &&
                    // inputData.displayAd !== "" &&
                    inputData.industry !== null;

                  if (isValid) {
                    history.push({
                      pathname:
                        "/ads/campaign/create/new/stepper/" + campaignId,
                      state: {
                        ...inputData,
                        ...props.isChecked,
                        ...location.state,
                        pamphletIsCheckedfromList: true,
                      },
                    });
                    // submitVoucherDetails();
                    // submitCampaignName();
                    // console.log(inputData);
                    // submitVoucherDetails();
                    // console.log(industryList);
                    // submitCampaignName();
                    // console.log(submitVoucherDetails);
                    // console.log(submitCampaignName);
                  }
                  if (
                    !props.isChecked.pamphletIsChecked &&
                    !props.isChecked.footerIsChecked &&
                    !props.isChecked.displayAdIsChecked
                  ) {
                    setSelectAdType(true);
                  }

                  if (props.campaignName === "") {
                    setIsCampaignNameEmpty(true);
                  }
                }}
              >
                Continue Now
              </div>
            </PopUp>
          )}
        </div>
      </div>
    </>
  );
};
export default ReachNewAudience;
