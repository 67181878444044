import React from "react";

import "./Hero.css";
import banner from "./Home-Page-Mobile-Banner.jpg";
import largeBanner from "./Home-Page-Slider-Banner.jpg";

function Hero() {
  let details = navigator.userAgent;

  /* Creating a regular expression 
  containing some mobile devices keywords 
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(details);
  return (
    <>
      <div
        className="hero-main-div"
        // style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="hero-main-div-img">
          {window.innerWidth < 600 && (
            <img className="hero-main-div-img" src={banner} alt="banner"></img>
          )}
          {window.innerWidth > 600 && (
            <img
              className="hero-main-div-img"
              src={largeBanner}
              alt="banner"
            ></img>
          )}
        </div>

        <div className="abs-grp">
          <div className="head-grp">
            <div className="head-1">ABSOLUTELY</div>
            <div className="head-2">
              GET <span style={{ color: "yellow" }}>FREE</span> PRINTS
            </div>
            <div className="head-3">DELIVERED AT HOME</div>
          </div>
          <div className="hero-button-flex">
            <a
              onClick="return gtag_report_conversion('http://ewayprint.com/home');"
              href="http://ewayprint.com/home"
            >
              <a
                href={
                  isMobileDevice
                    ? "https://ewayprint.page.link/eeow"
                    : "https://play.google.com/store/apps/details?id=com.iotexcel.ewayprint"
                }
                target="blank"
              >
                <button className="hero-button">
                  <span style={{ color: "white" }}>Download Now</span>
                </button>
              </a>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
