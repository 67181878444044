import React from "react";
import logo from "./logo.png";
import shortLogo from "./shortLogo.svg";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const CampaignHeader = (props) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/ads/login";
  const isRegistrationPage = location.pathname === "/ads/register";
  const isVerifyOtpPage = location.pathname === "/ads/verify";

  return (
    <>
      <div className={`Header__main_div ${props.className}`}>
        <div>
          <Link to="/">
            <img className="logo" src={logo} alt="logo"></img>
          </Link>
        </div>
        {!isLoginPage &&
          !isRegistrationPage &&
          location.pathname !== "/" &&
          !isVerifyOtpPage && (
            <>
              <div className="__divider"></div>
              <div className="new_campaign_text">New Campaign</div>
            </>
          )}
      </div>
    </>
  );
};

export default CampaignHeader;
