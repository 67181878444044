import "./BackNextBtn.css";
const BackNextBtn = (props) => {
  return (
    <>
      <div className="qr_code_creater_btns_wrapper">
        <div>
          <button className="back_btn_qr_creater" onClick={props.back}>
            Back
          </button>
        </div>
        <div>
          <button onClick={props.createOnClick} className="create_qr_btns">
            Create QR Code
          </button>
        </div>
      </div>
    </>
  );
};
export default BackNextBtn;
