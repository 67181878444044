import { createSlice } from "@reduxjs/toolkit";

import { API_URL } from "../constant/apiURL";
import { forEach } from "lodash";

const initialState = {
  campaigns: [],
  selectedCampaign: null,
  campaignId: null,
  voucherId: null,
  pamphletId: null,
  footerId: null,
  displayAdId: null,
  labelAdId: null,
  qrCodeId: null,
  overAllData: null,
  ratesTableRowData: null,
  amountToMultiply: null,
  websiteDetails: null,
  locationDetails: null,
  socialMediaDetails: null,
  videoDetails: null,
  campaignDetails: null,
  pamphletData: null,
  adTypeData: null,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    replaceCampaigns(state, action) {
      action.payload.forEach((campaign) => {
        let totalToBeDistribute =
          (campaign.pamphlet_id && campaign.qty_for_pamphlet) +
          (campaign.footer_id && campaign.qty_for_footer) +
          (campaign.display_ad_id && campaign.qty_for_display_ad) +
          (campaign.label_ad_id && campaign.qty_for_label_ad);

        let totalDistributed =
          (campaign.pamphlet_id && campaign.dist_qty_for_pamphlet) +
          (campaign.footer_id && campaign.dist_qty_for_footer) +
          (campaign.display_ad_id && campaign.dist_qty_for_display_ad) +
          (campaign.label_ad_id && campaign.dist_qty_for_label_ad);

        campaign.status = campaign.is_part_paid
          ? "Partially Paid"
          : !campaign.is_paid && !campaign.is_part_paid
          ? "Not Paid"
          : !campaign.is_approved
          ? "Pending"
          : !campaign.is_active && totalToBeDistribute !== totalDistributed
          ? "Paused"
          : campaign.is_active && totalToBeDistribute !== totalDistributed
          ? "Running"
          : "Finished";
      });
      state.campaigns = action.payload;
      state.selectedCampaign = action.payload[0]?.id;
    },
    changeSelectedCampaign(state, action) {
      state.selectedCampaign = action.payload.id;
    },
    setVoucherId(state, action) {
      state.voucherId = action.payload.voucherId;
    },
    setCampaignId(state, action) {
      state.campaignId = action.payload.campaignId;
    },
    setFooterId(state, action) {
      state.footerId = action.payload.footerId;
    },
    setDisplayAdId(state, action) {
      state.displayAdId = action.payload.displayAdId;
    },
    setLabelAdId(state, action) {
      state.labelAdId = action.payload.labelAdId;
    },
    setPamphletId(state, action) {
      state.pamphletId = action.payload.pamphletId;
    },
    setQrCodeId(state, action) {
      state.qrCodeId = action.payload.qrCodeId;
    },

    setOverAllData(state, action) {
      state.overAllData = { ...action.payload.overAllData };
    },
    setCampaignDetails(state, action) {
      state.campaignDetails = action.payload.campaignDetails;
    },
    setPamphletData(state, action) {
      state.pamphletData = action.payload.pamphletData;
    },
    setAdTypeData(state, action) {
      state.adTypeData = action.payload.adTypeData;
    },

    setRatesTableRowData(state, action) {
      state.ratesTableRowData = action.payload.ratesTableRowData;
    },
    getCampaignById(state, action) {
      state.campaigns.forEach((campaign) => console.log(campaign));
    },
    setAmountToMultiply(state, action) {
      state.amountToMultiply = action.payload.amountToMultiply;
    },

    updateCampaignList(state, action) {
      state.campaigns = [...state.campaigns, action.payload.campaign];
    },
    setWebsiteDetails(state, action) {
      state.websiteDetails = action.payload.websiteDetails;
    },
    setLocationDetails(state, action) {
      state.locationDetails = action.payload.locationDetails;
    },
    setSocialMediaDetails(state, action) {
      state.socialMediaDetails = action.payload.socialMediaDetails;
    },
    setVideoDetails(state, action) {
      state.videoDetails = action.payload.videoDetails;
    },
  },
});

export const fetchCampaignData = (token) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const res = await fetch(API_URL + "campaign/list/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (!res.ok) {
        // console.log(res);
        throw new Error("Could not fetch data");
      }

      const data = await res.json();

      return data;
    };

    try {
      const campaignsData = await fetchData();
      dispatch(campaignSlice.actions.replaceCampaigns(campaignsData));
    } catch (error) {
      console.error(error);
    }
  };
};

export const campaignActions = campaignSlice.actions;

export default campaignSlice.reducer;
