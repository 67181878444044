/* eslint-disable react/jsx-pascal-case */
import React, { Fragment, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/AuthPages/Login";
import ChangePassword from "./pages/AuthPages/ChangePassword";
import LandingPage_3 from "./components/LandingPage_3/LandingPage_3";
import TandC from "./components/Terms&Conditions/TandC";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ContactUsPage from "./components/LandingPage_3/components/ContactUsPage/ContactUsPage";
// import Header from "./components/CampaignLayout/Header/CampaignHeader";
// import CampaignLayout from "./components/CampaignLayout/CampaignLayout";
// import Input from "./components/UI/Inputs/Input";
// import Button from "./components/UI/GreenBtn/Button";
import "./App.css";
import { useSelector } from "react-redux";
import BusinessInfo from "./pages/AuthPages/BusinessInfo/BusinessInfo";
import DashboardLayout from "./components/DashboardLayout/DashboardLayout";
import { useState } from "react";
import CampaignList from "./components/Campaign/CampaignList/CampaignList";
import PamphletList from "./components/Pamphlet/PamphletList/PamphletList";

import PageNotFound from "./components/PageNotFound/PageNotFound";
import PaymentFailure from "./components/PaymentFailure/PaymentFailure";
import CampaignListDetail from "./components/Campaign/CampaignListDetail/CampaignListDetail";
import { useEffect } from "react";
import PDFReader from "./components/PDFReader/PDFReader";
import CampaignCreateNew from "./pages/NewFlowOfCampaign/CampaignCreateNew";
import CampaignBrandRecall from "./pages/NewFlowOfCampaign/BrandRecall/CampaignBrandRecall";
import CreateQrCode from "./pages/NewFlowOfCampaign/CreateQrCode/CreateQrCode";
import CampaignNewStepper from "./pages/NewFlowOfCampaign/CampaignNewStepper/CampaignNewStepper";
import SetBudget from "./pages/NewFlowOfCampaign/SetBudget/SetBudget";
import ReviewCampaign from "./pages/NewFlowOfCampaign/ReviewCampaign/ReviewCampaign";
import ReachNewAudience from "./pages/NewFlowOfCampaign/ReachNewAudience/ReachNewAudience";
import Awareness from "./pages/NewFlowOfCampaign/Awareness/Awareness";
import ClientSite from "./components/ClientSite";

import PlanSelectionPage from "./pages/Ewayprint_2.0.2/PlanSelectionPage/PlanSelectionPage";
import BudgetSelectionPage from "./pages/Ewayprint_2.0.2/BasicPlansPage/BasicPlansPage";
import BasicPlansPage from "./pages/Ewayprint_2.0.2/BasicPlansPage/BasicPlansPage";
import BasicPlanDetail from "./pages/Ewayprint_2.0.2/BasicPlanDetail/BasicPlanDetail";
import BusinessPlansPage from "./pages/Ewayprint_2.0.2/BusinessPlansPage/BusinessPlansPage";
import MyAccountPage from "./components/AuthComponents/MyAccountPage/MyAccountPage";
import HelpFaqPage from "./components/AuthComponents/HelpFaqPage/HelpFaqPage";
import BasicPlanNew from "./pages/Ewayprint_2.0.2/BasicPlanNew/BasicPlanNew";
import BasicPlanNewDetail from "./pages/Ewayprint_2.0.2/BasicPlanNewDetail/BasicPlanNewDetail";
import BusinessPlanPageDetails from "./pages/Ewayprint_2.0.2/BusinessPlanPageDetails/BusinessPlanPageDetails";
import BusinessPlanPageDetailDesk from "./pages/Ewayprint_2.0.2/BusinessPlanPageDetailDesk/BusinessPlanPageDetailDesk";
import { API_URL } from "./constant/apiURL";

const Accounts = React.lazy(() => import("./pages/Accounts"));
const Campaign = React.lazy(() => import("./pages/Campaign/Campaign"));
const VerifyOtp = React.lazy(() => import("./pages/AuthPages/VerifyOtp"));
const ForgetPassword = React.lazy(() =>
  import("./pages/AuthPages/ForgetPassword")
);
const CampaignCreate = React.lazy(() =>
  import("./pages/Campaign/CampaignCreate")
);
const CampaignDetail = React.lazy(() =>
  import("./pages/Campaign/CampaignDetail")
);

const PaymentFailed = React.lazy(() =>
  import("./components/Accounts/PaymentFailed")
);
const CampaignCreatedSuccess = React.lazy(() =>
  import("./pages/Campaign/CampaignCreatedSuccess")
);
const ResetPassword = React.lazy(() =>
  import("./pages/AuthPages/ResetPassword")
);
const PamphletCreate = React.lazy(() =>
  import("./pages/Pamphlet/PamphletCreate")
);

function App() {
  const token = useSelector((state) => state.auth.token);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // const isLoggedIn = false;
  const [selectedDashPage, setSelectedDashPage] = useState(0);
  const [qrCreated, setQrCreated] = useState(false);
  const [isChecked, setIsChecked] = useState({
    pamphletIsChecked: true,
    footerIsChecked: false,
    displayAdIsChecked: false,
  });

  // states for campaign create
  const [campaignName, setCampaignName] = useState();
  const [industry, setIndustry] = useState();
  const [pinCodes, setPinCodes] = useState("ALL");
  const [dummyPinCode, setDummyPinCode] = useState("411048");

  // states for repeat range and repeat count
  const [repeatCount, setRepeatCount] = useState();
  const [repeatRange, setRepeatRange] = useState();

  // states for id`s of voucher, pamphlet , footer and displayAd
  const [voucherId, setVoucherId] = useState();
  const [pamphletId, setPamphletId] = useState();
  const [footerId, setFooterId] = useState();
  const [displayAdId, setDisplayAdId] = useState();

  // state for plan types
  const [selectedPlan, setSelectedPlan] = useState(null);

  const location = useLocation();
  // console.log(location);
  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setSelectedDashPage(0);
    } else if (location.pathname === "/campaign-list") {
      setSelectedDashPage(1);
    } else if (location.pathname === "/pamphlet-list") {
      setSelectedDashPage(2);
    } else if (location.pathname === "/my-account") {
      setSelectedDashPage(3);
    } else if (location.pathname === "/help-faq") {
      setSelectedDashPage(4);
    }
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<p>Loading...</p>}>
        <Switch>
          <Route path="/terms-and-conditions">
            <TandC />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/pdf" exact>
            <PDFReader></PDFReader>
          </Route>
          <Route path="/ads/client-site/:campaignId">
            <ClientSite />
          </Route>

          {!isLoggedIn && (
            <Fragment>
              <Route path="/" exact>
                <Redirect to="/" />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              {/* <Route path="/home">
                <LandingPage_3 selected={"home"} />
              </Route> */}
              <Route path="/" exact>
                <LandingPage_3 selected={"advertiser"} />
              </Route>
              <Route path="/contact">
                <LandingPage_3 selected={"contactUs"} />
              </Route>
              <Route path="/register">
                {/* Using same login component for registration */}
                <Login />
              </Route>
              <Route path="/verify">
                <VerifyOtp />
              </Route>
              <Route path="/forget-password">
                <ForgetPassword />
              </Route>
              <Route path="/reset-password">
                <ResetPassword />
              </Route>
              <Route path="/terms-and-conditions">
                <TandC />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route path="/contact">
                <ContactUsPage />
              </Route>
            </Fragment>
          )}
          {isLoggedIn && (
            <Fragment>
              <Route path="/dashboard/plan-selection-page" exact>
                <PlanSelectionPage />
              </Route>
              {/* <Route path="/dashboard/basic-plans-page" exact>
                <BasicPlansPage />
              </Route> */}
              {/* <Route path="/dashboard/basic-plan-detail">
                <BasicPlanDetail />
              </Route> */}
              <Route path="/dashboard/basic-plan" exact>
                <BasicPlanNew />
              </Route>
              <Route path="/dashboard/basic-plan-detail">
                <BasicPlanNewDetail />
              </Route>
              <Route path="/dashboard/business-plans-page">
                <BusinessPlansPage />
              </Route>
              <Route path="/dashboard/business-plans-detail">
                <BusinessPlanPageDetails />
              </Route>
              <Route path="/dashboard/business-plans-detail-desktop">
                <BusinessPlanPageDetailDesk />
              </Route>
              <Route path="/campaign/create" exact>
                <CampaignCreate />
              </Route>
              <Route path="/campaign/create/new" exact>
                <CampaignCreateNew
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                />
              </Route>
              <Route path="/campaign/create/new/brand-recall/:id?" exact>
                <CampaignBrandRecall
                  setVoucherId={setVoucherId}
                  campaignName={campaignName}
                  qrCreated={qrCreated}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  setCampaignName={setCampaignName}
                  setIndustry={setIndustry}
                />
              </Route>
              <Route path="/campaign/create/new/stepper/:id?">
                <CampaignNewStepper
                  // states for id`s of ad types
                  setPamphletId={setPamphletId}
                  setFooterId={setFooterId}
                  setDisplayAdId={setDisplayAdId}
                  // states for campaign duration
                  setRepeatCount={setRepeatCount}
                  setRepeatRange={setRepeatRange}
                  isChecked={isChecked}
                />
              </Route>
              <Route path="/campaign/create/new/budget">
                <SetBudget isChecked={isChecked}></SetBudget>
              </Route>
              <Route path="/campaign/create/new/review/:id?" exact>
                <ReviewCampaign isChecked={isChecked}></ReviewCampaign>
              </Route>
              <Route path="/campaign/create/new/create-qr-code" exact>
                <CreateQrCode setQrCreated={setQrCreated} />
              </Route>

              <Route path="/campaign/create/new/reach-new-audience/:id?">
                <ReachNewAudience
                  setVoucherId={setVoucherId}
                  qrCreated={qrCreated}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  setPinCodes={setPinCodes}
                  setCampaignName={setCampaignName}
                  setIndustry={setIndustry}
                  campaignName={campaignName}
                />
              </Route>
              <Route path="/campaign/create/new/awareness/:id?">
                <Awareness
                  setCampaignName={setCampaignName}
                  setIndustry={setIndustry}
                  campaignName={campaignName}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                />
              </Route>

              <Route path="/" exact>
                <Redirect to="/dashboard" />
              </Route>
              <Route path="/business-info" exact>
                <BusinessInfo />
              </Route>
              <Route path="/changePassword">
                <ChangePassword />
              </Route>
              <Route path="/pamphlet/create" exact>
                <PamphletCreate />
              </Route>
              <Route path="/campaignSuccess/:campaignId">
                <CampaignCreatedSuccess />
              </Route>
              <Route path="/payments/success" exact>
                <PaymentSuccess></PaymentSuccess>
              </Route>
              <Route path="/payments/failure" exact>
                <PaymentFailure></PaymentFailure>
              </Route>
              <Route path="/dashboard" exact>
                <DashboardLayout
                  selectedDashPage={selectedDashPage}
                  setSelectedDashPage={setSelectedDashPage}
                >
                  <Dashboard isVisible={selectedDashPage === 0} />
                </DashboardLayout>
              </Route>
              <Route path="/campaign-list" exact>
                <DashboardLayout
                  selectedDashPage={selectedDashPage}
                  setSelectedDashPage={setSelectedDashPage}
                >
                  <CampaignList isVisible={selectedDashPage === 1} />
                </DashboardLayout>
              </Route>
              <Route path="/campaign-list/:campaignId" exact>
                <DashboardLayout
                  selectedDashPage={selectedDashPage}
                  setSelectedDashPage={setSelectedDashPage}
                >
                  <CampaignListDetail />
                </DashboardLayout>
              </Route>
              <Route path="/pamphlet-list" exact>
                <DashboardLayout
                  selectedDashPage={selectedDashPage}
                  setSelectedDashPage={setSelectedDashPage}
                >
                  <PamphletList
                    isVisible={selectedDashPage === 2}
                    setSelectedDashPage={setSelectedDashPage}
                  />
                </DashboardLayout>
              </Route>
              <Route path="/my-account">
                <DashboardLayout
                  selectedDashPage={selectedDashPage}
                  setSelectedDashPage={setSelectedDashPage}
                >
                  <MyAccountPage
                    isVisible={selectedDashPage === 3}
                    setSelectedDashPage={setSelectedDashPage}
                  />
                </DashboardLayout>
              </Route>

              <Route path="/help-faq">
                <DashboardLayout
                  selectedDashPage={selectedDashPage}
                  setSelectedDashPage={setSelectedDashPage}
                >
                  <HelpFaqPage
                    isVisible={selectedDashPage === 4}
                    setSelectedDashPage={setSelectedDashPage}
                  />
                </DashboardLayout>
              </Route>
            </Fragment>
          )}
          <Route path="*">
            <PageNotFound></PageNotFound>
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
