import { useState } from "react";
import "./MultipleInputFields.css";
const MultipleInputFields = (props) => {
  const [fields, setFields] = useState([{ title: "", url: "" }]);
  // States for Validation
  const [url, setUrl] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(true);

  // functions for title
  const handleChangeTitle = (index, event) => {
    const values = [...fields];
    values[index].title = event.target.value;
    setFields(values);
  };
  const validateUrl = (url) => {
    const validSchemes = ["http://", "https://", "ftp://"];
    const containsScheme = validSchemes.some((scheme) => url.includes(scheme));
    // const containsWww = url.includes("www");
    // const containsDotCom = url.includes(".com");
    return containsScheme;
  };

  // function for url
  const handleChangeUrl = (index, event) => {
    const values = [...fields];
    values[index].url = event.target.value;
    setFields(values);
    props.setMultiUrlDetails((state) => ({
      ...state,
      urls: values,
    }));
  };

  // function for adding fields
  const handleAddFieldUrl = () => {
    const values = [...fields];
    if (fields?.length <= 4) {
      values.push({ title: "", url: "" });
      setFields(values);
    }
  };

  // function for removing fields
  const handleRemoveFieldUrl = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  return (
    <div className="input_fields_main_div">
      {fields.map((field, index) => (
        <div className="title_url_wrapper" key={index}>
          <div className="title_wrapper">
            <div className="sub_title_child">Button Title</div>
            <input
              className="title_input"
              type="text"
              placeholder="Enter Title"
              onChange={(event) => handleChangeTitle(index, event)}
            />
          </div>
          <div className="url_input_wrapper">
            <div className="" style={{ marginRight: "1rem" }}>
              <div className="sub_title_child"> URL</div>
              <input
                className="multi_url_input"
                placeholder="https://"
                type="text"
                // value={field.url}
                onChange={(event) => {
                  handleChangeUrl(index, event);
                  setUrl(event.target.value);
                  setIsValidUrl(validateUrl(event.target.value));
                  props.setIsUrlValid(validateUrl(event.target.value));
                }}
              />
            </div>
            <div>
              <div style={{ color: "#fff" }}>div</div>
              {index === fields?.length - 1 && fields?.length > 1 && (
                <button
                  className="remove_btn"
                  onClick={() => handleRemoveFieldUrl(index)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
          {props.isMultipleLinksUrlsEmpty && (
            <div style={{ color: "red" }}>Please Enter Url</div>
          )}
        </div>
      ))}

      {fields.length < 5 && (
        <button className="add_input_fields_btn" onClick={handleAddFieldUrl}>
          + Add More Links
        </button>
      )}
      {!isValidUrl && (
        <p style={{ color: "red", marginTop: "0.5rem" }}>
          Please Enter Valid URL
        </p>
      )}
    </div>
  );
};

export default MultipleInputFields;
