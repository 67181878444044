import { useState } from "react";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";
import NewBudgetCard from "../../../components/UI/NewBudgetCard/NewBudgetCard";
import "./BasicPlansPage.css";
import benefitsListBasicPlans from "./benefitsList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { campaignActions } from "../../../store/campaign-slice";
import { API_URL } from "../../../constant/apiURL";
import { useEffect } from "react";
const BasicPlansPage = () => {
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [ratesList, setRatesList] = useState(null);

  const [customBgt1, setCustomBgt1] = useState("");
  const [customBgt2, setCustomBgt2] = useState("");
  const [customBgt3, setCustomBgt3] = useState("");
  const [customBgt4, setCustomBgt4] = useState("");

  const [selectedPlan1, setSelectedPlan1] = useState(false);
  const [selectedPlan2, setSelectedPlan2] = useState(false);
  const [selectedPlan3, setSelectedPlan3] = useState(false);
  const [selectedPlan4, setSelectedPlan4] = useState(false);

  const [isCustomInput1, setIsCustomInput1] = useState(false);
  const [isCustomInput2, setIsCustomInput2] = useState(false);
  const [isCustomInput3, setIsCustomInput3] = useState(false);
  const [isCustomInput4, setIsCustomInput4] = useState(false);

  const [saveBgt1, setSaveBgt1] = useState(false);
  const [saveBgt2, setSaveBgt2] = useState(false);
  const [saveBgt3, setSaveBgt3] = useState(false);
  const [saveBgt4, setSaveBgt4] = useState(false);

  const cardSelectHandler1 = () => {
    setSelectedPlan1(true);
    setSelectedPlan2(false);
    setSelectedPlan3(false);
    setSelectedPlan4(false);
  };
  const cardSelectHandler2 = () => {
    setSelectedPlan1(false);
    setSelectedPlan2(true);
    setSelectedPlan3(false);
    setSelectedPlan4(false);
  };
  const cardSelectHandler3 = () => {
    setSelectedPlan1(false);
    setSelectedPlan2(false);
    setSelectedPlan3(true);
    setSelectedPlan4(false);
  };
  const cardSelectHandler4 = () => {
    setSelectedPlan1(false);
    setSelectedPlan2(false);
    setSelectedPlan3(false);
    setSelectedPlan4(true);
  };

  const ctmBgtHandler1 = () => {
    setIsCustomInput1(true);
  };
  const ctmBgtHandler2 = () => {
    setIsCustomInput2(true);
  };
  const ctmBgtHandler3 = () => {
    setIsCustomInput3(true);
  };
  const ctmBgtHandler4 = () => {
    setIsCustomInput4(true);
  };

  const inputHandler1 = (e) => {
    setCustomBgt1(e.target.value);
  };
  const resetHandler1 = () => {
    setCustomBgt1("");
  };

  const inputHandler2 = (e) => {
    setCustomBgt2(e.target.value);
  };
  const resetHandler2 = () => {
    setCustomBgt2("");
  };

  const inputHandler3 = (e) => {
    setCustomBgt3(e.target.value);
  };
  const resetHandler3 = () => {
    setCustomBgt3("");
  };

  const inputHandler4 = (e) => {
    setCustomBgt4(e.target.value);
  };
  const resetHandler4 = () => {
    setCustomBgt4("");
  };
  const saveBgtHandler1 = () => {
    setSaveBgt1(true);
  };
  const saveBgtHandler2 = () => {
    setSaveBgt2(true);
  };
  const saveBgtHandler3 = () => {
    setSaveBgt3(true);
  };
  const saveBgtHandler4 = () => {
    setSaveBgt4(true);
  };

  const budgetQtyObj = {
    plan1: {
      budget: 10000,
      quantity: 2000,
    },
    plan2: {
      budget: 20000,
      quantity: 3000,
    },
    plan3: {
      budget: 30000,
      quantity: 3000,
    },
    plan4: {
      budget: 50000,
      quantity: 5000,
    },
  };
  const recBgtQtyToSend =
    (selectedPlan1 && budgetQtyObj.plan1) ||
    (selectedPlan2 && budgetQtyObj.plan2) ||
    (selectedPlan3 && budgetQtyObj.plan3) ||
    (selectedPlan4 && budgetQtyObj.plan4);
  const isCusBgtQty =
    (selectedPlan1 && isCustomInput1) ||
    (selectedPlan2 && isCustomInput2) ||
    (selectedPlan3 && isCustomInput3) ||
    (selectedPlan4 && isCustomInput4);

  const sideToSend =
    ((selectedPlan1 || selectedPlan3) && false) ||
    ((selectedPlan2 || selectedPlan4) && true);
  const sizeToSend =
    ((selectedPlan1 || selectedPlan2) && "A5") ||
    ((selectedPlan3 || selectedPlan4) && "A4");

  useEffect(() => {
    // Rate list api
    fetch(API_URL + "pamphlet-quantity-rate/list/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRatesList(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Rate Calculation as per size and side
  const isA5Single = selectedPlan1 && true;

  const isA5Duplex = selectedPlan2 && true;

  const isA4Single = selectedPlan3 && true;

  const isA4Duplex = selectedPlan4 && true;

  const a5dRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    ratesList[0].rate_points[0].price;

  const a5sRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    ratesList[1]?.rate_points[0]?.price;

  const a4dRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    ratesList[2]?.rate_points[0]?.price;

  const a4sRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    ratesList[3]?.rate_points[0]?.price;

  let amountToMultiply =
    (isA5Duplex && a5dRate) ||
    (isA5Single && a5sRate) ||
    (isA4Duplex && a4dRate) ||
    (isA4Single && a4sRate);

  let custQty1;
  let custQty2;
  let custQty3;
  let custQty4;

  if (isCusBgtQty) {
    custQty1 = selectedPlan1 && isCustomInput1 && customBgt1 / amountToMultiply;
    custQty2 = selectedPlan2 && isCustomInput2 && customBgt2 / amountToMultiply;
    custQty3 = selectedPlan3 && isCustomInput3 && customBgt3 / amountToMultiply;
    custQty4 = selectedPlan4 && isCustomInput4 && customBgt4 / amountToMultiply;
  }
  let isCtmBgt =
    (selectedPlan1 && isCustomInput1 && customBgt1 > 999 && saveBgt1) ||
    (selectedPlan2 && isCustomInput2 && customBgt2 > 999 && saveBgt2) ||
    (selectedPlan3 && isCustomInput3 && customBgt3 > 999 && saveBgt3) ||
    (selectedPlan4 && isCustomInput4 && customBgt4 > 999 && saveBgt4);

  const pamphletDetailsSubmitHandler = () => {
    const pamphletFormData = new FormData();
    pamphletFormData.append("pamphlet_type", "PP");
    pamphletFormData.append("is_duplex", sideToSend);
    pamphletFormData.append("size", sizeToSend);

    fetch(API_URL + "campaign/pamphlet-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: pamphletFormData,
    }).then(async (res) => {
      const data = await res.json();
      dispatch(campaignActions.setPamphletId({ pamphletId: data.id }));
      history.push({
        pathname: "/dashboard/basic-plan-detail",
        state: {
          pamphlet_id: data.id,
          recBgtQty: recBgtQtyToSend,
          isCtmBgt: isCtmBgt,
          customQty: custQty1 || custQty2 || custQty3 || custQty4,
          customBgt:
            (custQty1 && customBgt1) ||
            (custQty2 && customBgt2) ||
            (custQty3 && customBgt3) ||
            (custQty4 && customBgt4),
        },
      });
    });
  };

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />

      <div className="budget_selection_page_main_div">
        <div className="budget_selection_page_sub_div">
          <div className="sub_title heading2">Choose Your Plan</div>
          <div className="basic_plan_cards_wrapper">
            <NewBudgetCard
              side="Single Side"
              size="A5"
              pamphletBudget={budgetQtyObj.plan1.budget}
              pamphletQuantity={budgetQtyObj.plan1.quantity}
              benefitsList={benefitsListBasicPlans.plan1.list}
              selectedPlan={selectedPlan1}
              chooseOnClick={cardSelectHandler1}
              ctmBgtOnClick={ctmBgtHandler1}
              saveBgtOnClick={saveBgtHandler1}
              resetOnClick={resetHandler1}
              isCustomInput={isCustomInput1}
              customBgt={customBgt1}
              setCustomBgt={setCustomBgt1}
              onChange={inputHandler1}
              custQty={custQty1}
              isCtmBgt={selectedPlan1 && isCustomInput1 && customBgt1 > 999}
              saveBgt={saveBgt1}
            />
            <NewBudgetCard
              side="Double Side"
              size="A5"
              pamphletBudget={budgetQtyObj.plan2.budget}
              pamphletQuantity={budgetQtyObj.plan2.quantity}
              benefitsList={benefitsListBasicPlans.plan2.list}
              selectedPlan={selectedPlan2}
              chooseOnClick={cardSelectHandler2}
              ctmBgtOnClick={ctmBgtHandler2}
              saveBgtOnClick={saveBgtHandler2}
              resetOnClick={resetHandler2}
              isCustomInput={isCustomInput2}
              customBgt={customBgt2}
              setCustomBgt={setCustomBgt2}
              onChange={inputHandler2}
              custQty={custQty2}
              isCtmBgt={selectedPlan2 && isCustomInput2 && customBgt2 > 999}
              saveBgt={saveBgt2}
            />
            <NewBudgetCard
              side="Single Side"
              size="A4"
              pamphletBudget={budgetQtyObj.plan3.budget}
              pamphletQuantity={budgetQtyObj.plan3.quantity}
              benefitsList={benefitsListBasicPlans.plan3.list}
              selectedPlan={selectedPlan3}
              chooseOnClick={cardSelectHandler3}
              ctmBgtOnClick={ctmBgtHandler3}
              saveBgtOnClick={saveBgtHandler3}
              resetOnClick={resetHandler3}
              isCustomInput={isCustomInput3}
              customBgt={customBgt3}
              setCustomBgt={setCustomBgt3}
              onChange={inputHandler3}
              custQty={custQty3}
              isCtmBgt={selectedPlan3 && isCustomInput3 && customBgt3 > 999}
              saveBgt={saveBgt3}
            />
            <NewBudgetCard
              side="Double Side"
              size="A4"
              pamphletBudget={budgetQtyObj.plan4.budget}
              pamphletQuantity={budgetQtyObj.plan4.quantity}
              benefitsList={benefitsListBasicPlans.plan4.list}
              selectedPlan={selectedPlan4}
              chooseOnClick={cardSelectHandler4}
              ctmBgtOnClick={ctmBgtHandler4}
              saveBgtOnClick={saveBgtHandler4}
              resetOnClick={resetHandler4}
              isCustomInput={isCustomInput4}
              customBgt={customBgt4}
              setCustomBgt={setCustomBgt4}
              onChange={inputHandler4}
              custQty={custQty4}
              isCtmBgt={selectedPlan4 && isCustomInput4 && customBgt4 > 999}
              saveBgt={saveBgt4}
            />
          </div>
        </div>
      </div>
      <div className="bottom_fixed_div">
        <ButtonNew
          className="new_back_btn"
          name="Back"
          onClick={() => {
            history.push("/dashboard/plan-selection-page");
          }}
        />
        <ButtonNew
          className="new_continue_btn"
          name="Continue"
          onClick={() => {
            if (
              selectedPlan1 ||
              selectedPlan2 ||
              selectedPlan3 ||
              selectedPlan4
            ) {
              pamphletDetailsSubmitHandler();
            }
          }}
        />
      </div>
    </>
  );
};
export default BasicPlansPage;
