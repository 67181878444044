import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Chip, Grid } from "@mui/material";

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { API_URL } from "../../../constant/apiURL";
import { fetchCampaignData } from "../../../store/campaign-slice";
import {
  awarenessNewIcon,
  campaignsIcon,
  noCampaignsImg,
  rightArrow,
} from "../../../assets";

import "./CampaignList.css";
import { useRef } from "react";
let isInitial = true;
const CampaignList = (props) => {
  const token = useSelector((state) => state.auth.token);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCampaignData(token));
  }, [dispatch, token]);

  const campaigns = useSelector((state) => state.campaigns.campaigns);

  return (
    <div className="flex_grow_1 dashboard__main_div">
      {props.isVisible && (
        <div className="campaignList__main_div card_12px ">
          {/* <TableContainer
            // component={Paper}
            sx={{ borderRadius: "20px" }}
          >
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow sx={{ height: 76 }}>
                  <TableCell align="inherit">
                    <span className="sub_title">Campaign name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="sub_title">Status</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="sub_title">Details</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns?.map((campaign) => (
                  <TableRow
                    onClick={() => {
                      history.push(`/campaign-list/${campaign.id}`);
                    }}
                    key={campaign.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      margin: "1rem 0rem",
                      padding: "15px",
                    }}
                  >
                    <TableCell align="left">
                      <span className="f_s_20 f_w_500">{campaign?.name}</span>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "600",
                        color:
                          campaign?.status === "Not Paid"
                            ? "red"
                            : "" || campaign?.status === "Approval Pending"
                            ? "orange"
                            : "" || campaign?.status === "Paused"
                            ? "purple"
                            : "" || campaign?.status === "Running"
                            ? "#31b963"
                            : "" || campaign?.status === "Finished"
                            ? "blue"
                            : "" || campaign?.status === "Partially Paid"
                            ? "#e59400"
                            : "",
                      }}
                    >
                      {campaign?.status}
                    </TableCell>
                    <TableCell align="center" style={{ width: "100px" }}>
                      <span
                        className="view_details_text"
                        style={{ width: "100%" }}
                      >
                        Details
                        <img
                          style={{ width: "20px" }}
                          src={rightArrow}
                          alt="rightArrow"
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}

          <div className="sub_title">
            {" "}
            <span>
              <img src={campaignsIcon} alt="campaignsIcon" />
            </span>
            Campaigns
          </div>

          <div className="campaign_list_wrapper">
            <div className="cmpgn_details_heading_wrapper">
              <div className="cmpgn_no_name_wrapper">
                <div className="campaign_no_heading sub_title_child">No.</div>
                <div className="sub_title_child">Name</div>
              </div>
              <div className="cmpgn_status_more_wrapper">
                <div className="campaign_status_heading sub_title_child">
                  Campaign Status
                </div>
                <div className="sub_title_child m_r_2">More</div>
              </div>
            </div>
            {campaigns && campaigns.length > 0 ? (
              campaigns.map((campaign, index) => {
                const truncateName = (name) => {
                  return name.length > 23 && window.innerWidth < 700
                    ? name.substring(0, 23) + "..."
                    : name;
                };
                return (
                  <div
                    key={campaign.id}
                    className="cmpgn_details_data_wrapper pointer"
                    onClick={() => {
                      history.push(`/campaign-list/${campaign.id}`);
                    }}
                  >
                    <div className="cmpgn_no_name_detail_wrapper">
                      <div className="campaign_no sub_title_child no_wrapper">
                        {index + 1}
                      </div>
                      <div className="sub_title_child name_wrapper">
                        <span className="campaign_icon">
                          <img
                            width="36px"
                            src={awarenessNewIcon}
                            alt="awarenessNewIcon"
                          />
                        </span>
                        &nbsp;&nbsp;
                        {truncateName(campaign.name)}
                      </div>
                    </div>
                    <div className="cmpgn_status_more_wrapper">
                      <div
                        className="campaign_status m_l_2"
                        style={{
                          color: "#fff",
                          padding: "0px 12px",
                          borderRadius: "15px",
                          backgroundColor:
                            campaign?.status === "Not Paid"
                              ? "red"
                              : campaign?.status === "Pending"
                              ? "orange"
                              : campaign?.status === "Paused"
                              ? "purple"
                              : campaign?.status === "Running"
                              ? "#31b963"
                              : campaign?.status === "Finished"
                              ? "blue"
                              : campaign?.status === "Partially Paid"
                              ? "#e59400"
                              : "",
                        }}
                      >
                        {campaign.status}
                      </div>
                      <div className=" green_text text_underline ">
                        View Details
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-campaigns">
                <img src={noCampaignsImg} alt="No campaigns available" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignList;
