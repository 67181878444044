import React from "react";
import "./DashboardLayout.css";
import DashboardHeader from "./DashboardHeader/DashboardHeader.jsx";
import SideBarButton from "./DashboardHeader/SideBarButtons/SideBarButton";
import {
  account,
  accountSelected,
  campaignList,
  campaignListActive,
  ewayPrintLogo,
  helpFaqIcon,
  helpFaqIconSelected,
  logout,
  menu,
  overviewImg,
  overviewImgActive,
  pamphletList,
  pamphletListActive,
  profile,
} from "../../assets";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import Button from "../UI/GreenBtn/Button";
import HorizontalDivider from "../UI/Dividers/HorizontalDivider";

const DashboardLayout = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const [logoutModalIsOpen, setLogoutModalIsOpen] = useState(false);
  const [sideBarlIsOpen, setSideBarlIsOpen] = useState(false);

  const toggle = () => {
    setLogoutModalIsOpen(!logoutModalIsOpen);
  };
  const toggleSideBar = () => {
    setSideBarlIsOpen(!sideBarlIsOpen);
  };

  const businessName =
    localStorage.getItem("businessName") !== null
      ? localStorage.getItem("businessName")
      : "";
  const phone = localStorage.getItem("phone");
  return (
    <>
      {/* <DashboardHeader toggleSideBar={toggleSideBar} /> */}
      {logoutModalIsOpen && (
        <CustomModal
          BtnGrpNeeded
          onClose={toggle}
          onConfirm={() => {
            dispatch(authActions.logout());
            history.replace("/");
          }}
          closeBtnName="No"
          confirmBtnName="Yes"
        >
          <span className="title">Are you sure you want to logout ?</span>
        </CustomModal>
      )}
      <div className="header_ham_menu pointer" onClick={toggleSideBar}>
        <img src={menu} alt="menu.svg" />
      </div>
      {sideBarlIsOpen && (
        <div>
          <div className="backdrop" onClick={toggleSideBar}></div>
          <div className="ham_menu__sidebar">
            <div>
              <button
                className="create_campaign_btn"
                onClick={() => {
                  if (campaigns.length > 0) {
                    history.push("/dashboard/business-plans-page");
                  } else {
                    history.push("/dashboard/business-plans-page");
                  }
                  toggleSideBar();
                }}
              >
                + Create Campaign
              </button>
              <HorizontalDivider
                style={{ height: "2px", margin: "30px 0px" }}
              />

              <div className="sidebar_profile_section_wrapper m_b_2">
                <div>
                  <div className="profile_icon">
                    <img className="profile_icon" src={profile} alt="profile" />
                  </div>
                </div>
                <div className="profile_detail_wrapper m_t_0_5">
                  <div className="f_w_400 f_s_16">{businessName}</div>

                  <div className="f_w_400 f_s_14 opaque">{phone}</div>
                </div>
              </div>
            </div>
            <div className="sidebar_btn_wrapper ">
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(0);
                  history.push("/dashboard");
                  toggleSideBar();
                }}
                btnName="Overview"
                btnImg={
                  props.selectedDashPage === 0 ? overviewImgActive : overviewImg
                }
                isActive={props.selectedDashPage === 0}
              />
            </div>
            <div className="sidebar_btn_wrapper ">
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(1);
                  history.push("/campaign-list");
                  toggleSideBar();
                }}
                btnName="Campaign List"
                btnImg={
                  props.selectedDashPage === 1
                    ? campaignListActive
                    : campaignList
                }
                isActive={props.selectedDashPage === 1}
              />
            </div>
            {/* <div>
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(3);
                  history.push("/ads/my-account");
                }}
                btnName="My Account"
                btnImg={
                  props.selectedDashPage === 3 ? accountSelected : account
                }
                isActive={props.selectedDashPage === 3}
              />
            </div> */}

            <div className="sidebar_btn_wrapper ">
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(4);
                  history.push("/help-faq");
                }}
                btnName="Help & FAQ"
                btnImg={
                  props.selectedDashPage === 4
                    ? helpFaqIconSelected
                    : helpFaqIcon
                }
                isActive={props.selectedDashPage === 4}
              />
            </div>
            {/* <div>
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(2);
                  history.push("/ads/pamphlet-list");
                  toggleSideBar();
                }}
                btnName="Pamphlet List"
                btnImg={
                  props.selectedDashPage === 2
                    ? pamphletListActive
                    : pamphletList
                }
                isActive={props.selectedDashPage === 2}
              />
            </div> */}
            <div style={{ flex: 1 }}></div>
            <div className="logout_btn_wrapper">
              {" "}
              <button className="logout_btn" onClick={toggle}>
                Logout
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={"dashboard__body__div w_100 "}>
        <div className="dashboard__container_div">
          <div className="dashboard__sidebar">
            <div>
              <img
                width="230px"
                className="logo"
                src={ewayPrintLogo}
                alt="logo"
              ></img>
            </div>
            <div className="sidebar_profile_section_wrapper m_b_2">
              <div>
                <div className="profile_icon">
                  <img className="profile_icon" src={profile} alt="profile" />
                </div>
              </div>
              <div className="profile_detail_wrapper m_t_0_5">
                <div className="f_w_400 f_s_16">{businessName}</div>

                <div className="f_w_400 f_s_14 opaque">{phone}</div>
              </div>
            </div>
            <div className="sidebar_btn_wrapper">
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(0);
                  history.push("/dashboard");
                }}
                btnName="Overview"
                btnImg={
                  props.selectedDashPage === 0 ? overviewImgActive : overviewImg
                }
                isActive={props.selectedDashPage === 0}
              />
            </div>
            <div className="sidebar_btn_wrapper">
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(1);
                  history.push("/campaign-list");
                }}
                btnName="Campaign List"
                btnImg={
                  props.selectedDashPage === 1
                    ? campaignListActive
                    : campaignList
                }
                isActive={props.selectedDashPage === 1}
              />
            </div>
            {/* <div>
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(3);
                  history.push("/ads/my-account");
                }}
                btnName="My Account"
                btnImg={
                  props.selectedDashPage === 3 ? accountSelected : account
                }
                isActive={props.selectedDashPage === 3}
              />
            </div> */}

            <div className="sidebar_btn_wrapper">
              <SideBarButton
                onClick={() => {
                  props.setSelectedDashPage(4);
                  history.push("/help-faq");
                }}
                btnName="Help & FAQ"
                btnImg={
                  props.selectedDashPage === 4
                    ? helpFaqIconSelected
                    : helpFaqIcon
                }
                isActive={props.selectedDashPage === 4}
              />
            </div>
            <div style={{ flex: 1 }}></div>
            <div className="logout_btn_wrapper">
              {" "}
              <button className="logout_btn" onClick={toggle}>
                Logout
              </button>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
