import React from "react";
import "./HztlBarDotted.css";
function HztlBarDotted(props) {
  return (
    <>
      <hr style={props.style} className="dotted-divider" />
    </>
  );
}

export default HztlBarDotted;
