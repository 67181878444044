import React from "react";
import { useHistory } from "react-router-dom";
import { pageNotFound } from "../../assets";
import { arrowLeft } from "../../assets";
import CampaignLayout from "../CampaignLayout/CampaignLayout";
import SideBarButton from "../DashboardLayout/DashboardHeader/SideBarButtons/SideBarButton";
import "./PageNotFound.css";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <CampaignLayout>
      <div className="container-pageNotFound">
        <div className="img-div">
          <img
            className="pagenotfound-img"
            src={pageNotFound}
            alt="pageNotFound"
          ></img>
        </div>

        <div className="titlex2" style={{ fontSize: "44px" }}>
          Page not found !
        </div>

        <SideBarButton
          style={{
            padding: "16px 40px",
            width: "195px",
            height: "62px",
            marginTop: "60px",
          }}
          onClick={() => {
            history.push("/");
          }}
          btnName="Go Back"
          btnImg={arrowLeft}
          isActive
        ></SideBarButton>
      </div>
    </CampaignLayout>
  );
};

export default PageNotFound;
