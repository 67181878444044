import { easeQuadInOut } from "d3-ease";
import React, { useEffect, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { useSelector } from "react-redux";
import { API_URL } from "../../constant/apiURL";
import AnimatedProgressProvider from "./AnimatedProvider";
import "./CampaignDetail.css";
import { useHistory } from "react-router-dom";
import { VerticleDashedBar, scansIcon } from "../../assets";

const HelperComponent = (props) => {
  return (
    <div className={` helper_compo_positions ${props.className}`}>
      <div className="body_text_xs opaque">{props.title}</div>
      <div className="sub_title">{props.value}</div>
    </div>
  );
};

const CampaignDetail = (props) => {
  // const percentage = props.percentage();

  const history = useHistory();
  const [setselectedOption, setSetSelectedOption] = useState("distributed");

  const percentage =
    (setselectedOption === "distributed" &&
      props.percentage() > 0 &&
      props.percentage().toFixed(0)) ||
    (setselectedOption === "tobedistributed" &&
      props.balQtyPercentage() > 0 &&
      props.balQtyPercentage().toFixed(0));

  const urlType = props.selectedCampaign?.qr_code_id?.url_type;
  const totalReach = props.selectedCampaign?.dist_qty_for_pamphlet;
  const QrHitQty = props.selectedCampaign?.qr_code_hit_qty;

  // changing date format yy/mm/dd into dd/mm/yy
  const date = new Date(props.distributionStartDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const distStartDate = `${day}/${month}/${year}`;

  const isNanTotalQty = isNaN(props.totalQty());
  const isNanDistrQty = isNaN(props.distributedQty());

  const getColor = () => {
    if (props.adTypeAll) {
      return "#000";
    } else if (props.adTypePamphlet) {
      return "#43ba56";
    } else if (props.adTypeFooter) {
      return "rgba(138, 43, 226, 1)";
    } else if (props.adTypeLabelAd) {
      return "rgb(243, 221, 27)";
    } else if (props.adTypeDisplayAd) {
      return "rgba(65, 105, 225, 1)";
    }
  };

  return (
    <div className={`campaignDetail__main_div  ${props.className}`}>
      <div className="child1">
        <div className="dashb_cpgn_name_adtype_label_wrapper">
          <div className="sub_title">
            {props.name ? props.name : "No Campaigns"}
          </div>
          <div className="body_text_xs text_underline">
            {props.adTypeAll && "All"}
            {props.adTypePamphlet && "Pamphlet"}
            {props.adTypeFooter && "Footer"}
            {props.adTypeDisplayAd && "Display Ad"}
          </div>
        </div>

        {props.selectedCampaign?.id !== null && (
          <p className="f_s_14">
            Distribution Start date :{" "}
            {props.distributionStartDate ? distStartDate : "-------"}
          </p>
        )}
        {/* <div>
          <div className="body_text_xs ">
            {props.adTypeAll && "Total Distributed"}
            {props.adTypePamphlet && "Distributed Pamphlet"}
            {props.adTypeFooter && "Distributed Footer"}
            {props.adTypeDisplayAd && "Distributed Display Ad"}
          </div>

          <div
            className={`f_s_40 f_w_700
            ${props.adTypePamphlet && "green_text"}
            ${props.adTypeFooter && "purple_text"}
            ${props.adTypeDisplayAd && "blue_text"}
            ${props.adTypeLabelAd && "yellow_text"}
            `}
          >
            {props.distributedQty && !isNanDistrQty
              ? props.distributedQty()
              : "00"}
          </div>
          <div className=" f_w_700">
            To Be Distribute :{" "}
            {props.totalQty && !isNanTotalQty ? props.totalQty() : "00"}
          </div>
        </div> */}

        {/* <div className="helpers_grp">
          {props.adTypeAll && null} */}

        {/* {props.adTypePamphlet &&
            props.selectedCampaign?.pamphlet_id !== null && (
              <>
                <HelperComponent title="Side" value={props.side} />
                <HelperComponent title="Size" value={props.adTypesDetails()} />
              </>
            )} */}
        {/* <div
            className={`
            ${props.adTypePamphlet && "horizontal-progress-bar-pamph"}
            ${props.adTypeFooter && "horizontal-progress-bar-footer"}
            ${props.adTypeDisplayAd && "horizontal-progress-bar-disp"}
            ${props.adTypeLabelAd && "horizontal-progress-bar-lbl"}
            ${props.adTypeAll && "horizontal-progress-bar-all"}`}
          >
            <div
              className={`${props.adTypePamphlet && "progress-fill-pamph"}
              ${props.adTypeFooter && "progress-fill-footer"}
              ${props.adTypeDisplayAd && "progress-fill-disp"}
              ${props.adTypeLabelAd && "progress-fill-lbl"}
              ${props.adTypeAll && "progress-fill-all"}`}
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
          <div className="percenteg_of_bar_wrapper">
            <div className="f_w_600">
              {props.percentage() > 0 ? props.percentage().toFixed(2) : 0}%
              Completed
            </div>
          </div> */}
        {/* {props.adTypeFooter && (
            <HelperComponent title="Height" value={props.adTypesDetails()} />
          )} */}
        {/* {props.adTypeDisplayAd && (
            <>
              <HelperComponent
                title="Ad URL Type"
                value={props.adTypesDetails()}
              />
            </>
          )} */}

        {/* {props.adTypeAll && (
            <HelperComponent title="Location" value={"Pune"} />
          )} */}
        {/* </div> */}
        <div className="child2">
          <div className="circular_progressBar">
            <CircularProgressbarWithChildren
              value={percentage}
              strokeWidth={13}
              styles={buildStyles({
                pathTransition: "stroke-dashoffset 2s ease 0s",
                textColor: "#000000",
                pathColor: getColor(),
                trailColor: "#EEEDED",
                strokeLinecap: "round",
              })}
            >
              <div
                className={`progressBar_title
            ${props.adTypePamphlet && "green_text"}
            ${props.adTypeFooter && "purple_text"}
            ${props.adTypeDisplayAd && "blue_text"}
            ${props.adTypeLabelAd && "yellow_text"}
            `}
              >
                {props.percentage() > 0 ? props.percentage().toFixed(0) : 0}%
              </div>
              {/* <div className="progressBar__sub_title">Completed</div> */}
            </CircularProgressbarWithChildren>
          </div>
          {props.isQrCodeId && (
            <div>
              <img src={VerticleDashedBar} alt="VerticleDashedBar"></img>
            </div>
          )}

          {props.isQrCodeId && (
            <div className="dashb_qr_detail_wrapper">
              <h5>Qr Code</h5>
              <img width="44px" src={scansIcon} alt="scansIcon"></img>
              <p>Total Scans - {QrHitQty > 0 ? QrHitQty : 0}</p>
            </div>
          )}
        </div>
        <div className="all_qtys_wrapper">
          <div className="f_w_500 f_s_16">
            Total Qty :
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {isNaN(props.totalQty()) ? 0 : props.totalQty()}
          </div>
          <div className="f_w_500 f_s_16">
            Distributed Qty : &nbsp;
            {isNaN(props.distributedQty()) ? 0 : props.distributedQty()}
          </div>
          <div className="f_w_500 f_s_16">
            Balance Qty : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {isNaN(props.balanceQty()) ? 0 : props.balanceQty()}
          </div>
        </div>
        {/* <div className="remain_qty_toggle_wrapper m_t_1">
          <div className="dashb_balance_qty_wrapper">
            <div>Balance Quantity</div>
            <div className="f_w_600">{props.balanceQty()}</div>
          </div>
          <div>
            <div className="dashb_toggle_wrapper">
              <div
                className={
                  setselectedOption === "distributed"
                    ? "selected_distribution_toggle"
                    : "unselected_distribution_toggle"
                }
                onClick={() => {
                  setSetSelectedOption("distributed");
                }}
              ></div>{" "}
              <p>Total Distributed</p>
            </div>
            <div className="dashb_toggle_wrapper">
              <div
                className={
                  setselectedOption === "tobedistributed"
                    ? "selected_distribution_toggle"
                    : "unselected_distribution_toggle"
                }
                onClick={() => {
                  setSetSelectedOption("tobedistributed");
                }}
              ></div>
              <p>Total Be Distributed</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CampaignDetail;
