import React from "react";

import "./AboutUsAdvertiser.css";
import illus1 from "./illus1.png";
import illus2 from "./illusnew2.png";
import illus3 from "./illusnew3.png";
import illus4 from "./illusnew4.png";
import illus5 from "./illus5.png";
import aboutUsNewImg from "./image-about us (1).png";
import logo from "./Group 46.png";
import playStoreIcon from "./play store.svg";
import downloadIcon from "./download_for_offline (1).svg";
import printConnect from "./print_connect.svg";
import AppDownloadsCard from "./AppDownloadsCard/AppDownloadsCard";
const data = [
  {
    heading: "Deliver Your Message Directly - To Stand Out",
    text: "All your competitors are pounding inbox 24/7 or getting snowed under digital info clutter.",
    text2:
      "Stand out from your competitors by delivering mails directly to your audience to form a deeper impression.",
    image: illus1,
  },
  {
    heading: "Enables Consistency & Fulfilment",
    text: "Direct mail marketing is much more consistent than online ads. When you launch a direct mail campaign, you’ll have a reasonably good idea of when the demand will increase & can reach out the audience accordingly.    ",
    text2:
      "Alternatively, you never know when online ads will become popular, spike demand & overwhelmed the audience.",
    image: illus2,
  },
  {
    heading: "The Tactile Value of Physical Mail",
    text: "According to writer & researcher Roger Dooley,Direct mail is easier to process mentally & uses21% less cognitive effort than digital advertising.",
    text2:
      "Research has shown that due to the reduced attention span, info shared via print media leads tothe higher recall & retentive value as compared todigital media.",
    image: illus3,
  },
  {
    heading: "Builds Trust & Brand Loyalty",
    text: "Many consumers still consider direct mail to be more trustworthy than online ads. They’re skeptical of online scams & frauds & more likely to trust a business with a physical address.",
    text2:
      "When you reach a prospect through direct mail, you add legitimacy to your brand which helps build brand recognition & trust.",
    image: illus4,
  },
  {
    heading: "Track, Trace & Measure Impact in Real-Time",
    text: "Prospects can track & monitor the KPI’s of direct mail campaign with our user-friendly state of art dashboard using the credentials provided by our team. This enables them to view the statistics of their campaign & QR scans.",
    image: illus5,
  },
];
const HelperSec = (props) => {
  return (
    <div
      className={`item ${
        props.reverse && window.innerWidth >= 1000 && "flex_row_reverse"
      }`}
    >
      <div className="child1">
        <div className="ads__new_heading" style={{ lineHeight: "43px" }}>
          {props.heading}
        </div>
        <div className="ads_text">{props.text}</div>
        <br />
        <div className="ads_text">{props.text2}</div>
      </div>
      <div className="child2">
        <img src={props.image} alt="illus1"></img>
      </div>
    </div>
  );
};

const AboutUsAdvertiser = (props) => {
  return (
    <div className="abt-ads-main-div" id="about_us_section">
      <div className="abt_ads_para_container">
        <div className="about_us_text_content_wrapper">
          <div className="abt-ads-head-grp">
            <div
              className={
                window.innerWidth > 700
                  ? "abt-ads-head-grp-text dark_green_text"
                  : "content__heading dark_green_text"
              }
            >
              About us
            </div>
          </div>
          <p className="ads_text">
            <b>
              At Ewayprint Ads, we're passionate about helping businesses
              connect with their customers in the most powerful way possible -
              through direct mail marketing.
            </b>{" "}
            With our expertise, we've fine-tuned the art of crafting{" "}
            personalized, tangible campaigns that leave a lasting impact.
          </p>
          <p className="ads_text">
            Our team of creative minds and marketing strategists are dedicated
            to delivering results that matter to your business. We believe in
            the unique potential of each piece of mail and work tirelessly to
            ensure it resonates with your target audience.
            <b>
              From design to delivery, we track, trace, and measure every step
              of the journey to guarantee your campaign's success.
            </b>
          </p>
          <p className="ads_text">
            <b>
              Get ready to Discover the power of direct mail marketing with
              Ewayprint Ads.
            </b>
          </p>
        </div>
        <div>
          <img
            src={aboutUsNewImg}
            alt="aboutUsNewImg"
            className="about_us_new_img"
          />
        </div>
      </div>

      {/* <AppDownloadsCard /> */}

      <div className="ads__wcu ">
        <div className="content__heading  dark_green_text">
          Why Direct Mail Marketing?
        </div>
        <div className="ads__wcu_para_con">
          <p>
            <b>
              Direct mail marketing stands out as a less common approach, which
              allows it to create a lasting impression on the audience. <br />
            </b>{" "}
            Here are some reasons to choose it:
          </p>
        </div>
      </div>
      <div className="ads__section_container">
        {data.map((item, index) => (
          <HelperSec
            key={index}
            heading={item.heading}
            text={item.text}
            text2={item.text2}
            image={item.image}
            reverse={index % 2 !== 0}
          />
        ))}
      </div>
    </div>
  );
};

export default AboutUsAdvertiser;
