import React from "react";

import "../../index.css";

const DashBoardCardWrapper = (props) => {
  return (
    <div className={`card_12px ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default DashBoardCardWrapper;
