import { Grid } from "@mui/material";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { editIcon, closeIcon } from "../../../assets";
import PaymentModel from "../../../components/Accounts/PaymentModel";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import HorizontalDivider from "../../../components/UI/Dividers/HorizontalDivider";
import Button from "../../../components/UI/GreenBtn/Button";
import { API_URL } from "../../../constant/apiURL";
import "./ReviewCampaign.css";

const ReviewCampaign = (props) => {
  const paymentModalRef = useRef();
  // const [pamphletDetails, setPamphletDetails] = useState({});
  // const [footerDetails, setFooterDetails] = useState({});
  // const [displayAdDetails, setDisplayAdDetails] = useState({});
  const history = useHistory();
  const location = useLocation();
  // console.log(location?.state);
  // for plan code
  const isCodeValid = location?.state?.isCodeValid;
  const quantityUsingCode = location?.state?.pamphletDetails.quantityUsingCode;
  const amountUsingCode = location?.state?.pamphletDetails.amountUsingCode;
  const sizeUsingCode = location?.state?.pamphletDetails.sizeUsingCode;
  const sideUsingCode = location?.state?.pamphletDetails.sideUsingCode;
  const isDuplex = sideUsingCode === true ? "Double Side" : "Single Side";

  const token = useSelector((state) => state.auth.token);
  const campaignId =
    useSelector((state) => state?.campaigns?.overAllData?.campaignId) ||
    location?.state?.currentCampaign?.id;
  // console.log(campaignId);
  const pamphletId = useSelector((state) => state.campaigns.pamphletId);
  const totalAmount = useSelector(
    (state) => state?.campaigns?.overAllData?.totalAmount
  );
  const pamphletQty = useSelector(
    (state) => state?.campaigns?.overAllData?.pamphletQty
  );
  // console.log(totalAmount);
  const budget = totalAmount;
  // console.log(budget);
  // console.log("==========================================");
  // console.log(campaignId);
  // // console.log(overAllData);
  // console.log("==========================================");

  // pamphlet details
  const frontFileName = location?.state?.pamphletDetails?.frontFileName;
  const backFileName = location?.state?.pamphletDetails?.backFileName;
  const pamphletSize = location?.state?.pamphletDetails?.pamphletSize;
  const pamphletSide = location?.state?.pamphletDetails?.pamphletSide;

  //footer details
  const footerFileName = location?.state?.footerDetails?.footerFileName;
  const footerHeight = location?.state?.footerDetails?.footerHeight;

  // display Ad Details
  const displayAdFileName =
    location?.state?.displayAdDetails?.displayAdFileName;
  const displayAdURL = location?.state?.displayAdDetails?.displayAdURL;

  //Quantity of ad types
  // const budget = location.state.enteredBudget;

  const pamphletQuantity = location?.state?.pamphletQuantity;
  const footerQuantity = location?.state?.footerQuantity;
  const displayAdQuantity = location?.state?.displayAdQuantity;

  // Amount to pay
  const tax = 5;
  let payableTaxAmount = Math.floor(+budget * tax * 0.01);
  let correctAmount = payableTaxAmount + budget;
  // console.log(correctAmount);
  let finalTotalAmountPayable = Math.floor(+budget * tax * 0.01) + +budget;
  let totalAmountToPay = Math.floor(+budget + +payableTaxAmount);

  // For deducting amount from wallet
  // const deductAmount = (campaignId) => {
  //   fetch(API_URL + "campaign/deduct-payment/", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //     body: JSON.stringify({
  //       campaign_id: campaignId,
  //     }),
  //   })
  //     .then((res) => {
  //       if (res.ok) {
  //         history.push("/campaignSuccess/" + campaignId);
  //       } else {
  //         return res.json().then((data) => {
  //           // console.log(data);
  //           let errorMsg = "Something went wrong!";
  //           for (let key in data) {
  //             errorMsg = data[key];
  //           }
  //           throw new Error(errorMsg);
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       alert(err.message);
  //     });
  // };

  const submitHandler = (event) => {
    event.preventDefault();

    // if (+totalAmountToPay > 0) {
    paymentModalRef.current.openModal();
    // } else {
    //   // createCampaign(false);
    // deductAmount(campaignId);

    // }
  };

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />
      <div className="review_campaign_main_div">
        <div className="review_campaign_main_sub_div">
          <div className="review_campaign_text_wrapper">
            <div
              className="review_campaign_text heading"
              onClick={() => {
                //  console.log ("CID" + campaignId);
              }}
            >
              Your Campaign Details
            </div>
          </div>

          {(location?.state?.brandRecallPageState?.pamphletIsChecked === true ||
            location?.state?.brandRecallPageState
              ?.pamphletIsCheckedfromList) && (
            <div className="pamphlet_ad_details_div_card">
              <div className="pamphlet_ad_details_text_wrapper">
                <div className="pamphlet_ad_details_text sub_title">
                  Pamphlet Ad Details
                </div>
                <div className="icon_wrapper">
                  <div className="edit_icon_div">
                    {/* <img
                      className="edit_icon"
                      src={editIcon}
                      alt="editIcon"
                    ></img> */}
                  </div>
                  {/* <div className="close_icon_div">
                    <img
                      className="close_icon"
                      src={closeIcon}
                      alt="editIcon"
                    ></img>
                  </div> */}
                </div>
              </div>
              <div className="pamphlet_details_review_wrapper">
                <div className="pamphlet_details_review_child1">
                  <div className="sub_title_child">Size -</div>
                  <div className="sub_title_child">Side -</div>
                  <div className="sub_title_child">File Name 1 -</div>

                  {pamphletSide === "Double Side" && (
                    <div className="sub_title_child">File Name 2</div>
                  )}
                </div>

                <div className="pamphlet_details_review_child2">
                  <>
                    <div className="sub_title_child">
                      {isCodeValid ? sizeUsingCode : pamphletSize}
                    </div>
                    <div className="sub_title_child">
                      {isCodeValid ? isDuplex : pamphletSide}
                    </div>
                    <div className="sub_title_child">{frontFileName}</div>
                    {pamphletSide === "Double Side" && (
                      <div className="sub_title_child">{backFileName}</div>
                    )}
                  </>
                </div>
              </div>
            </div>
          )}

          {location?.state?.brandRecallPageState?.footerIsChecked === true && (
            <div className="footer_ad_details_card">
              <div className="pamphlet_ad_details_text_wrapper">
                <div className="pamphlet_ad_details_text sub_title">
                  Footer Ad Details
                </div>
                <div className="icon_wrapper">
                  <div className="edit_icon_div">
                    {/* <img
                      className="edit_icon"
                      src={editIcon}
                      alt="editIcon"
                    ></img> */}
                  </div>
                  <div className="close_icon_div">
                    <img
                      className="close_icon"
                      src={closeIcon}
                      alt="editIcon"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="pamphlet_details_review_wrapper">
                <div className="pamphlet_details_review_child1">
                  <div className="sub_title_child">File Name -</div>
                  <div className="sub_title_child">Height</div>
                </div>

                <div className="pamphlet_details_review_child2">
                  <div className="sub_title_child">{footerFileName}</div>
                  <div className="sub_title_child">{footerHeight}</div>
                </div>
              </div>
            </div>
          )}

          {location?.state?.brandRecallPageState?.displayAdIsChecked ===
            true && (
            <div className="display_ad_details_card">
              <div className="pamphlet_ad_details_text_wrapper">
                <div className="pamphlet_ad_details_text sub_title">
                  Display Ad Details
                </div>
                <div className="icon_wrapper">
                  <div className="edit_icon_div">
                    {/* <img
                      className="edit_icon"
                      src={editIcon}
                      alt="editIcon"
                    ></img> */}
                  </div>
                  <div className="close_icon_div">
                    <img
                      className="close_icon"
                      src={closeIcon}
                      alt="editIcon"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="pamphlet_details_review_wrapper">
                <div className="pamphlet_details_review_child1">
                  <div className="sub_title_child">File Name -</div>
                </div>

                <div className="pamphlet_details_review_child2">
                  <div className="sub_title_child"> {displayAdFileName}</div>
                </div>
              </div>
            </div>
          )}

          <div className="budget_details_card_review">
            <div className="budget_card_info_wrapper">
              <div className="card_heading sub_title">Plan Details</div>
              <div className="card_info_para">
                Based on your Quantity of &nbsp;
                <span className="sub_title_child">{pamphletQty}</span>
                &nbsp; Your Amount will be
              </div>
            </div>

            <div className="ad_types_quantity_wrapper m_t_1">
              <div className="ad_types_details ">
                <div className="sub_title">Ad types</div>

                {(location?.state?.brandRecallPageState?.pamphletIsChecked ===
                  true ||
                  location?.state?.brandRecallPageState
                    ?.pamphletIsCheckedfromList) && (
                  <div className="sub_title_child">Pamphlet</div>
                )}
                {location?.state?.brandRecallPageState?.footerIsChecked ===
                  true && <div className="sub_title_child">Footer</div>}
                {location?.state?.brandRecallPageState?.displayAdIsChecked ===
                  true && <div className="sub_title_child">Display Ad</div>}
              </div>

              <div className="quantity_details">
                <div className="sub_title quantity_text">Amount</div>

                {(location?.state?.brandRecallPageState?.pamphletIsChecked ===
                  true ||
                  location?.state?.brandRecallPageState
                    ?.pamphletIsCheckedfromList) && (
                  <div className="sub_title_child">{budget}</div>
                )}
                {location?.state?.brandRecallPageState?.footerIsChecked ===
                  true && (
                  <div className="sub_title_child">{footerQuantity}</div>
                )}
                {location?.state?.brandRecallPageState?.displayAdIsChecked ===
                  true && (
                  <div className="sub_title_child">{displayAdQuantity}</div>
                )}
              </div>
            </div>
          </div>

          <div className="payment_summary_text_wrapper">
            <div className="sub_title">Payment Summary</div>
          </div>

          <div className="payment_summary_details_wrapper">
            <div className="payment_summary_child1">
              <div className="sub_title_child">Amount</div>
              <div className="sub_title_child">GST ({tax}% Tax)</div>
            </div>
            <div className="payment_summary_child2">
              <div className="sub_title_child">₹{budget}</div>
              <div className="sub_title_child">₹{payableTaxAmount}.00</div>
            </div>
          </div>
          <div className="divider_review">
            <HorizontalDivider></HorizontalDivider>
          </div>
          <div className="payment_summary_details_wrapper">
            <div className="payment_summary_child1">
              <div className="sub_title">Total Cost </div>
            </div>
            <div className="payment_summary_child2">
              <div className="sub_title">₹{totalAmountToPay}</div>
            </div>
          </div>
          <div className="total_cost_wrapper"></div>
          <div className="center_div">
            <div className="wrapper">
              <div className="divider_brand_recall">
                <HorizontalDivider />
              </div>
              <div className="button_wrapper">
                {/* <div></div> */}
                <Button
                  className="white"
                  onClick={() => {
                    history.goBack();
                  }}
                  btnTxtClassName="sub_title text_black"
                  name="Back"
                />
                <Button type="submit" name="Continue" onClick={submitHandler} />
              </div>
              <Grid item>
                <PaymentModel
                  budget={budget}
                  payableTaxAmount={payableTaxAmount}
                  ref={paymentModalRef}
                  amount={totalAmountToPay}
                  discount={0}
                  createdCampaignId={campaignId}
                  // createCampaign={createCampaign}
                  isCreated={false}
                  campaignId={null}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewCampaign;
