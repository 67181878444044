import "./BusinessPageDetails.css";
import { businessPageColor } from "../../../../../assets/UrlTypeColorIcons";
import { useState } from "react";
import { facebookLogo, instaLogo } from "../../../../../assets";
import { useEffect } from "react";
const BusinessPageDetails = (props) => {
  const [businessDetails, setBusinessDetails] = useState({
    businessName: "",
    imgOfBusinessPage: null,
    description: "",
    phoneNo: "",
    email: "",
    website: "",
    storeAddress: "",
    openingTime: "",
    closingTime: "",
    url1: "",
    url2: "",
  });

  const setData = (dataKey, dataValue) => {
    setBusinessDetails((state) => ({ ...state, [`${dataKey}`]: dataValue }));
  };
  useEffect(() => {
    props.setBusinessPageDetails(businessDetails);
  }, [businessDetails]);

  // States for Validation
  const [url, setUrl] = useState("");
  const [isValidUrl1, setIsValidUrl1] = useState(true);
  const [isValidUrl2, setIsValidUrl2] = useState(true);
  const [isValidUrl3, setIsValidUrl3] = useState(true);

  const validateUrl = (url) => {
    const validSchemes = ["http://", "https://", "ftp://"];
    const containsScheme = validSchemes.some((scheme) => url.includes(scheme));
    // const containsWww = url.includes("www");
    // const containsDotCom = url.includes(".com");
    return containsScheme;
  };

  const [previewUrl, setPreviewUrl] = useState("");

  const multiUrlImgHandler = (e) => {
    const file = e.target.files[0];
    setData("imgOfBusinessPage", e.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="multiple_url_details_main_div">
        <div className="multiple_url_details_sub_div">
          <div className="sub_title url_type_heading">
            <span className="url_heading_icon">
              <img src={businessPageColor} alt="businessPageColor" />
            </span>
            Business Page
          </div>

          <div className="url_detail_section_wrapper">
            <div className="sub_title_child">Business Name</div>
            <div>
              <input
                className="website_url_input"
                type="text"
                placeholder="Your Business Name"
                onChange={(e) => {
                  setData("businessName", e.target.value);
                }}
              />
              {props.isBusinessPageBusinessNameEmpty && (
                <div style={{ color: "red" }}> Please Enter Business Name</div>
              )}
            </div>
          </div>

          <div className="img_detail_section_wrapper">
            <div className="sub_title_child">Image / Logo</div>
            <div>
              <input
                className="img_input"
                type="file"
                name=""
                id=""
                onChange={multiUrlImgHandler}
              />
              {props.isBusinessPageImgEmpty && (
                <div style={{ color: "red" }}> Please Attach Image / Logo</div>
              )}
            </div>
          </div>
          <div className="logo_preview_wrapper">
            {previewUrl && (
              <img
                className="logo_preview"
                src={previewUrl}
                alt="ImagePreview"
              />
            )}
          </div>
          <div
            style={{ marginTop: "1.5rem" }}
            className="img_detail_section_wrapper"
          >
            <div className="sub_title_child">Description</div>
            <div>
              <textarea
                className="description_input"
                style={{ padding: "10px" }}
                name=""
                id=""
                cols=""
                rows="3"
                placeholder="Describe About Your Business"
                onChange={(e) => {
                  setData("description", e.target.value);
                }}
              ></textarea>
              {props.isBusinessPageDescriptionEmpty && (
                <div style={{ color: "red" }}> Please Enter Description</div>
              )}
            </div>
          </div>

          <div
            // style={{ marginTop: "1.5rem" }}
            className="img_detail_section_wrapper"
          >
            <div className="sub_title_child">Phone Number</div>
            <div>
              <input
                className="website_url_input"
                type="number"
                name=""
                id="phone_no"
                placeholder="Enter Phone Number"
                onChange={(e) => {
                  setData("phoneNo", e.target.value);
                }}
              />
              {props.isBusinessPagePhoneNoEmpty && (
                <div style={{ color: "red" }}> Please Enter Phone Number</div>
              )}
            </div>
          </div>

          <div className="title_url_wrapper">
            <div className="title_wrapper">
              <div className="sub_title_child">
                Email <span style={{ color: "#929292" }}>(optional)</span>
              </div>
              <input
                style={{ width: "100%" }}
                className="website_url_input"
                type="text"
                placeholder="Enter Email"
                onChange={(e) => {
                  setData("email", e.target.value);
                }}
              />
            </div>
            <div style={{}} className="title_wrapper">
              <div>
                <div className="sub_title_child">
                  Website <span style={{ color: "#929292" }}>(optional)</span>
                </div>
                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  placeholder="https://"
                  type="text"
                  onChange={(e) => {
                    setData("website", e.target.value);
                    setUrl(e.target.value);
                    setIsValidUrl1(validateUrl(e.target.value));
                    props.setIsUrlValid(validateUrl(e.target.value));
                  }}
                />
                {!isValidUrl1 && (
                  <p style={{ color: "red", marginTop: "0.5rem" }}>
                    Please Enter Valid URL
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="img_detail_section_wrapper">
            <div className="sub_title_child">Store Address</div>
            <div>
              <textarea
                className="description_input"
                style={{ padding: "10px" }}
                name=""
                id=""
                cols=""
                rows="3"
                placeholder="Enter Shop no , LandMark, Street,Area "
                onChange={(e) => {
                  setData("storeAddress", e.target.value);
                }}
              ></textarea>
              {props.isBusinessPageStoreAddressEmpty && (
                <div style={{ color: "red" }}> Please Enter Store Address</div>
              )}
            </div>
          </div>

          <div className="title_url_wrapper">
            <div className="title_wrapper">
              <div className="sub_title_child">
                Opening Time{" "}
                <span style={{ color: "#929292" }}>(optional)</span>
              </div>
              <div>
                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  type="time"
                  onChange={(e) => {
                    // Convert the input time to a 12-hour format
                    const selectedTime = new Date(
                      `2000-01-01T${e.target.value}`
                    );
                    const formattedTime = selectedTime.toLocaleTimeString([], {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    });

                    // Update the state with the formatted time
                    setData("openingTime", formattedTime);
                  }}
                  placeholder="HH:mm"
                />
              </div>
            </div>
            <div className="title_wrapper">
              <div className="sub_title_child">
                Closing Time{" "}
                <span style={{ color: "#929292" }}>(optional)</span>
              </div>
              <div>
                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  type="time"
                  onChange={(e) => {
                    // Convert the input time to a 12-hour format
                    const selectedTime = new Date(
                      `2000-01-01T${e.target.value}`
                    );

                    const formattedTime = selectedTime.toLocaleTimeString([], {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    });

                    // Update the state with the formatted time
                    setData("closingTime", formattedTime);
                  }}
                  placeholder="HH:mm"
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: "1rem" }} className="url_input_wrapper">
            <div style={{ marginRight: "1rem", width: "100%" }}>
              <div className="sub_title_child">
                Social Media Links{" "}
                <span style={{ color: "#929292" }}>(optional)</span>
              </div>
              <div
                style={{ marginTop: "0.5rem" }}
                className="logo_input_wrapper"
              >
                <span style={{ marginRight: "1rem" }}>
                  <img src={instaLogo} alt="instaLogo" />
                </span>
                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  placeholder="https://"
                  type="text"
                  onChange={(e) => {
                    setData("url1", e.target.value);
                    setUrl(e.target.value);
                    setIsValidUrl2(validateUrl(e.target.value));
                    props.setIsUrlValid(validateUrl(e.target.value));
                  }}
                />
              </div>
              {!isValidUrl2 && (
                <p
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginLeft: "3.5rem",
                  }}
                >
                  Please Enter Valid URL
                </p>
              )}
              <div
                style={{ marginTop: "0.5rem" }}
                className="logo_input_wrapper"
              >
                <span style={{ marginRight: "1rem" }}>
                  <img src={facebookLogo} alt="facebookLogo" />
                </span>

                <input
                  style={{ width: "100%", marginTop: "1rem" }}
                  className="website_url_input"
                  placeholder="https://"
                  type="text"
                  onChange={(e) => {
                    setData("url2", e.target.value);
                    setUrl(e.target.value);
                    setIsValidUrl3(validateUrl(e.target.value));
                    props.setIsUrlValid(validateUrl(e.target.value));
                  }}
                />
              </div>
              {!isValidUrl3 && (
                <p
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginLeft: "3.5rem",
                  }}
                >
                  Please Enter Valid URL
                </p>
              )}
            </div>
          </div>

          <div style={{ marginBottom: "8rem" }}></div>
        </div>
      </div>
    </>
  );
};
export default BusinessPageDetails;
