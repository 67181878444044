import "./LocationDetails.css";
import captivePortalColor from "./../../../../../assets/UrlTypeColorIcons/captive_portal (2).svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../../../../store/campaign-slice";
import { locationColorIcon } from "../../../../../assets/UrlTypeColorIcons";

const LocationDetails = (props) => {
  const dispatch = useDispatch();
  const [locationDetails, setLocationDetails] = useState({
    url: "",
  });
  const setData = (dataKey, dataVal) => {
    setLocationDetails((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };

  useEffect(() => {
    dispatch(
      campaignActions.setLocationDetails({ locationDetails: locationDetails })
    );
  }, [locationDetails, dispatch]);

  // States for Validation
  const [url, setUrl] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(true);

  const validateUrl = (url) => {
    const validSchemes = ["http://", "https://", "ftp://"];
    const containsScheme = validSchemes.some((scheme) => url.includes(scheme));
    // const containsWww = url.includes("www");
    // const containsDotCom = url.includes(".com");
    return containsScheme;
  };

  return (
    <>
      <div className="website_details_main_div">
        <div className="sub_title url_type_heading">
          <span className="url_heading_icon">
            <img src={locationColorIcon} alt="captivePortalColor" />
          </span>
          Location
        </div>
        <div style={{ marginLeft: "36px" }}>
          <div className="url_detail_section_wrapper">
            <div className="sub_title_child">Location Url</div>
            <div>
              <input
                className="website_url_input"
                type="text"
                name="url"
                id="url"
                placeholder="https://"
                value={url}
                onChange={(e) => {
                  setData("url", e.target.value);
                  const inputUrl = e.target.value;
                  setUrl(inputUrl);
                  setIsValidUrl(validateUrl(inputUrl));
                  props.setIsUrlValid(validateUrl(inputUrl));
                }}
              />
            </div>
            <div className="guideline_text">
              Enter the Google Maps link for your store location.
            </div>
            {!isValidUrl && (
              <p style={{ color: "red", marginTop: "0.5rem" }}>
                Please Enter Valid URL
              </p>
            )}
            {props.isLocationUrlEmpty && isValidUrl && (
              <div style={{ color: "red", marginTop: "4px" }}>
                Please Enter Url
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default LocationDetails;
