import React from "react";
import { qeCodeIcon, triangleInfoIcon } from "../../../assets";
import "./QrCodeCreateCard.css";
function QrCodeCreateCard(props) {
  return (
    <div className="stepper_input_main_div">
      <div className="campaign_input_sub_div">
        <h4 className="m_b_2">
          <span>
            <img src={qeCodeIcon} alt="qeCodeIcon" />
          </span>
          &nbsp; QR Code
        </h4>

        {props.isQr ? (
          <>
            <div className="qr_code_text_new_stepper text-center f_w_500 f_s_18">
              When this QR is scanned, potential customers can be directed to
              your website, social media accounts, or even a promotional video.
            </div>

            <div
              className="create_qr_btn_new_wrapper pointer"
              onClick={props.onClick}
            >
              <div className="m_t_2 text-center create_qr_btn_new">
                Create QR Code &nbsp;
                <span>
                  <img src={qeCodeIcon} alt="qeCodeIcon" />
                </span>{" "}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="qr_not_selected_div_wrapper">
              <img src={triangleInfoIcon} alt="qeCodeIcon" />
              <div className="qr_code_text_new_stepper text-center f_w_500 ">
                Unfortunately, your current plan does not include the QR code,
                so you cannot use the QR code facility. To access this feature,
                please choose the QR code plan.
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default QrCodeCreateCard;
