import React from "react";

import "./ContactUs.css";
import fbSvg from "./fb.png";
import instaSvg from "./insta.png";
import twitterSvg from "./twt.png";
import emailSvg from "./email.svg";
import locationSvg from "./location-svgrepo-com.svg";
import phone from "./Contact.png";
import ewayadz from "./Ewayprint.png";
import ewayadz1 from "./Ewayprint1.png";
import upperArrow from "./Up_arrow_white.svg";
import { Link, useLocation } from "react-router-dom";
function ContactUs(props) {
  const location = useLocation();
  const ads = location.pathname.startsWith("/ads");

  return (
    <>
      <div
        ref={props.forward}
        className={`contact-us-3-mainDiv ${
          ads && "black contact-us-3-mainDiv__ads"
        }`}
        // style={{ backgroundImage: `url(${contactUsbg})` }}
      >
        <div className="C1">
          <img
            style={{ width: "11vw", marginLeft: "-3vw" }}
            className="contactus-c1-img"
            src={ads ? ewayadz1 : ewayadz}
            alt="logo"
          ></img>
          {/* <div>About Us</div> */}
          {/* <div>Customers</div> */}
          {/* <div>Advertiser</div> */}
          {/* <div>Media</div>
          <div>Blog</div> */}
        </div>
        <div className="C2">
          {!ads && (
            <>
              <div>
                <div style={{ fontSize: "1.5em", fontWeight: "700" }}>
                  Contact Us
                </div>
                <div className="contact-us-divider"></div>
              </div>
              <div className="contact-us-phone">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "0.5em",
                  }}
                >
                  <div className="contactus-icon-text-grp">
                    <div>
                      <img src={phone} alt="p"></img>
                    </div>
                    <div className="phone-bold-font">Phone</div>
                  </div>

                  <div align="center" style={{ cursor: "pointer" }}>
                    <a href="tel:+917770010066">(+91) 7770010066</a>
                  </div>
                </div>
              </div>

              <div className="contact-us-email">
                <div
                  className="contactus-icon-text-grp-nthchild"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "0.5em",
                  }}
                >
                  <div className="contactus-icon-text-grp">
                    <div>
                      <img src={emailSvg} alt="e"></img>
                    </div>
                    <div className="email-bold-font">Email</div>
                  </div>
                  <a href="mailto: ads@ewayprint.com">
                    <div align="center" style={{ cursor: "pointer" }}>
                      ads@ewayprint.com
                    </div>
                  </a>
                </div>
              </div>

              <div className="contact-us-address">
                <div
                  className="contactus-icon-text-grp-nthchild"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "0.5em",
                  }}
                >
                  <div className="contactus-icon-text-grp">
                    <div>
                      <img src={locationSvg} alt="e"></img>
                    </div>
                    <div className="email-bold-font">Address</div>
                  </div>

                  <div
                    align="center"
                    style={
                      {
                        // cursor: "pointer",
                      }
                    }
                  >
                    {!ads
                      ? "UPPER GROUND, OFFICE NO U 71, CLOVER HILLS PLAZA, NIBM ROAD, KONDHWA KHURD, Pune, Pune, Maharashtra, 411048"
                      : ""}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="C3">
          <div className={`${ads && "whiteColor"}`}>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </div>
          {/* <div>Disclaimer</div> */}
          <div className={`${ads && "whiteColor"}`}>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
          <div className="follow-div">
            <div>
              <div
                style={{ fontSize: "1.5em", fontWeight: "700" }}
                className={`${ads && "whiteColor"}`}
              >
                Follow Us
              </div>
              <div className="follow-us-divider"></div>
            </div>

            <div className="follow-us-icons">
              <div>
                <a
                  href="https://www.instagram.com/ewayprint/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instaSvg}
                    alt="insta"
                    width={"50px"}
                    height={"50px"}
                    className="obj_fit_contain"
                  ></img>
                </a>
              </div>
              <div>
                <a
                  href="https://twitter.com/EwayPrint"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitterSvg}
                    alt="twt"
                    width={"50px"}
                    height={"50px"}
                    className="obj_fit_contain"
                  ></img>
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/EwayprintIndia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={fbSvg}
                    alt="fb"
                    width={"50px"}
                    height={"50px"}
                    className="obj_fit_contain"
                  ></img>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: "1rem", width: "100%" }}
            className="to_top_main_div "
          >
            {/* <div className=" white-text f_s_22">To Top &nbsp;</div> */}
            <img
              src={upperArrow}
              alt="fb"
              width={"50px"}
              height={"50px"}
              className="obj_fit_contain pointer"
              onClick={() => {
                document.getElementById("Nav").scrollIntoView();
              }}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
