import React from "react";
import { useState } from "react";

import "./input.css";
import notVisible from "./notVisible.svg";
import Visible from "./visibility.svg";

const Input = (props) => {
  const [show, setShow] = useState(false);
  const classes = `Eway_input_main ${props.className}`;

  return (
    <div className={classes} onClick={props.onClick}>
      <span className=" sub_title">{props.label ? props.label : ""}</span>
      <input
        {...(props.maxLength && { maxLength: props.maxLength })}
        {...(props.pattern && { pattern: props.pattern })}
        onChange={props.onChange}
        value={props.value}
        className={`Eway_input_compo  w_100 ${props.inputClasses}`}
        placeholder={`${props.placeholder}`}
        type={
          props.type === "password" && show
            ? "text"
            : props.type
            ? props.type
            : "text"
        }
      ></input>
      {props.type === "password" && (
        <div className="visibility_img">
          {!show && (
            <img
              style={{ width: "24px" }}
              onClick={() => {
                setShow(!show);
                // props.getShow(show);
              }}
              src={notVisible}
              alt="notvisible"
            ></img>
          )}
          {show && (
            <img
              style={{ width: "24px" }}
              onClick={() => {
                setShow(!show);
                // props.getShow(show);
              }}
              src={Visible}
              alt="notvisible"
            ></img>
          )}
        </div>
      )}
    </div>
  );
};

export default Input;
