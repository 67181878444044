import React from "react";

import "./Git.css";
import email from "./email.svg";
import Contact from "./Contact.png";
import { useLocation } from "react-router-dom";

function Git() {
  const location = useLocation();
  const ads = location.pathname.startsWith("/ads");
  return (
    <div className="Git-main-div">
      <div className="Git-c1">
        <div className="Git-head">Get in touch with us.</div>
        <div className="Git-body">
          Have a simple question or need general information?
          <br /> Our Ewayprint Customer Support team will help you
          <span>Contact Between (10:30 am to 6:30 pm)</span>
        </div>
      </div>
      <div className="Git-c2">
        <div className="Git-c2-box">
          <img className="Git-c2-box-img1" src={Contact} alt="phone"></img>
          <div>
            {ads ? (
              <>
                <a href="tel:+917770010066 ">(+91) 7770010066</a>
              </>
            ) : (
              <a href="tel:+919021410511">(+91) 9021410511</a>
            )}
          </div>
        </div>

        <div className="Git-c2-box">
          <img className="Git-c2-box-img2" src={email} alt="phone"></img>
          <div>
            {" "}
            {ads ? (
              <>
                <a href="mailto: ads@ewayprint.com"> ads@ewayprint.com</a>
              </>
            ) : (
              <a href="mailto: support@ewayprint.com"> support@ewayprint.com</a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Git;
