import React from "react";
import "./ContactUs.scss";
import emailSvg from "./email.svg";
import phone from "./Contact.png";
import FormSec from "../../ContactUsPage/FormSec/FormSec";

const ContactUs = () => {
  return (
    <div className="ContactUs_" id="Contact">
      <div className="ContactUs__main">
        <div className="info">
          <div className="info__heading">Request a Demo Campaign</div>
          <p>
            We are excited to offer you the opportunity to experience our
            services firsthand through a personalized demo campaign. To request
            your demo, simply provide us with your details by filling out the
            form .
          </p>

          {/* <div className="info__heading">Reach us at</div>
          <div style={{ cursor: "pointer" }} className="info__text">
            <div>
              <img src={emailSvg} alt="email" />
            </div>
            <a href="mailto: support@ewayprint.com">support@ewayprint.com</a>
          </div>
          <div style={{ cursor: "pointer" }} className="info__text">
            <div>
              <img src={phone} alt="phone" />
            </div>
            <a href="tel:+917770010066">+91 7770010066</a>
          </div> */}
        </div>
        <div className="form">
          <FormSec isFromAds />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
