import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import greenCheck from "./Tick-icon.svg";

import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import HorizontalDivider from "../../../components/UI/Dividers/HorizontalDivider";
import Button from "../../../components/UI/GreenBtn/Button";
import Input from "../../../components/UI/Inputs/Input";
import PlanCodeReviewCard from "../../../components/UI/PlanCodeReviewCard/PlanCodeReviewCard";
import TableWithRadio from "../../../components/UI/TableWithRadio/TableWithRadio";

import { API_URL } from "../../../constant/apiURL";
import useHttp from "../../../Hooks/useHttp";
import { campaignActions } from "../../../store/campaign-slice";
import "./SetBudget.css";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import CustomBudgetCard from "../../../components/UI/CustomBudgetCard/CustomBudgetCard";
import QuantityInCards from "./QuantityInCards/QuantityInCards";
const SetBudget = (props) => {
  // redux
  const token = useSelector((state) => state.auth.token);
  const voucherId = useSelector((state) => state.campaigns.voucherId);
  const pamphletId = useSelector((state) => state.campaigns.pamphletId);
  const footerId = useSelector((state) => state.campaigns.footerId);
  const displayAdId = useSelector((state) => state.campaigns.displayAdId);
  const qrCodeId = useSelector((state) => state.campaigns.qrCodeId);
  const ratesTableRowData = useSelector(
    (state) => state.campaigns.ratesTableRowData
  );

  // variables for custom quantity discount calculation
  let discountForCustomQty;
  let discPer;
  let cstmQtyTotalAmt;
  let cstmQtyTotalAmtWithDisc;
  let cstmQtyTotalAmtWithDiscount;
  // console.log(cstmQtyTotalAmtWithDiscount);

  // let defaultPlanQuantity =
  //   ratesTableRowData != null && ratesTableRowData.quantity;

  const [responseAmount, setResponseAmount] = useState();
  const [
    locationList: data,
    locationError: error,
    isLocationLoading: isLoading,
  ] = useHttp(API_URL + "campaign/locations-list/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentCampaignId = location?.state?.currentCampaign?.id;
  const isFromList =
    location.state.brandRecallPageState.pamphletIsCheckedfromList;
  const isDynamicPinCodes =
    location.state.brandRecallPageState.reachNewAudience;
  const isPrePrinted = location.state.isPrePrinted;
  // size for rates
  const isA5Single =
    location.state.pamphletDetails.pamphletSide === "Single Side" &&
    location.state.pamphletDetails.pamphletSize === "A5";

  const isA5Duplex =
    location.state.pamphletDetails.pamphletSide === "Double Side" &&
    location.state.pamphletDetails.pamphletSize === "A5";

  const isA4Single =
    location.state.pamphletDetails.pamphletSide === "Single Side" &&
    location.state.pamphletDetails.pamphletSize === "A4";

  const isA4Duplex =
    location.state.pamphletDetails.pamphletSide === "Double Side" &&
    location.state.pamphletDetails.pamphletSize === "A4";

  // Rates api
  const [ratesList: data, ratesError: error, isRatesLoading: isLoading] =
    useHttp(API_URL + "pamphlet-quantity-rate/list/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

  // Rates;
  const a5d =
    ratesList != null &&
    ratesList.length >= 0 &&
    ratesList[0].rate_points[0].price;

  const a5s =
    ratesList != null &&
    ratesList.length >= 0 &&
    ratesList[1]?.rate_points[0]?.price;
  const a4d =
    ratesList != null &&
    ratesList.length >= 0 &&
    ratesList[2]?.rate_points[0]?.price;
  const a4s =
    ratesList != null &&
    ratesList.length >= 0 &&
    ratesList[3]?.rate_points[0]?.price;

  // Rate Through Api
  let amountToMultiply =
    (isA5Duplex && a5d) ||
    (isA5Single && a5s) ||
    (isA4Duplex && a4d) ||
    (isA4Single && a4s);
  // console.log(amountToMultiply);

  // data using useLocation
  const repeatCount = location.state.pamphletDetails.countSelected;
  const repeatRange = location.state.pamphletDetails.repeatRangeSelected;
  const planCodeId = location.state.pamphletDetails.planCodeId;
  // console.log(planCodeId);
  const campaignName = location.state.brandRecallPageState.campaignName;
  const distributionStartDate = location.state.brandRecallPageState.startOn;

  const industry = location.state.brandRecallPageState.industry;

  const dynamicPinCodes = location?.state?.brandRecallPageState?.pinCodes;

  //plan code data using useLocation
  const isCodeValid = location.state.isCodeValid;
  // console.log("isCodeValid", isCodeValid);
  // console.log(location.state.pamphletDetails, "location.state.pamphletDetails");
  const amountUsingCode = location.state.pamphletDetails.amountUsingCode;
  const quantityUsingCode = location.state.pamphletDetails.quantityUsingCode;
  const sideUsingCode = location.state.pamphletDetails.sideUsingCode;
  const sizeUsingCode = location.state.pamphletDetails.sizeUsingCode;
  const discountUsingCode = location.state.pamphletDetails.discountUsingCode;
  // console.log("amountUsingCode", amountUsingCode);

  // calculation of plan Code Amount
  const tempDiscount = 1 - discountUsingCode / 100;
  const totalAmountUsingCode = amountUsingCode / tempDiscount;
  // console.log("totalAmountUsingCode", totalAmountUsingCode);

  // states for quantity
  const [enteredBudget, setEnteredBudget] = useState();
  const [enteredQuantity, setEnteredQuantity] = useState(null);
  const [overAllQuantity, setOverAllQuantity] = useState();
  const [pamphletQuantity, setPamphletQuantity] = useState();
  const [footerQuantity, setFooterQuantity] = useState();
  const [displayAdQuantity, setDisplayAdQuantity] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [errorBtnClick, setErrorBtnClick] = useState(false);
  const [campaignSuccess, setCampaignSuccess] = useState(false);
  const [amountWithDisc, setAmountWithDisc] = useState();
  const [campaignId, setCampaignId] = useState(null);
  const [isMaxQtyValid, setIsMaxQtyValid] = useState(false);
  // const [isMinQtyValid, setIsMinQtyValid] = useState(false);

  // states for conditional rendering
  const [isCustomDetails, setIsCustomDetails] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  // console.log(selectedPlan, "selectedPlan");

  // quantity to post in api
  const quantityOfCode = isCodeValid && quantityUsingCode;
  // console.log(quantityOfCode);
  // const quantityOfWithoutCode =
  //   ratesTableRowData?.quantity === "Custom Quantity"
  //     ? enteredQuantity
  //     : defaultPlanQuantity;

  // custom quantity discount calculation

  if (enteredQuantity >= 500 && enteredQuantity < 2000) {
    discountForCustomQty = 0;
    discPer = 0.0;
    cstmQtyTotalAmt = enteredQuantity * amountToMultiply;

    cstmQtyTotalAmtWithDisc =
      enteredQuantity * amountToMultiply -
      enteredQuantity * amountToMultiply * discPer;
    cstmQtyTotalAmtWithDiscount = Math.floor(cstmQtyTotalAmtWithDisc);
    // console.log(Math.floor(cstmQtyTotalAmtWithDisc));
  }
  if (enteredQuantity >= 2000 && enteredQuantity < 5000) {
    discountForCustomQty = 5;
    discPer = 0.05;
    cstmQtyTotalAmt = enteredQuantity * amountToMultiply;
    cstmQtyTotalAmtWithDisc =
      enteredQuantity * amountToMultiply -
      enteredQuantity * amountToMultiply * discPer;
    cstmQtyTotalAmtWithDiscount = Math.floor(cstmQtyTotalAmtWithDisc);
    // console.log(cstmQtyTotalAmtWithDiscount);
  }
  if (enteredQuantity >= 5000 && enteredQuantity < 10000) {
    discountForCustomQty = 10;
    discPer = 0.1;
    cstmQtyTotalAmt = enteredQuantity * amountToMultiply;
    cstmQtyTotalAmtWithDisc =
      enteredQuantity * amountToMultiply -
      enteredQuantity * amountToMultiply * discPer;
    cstmQtyTotalAmtWithDiscount = Math.floor(cstmQtyTotalAmtWithDisc);
    // console.log(cstmQtyTotalAmtWithDiscount);
  }
  if (enteredQuantity >= 10000 && enteredQuantity < 20000) {
    discountForCustomQty = 15;
    discPer = 0.15;
    cstmQtyTotalAmt = enteredQuantity * amountToMultiply;
    cstmQtyTotalAmtWithDisc =
      enteredQuantity * amountToMultiply -
      enteredQuantity * amountToMultiply * discPer;
    cstmQtyTotalAmtWithDiscount = Math.floor(cstmQtyTotalAmtWithDisc);
    // console.log(cstmQtyTotalAmtWithDiscount);
  }
  if (enteredQuantity >= 20000 && enteredQuantity <= 50000) {
    discountForCustomQty = 20;
    discPer = 0.2;
    cstmQtyTotalAmt = enteredQuantity * amountToMultiply;
    cstmQtyTotalAmtWithDisc =
      enteredQuantity * amountToMultiply -
      enteredQuantity * amountToMultiply * discPer;
    cstmQtyTotalAmtWithDiscount = Math.floor(cstmQtyTotalAmtWithDisc);
    // console.log(cstmQtyTotalAmtWithDiscount);
  }

  // amount to save for review page
  const amountOfCode = isCodeValid && amountUsingCode;
  const amountAmongRadioAndCustom =
    ratesTableRowData?.quantity === "Custom Quantity"
      ? cstmQtyTotalAmtWithDiscount
      : amountWithDisc;

  // Array for month duration
  let monthDurationArray = [
    { id: 90, name: "3 Months" },
    { id: 180, name: "6 Months" },
    { id: 360, name: "12 Months" },
  ];

  // cards data
  const prePrintedPamphletAmount = 4;
  const recommendedPlanQty = 10000;
  const recommendedPlanPrice = isPrePrinted
    ? recommendedPlanQty * prePrintedPamphletAmount
    : recommendedPlanQty * amountToMultiply;

  const customAmountCard =
    enteredQuantity === null
      ? 0
      : isPrePrinted
      ? enteredQuantity * prePrintedPamphletAmount
      : enteredQuantity * amountToMultiply;

  const pamphletSide = location.state.pamphletDetails.pamphletSide;
  const pamphletSize = location.state.pamphletDetails.pamphletSize;

  // quantity to post in api
  // const quantityOfCode = isCodeValid && quantityUsingCode;
  // console.log(quantityOfCode);
  const quantityOfWithoutCode =
    selectedPlan === "recommended plan" ? recommendedPlanQty : enteredQuantity;

  const defaultPlanQuantity =
    selectedPlan === "recommended plan" && recommendedPlanQty;

  // Amount of cards to post in api
  const amountCards =
    selectedPlan === "recommended plan"
      ? recommendedPlanPrice
      : customAmountCard;

  // states for month duration
  const [selectedMonth, setSelectedMonth] = useState(monthDurationArray[0].id);

  // function for month dropdown
  const getMonthSelected = (e) => {
    setSelectedMonth(+e.target.value);
  };
  // console.log(quantityOfCode, quantityOfWithoutCode);

  //Api Campaign Update
  const updateCampaignHandler = () => {
    let pinCodesCSV =
      locationList &&
      locationList.map((l) => l.pin_code !== "All" && l.pin_code).join(" ,");
    fetch(API_URL + `campaign/update/${currentCampaignId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        amount: !isCodeValid ? amountCards : null,
        pamphlet_plan_code: planCodeId,
        qty_for_pamphlet: isCodeValid ? quantityOfCode : quantityOfWithoutCode,
        // voucher_id: voucherId,
        pamphlet_id: pamphletId,
        // footer_id: footerId,
        // display_ad_id: displayAdId,
        // campaign_completion_duration: selectedMonth,
        repeat_count_for_pamphlet: repeatCount,
        repeat_range_for_pamphlet: repeatRange,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setResponseAmount(data.amount);
            setOverAllQuantity(data.amount);
            dispatch(
              campaignActions.setOverAllData({
                overAllData: {
                  campaignId: data.id,
                  amount: isCodeValid ? amountUsingCode : null,
                  name: campaignName,
                  pamphletQty: isCodeValid
                    ? quantityOfCode
                    : quantityOfWithoutCode,
                  start_on: distributionStartDate,
                  sub_industry: industry,
                  pin_codes: location.state.reachNewAudience
                    ? dynamicPinCodes
                    : pinCodesCSV,
                  voucher_id: voucherId,
                  pamphlet_id: pamphletId,
                  footer_id: footerId,
                  display_ad_id: displayAdId,
                  campaign_completion_duration: selectedMonth,
                  repeat_count_for_pamphlet: repeatCount,
                  repeat_range_for_pamphlet: repeatRange,
                  totalAmount: isCodeValid ? amountOfCode : amountCards,
                  overAllQty:
                    data.qty_for_pamphlet +
                    (data.qty_for_footer && data.qty_for_footer) +
                    (data.qty_for_display_ad && data.qty_for_display_ad),
                },
              })
            );
            setPamphletQuantity(data.qty_for_pamphlet);
            setFooterQuantity(data.qty_for_footer);
            setDisplayAdQuantity(data.qty_for_display_ad);
          });
        }
      })
      .then((json) => {
        // console.log(json);
      });
  };

  // console.log(isCodeValid);
  // console.log(amountAmongRadioAndCustom);
  //  Api campaign create
  const submitCampaignCreateBrandRecall = () => {
    let pinCodesCSV =
      locationList &&
      locationList.map((l) => l.pin_code !== "All" && l.pin_code).join(" ,");

    fetch(API_URL + "campaign/create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        amount: !isCodeValid ? amountCards : null,
        pamphlet_plan_code: planCodeId,
        qty_for_pamphlet: isCodeValid ? quantityOfCode : quantityOfWithoutCode,
        name: campaignName,
        start_on: distributionStartDate,
        sub_industry: industry,
        pin_codes: pinCodesCSV,
        voucher_id: voucherId,
        pamphlet_id: pamphletId,
        footer_id: footerId,
        display_ad_id: displayAdId,
        qr_code_id: qrCodeId,
        // campaign_completion_duration: selectedMonth,
        repeat_count_for_pamphlet: repeatCount,
        repeat_range_for_pamphlet: repeatRange,
      }),
    }).then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          // console.log(data);
          setCampaignId(data.id);
          setResponseAmount(data.total_amount);
          setOverAllQuantity(data.amount);
          dispatch(
            campaignActions.setOverAllData({
              overAllData: {
                campaignId: data.id,
                amount: isCodeValid ? amountUsingCode : null,
                name: campaignName,
                pamphletQty: isCodeValid
                  ? quantityOfCode
                  : quantityOfWithoutCode,
                start_on: distributionStartDate,
                sub_industry: industry,
                pin_codes: location.state.reachNewAudience
                  ? dynamicPinCodes
                  : pinCodesCSV,
                voucher_id: voucherId,
                pamphlet_id: pamphletId,
                footer_id: footerId,
                display_ad_id: displayAdId,
                campaign_completion_duration: selectedMonth,
                repeat_count_for_pamphlet: repeatCount,
                repeat_range_for_pamphlet: repeatRange,
                totalAmount: isCodeValid ? amountOfCode : amountCards,

                overAllQty:
                  data.qty_for_pamphlet +
                  (data.qty_for_footer && data.qty_for_footer) +
                  (data.qty_for_display_ad && data.qty_for_display_ad),
              },
            })
          );
          setPamphletQuantity(data.qty_for_pamphlet);
          setFooterQuantity(data.qty_for_footer);
          setDisplayAdQuantity(data.qty_for_display_ad);
        });
      }
    });
  };

  const quantity = {
    enteredBudget: enteredBudget,
    overAllQuantity: overAllQuantity,
    pamphletQuantity: pamphletQuantity,
    footerQuantity: footerQuantity,
    displayAdQuantity: displayAdQuantity,
  };

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />
      <div className="set_budget_main_div">
        <div className="set_budget_sub_div">
          <div className="set_budget_text_wrapper">
            <div className="heading">Pricing Plan</div>
          </div>

          {location.state.isCodeValid === false && (
            <QuantityInCards
              recommendedPlanQty={recommendedPlanQty}
              recommendedPlanPrice={recommendedPlanPrice}
              pamphletSide={pamphletSide}
              setEnteredQuantity={setEnteredQuantity}
              pamphletSize={pamphletSize}
              setSelectedPlan={setSelectedPlan}
              customAmountCard={customAmountCard}
            />
          )}
          {/* <div className="campaign_duration_dropdown_main_month">
            <div style={{ textAlign: "center" }} className="sub_title ">
              Campaign Duration
            </div>
            <div className="months_dropdown_wrapper">
              <select onChange={getMonthSelected} className="dropdown_months">
                {monthDurationArray.map((months) => {
                  return (
                    <option value={months.id} key={months.id}>
                      {months.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div> */}
          {/* {location.state.isCodeValid === false && ( */}
          {/* <> */}
          {/* <div className="rates_table_main_div">
                <div className="rec_wrapper sub_title"></div>

                <TableWithRadio
                  setAmountWithDisc={setAmountWithDisc}
                  rateOfA5Duplex={isA5Duplex && a5d}
                  rateOfA5Single={isA5Single && a5s}
                  rateOfA4Duplex={isA4Duplex && a4d}
                  rateOfA4Single={isA4Single && a4s}
                  enteredQuantity={enteredQuantity}
                />
              </div> */}
          {/* <div className="enter_budget_child1">
                  <input
                    type="checkbox"
                    value="custom_qty"
                    id="custom_qty"
                    checked={isChecked}
                    onChange={(e) => {
                      setIsChecked(e.target.checked);
                    }}
                  />
                  &nbsp; &nbsp;
                  <label htmlFor="custom_qty" className="sub_title_child">
                    Enter Custom Quantity
                  </label>
                </div> */}
          {/* <div className="enter_budget_input">
              <div className="input_and_button_wrapper ">
                <Input
                  onChange={(e) => {
                    setEnteredBudget(+e.target.value);
                  }}
                  type="number"
                  placeholder="Enter Your Budget"
                ></Input>
              </div>
            </div> */}
          {/* {ratesTableRowData?.quantity === "Custom Quantity" && (
                <div className="enter_budget_wrapper">
                  <div className="input_and_button_wrapper">
                    <input
                      className="qty_input"
                      onChange={(e) => {
                        setEnteredQuantity(+e.target.value);
                        if (+e.target.value > 50000) {
                          setIsMaxQtyValid(true);
                        }
                        if (
                          +e.target.value <= 50000 &&
                          +e.target.value >= 500
                        ) {
                          setIsMaxQtyValid(false);
                          setIsMinQtyValid(false);
                        }
                        if (+e.target.value < 500) {
                          setIsMinQtyValid(true);
                        }
                        if (+e.target.value < 500) {
                          setIsCustomDetails(false);
                        }
                        if (+e.target.value > 20000) {
                          setIsCustomDetails(false);
                        }
                      }}
                      type="number"
                      placeholder="Enter Quantity"
                    ></input>
                    <button
                      className="cutom_qty_btn"
                      onClick={() => {
                        if (
                          enteredQuantity >= 500 &&
                          enteredQuantity <= 50000
                        ) {
                          setIsCustomDetails(true);
                        }
                      }}
                    >
                      Check Details
                    </button>
                  </div>
                  {isMinQtyValid && (
                    <p style={{ color: "red" }}>
                      Minimum Quantity For Pamhlet is 500
                    </p>
                  )}

                  {isMaxQtyValid && (
                    <p style={{ color: "red" }}>
                      Maximum Quantity For Pamhlet is 50,000 Only
                    </p>
                  )}
                  {isCustomDetails && (
                    <div className="custom_qty_details_wrapper">
                      <div>
                        <div className="sub_title">Quantity</div>
                        <div
                          style={{ textAlign: "center" }}
                          className="sub_title_child"
                        >
                          {enteredQuantity}
                        </div>
                      </div>

                      <div>
                        <div className="sub_title">Amount</div>
                        <div
                          style={{ textAlign: "center" }}
                          className="sub_title_child"
                        >
                          {cstmQtyTotalAmt}
                        </div>
                      </div>

                      <div>
                        <div className="sub_title">Discount</div>
                        <div
                          style={{ textAlign: "center" }}
                          className="sub_title_child"
                        >
                          {discountForCustomQty}%
                        </div>
                      </div>
                      <div>
                        <div className="sub_title">Total</div>
                        <div
                          style={{ textAlign: "center" }}
                          className="sub_title_child"
                        >
                          {cstmQtyTotalAmtWithDiscount}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </> */}
          {/* )} */}
          {location.state.isCodeValid === true && (
            <>
              <PlanCodeReviewCard
                totalAmountUsingCode={totalAmountUsingCode}
                discount={discountUsingCode}
                amount={amountUsingCode}
                quantity={quantityOfCode}
                sideUsingCode={sideUsingCode}
                sizeUsingCode={sizeUsingCode}
              />
              {campaignSuccess && (
                <p
                  className="sub_title_child"
                  style={{ color: "green", marginTop: "1rem " }}
                >
                  Plan Submitted Successfully &nbsp; &nbsp;
                  <img src={greenCheck} alt="greenCheck" />
                </p>
              )}
            </>
          )}
          {errorBtnClick && (
            <p style={{ color: "red", marginTop: "1rem" }}>
              Please Select Plan
            </p>
          )}
          <div className="center_div">
            <div className="wrapper">
              <div className="divider_brand_recall">
                <HorizontalDivider />
              </div>
              <div className="button_wrapper">
                <Button
                  className="white"
                  onClick={() => {
                    history.goBack();
                  }}
                  btnTxtClassName="sub_title text_black"
                  name="Back"
                />
                <Button
                  name="Continue"
                  onClick={() => {
                    if (selectedPlan === "" || selectedPlan === "") {
                      setErrorBtnClick(true);
                    } else {
                      setErrorBtnClick(false);
                    }

                    if (
                      (enteredQuantity >= 1000 && enteredQuantity <= 50000) ||
                      defaultPlanQuantity ||
                      amountUsingCode > 0
                    ) {
                      setCampaignSuccess(true);
                      if (isFromList) {
                        updateCampaignHandler();
                        history.replace({
                          pathname: "/ads/campaign/create/new/review",
                          state: { ...location.state, ...quantity },
                        });
                      } else {
                        if (
                          (campaignId === null &&
                            enteredQuantity >= 1000 &&
                            enteredQuantity <= 50000) ||
                          defaultPlanQuantity ||
                          amountUsingCode > 0
                        ) {
                          submitCampaignCreateBrandRecall();
                          history.replace({
                            pathname: "/ads/campaign/create/new/review",
                            state: { ...location.state, ...quantity },
                          });
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SetBudget;
