import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FaqAccordian.css";
import { useDispatch } from "react-redux";

export default function FaqAccordian() {
  const questionsObj = {
    q1: "What is ewayprint ads & what does it offer?",
    q2: "How is ewayprint ads different from social media advertising?",
    q3: "How can I track the progress of my campaigns?",
    q4: "Can I set how often my pamphlet is distributed to same person?",
    q5: "How can I trust that my pamphlets are being delivered & reaching to the target audience?",
    q6: "Can I see a proof of my pamphlet before it goes into distribution?",
    q7: "Do you have a dedicated support team?",
  };

  const answerObj = {
    ans1: "Ewayprint is a leading pamphlet distribution company that provides a personalized & customized advertising for web business.",
    ans2: "Ewayprint ads provides a unique approach to advertising by delevering promotional flyers directly to your target audience`s doorstep.",
    ans3: "With our real-time monitoring system, You can track the progress of your campaign & see the number of pamphlets distributed at any given time.",
    ans4: "Yes, you can choose to have your pamphlets delivered to the same person one-time or after a gap of days. This gives you complete control over your advertising campaigns.",
    ans5: "Ewayprint ads uses a tracking system to ensure that each pamphlet is delivered and recieved by the target audience. You can check the result of your investment through our real-time monitoring dashboard",
    ans6: "Yes, you can view a proof of your pamphlet before it goes into distribution.",
    ans7: "Yes,  at ewayprint we have dedicated support team that provides personalized support & ensures that your advertising campaign is a success",
  };

  const subAns = {
    ans1: "We help compaies reach their target audience through distribution of business flyers in a secure & efficient manner.",
    ans6: "Our expert design team will work to create a design that meets your needs. And you will have opportunity to review and approve the proof before it goes into production.",
  };
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {questionsObj.q1}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answerObj.ans1}</Typography>
          <br />
          <Typography>{subAns.ans1}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {questionsObj.q2}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answerObj.ans2}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {questionsObj.q3}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answerObj.ans3}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {questionsObj.q4}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answerObj.ans4}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {questionsObj.q5}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answerObj.ans5}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {questionsObj.q6}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answerObj.ans6}</Typography>
          <br />
          <Typography>{subAns.ans6}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {questionsObj.q7}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answerObj.ans7}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
