import "./CardNewFlow.css";
import check from "./check (1).svg";

const CardNewFlow = (props) => {
  return (
    <>
      <div
        onClick={props.onClick}
        className={props.isSelected ? "selected_card" : "card"}
      >
        <div className="child_wrapper">
          <div className="marketing_icon">{props.Image}</div>
          <div className="m_t_1 sub_title ">{props.Goal}</div>

          <div className="plan_details sub_title_child">{props.Details}</div>
          {/* <div className="learn_more_button">
            {props.Button} {props.ButtonIcon}
          </div> */}
          <button
            className={
              props.isSelected && !props.comingSoon
                ? "selected_goal_btn"
                : "select_goal_btn"
            }
          >
            {props.isSelected && !props.comingSoon ? (
              <img className="selected_goal_btn" src={check} alt="check" />
            ) : props.comingSoon ? (
              "Coming Soon"
            ) : (
              "Select Goal"
            )}
          </button>
        </div>
      </div>
    </>
  );
};
export default CardNewFlow;
