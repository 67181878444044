import React from "react";
import "./Card.css";

function Card(props) {
  return (
    <>
      <div className="HIW-card-main-div">
        <div className="HIW-card-border">
          <img src={props.img} alt="not known"></img>
        </div>
        <div className="HIW-card-text">
          <p>{props.text}</p>
        </div>
      </div>
    </>
  );
}

export default Card;
