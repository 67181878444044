import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { CustomScroll } from "react-custom-scroll";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import "./CampaignTable.css";
import HorizontalDivider from "../UI/Dividers/HorizontalDivider";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../store/campaign-slice";
import { awarenessNewIcon } from "../../assets";
function CampaignTable(props) {
  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const selectedCampaign = useSelector(
    (state) => state.campaigns.selectedCampaign
  );

  const handleSelect = (id) => {
    dispatch(campaignActions.changeSelectedCampaign({ id: id }));
  };

  return (
    <div
      className="campaign_table_flex  card_12px elevate_16px"
      style={{
        // padding: "24px",
        margin: "12px",
        backgroundColor: "#ffff",
      }}
    >
      {/* <Table aria-label="simple table"> */}
      {/* <TableHead> */}
      {/* <TableRow> */}
      <div
        style={{
          // backgroundColor: "#D6F1E0",
          backgroundColor: "##31b963",
          borderRadius: "20px 20px 0px 0px",
        }}
      >
        {/* <div className="sub_title">Select campaign</div> */}
      </div>
      {/* <TableCell align="right">Order Completed</TableCell> */}
      {/* </TableRow> */}
      {/* </TableHead> */}
      <div
        style={{ height: "100%", maxHeight: "340px" }}
        className="custom-scroll-container with-bg-color"
      >
        <CustomScroll heightRelativeToParent="100%">
          {campaigns.length === 0 && (
            <div className="campaign-listItems-grp">
              <div
                className={`campaign-listItem `}
                style={{ cursor: "pointer" }}
              >
                <div className="sub_title"> No Campaigns</div>
                <div className="sub_title"></div>
              </div>
              {/* <HorizontalDivider /> */}
            </div>
          )}

          {campaigns.map((campaign) => {
            const isSelected = campaign.id === selectedCampaign;

            const date = new Date(campaign?.created_on?.substring(0, 10));
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const createdOnDate = `${day}-${month}-${year}`;
            return (
              <div
                key={campaign.id}
                id={campaign.id}
                onClick={() => handleSelect(campaign.id)}
                className="campaign-listItems-grp"
              >
                <div
                  className={`campaign-listItem  ${
                    isSelected
                      ? "campaign_is_selected "
                      : "campaign_is_unselected"
                  } `}
                  style={{ cursor: "pointer" }}
                  // onClick={() => {
                  //   props.onCampaignChangeHandler(campaign);
                  // }}
                >
                  <div>
                    <div className="">{campaign.name}</div>
                    <div className="">
                      Creation Date :&nbsp;
                      <span className="">{createdOnDate}</span>
                    </div>
                  </div>
                  {/* <div className="sub_title">
                    QR Code Hit Quantity :&nbsp;
                    <span className="sub_title_child">
                      {campaign?.qr_code_id !== null
                        ? campaign.qr_code_hit_qty
                        : "QR code not selected"}
                    </span>
                  </div> */}
                  <div>
                    <img
                      width="36px"
                      src={awarenessNewIcon}
                      alt="awarenessNewIcon"
                    />
                  </div>
                </div>
                {/* <HorizontalDivider /> */}
              </div>
            );
          })}
        </CustomScroll>
      </div>
      {/* </Table> */}
    </div>
  );
}

export default CampaignTable;
