import { useEffect, useState } from "react";

import "./MyAccountPage.css";
import { editIcon2 } from "../../../assets";
import { useSelector } from "react-redux";
import { API_URL } from "../../../constant/apiURL";
const MyAccountPage = (props) => {
  const token = useSelector((state) => state.auth.token);

  const [editing, setEditing] = useState(false);

  const [businessName, setBusinessName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [email, setEmail] = useState();

  const [businessNameToSend, setBusinessNameToSend] = useState();
  const [phoneNoToSend, setPhoneNoToSend] = useState();
  const [emailToSend, setEmailToSend] = useState();

  const businessNameHandler = (e) => {
    setBusinessNameToSend(e.target.value);
  };
  const editBusinessNameHandler = () => {
    setEditing(true);
    setBusinessName("");
  };

  const phoneNoHandler = (e) => {
    setPhoneNoToSend(e.target.value);
  };

  const editPhoneNoHandler = () => {
    setEditing(true);
    setPhoneNo("");
  };

  const emailHandler = (e) => {
    setEmailToSend(e.target.value);
  };
  const editEmailHandler = () => {
    setEditing(true);
    setEmail("");
  };

  const handleCancelClick = () => {
    setEditing(false);
  };

  useEffect(() => {
    fetch(API_URL + "accounts/profile/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setBusinessName(data.name);
        setPhoneNo(data.contact_number);
        setEmail(data.email);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSaveClick = () => {
    const formData = new FormData();
    formData.append(
      "name",
      businessNameToSend ? businessNameToSend : businessName
    );
    formData.append("contact_number", phoneNoToSend ? phoneNoToSend : phoneNo);
    formData.append("email", emailToSend ? emailToSend : email);

    fetch(API_URL + "accounts/update-profile/", {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setBusinessName(data.name ? data.name : businessName);
        setPhoneNo(data.contact_number ? data.contact_number : phoneNo);
        setEmail(data.email ? data.email : email);
      })
      .catch((error) => {
        console.log(error);
      });

    setEditing(false);
  };

  return (
    <>
      <div className="flex_grow_1 dashboard__main_div">
        {props.isVisible && (
          <div className="my_account_page_main_div">
            <div className="my_account_page_sub_div">
              <div className="sub_title green_text">My Account</div>
              {!editing && (
                <div className="business_name_text_input_wrapper m_t_1">
                  <div className="sub_title_child">Business Name</div>
                  <div className="input_edit_wrapper">
                    <div>{businessName ? businessName : ""}</div>
                    <span
                      className="edit-icon"
                      onClick={editBusinessNameHandler}
                    >
                      <img src={editIcon2} alt="editIcon2" />
                    </span>
                  </div>
                </div>
              )}
              {!editing && (
                <div className="business_name_text_input_wrapper m_t_0_5">
                  <div className="sub_title_child">Phone Number</div>
                  <div className="input_edit_wrapper">
                    <div>{phoneNo ? phoneNo : ""}</div>
                    <span className="edit-icon" onClick={editPhoneNoHandler}>
                      <img src={editIcon2} alt="editIcon2" />
                    </span>
                  </div>
                </div>
              )}
              {!editing && (
                <div className="business_name_text_input_wrapper m_t_0_5">
                  <div className="sub_title_child">E-Mail</div>
                  <div className="input_edit_wrapper">
                    <div>{email ? email : ""}</div>
                    <span className="edit-icon" onClick={editEmailHandler}>
                      <img src={editIcon2} alt="editIcon2" />
                    </span>
                  </div>
                </div>
              )}
              {editing && (
                <>
                  <div className="sub_title_child m_t_1">Business Name</div>
                  <input
                    className="my_account_input "
                    type="text"
                    name="business-name"
                    id="businessName"
                    onChange={businessNameHandler}
                  />
                  <br></br>
                </>
              )}
              {editing && (
                <>
                  <div className="sub_title_child m_t_0_5">Phone No</div>
                  <input
                    className="my_account_input "
                    type="number"
                    name=""
                    id=""
                    onChange={phoneNoHandler}
                  />
                </>
              )}
              {editing && (
                <>
                  <div className="sub_title_child m_t_0_5">E-mail</div>
                  <input
                    className="my_account_input "
                    type="email"
                    name=""
                    id=""
                    onChange={emailHandler}
                  />
                </>
              )}

              {editing && (
                <div className="input_cancel_save_btn_wrapper m_t_1">
                  <button
                    className="cancel_btn m_r_1"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    className="save_changes_btn"
                    onClick={handleSaveClick}
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default MyAccountPage;
