// import { Carousel } from "react-bootstrap";
import React from "react";
import "./Testimonials.css";
import user from "./User (1).svg";
import star from "./star.svg";
import quote from "./quote.svg";
import Carousel from "./Carousel/Carousel";

function Testimonials() {
  const names = [
    "saipanmuluk mujawar",
    "Kiran Pawar",
    "Yash Gitte",
    "Anamm Jahangir",
  ];
  const reviews = [
    "Really helpfull for students thanks a lot for everything never disappointed always on time good quality paper used and good quality printing",
    " Best application for printouts 👌👌. Lowest price as compared to print shop. And the best part of paid service is that they provides fast delivery and good quality printouts. 🥳",
    "I print 10-20 order per month, no problem and always on time Or early, ink always good quality and the paper 📄 is good, and best packing✉️ waterproof. 👌🏻👌🏻",
    "Having really helpful and wonderful experience using this aap . It is easy to use and order delivered directly to my home also I love the paking 🙂 thank you for your best service",
  ];
  const reviewsData = [
    {
      name: "saipanmuluk mujawar",
      review:
        "Really helpfull for students thanks a lot for everything never disappointed always on time good quality paper used and good quality printing",
    },
    {
      name: "Kiran Pawar",
      review:
        " Best application for printouts 👌👌. Lowest price as compared to print shop. And the best part of paid service is that they provides fast delivery and good quality printouts. 🥳",
    },
    {
      name: "Yash Gitte",
      review:
        "I print 10-20 order per month, no problem and always on time Or early, ink always good quality and the paper 📄 is good, and best packing✉️ waterproof. 👌🏻👌🏻",
    },
    {
      name: "Anamm Jahangir",
      review:
        "Having really helpful and wonderful experience using this aap . It is easy to use and order delivered directly to my home also I love the paking 🙂 thank you for your best service",
    },
  ];
  return (
    <div className="Testimonials-main-div">
      <div>
        <div className="Love_From_Users">Love From Users</div>
        <div className="testimonial-divider"></div>
      </div>

      <div className="testimonial-carousel">
        <Carousel items={reviewsData}></Carousel>
        {/* <Carousel
          className="testimonial-carousel-flex"
          controls={true}
          indicators={false}
        >
          {/* {reviews.map((review, index) => {
            return (
              <Carousel.Item
                className="testimonial-carousel-item-flex"
                interval={3000}
              >
                <div className="carousel-item-flex-map">
                  <div className="carousel-item-flex-1st-child">
                    <img src={quote} alt="quote"></img>
                  </div>
                  <div className="carousel-item-flex-2nd">
                    <div className="testimonial-c2">
                      <div>
                        <p className="testimonial-review">{review}</p>
                      </div>
                    </div>
                    <div className="testimonials-user-sec">
                      <img
                        // style={{ width: "100%" }}
                        className="carousel-item-flex-map-img"
                        src={user}
                        alt="First slide"
                      />
                      <div className="testimonial-name">
                        <name>{names[index]}</name>
                        <div className="testimonial-star-grp">
                          <img src={star} alt="star"></img>
                          <img src={star} alt="star"></img>
                          <img src={star} alt="star"></img>
                          <img src={star} alt="star"></img>
                          <img src={star} alt="star"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })} */}

        {/* <Carousel.Item interval={3000}>
            <img src={user} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img src={user} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img src={user} alt="Forth slide" />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img src={user} alt="Fifth slide" />
          </Carousel.Item> 
        </Carousel> */}
      </div>
    </div>
  );
}

export default Testimonials;
