import { Hidden } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./Nav.css";
import ewayPrint from "./Ewayprint.png";
import ewayPrintNewLogo from "./Logo with name_a.png";
import { useState } from "react";
import Hamburger_icon from "./Hamburger_icon.svg";
import x from "./x.png";
import HeroSec from "../AdvertiserPage/HeroSec/HeroSec";

const Nav2 = (props) => {
  const location = useLocation();
  const path = location.pathname;
  const [active, setActive] = useState(false);
  let details = navigator.userAgent;

  /* Creating a regular expression 
  containing some mobile devices keywords 
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(details);
  return (
    <>
      <div className="nav-main-div" id="Nav">
        <div className="nav-c1">
          <Link to="/">
            <img
              style={{ width: "12em" }}
              src={ewayPrintNewLogo}
              alt="ewayPrint"
            ></img>
          </Link>
        </div>
        <Hidden mdDown>
          <div className="nav-c2">
            <div className="nav-item-grp">
              {/* <div className={`${props.page === "home" ? "" : ""}`}>
                {path.startsWith("/ads") && (
                  <Link to={{ pathname: "/home", from: "advertiser" }}>
                    Ewayprint
                  </Link>
                )}
                {!path.startsWith("/ads") && <Link to="/home">Home</Link>}
              </div> */}
              <div className="nav-divider-div">
                <div
                  className={`${
                    props.page === "home" ? "nav-divider-green" : "nav-divider"
                  }`}
                ></div>
              </div>
            </div>

            {path.startsWith("/") && (
              <div className="nav-item-grp">
                <div
                  className={`${props.page === "home" ? "" : ""}`}
                  onClick={(e) => {
                    return <HeroSec />;
                  }}
                >
                  {path.startsWith("/") && (
                    <div
                      onClick={() =>
                        document
                          .getElementById("about_us_section")
                          .scrollIntoView()
                      }
                    >
                      About Us
                    </div>
                  )}
                </div>
                <div className="nav-divider-div">
                  <div
                    className={`${
                      props.page !== "home" ? "nav-divider" : null
                    }`}
                  ></div>
                </div>
              </div>
            )}

            {path.startsWith("/") && (
              <div className="nav-item-grp">
                <div
                  className={`${props.page === "home" ? "" : ""}`}
                  onClick={(e) => {
                    return <HeroSec />;
                  }}
                >
                  {path.startsWith("/") && (
                    <div
                      onClick={() =>
                        document.getElementById("HowItWorks").scrollIntoView()
                      }
                    >
                      How it works
                    </div>
                  )}
                </div>
                <div className="nav-divider-div">
                  <div
                    className={`${
                      props.page !== "home" ? "nav-divider" : null
                    }`}
                  ></div>
                </div>
              </div>
            )}

            {path.startsWith("/") && (
              <div className="nav-item-grp">
                <div className={`${props.page === "home" ? "" : ""}`}>
                  {path.startsWith("/") && (
                    <div
                      onClick={() =>
                        document.getElementById("FAQ").scrollIntoView()
                      }
                    >
                      FAQ
                    </div>
                  )}
                </div>
                <div className="nav-divider-div">
                  <div
                    className={`${
                      props.page !== "home" ? "nav-divider" : null
                    }`}
                  ></div>
                </div>
              </div>
            )}

            {path.startsWith("/") && (
              <div className="nav-item-grp">
                <div className={`${props.page === "home" ? "" : ""}`}>
                  {path.startsWith("/") && (
                    <div
                      onClick={() =>
                        document.getElementById("Contact").scrollIntoView()
                      }
                    >
                      Contact Us
                    </div>
                  )}
                </div>
                <div className="nav-divider-div">
                  <div
                    className={`${
                      props.page !== "home" ? "nav-divider" : null
                    }`}
                  ></div>
                </div>
              </div>
            )}
            {/* {path.startsWith("/ads") && (
              <div className="nav-item-grp">
                <div className={`${props.page === "advertiser" ? "" : ""}`}>
                  <Link to="/ads">Advertiser</Link>
                </div>
                <div className="nav-divider-div">
                  <div
                    className={`${
                      props.page === "advertiser"
                        ? "nav-divider-green"
                        : "nav-divider"
                    }`}
                  ></div>
                </div>
              </div>
            )} */}
            {/* {location.from === "advertiser" && (
              <div className="nav-item-grp">
                <div className={`${props.page === "advertiser" ? "" : ""}`}>
                  <Link to="/ads">Advertiser</Link>
                </div>
                <div className="nav-divider-div">
                  <div
                    className={`${
                      props.page === "advertiser"
                        ? "nav-divider-green"
                        : "nav-divider"
                    }`}
                  ></div>
                </div>
              </div>
            )} */}

            {path !== "/" && path !== "/contact" && (
              <div className="nav-item-grp">
                {" "}
                <div
                  className={`nav_button${
                    props.page === "contactUs" ? "" : ""
                  }`}
                >
                  <Link to="/contact">Contact Us</Link>
                </div>
                <div className="nav-divider-div">
                  <div
                    className={`${
                      props.page === "contactUs"
                        ? "nav-divider-green"
                        : "nav-divider"
                    }`}
                  ></div>
                </div>
              </div>
            )}
            {path.startsWith("/") && (
              <div className="nav-item">
                <div className="nav-item-grp">
                  <Link to="/login">
                    <button className="nav-signup-btn">
                      {/* <div style={{ marginBottom: 2 }}>
                        <img src={Login} alt="login"></img>
                      </div> */}
                      <div className="Sign_Up">Sign In </div>
                    </button>
                  </Link>
                </div>
              </div>
            )}
            {/* {path !== "/" && path !== "/contact" && (
              <div className="nav-item">
                <div className="nav-item-grp">
                  {" "}
                  <a
                    onClick="return gtag_report_conversion('http://ewayprint.com/home');"
                    href="http://ewayprint.com/home"
                  >
                    <a
                      href={
                        isMobileDevice
                          ? "https://ewayprint.page.link/eeow"
                          : "https://play.google.com/store/apps/details?id=com.iotexcel.ewayprint"
                      }
                      target="blank"
                    >
                      <button
                        // onClick={() => props.setPage("contactUs")}
                        className="nav-signup-btn"
                      >
                        <span style={{ color: "#fff", fontWeight: "600" }}>
                          Download
                        </span>
                      </button>
                    </a>
                  </a>
                </div>
              </div>
            )} */}
          </div>
        </Hidden>{" "}
        <Hidden mdUp>
          <div className="navBar-toogle-button">
            {!active && (
              <img
                className="img-ham"
                onClick={() => setActive(true)}
                src={Hamburger_icon}
                alt="Hamburger_icon"
              ></img>
            )}
            {active && (
              <img
                className="img-x"
                onClick={() => setActive(false)}
                src={x}
                alt="x"
              ></img>
            )}
          </div>
        </Hidden>
      </div>
      {active && (
        <div className="collapsable-div">
          <div className="collapsable-div-ul">
            {/* <div className="nav-item">
              <div className="nav-item-grp">
                <div
                  // onClick={() => props.setPage("home")}
                  className={`${props.page === "home" ? "" : ""}`}
                >
                  {path.startsWith("/") && (
                    <Link to="/home" state={{ from: "advertiser" }}>
                      Ewayprint
                    </Link>
                  )}
                  {!path.startsWith("/ads") && (
                    <Link to="/home" state={{ from: "" }}>
                      Home
                    </Link>
                  )}
                </div>
              </div>
            </div> */}

            {path.startsWith("/") && (
              <div className="nav-item">
                <div className="nav-item-grp">
                  <div className={`${props.page === "home" ? "" : ""}`}>
                    {path.startsWith("/") && (
                      <div
                        onClick={() =>
                          document
                            .getElementById("about_us_section")
                            .scrollIntoView()
                        }
                        className="btn_for_phone"
                      >
                        About Us
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {path.startsWith("/") && (
              <div className="nav-item">
                <div className="nav-item-grp">
                  <div className={`${props.page === "home" ? "" : ""}`}>
                    {path.startsWith("/") && (
                      <div
                        onClick={() =>
                          document.getElementById("HowItWorks").scrollIntoView()
                        }
                        className="btn_for_phone"
                      >
                        How it works
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {path.startsWith("/") && (
              <div className="nav-item">
                <div className="nav-item-grp">
                  <div className={`${props.page === "home" ? "" : ""}`}>
                    {path.startsWith("/") && (
                      <div
                        onClick={() =>
                          document.getElementById("FAQ").scrollIntoView()
                        }
                        className="btn_for_phone"
                      >
                        FAQ
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {path.startsWith("/") && (
              <div className="nav-item">
                <div className="nav-item-grp">
                  <div className={`${props.page === "home" ? "" : ""}`}>
                    {path.startsWith("/") && (
                      <div
                        onClick={() =>
                          document.getElementById("Contact").scrollIntoView()
                        }
                        className="btn_for_phone"
                      >
                        Contact Us
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {path.startsWith("/") && (
              <div className="nav-item">
                <div className="nav-item-grp">
                  <Link to="/login">
                    <button className="nav-signup-btn">
                      {/* <div style={{ marginBottom: 2 }}>
                        <img src={Login} alt="login"></img>
                      </div> */}
                      <div className="Sign_Up">Log In</div>
                    </button>
                  </Link>
                </div>
              </div>
            )}
            {/* {path !== "/ads" && path !== "/ads/contact" && (
              <div className="nav-item">
                <div className="nav-item-grp">
                  {" "}
                  <a
                    onClick="return gtag_report_conversion('http://ewayprint.com/home');"
                    href="http://ewayprint.com/home"
                  >
                    <a
                      href={
                        isMobileDevice
                          ? "https://ewayprint.page.link/eeow"
                          : "https://play.google.com/store/apps/details?id=com.iotexcel.ewayprint"
                      }
                      target="blank"
                    >
                      <button
                        // onClick={() => props.setPage("contactUs")}
                        className="nav-signup-btn"
                      >
                        <span style={{ color: "#fff", fontWeight: "600" }}>
                          Download
                        </span>
                      </button>
                    </a>
                  </a>
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default Nav2;
