import "./ButtonNew.css";
const ButtonNew = (props) => {
  return (
    <>
      <div className="new_btn_wrapper">
        <button className={props.className} onClick={props.onClick}>
          {props.name}
        </button>
      </div>
    </>
  );
};
export default ButtonNew;
