import { benefitsIcon } from "../../../assets";

const benefitsListBasicPlans = {
  plan1: {
    icon: benefitsIcon,
    list: [
      "Distribute pamphlets across Pune city",
      "Increase your brand awareness",
      "Interactive QR Code feature",
    ],
  },
  plan2: {
    icon: benefitsIcon,
    list: [
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
    ],
  },

  plan3: {
    icon: benefitsIcon,
    list: [
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
    ],
  },

  plan4: {
    icon: benefitsIcon,
    list: [
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
      "Benefits Lorem ipsum",
    ],
  },
};
export default benefitsListBasicPlans;
