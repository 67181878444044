import React, { useState } from "react";

import Card from "./Card";
import "./HowItWorks.css";
import step01 from "./Step01.png";
import step02 from "./Step02.png";
import step03 from "./Step03.png";
import step04 from "./Step04.png";
import step05 from "./Step05.png";
import Flyer from "./Flyer.png";
import Online from "./Online.png";
import distributionIcon from "./Distribution icon 1.svg";
import profile from "./profile.svg";
import delivery from "./delivery.svg";
import upload from "./upload.svg";
import next from "./next.svg";
import mailMktgImg from "./mail_marketing_img.png";
import bulletPointIcon from "./bulletPointIcon.svg";
import { Grid } from "@mui/material";
import EwayprintAppDetail from "../EwayprintAppDetail/EwayprintAppDetail";

const data = [
  {
    heading: "Design & Printing",
    // text: "Place order of your flyers online",
    image: step03,
  },
  {
    heading: "Distribution",
    // text: "Our design team will create your flyer",
    image: step04,
  },
  {
    heading: "Measure Impact",
    // text: "We use hi-quality printing materials for your flyers",
    image: step05,
  },
  // {
  //   heading: "Distribution",
  //   text: "Our delivery team securely distributes your flyers door-to-door",
  //   image: step04,
  // },
  // {
  //   heading: "Analytics",
  //   text: "Measure the results of your ad campaign on dashboard",
  //   image: step05,
  // },
];
const comparison = [
  {
    heading: "Memorable",
    para1:
      "Physical mail can leave a lasting impression. Tangible materials like pamphlets, are often kept longer, making them more memorable.",
    para2:
      "Digital content on social media can be quickly scrolled past, making it less memorable.",
  },
  {
    heading: "Tangible",
    para1:
      "Physical materials are tangible and can be touched and felt, making them more engaging.",
    para2:
      "Digital content is intangible and relies on visual and text elements to engage the audience.",
  },
  {
    heading: "Less Common",
    para1:
      "In the age of digital marketing, direct mail is less common, which can make it stand out ",
    para2:
      "Social media advertising is more common and can sometimes get lost in the sea of online content",
  },
  {
    heading: "Personalization",
    para1:
      "Direct mail marketing offers a level of personalization that other forms of advertising can't match. Personalization is essential because it helps businesses connect with their target audience on a deeper level. When customers feel like a company understands their needs and preferences, they are more likely to engage with that company and make a purchase.",
    para2:
      "Digital media is ever evolving with a high dependency on algorithms. Adapting to multiple platforms requires diverse technical skills to maintain presence on various digital channels (i.e websites, social media, search engines). Negative feedback gets amplified online very quickly. Consumers are inundated with online content which leads to ad fatigue & reduce effectiveness.",
  },
  // {
  //   heading: "User experience",
  //   para1:
  //     "Ewayprint ads provide a simple & straightforward service that requires no technical skills",
  //   para2: "While digital ads can be complex & challenging for some users",
  // },
];
const NumHelper = (props) => {
  return (
    <div className="HIW__num_circle">
      <span className="number_text">{props.number}</span>
    </div>
  );
};
const TblItemHelper = (props) => {
  const [expanded, setExpanded] = useState(false);
  let maxCharacters = 140;
  const toggleText = () => {
    setExpanded(!expanded);
  };

  const [expanded2, setExpanded2] = useState(false);

  const toggleText2 = () => {
    setExpanded2(!expanded2);
  };

  return (
    <>
      <div className="row_of_comp_tbl sub_title">{props.heading}</div>
      <div className="row__comp_tbl ">
        <div>
          <p>
            {expanded ? props.para1 : props.para1.slice(0, maxCharacters)}
            {props.para1.length > maxCharacters && (
              <button className="see_more_btn" onClick={toggleText}>
                {expanded ? "See Less" : "See More"}
              </button>
            )}
          </p>
        </div>
        <div>
          <p>
            {expanded2 ? props.para2 : props.para2.slice(0, maxCharacters)}
            {props.para2.length > maxCharacters && (
              <button className="see_more_btn" onClick={toggleText2}>
                {expanded2 ? "See Less" : "See More"}
              </button>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

const CardHelper = (props) => {
  return (
    <div className="HIW__Step_card">
      <img className="HIW__Step_card_img" src={props.image} alt="step1"></img>
      <div className="content__subheading " style={{ padding: "6px 0" }}>
        {props.heading}
      </div>
      <div className="content__text" style={{ lineHeight: "20px" }}>
        {props.text}
      </div>
    </div>
  );
};

function HowItWorks() {
  const bulletPointsObj = [
    {
      points:
        "At ewayprint, our clients leverage our app called Ewayprint for their documentation (printouts).",
    },
    {
      points:
        "The printouts are professionally couriered inside an envelope along with printed adverts of our prospects.",
    },
    {
      points:
        "The packet reaches directly to the client through direct mail marketing channel.",
    },
    {
      points:
        "Paper adverts reach beyond doorstep transforming into a higher shelf life.",
    },
    { points: "The prospects can view the info at their ease & convenience." },
    {
      points:
        "Direct mailers are personalized, clutter free & highly reliable. The chances of a direct mail being opened & read instantly are higher than e-mail or digital ads.",
    },
  ];
  return (
    <>
      <div className="HIW-main-div" id="HowItWorks">
        <div className="HIW__content_container">
          <div className="content__heading dark_green_text">How it works?</div>
          <div className="f_w_400 text-center">
            Here is the complete cycle for getting started with us
          </div>
          <div className="HIW__horizontal_line">
            {[1, 2, 3].map((item, index) => {
              return <NumHelper number={item} key={index} />;
            })}
          </div>
          <div className="HIW__images_grp">
            {data.map((item, index) => (
              <CardHelper
                key={index}
                image={item.image}
                heading={item.heading}
                text={item.text}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mktg_strategy_wrapper">
        <div className="mail_mktg_bullet_points_main_div text_white ">
          <div className="m_b_0_5">
            <img
              className="distribution_icon"
              src={distributionIcon}
              alt="distributionIcon"
            />
          </div>
          <h2 className="ads__text__component dark_green_text text-center">
            Direct Mail Marketing Strategy
          </h2>
          <div className="ads_text m_t_2 text-center">
            Direct mail marketing is physical correspondence you send to
            customers in the hopes of getting them to patronize your business.
          </div>
          <div className="ads_text m_b_2 text-center">
            All that your direct mailers need to have is something identifying
            you or your business, to call to action (CTA), and a way for your
            customers to contact you.
          </div>

          <div className="bullet_points_list">
            {bulletPointsObj?.map((item, index) => {
              return (
                <div className="bullet_point_icon_text_wrapper" key={index}>
                  <div>
                    <img src={bulletPointIcon} alt="bulletPointIcon" />
                  </div>
                  <div className="m_t_1 m_b_1 ads_text">{item.points}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <EwayprintAppDetail />

      <div className="comparison_container">
        <div className="comparison_container_child">
          <div className="content__heading dark_green_text m_t_2">
            Direct Mail Marketing vs. Social media advertising
          </div>
          <p
            className="content__text"
            style={{ color: "black", marginTop: "20px", padding: "0 20px" }}
          >
            When it comes to promoting your business, the choice between
            traditional marketing methods like direct mail marketing & modern
            marketing methods like social media advertising can be a tough one.
          </p>
          <p
            className="content__text"
            style={{ color: "black", padding: "0 20px" }}
          >
            <b>
              {" "}
              Here’s a comparison of Direct mail marketing & social media
              advertising to help you decide which one is the best for your
              business
            </b>
          </p>
          <div className="table__container">
            <div className="table__container_container">
              <div className="row_1_comp_tbl m_b_1">
                <div className="tbl__colmn1_head">
                  <img src={Flyer} alt="flyer"></img>
                  <div
                    className="content_table_heading text-center"
                    style={{ color: "black", marginTop: "20px" }}
                  >
                    Direct Mail Marketing
                  </div>
                </div>
                <div className="tbl__colmn1_head">
                  <img src={Online} alt="Online"></img>
                  <div
                    className="content_table_heading text-center"
                    style={{ color: "black", marginTop: "20px" }}
                  >
                    Social media advertising
                  </div>
                </div>
              </div>
              {comparison.map((section, index) => (
                <TblItemHelper
                  key={index}
                  heading={section.heading}
                  para1={section.para1}
                  para2={section.para2}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
