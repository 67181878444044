import { Chip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { arrowRight, failedGIF, successGIF } from "../../assets";
import { API_URL } from "../../constant/apiURL";
import { fetchCampaignData } from "../../store/campaign-slice";
import CampaignLayout from "../CampaignLayout/CampaignLayout";
import SideBarButton from "../DashboardLayout/DashboardHeader/SideBarButtons/SideBarButton";
import HorizontalDivider from "../UI/Dividers/HorizontalDivider";
let isInitial = true;
const PaymentFailure = () => {
  const createdCampaignId = localStorage.getItem("createdCampaignId");
  const amountPaid = localStorage.getItem("amountPaid");

  const [campaign, setCampaign] = useState(null);
  const [pamphlet, setPamphlet] = useState(null);

  const tax = 5;
  let payableTaxAmount = Math.floor(+campaign?.total_amount * tax * 0.01);
  // console.log(payableTaxAmount, "payableTaxAmount");
  let amountInclTax = campaign?.total_amount + payableTaxAmount;
  const selectedBudget = campaign?.budget_amount;
  const isCouponApplicable =
    campaign?.coupon?.coupon_code === "ABCDE" ? true : false;

  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const token = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();

  useEffect(() => {
    const _campaign = campaigns.find(
      (campaign) => campaign.id === parseInt(createdCampaignId)
    );
    if (_campaign) {
      setCampaign(_campaign);
    } else if (isInitial) {
      isInitial = false;
      dispatch(fetchCampaignData(token));
    }
    if (_campaign) {
      (async () => {
        const resPam = await fetch(
          API_URL + `campaign/pamphlet-detail/${_campaign.pamphlet_id?.id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        const dataPam = await resPam.json();
        setPamphlet(dataPam);
      })();
    }
  }, [campaigns, dispatch, token, createdCampaignId]);

  let discount;
  if (campaign?.coupon?.lumpsum_discount === 0) {
    const discountPercent = campaign?.coupon?.discount;

    const discountAmount = ((selectedBudget * discountPercent) / 100).toFixed(
      0
    );
    discount = discountAmount;
  } else {
    if (selectedBudget > campaign?.coupon?.lumpsum_discount) {
      discount = campaign?.coupon?.lumpsum_discount;
    }
  }

  return (
    <CampaignLayout>
      <div className="PaymentSuccess_main_container">
        <div>
          <img
            className="PaymentSuccess_gif"
            src={failedGIF}
            alt="failed.gif"
          ></img>
        </div>
        <div className="campaign_detail_title">Payment Failed !!</div>
        <div className="campaign_detail_subtitle">
          You can track your campaign from your dashboard
        </div>
        <Link to="/dashboard">
          <SideBarButton
            style={{
              padding: "41px -3px",
              width: "316px",
              height: "62px",
              marginTop: "20px",
              display: "flex",
              flexDirection: "row-reverse",
            }}
            btnName="Go to Dashboard"
            btnImg={arrowRight}
            isActive
          />
        </Link>
        <div className="campaign_detail_table">
          <div className="campaign_detail_table_head">
            <div className="campaign_detail_table_title truncate">
              {campaign?.name}
            </div>{" "}
            <div className="campaign_detail_table_date opaque">
              {campaign?.created_on.substring(0, 10)}
            </div>
          </div>
          <HorizontalDivider style={{ height: "2px" }} />
          <div className="campaign_detail_table_content_container" v>
            <div id="second2nd_div">
              <div
                style={{ width: "100%" }}
                className="body_text_xs green_text"
              >
                Payment details
              </div>
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="payment_firstChild">Price </div>
                <div className="payment_lastChild">
                  ₹{campaign?.total_amount}
                </div>
              </div>
              {!isCouponApplicable && selectedBudget && (
                <div className="campaign_detail_table_content_text">
                  <div
                    style={{ fontWeight: "600" }}
                    className="payment_list_firstChild "
                  >
                    Discount :
                  </div>
                  <div className="">₹{discount}</div>
                </div>
              )}
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="payment_firstChild sub_title">GST(5%) </div>
                <div className="payment_lastChild">₹{payableTaxAmount}</div>
              </div>
              <br />
              <HorizontalDivider />
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div className="payment_firstChild sub_title">Total </div>
                <div className="payment_lastChild">₹{amountInclTax}</div>
              </div>

              <HorizontalDivider style={{ marginTop: "1rem" }} />
              {amountPaid < amountInclTax && (
                <div
                  style={{ width: "100%" }}
                  className="campaign_detail_table_content_text"
                >
                  <div className="payment_firstChild sub_title">
                    Partial Payment{" "}
                  </div>
                  <div className="payment_lastChild">₹{amountPaid}</div>
                </div>
              )}
              {amountPaid < amountInclTax && (
                <div
                  style={{ width: "100%" }}
                  className="campaign_detail_table_content_text"
                >
                  <div className="payment_firstChild sub_title">
                    Remaining Payment{" "}
                  </div>
                  <div className="payment_lastChild">
                    ₹{amountInclTax - amountPaid}
                  </div>
                </div>
              )}
              <div
                style={{ width: "100%" }}
                className="campaign_detail_table_content_text"
              >
                <div
                  className="payment_firstChild"
                  style={{ fontWeight: "600" }}
                >
                  Payment status:
                </div>
                <div
                  className="payment_lastChild"
                  style={{
                    fontWeight: "600",
                    color: "red",
                  }}
                >
                  Not Paid
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CampaignLayout>
  );
};

export default PaymentFailure;
