import React from "react";
import {
  androidIcon,
  androidWebIcon,
  appleIcon,
  devicesIcom,
  linuxIcon,
  windowsIcon,
} from "../../../assets";
import "./DistributionPlatform.css";
function DistributionPlatform(props) {
  let platforms = [
    {
      icon: "afaef",
      name: "Android",
      counts: "34",
    },
    {
      icon: "afaef",
      name: "Android",
      counts: "34",
    },
    {
      icon: "afaef",
      name: "Android",
      counts: "34",
    },
    {
      icon: "afaef",
      name: "Android",
      counts: "34",
    },
    {
      icon: "afaef",
      name: "Android",
      counts: "34",
    },
  ];

  // const roundedDistPercentage = Math.round();
  // console.log(props.distributedQty());
  return (
    <div
      className="dist_plat_table_flex  card_12px elevate_16px"
      style={{
        // padding: "24px",
        margin: "12px",
        backgroundColor: "#ffff",
      }}
    >
      {/* <Table aria-label="simple table"> */}
      {/* <TableHead> */}
      {/* <TableRow> */}
      <div
        style={{
          // backgroundColor: "#D6F1E0",
          backgroundColor: "##31b963",
          borderRadius: "20px 20px 0px 0px",
        }}
      >
        <div className="sub_title">
          <span>
            <img src={devicesIcom} alt="devicesIcom" />
          </span>{" "}
          Users Platforms
        </div>
        <div className="type_counts_text_wrapper m_t_1">
          <div>Types</div>
          <div>Count</div>
        </div>

        <div className="pltfrm_list_wrapper">
          <div className="device_item">
            <div>
              <span>
                <img src={androidIcon} alt="androidIcon" />
              </span>
              &nbsp;&nbsp; Android
            </div>
            <div>
              {isNaN(Math.round(props.distributedQty() * 0.85))
                ? 0
                : Math.round(props.distributedQty() * 0.85)}
            </div>
          </div>
          <div className="device_item">
            <div>
              <span>
                <img src={appleIcon} alt="appleIcon" />
              </span>
              &nbsp;&nbsp; Iphone (Web)
            </div>
            <div>
              {isNaN(Math.round(props.distributedQty() * 0.05))
                ? 0
                : Math.round(props.distributedQty() * 0.05)}
            </div>
          </div>
          <div className="device_item">
            <div>
              <span>
                <img src={androidWebIcon} alt="androidWebIcon" />
              </span>
              &nbsp;&nbsp; Android (Web)
            </div>
            <div>
              {isNaN(Math.round(props.distributedQty() * 0.05))
                ? 0
                : Math.round(props.distributedQty() * 0.05)}
            </div>
          </div>
          <div className="device_item">
            <div>
              <span>
                <img src={linuxIcon} alt="linuxIcon" />
              </span>
              &nbsp;&nbsp; Linux
            </div>
            <div>
              {isNaN(Math.round(props.distributedQty() * 0.01))
                ? 0
                : Math.round(props.distributedQty() * 0.01)}
            </div>
          </div>
          <div className="device_item">
            <div>
              <span>
                <img src={windowsIcon} alt="windowsIcon" />
              </span>
              &nbsp;&nbsp; Windows
            </div>
            <div>
              {isNaN(Math.round(props.distributedQty() * 0.04))
                ? 0
                : Math.round(props.distributedQty() * 0.04)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DistributionPlatform;
