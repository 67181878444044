import React from "react";
import "./SideBarButton.css";

const SideBarButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={`unselected_sideBar__button ${
        props.isActive && "sideBar__button green_text"
      } ${props.className} `}
      style={props.style}
    >
      <div>
        <img src={props.btnImg} alt="abc"></img>
      </div>

      <div
        className={`sideBar__button_text ${
          !props.isActive && "btn_isNotActive"
        }`}
      >
        {props.btnName}
      </div>
    </button>
  );
};

export default SideBarButton;
