import React, { useState } from "react";
import { API_URL } from "../../../../../constant/apiURL";
import "./FormSec.css";

function FormSec(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [statusOk, setStatusOk] = useState(false);
  // console.log(typeof phone);
  const handleSubmit = () => {
    let formData = new FormData();
    // if (
    //   nameRef ||
    //   emailRef ||
    //   subjectRef ||
    //   msgRef !== "" ||
    //   phoneRef.length !== 0
    // ) {
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("subject", subject);
    formData.append("message", message);
    // }

    fetch(`${API_URL}contact_us/`, {
      method: "POST",
      body: formData,
    }).then((res) => {
      if (res.ok) {
        setStatusOk(true);
      }
    });
  };
  setTimeout(() => {
    if (statusOk) {
      setStatusOk(false);
      setName("");
    }
  }, 3000);

  return (
    <div className={`"FormSec-main-div" ${props.isFromAds && "white"}`}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
          setName("");
          setEmail("");
          setPhone("");
          setSubject("");
          setMessage("");
        }}
      >
        <div className="FormSec-form-flex">
          <div className="FormSec-input-grp-1">
            <div>
              <label>
                Name <span className="opaque">(optional)</span>
              </label>
            </div>
            <div>
              <input
                onChange={(e) => setName(e.target.value)}
                type="text"
                value={name}
                placeholder="Name"
              ></input>
            </div>
          </div>
          <div className="FormSec-input-grp-1">
            <div>
              <label>Email *</label>
            </div>
            <div>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
                placeholder="Email"
              ></input>
            </div>
          </div>
          <div className="FormSec-input-grp-1">
            <div>
              <label>
                Phone <span className="opaque">(optional)</span>
                {phone.length > 10 && (
                  <span style={{ color: "red" }} className="blink_me">
                    ( 10 Digits Only)
                  </span>
                )}
              </label>
            </div>
            <div>
              <input
                onChange={(e) => setPhone(e.target.value)}
                type="number"
                value={phone}
                placeholder="Phone No."
              ></input>
            </div>
          </div>
          <div className="FormSec-input-grp-1">
            <div>
              <label>Business Name *</label>
            </div>
            <div>
              <input
                onChange={(e) => setSubject(e.target.value)}
                type="text"
                value={subject}
                placeholder="Business Name"
              ></input>
            </div>
          </div>
          <div className="FormSec-input-grp-2">
            <div>
              <label>Message *</label>
            </div>
            <div>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                rows="7"
                id="message"
                value={message}
                placeholder="Message"
              ></textarea>
            </div>
          </div>
          <div>
            <button
              className={` ${
                statusOk
                  ? "FormSec-submit-button-green"
                  : "FormSec-submit-button"
              }`}
              type="submit"
            >
              {statusOk ? "Sent Successfully" : "Send Message"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FormSec;
