import React from "react";
import { Line } from "react-chartjs-2";
import { Paper } from "@mui/material";
import { fontSize, fontWeight } from "@mui/system";
import { Bar } from "react-chartjs-2";

import "./ChartSection.css";

// const options = {
//   elements: {
//     line: {
//       tension: 0.4,
//     },
//   },
//   scales: {
//     y: {
//       ticks: {
//         precision: 0,
//       },
//     },
//   },
//   responsive: true,
//   maintainAspectRatio: false,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     // title: {
//     //   display: true,
//     //   // text: "Daily Printed Orders",
//     //   font: {
//     //     size: 25,
//     //   },
//     // },
//   },
// };

function ChartSection(props) {
  const barWidth = window.innerWidth < 700 ? 5 : 10;
  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true, // Start the x-axis from zero
      },
      y: {
        beginAtZero: true, // Start the y-axis from zero
        ticks: {
          precision: 0,
        },
      },
    },
    barThickness: barWidth, // Adjust the bar width as needed (in pixels)
    barPercentage: 5,
  };
  var receivedDates = [];
  // console.log(props.allData);
  props?.dates().forEach(getStrDate);
  function getStrDate(date) {
    let receivedDate = date.toISOString().substring(0, 10);
    let finalDate = `${receivedDate.substring(8, 10)}-${receivedDate.substring(
      5,
      7
    )}-${receivedDate.substring(0, 4)}`;
    receivedDates.push(finalDate);
  }

  const barColor =
    (props.adType.pamphlet && "rgb(49, 185, 99)") ||
    (props.adType.footer && "rgba(138, 43, 226, 1)") ||
    (props.adType.display && "rgba(65, 105, 225, 1)") ||
    (props.adType.label && "rgb(243, 221, 27)");

  const data = {
    labels: [...receivedDates],
    datasets: [
      {
        label: `No.of ${
          props.adType.pamphlet
            ? "Pamphlet "
            : props.adType.footer
            ? "Footer "
            : props.adType.display
            ? "DisplayAd "
            : props.adType.label
            ? "LabelAd"
            : ""
          // } ${props.adType.footer && "Footer "} ${
          //   props.adType.display && "Display "
        } Distributed`,
        data: [...props.records()],
        fill: true,
        backgroundColor: barColor,
        borderColor: barColor,
        borderWidth: 3,
        borderRadius: 10,
        // type: "bar",
      },
    ],
  };
  const allData = {
    labels: [...receivedDates],
    datasets: [
      {
        label: "Pamphlets ",
        data: [...props?.allData.pamphlet.pamphletRecordsArr],
        fill: true,
        bezierCurve: true,
        backgroundColor: "rgb(49, 185, 99)",
        borderColor: "rgb(49, 185, 99)",
        borderWidth: 3,
        borderRadius: 10,
      },
      {
        label: "Footer ",
        data: [...props?.allData.footer.footerRecordsArr],
        fill: true,
        backgroundColor: "rgba(138, 43, 226, 1)",
        borderColor: "rgba(138, 43, 226, 1)",
        borderWidth: 3,
        borderRadius: 10,
      },
      {
        label: "DisplayAd ",
        fill: true,
        data: [...props?.allData?.displayAd.displayAdRecordsArr],

        backgroundColor: "rgba(65, 105, 225, 1)",
        borderColor: "rgba(65, 105, 225, 1)",
        borderWidth: 3,
        borderRadius: 10,
      },
      {
        label: "LabelAd ",
        fill: true,
        data: [...props?.allData?.labelAd.labelAdRecordsArr],

        backgroundColor: "rgb(243, 221, 27)",
        borderColor: "rgb(243, 221, 27)",
        borderWidth: 3,
        borderRadius: 10,
      },
    ],
  };

  return (
    <>
      <div className="quantity_main_div">
        <div className="no_of_quantity">Quantity</div>
      </div>
      <div
        className={` ${props.className}`}
        style={{ margin: "12px", backgroundColor: "#ffff" }}
      >
        {!props.adType.all && (
          <Bar data={data} options={options} style={{ height: "500px" }} />
        )}
        {props.adType.all && (
          <Bar data={allData} options={options} style={{ height: "500px" }} />
        )}
      </div>
      <div className="dstbtn_date_text">Distribution Dates</div>
    </>
  );
}

export default ChartSection;
