import React from "react";
import "./PopUp.css";
import { closeIcon } from "../../../assets";

function PopUp(props) {
  return (
    <div className="popup">
      <div
        className={
          props.filePreview ? "popup-inner2 popup-backdrop" : "popup-inner"
        }
      >
        <button className="close-btn" onClick={props.onClose}>
          {props.onClose && (
            <div>
              <img src={closeIcon} alt="closeIcon" />
            </div>
          )}
        </button>
        {props.children}
      </div>
    </div>
  );
}

export default PopUp;
