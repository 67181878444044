import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useState, useId } from "react";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";
import NewBudgetCard from "../../../components/UI/NewBudgetCard/NewBudgetCard";
import "./BusinessPlansPage.css";
import benefitsListBasicPlans from "./benefitsList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { campaignActions } from "../../../store/campaign-slice";
import { API_URL } from "../../../constant/apiURL";
import { useEffect } from "react";
import BusinessBudgetCard from "../../../components/UI/BusinessBudgetCard/BusinessBudgetCard";
import ToggleButtonsCard from "../../../components/UI/ToggleButtonsCard/ToggleButtonsCard";
import ToggleBusinessPlan from "../../../components/UI/ToggleBusinessPlan/ToggleBusinessPlan";
import CheckBoxPrePrinted from "../../../components/UI/CheckBoxPrePrinted/CheckBoxPrePrinted";
import benefitsListBusinessPlans from "./benefitsList";
import BusinessCheckboxCard from "../../../components/UI/BusinessCheckboxCard/BusinessCheckboxCard";
import BusinessCheckboxQtyCard from "../../../components/UI/BusinessCheckboxQtyCard/BusinessCheckboxQtyCard";
import {
  arrowLeftNew,
  month12png,
  month3png,
  month6png,
  plane_icon,
  rightArrowNew,
  whiteRightArrow,
} from "../../../assets";

const BusinessPlansPage = () => {
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const clientId = useSelector((state) => state.auth.clientId);
  const dispatch = useDispatch();
  const [ratesList, setRatesList] = useState(null);

  const [campaignType, setCampaignType] = useState("Quantity");
  // Budget adtype checkbox
  const [adTypesCard2, setAdTypesCard2] = useState();
  const [adTypesCard3, setAdTypesCard3] = useState();
  const [adTypesCard4, setAdTypesCard4] = useState();

  // Quantity adtype checkbox
  const [qtyAdTypesCard2, setQtyAdTypesCard2] = useState();
  const [qtyAdTypesCard3, setQtyAdTypesCard3] = useState();
  const [qtyAdTypesCard4, setQtyAdTypesCard4] = useState();

  // quantity based budget
  const [qtyBasedBgt, setQtyBasedBgt] = useState(0);

  // Quantity for ad types
  const [pamphQty, setPamphQty] = useState();
  const [codeOfPamph, setCodeOfPamph] = useState("");
  const [ftrQty, setFtrQty] = useState();
  const [dispQty, setDispQty] = useState();
  const [labelQty, setLabelQty] = useState();

  // Data using redux
  const adTypeData = useSelector((state) => state.campaigns.adTypeData);
  // const campaignType = adTypeData?.campaignType;

  // for budget plan
  const [customBgt1, setCustomBgt1] = useState("");
  const [customBgt2, setCustomBgt2] = useState("");
  const [customBgt3, setCustomBgt3] = useState("");
  const [customBgt4, setCustomBgt4] = useState("");

  const [selectedPlan1, setSelectedPlan1] = useState(false);
  const [selectedPlan2, setSelectedPlan2] = useState(false);
  const [selectedPlan3, setSelectedPlan3] = useState(false);
  const [selectedPlan4, setSelectedPlan4] = useState(false);

  const [isCustomInput1, setIsCustomInput1] = useState(false);
  const [isCustomInput2, setIsCustomInput2] = useState(false);
  const [isCustomInput3, setIsCustomInput3] = useState(false);
  const [isCustomInput4, setIsCustomInput4] = useState(false);

  const [saveBgt1, setSaveBgt1] = useState(false);
  const [saveBgt2, setSaveBgt2] = useState(false);
  const [saveBgt3, setSaveBgt3] = useState(false);
  const [saveBgt4, setSaveBgt4] = useState(false);

  // validation state
  const [minBgtError1, setMinBgtError1] = useState(false);
  const [minBgtError2, setMinBgtError2] = useState(false);
  const [minBgtError3, setMinBgtError3] = useState(false);
  const [minBgtError4, setMinBgtError4] = useState(false);

  const [maxBgtError1, setMaxBgtError1] = useState(false);
  const [maxBgtError2, setMaxBgtError2] = useState(false);
  const [maxBgtError3, setMaxBgtError3] = useState(false);
  const [maxBgtError4, setMaxBgtError4] = useState(false);

  // for quantity plan

  const [isCustomQtyInput1, setIsQtyCustomInput1] = useState(false);
  const [isCustomQtyInput2, setIsQtyCustomInput2] = useState(false);
  const [isCustomQtyInput3, setIsQtyCustomInput3] = useState(false);
  const [isCustomQtyInput4, setIsQtyCustomInput4] = useState(false);

  // card selection handler
  const cardSelectHandler1 = () => {
    if (!minBgtError1 && !maxBgtError1) {
      setSelectedPlan1(true);
      setSelectedPlan2(false);
      setSelectedPlan3(false);
      setSelectedPlan4(false);

      setIsCustomInput2(false);
      setIsCustomInput3(false);
      setIsCustomInput4(false);

      setIsQtyCustomInput2(false);
      setIsQtyCustomInput3(false);
      setIsQtyCustomInput4(false);
    }
  };
  const cardSelectHandler2 = () => {
    if (!minBgtError2 && !maxBgtError2) {
      setSelectedPlan1(false);
      setSelectedPlan2(true);
      setSelectedPlan3(false);
      setSelectedPlan4(false);

      setIsCustomInput1(false);
      setIsCustomInput3(false);
      setIsCustomInput4(false);

      setIsQtyCustomInput1(false);
      setIsQtyCustomInput3(false);
      setIsQtyCustomInput4(false);

      document.getElementById("bottom_btns").scrollIntoView();
    }
  };
  const cardSelectHandler3 = () => {
    if (!minBgtError3 && !maxBgtError3) {
      setSelectedPlan1(false);
      setSelectedPlan2(false);
      setSelectedPlan3(true);
      setSelectedPlan4(false);

      setIsCustomInput1(false);
      setIsCustomInput2(false);
      setIsCustomInput4(false);

      setIsQtyCustomInput1(false);
      setIsQtyCustomInput2(false);
      setIsQtyCustomInput4(false);

      document.getElementById("bottom_btns").scrollIntoView();
    }
  };
  const cardSelectHandler4 = () => {
    if (!minBgtError4 && !maxBgtError4) {
      setSelectedPlan1(false);
      setSelectedPlan2(false);
      setSelectedPlan3(false);
      setSelectedPlan4(true);

      setIsCustomInput1(false);
      setIsCustomInput2(false);
      setIsCustomInput3(false);

      setIsQtyCustomInput1(false);
      setIsQtyCustomInput2(false);
      setIsQtyCustomInput3(false);

      document.getElementById("bottom_btns").scrollIntoView();
    }
  };

  // cutom budget enabled handler

  const ctmBgtHandler1 = () => {
    setIsCustomInput1(true);
  };
  const ctmBgtHandler2 = () => {
    setIsCustomInput2(true);
  };
  const ctmBgtHandler3 = () => {
    setIsCustomInput3(true);
  };
  const ctmBgtHandler4 = () => {
    setIsCustomInput4(true);
  };

  // cutom budget enabled handler
  const ctmQtyHandler1 = () => {
    setIsQtyCustomInput1(true);
    setIsQtyCustomInput2(false);
    setIsQtyCustomInput3(false);
    setIsQtyCustomInput4(false);
  };
  const ctmQtyHandler2 = () => {
    setIsQtyCustomInput1(false);
    setIsQtyCustomInput2(true);
    setIsQtyCustomInput3(false);
    setIsQtyCustomInput4(false);
  };
  const ctmQtyHandler3 = () => {
    setIsQtyCustomInput1(false);
    setIsQtyCustomInput2(false);
    setIsQtyCustomInput3(true);
    setIsQtyCustomInput4(false);
  };
  const ctmQtyHandler4 = () => {
    setIsQtyCustomInput1(false);
    setIsQtyCustomInput2(false);
    setIsQtyCustomInput3(false);
    setIsQtyCustomInput4(true);
  };

  // custom input and custom input reset handler
  const inputHandler1 = (e) => {
    setCustomBgt1(+e.target.value);
    if (+e.target.value < 2000) {
      setMinBgtError1(true);
    } else {
      setMinBgtError1(false);
    }
    // if (+e.target.value > 500000) {
    //   setMaxBgtError1(true);
    // } else {
    //   setMaxBgtError1(false);
    // }
  };
  const resetHandler1 = () => {
    setCustomBgt1("");
    setIsCustomInput1(false);

    setSaveBgt1(false);
    setMinBgtError1(false);
    setMaxBgtError1(false);
  };

  const inputHandler2 = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setCustomBgt2(+e.target.value);
    if (+e.target.value < 2000) {
      setMinBgtError2(true);
    } else {
      setMinBgtError2(false);
    }
    // if (+e.target.value > 500000) {
    //   setMaxBgtError2(true);
    // } else {
    //   setMaxBgtError2(false);
    // }
  };
  const resetHandler2 = () => {
    setCustomBgt2("");
    setIsCustomInput2(false);
    setSaveBgt2(false);

    setMinBgtError2(false);
    setMaxBgtError2(false);
  };

  const inputHandler3 = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setCustomBgt3(+e.target.value);
    if (+e.target.value < 2000) {
      setMinBgtError3(true);
    } else {
      setMinBgtError3(false);
    }
    // if (+e.target.value > 500000) {
    //   setMaxBgtError3(true);
    // } else {
    //   setMaxBgtError3(false);
    // }
  };
  const resetHandler3 = () => {
    setCustomBgt3("");
    setIsCustomInput3(false);
    setSaveBgt3(false);
    setMinBgtError3(false);
    setMaxBgtError3(false);
  };

  const inputHandler4 = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setCustomBgt4(+e.target.value);
    if (+e.target.value < 2000) {
      setMinBgtError4(true);
    } else {
      setMinBgtError4(false);
    }
    // if (+e.target.value > 500000) {
    //   setMaxBgtError4(true);
    // } else {
    //   setMaxBgtError4(false);
    // }
  };
  const resetHandler4 = () => {
    setCustomBgt4("");
    setIsCustomInput4(false);
    setSaveBgt4(false);
    setMinBgtError4(false);
    setMaxBgtError4(false);
  };

  // save budget handlers
  const saveBgtHandler1 = () => {
    if (!minBgtError1 && !maxBgtError1) {
      setSaveBgt1(true);
    }
  };
  const saveBgtHandler2 = () => {
    if (!minBgtError2 && !maxBgtError2) {
      setSaveBgt2(true);
    }
  };
  const saveBgtHandler3 = () => {
    if (!minBgtError3 && !maxBgtError3) {
      setSaveBgt3(true);
    }
  };
  const saveBgtHandler4 = () => {
    if (!minBgtError4 && !maxBgtError4) {
      setSaveBgt4(true);
    }
  };

  // save budget handlers
  const saveQtyHandler1 = () => {
    setIsQtyCustomInput1(false);
  };
  const saveQtyHandler2 = () => {
    setIsQtyCustomInput2(false);
  };
  const saveQtyHandler3 = () => {
    setIsQtyCustomInput3(false);
  };
  const saveQtyHandler4 = () => {
    setIsQtyCustomInput4(false);
  };

  // Budget Object
  const recBudget = {
    plan1: 10000,
    plan2: 125000,
    plan3: 250000,
    plan4: 500000,
  };

  let isCtmBgt =
    (isCustomInput1 && customBgt1 > 999 && saveBgt1) ||
    (isCustomInput2 && customBgt2 > 999 && saveBgt2) ||
    (isCustomInput3 && customBgt3 > 999 && saveBgt3) ||
    (isCustomInput4 && customBgt4 > 999 && saveBgt4);

  const pamphletDetailsSubmitHandler = () => {
    const pamphletFormData = new FormData();
    pamphletFormData.append("pamphlet_type", "UP");
    pamphletFormData.append(
      "is_duplex",
      adTypeData?.pamphletSide === "Double" ? true : false
    );
    pamphletFormData.append("size", adTypeData?.pamphletSize);
    pamphletFormData.append("paper_quality", adTypeData?.pamphletQuality);

    fetch(API_URL + "campaign/pamphlet-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: pamphletFormData,
    }).then(async (res) => {
      const data = await res.json();
      dispatch(campaignActions.setPamphletId({ pamphletId: data.id }));
    });
  };

  const footerDetailsSubmitHandler = (e) => {
    const footerFormData = new FormData();

    footerFormData.append("height", adTypeData?.footerSize);
    // footerFormData.append("footer_name", "-----");
    // footerFormData.append("footer_file", footerFrontImgInput);

    fetch(API_URL + "campaign/footer-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: footerFormData,
    }).then(async (res) => {
      const data = await res.json();
      dispatch(campaignActions.setFooterId({ footerId: data.id }));
    });
  };

  const displayAdDetailSubmitHandler = () => {
    const displayAdFormData = new FormData();
    displayAdFormData.append("screen_size", adTypeData?.displayAdSize);

    fetch(API_URL + "campaign/display-ad-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: displayAdFormData,
    }).then(async (res) => {
      const data = await res.json();
      // console.log(data);
      dispatch(campaignActions.setDisplayAdId({ displayAdId: data.id }));
    });
  };

  const labelAdDetailSubmitHandler = () => {
    const labelAdFormData = new FormData();
    labelAdFormData.append("size", adTypeData?.labelAdSize);
    labelAdFormData.append("client", clientId);

    fetch(API_URL + "campaign/label-ad-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: labelAdFormData,
    }).then(async (res) => {
      const data = await res.json();
      // console.log(data);
      dispatch(campaignActions.setLabelAdId({ labelAdId: data.id }));
    });
  };

  let monthPlans = [month3png, month6png, month12png];

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />

      <div className="business_page_main_div">
        <div className="business_page_sub_div">
          <div className="campaign_type_wrapper">
            <div className="sub_title m_l_r_2 f_s_30 text_uppercase">
              Choose Your<span className="green_theme"> Plan</span>
            </div>
            {/* <div className="plane_icon_wrapper m_l_r_2">
              <img width="200px" src={plane_icon} alt="plane_icon" />
            </div> */}
            <div className="plan_toggle_wrapper m_l_r_2">
              <h5
                className={
                  campaignType === "Quantity"
                    ? "green_text sub_title"
                    : "sub_title"
                }
              >
                Quantity Plan
              </h5>
              <ToggleButtonGroup
                color="primary"
                value={campaignType}
                exclusive
                // onChange={handleQualityChange}
                aria-label="Platform"
                sx={{
                  // width: "180px",
                  color: "#43ba56",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  borderRadius: "30px",
                  padding: "1px",
                  margin: "0.5rem",
                  width: "75px",
                  height: "35px",
                }}
                className="business_toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    campaignType === "Quantity"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="Quantity"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setCampaignType("Quantity");
                  }}
                ></ToggleButton>

                <ToggleButton
                  className={
                    campaignType === "Budget"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="Budget"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setCampaignType("Budget");
                  }}
                ></ToggleButton>
              </ToggleButtonGroup>
              <h5
                className={
                  campaignType === "Budget"
                    ? "green_text sub_title"
                    : "sub_title"
                }
              >
                Budget Plan
              </h5>
            </div>
          </div>

          <div className="business_plan_cards_wrapper">
            <div className="business_toggles_wrapper">
              {/* <div className="pre_printed_checkbox_wrapper">
                <CheckBoxPrePrinted />
              </div> */}
              <ToggleBusinessPlan isFooter={true} />
            </div>

            <div className="business_plan_cards_sub_wrapper">
              {/* <BusinessBudgetCard
                selectedRecomendedBgt={recBudget.plan1}
                benefitsList={benefitsListBusinessPlans.plan1.list}
                selectedPlan={selectedPlan1}
                selectedPlan1={selectedPlan1}
                chooseOnClick={cardSelectHandler1}
                ctmBgtOnClick={ctmBgtHandler1}
                saveBgtOnClick={saveBgtHandler1}
                resetOnClick={resetHandler1}
                isCustomInput={isCustomInput1}
                customBgt={customBgt1}
                setCustomBgt={setCustomBgt1}
                onChange={inputHandler1}
                isCtmBgt={isCustomInput1 && customBgt1 > 999}
                saveBgt={saveBgt1}
                // which ad types to show in card
                isFooter={true}
                // adtype data
                footerSize={adTypeData?.footerSize}
                // to get qty
                setFtrQty={setFtrQty}
                // validation
                minBgtError={minBgtError1}
                maxBgtError={maxBgtError1}
              /> */}
              {/* <BusinessBudgetCard
                selectedRecomendedBgt={recBudget.plan2}
                benefitsList={benefitsListBusinessPlans.plan2.list}
                selectedPlan={selectedPlan2}
                selectedPlan2={selectedPlan2}
                chooseOnClick={cardSelectHandler2}
                ctmBgtOnClick={ctmBgtHandler2}
                saveBgtOnClick={saveBgtHandler2}
                resetOnClick={resetHandler2}
                isCustomInput={isCustomInput2}
                customBgt={customBgt2}
                setCustomBgt={setCustomBgt2}
                onChange={inputHandler2}
                isCtmBgt={isCustomInput2 && customBgt2 > 999}
                saveBgt={saveBgt2}
                // which ad types to show in card
                isFooter={true}
                isPamphlet={true}
                // adtype data
                pammphletSize={adTypeData?.pamphletSize}
                pammphletSide={adTypeData?.pamphletSide}
                pammphletQlty={adTypeData?.pamphletQuality}
                footerSize={adTypeData?.footerSize}
                //to get qty
                setFtrQty={setFtrQty}
                setPamphQty={setPamphQty}
                // validation
                minBgtError={minBgtError2}
                maxBgtError={maxBgtError2}
              /> */}
              {/* <BusinessBudgetCard
                selectedRecomendedBgt={recBudget.plan3}
                benefitsList={benefitsListBusinessPlans.plan3.list}
                selectedPlan={selectedPlan3}
                selectedPlan3={selectedPlan3}
                chooseOnClick={cardSelectHandler3}
                ctmBgtOnClick={ctmBgtHandler3}
                saveBgtOnClick={saveBgtHandler3}
                resetOnClick={resetHandler3}
                isCustomInput={isCustomInput3}
                customBgt={customBgt3}
                setCustomBgt={setCustomBgt3}
                onChange={inputHandler3}
                isCtmBgt={isCustomInput3 && customBgt3 > 999}
                saveBgt={saveBgt3}
                // darkTheme={true}

                // which ad types to show in card
                isDisplayAd={true}
                isFooter={true}
                isPamphlet={true}
                // isVoucher={true}

                // adtype data
                pammphletSize={adTypeData?.pamphletSize}
                pammphletSide={adTypeData?.pamphletSide}
                pammphletQlty={adTypeData?.pamphletQuality}
                footerSize={adTypeData?.footerSize}
                displayAdSize={adTypeData?.displayAdSize}
                // to get qty
                setPamphQty={setPamphQty}
                setFtrQty={setFtrQty}
                setDispQty={setDispQty}
                // validation
                minBgtError={minBgtError3}
                maxBgtError={maxBgtError3}
              /> */}

              {/* Quantity Based Cards */}
              {campaignType === "Quantity" && (
                <>
                  <BusinessCheckboxQtyCard
                    campaignType={campaignType}
                    selectedRecomendedBgt={recBudget.plan2}
                    monthPlan={monthPlans?.[0]}
                    // benefitsList={benefitsListBusinessPlans.plan4.list}
                    selectedPlan={selectedPlan2}
                    selectedPlan2={selectedPlan2}
                    chooseOnClick={cardSelectHandler2}
                    ctmQtyOnClick={ctmQtyHandler2}
                    saveQtyOnClick={saveQtyHandler2}
                    resetOnClick={resetHandler2}
                    isCustomInput={isCustomQtyInput2}
                    onChange={inputHandler2}
                    setIsQtyCustomInput2={setIsQtyCustomInput2}
                    setIsQtyCustomInput3={setIsQtyCustomInput3}
                    setIsQtyCustomInput4={setIsQtyCustomInput4}
                    // darkTheme={true}
                    // which ad types to show in card
                    setQtyAdTypesCard2={setQtyAdTypesCard2}
                    setQtyAdTypesCard3={setQtyAdTypesCard3}
                    setQtyAdTypesCard4={setQtyAdTypesCard4}
                    card2={true}
                    // adtype data
                    setCodeOfPamph={setCodeOfPamph}
                    pammphletSize={adTypeData?.pamphletSize}
                    pammphletSide={adTypeData?.pamphletSide}
                    pammphletQlty={adTypeData?.pamphletQuality}
                    footerSize={adTypeData?.footerSize}
                    displayAdSize={adTypeData?.displayAdSize}
                    labelAdSize={adTypeData?.labelAdSize}
                    // to get qty
                    setPamphQty={setPamphQty}
                    setFtrQty={setFtrQty}
                    setDispQty={setDispQty}
                    setLabelQty={setLabelQty}
                    // to get budget
                    setQtyBasedBgt={setQtyBasedBgt}
                    // validation
                    minBgtError={minBgtError2}
                    maxBgtError={maxBgtError2}
                    // comingSoon={true}
                  />

                  <BusinessCheckboxQtyCard
                    campaignType={campaignType}
                    selectedRecomendedBgt={recBudget.plan3}
                    monthPlan={monthPlans?.[1]}
                    // benefitsList={benefitsListBusinessPlans.plan4.list}
                    selectedPlan={selectedPlan3}
                    selectedPlan3={selectedPlan3}
                    chooseOnClick={cardSelectHandler3}
                    ctmQtyOnClick={ctmQtyHandler3}
                    saveQtyOnClick={saveQtyHandler3}
                    resetOnClick={resetHandler3}
                    isCustomInput={isCustomQtyInput3}
                    onChange={inputHandler3}
                    // darkTheme={true}
                    // which ad types to show in card
                    card3={true}
                    setQtyAdTypesCard2={setQtyAdTypesCard2}
                    setQtyAdTypesCard3={setQtyAdTypesCard3}
                    setQtyAdTypesCard4={setQtyAdTypesCard4}
                    // adtype data
                    setCodeOfPamph={setCodeOfPamph}
                    pammphletSize={adTypeData?.pamphletSize}
                    pammphletSide={adTypeData?.pamphletSide}
                    pammphletQlty={adTypeData?.pamphletQuality}
                    footerSize={adTypeData?.footerSize}
                    displayAdSize={adTypeData?.displayAdSize}
                    labelAdSize={adTypeData?.labelAdSize}
                    // to get qty
                    setPamphQty={setPamphQty}
                    setFtrQty={setFtrQty}
                    setDispQty={setDispQty}
                    setLabelQty={setLabelQty}
                    // to get budget
                    setQtyBasedBgt={setQtyBasedBgt}
                    // validation
                    minBgtError={minBgtError3}
                    maxBgtError={maxBgtError3}
                    // comingSoon={true}
                  />

                  <BusinessCheckboxQtyCard
                    campaignType={campaignType}
                    selectedRecomendedBgt={recBudget.plan4}
                    monthPlan={monthPlans?.[2]}
                    // benefitsList={benefitsListBusinessPlans.plan4.list}
                    selectedPlan={selectedPlan4}
                    selectedPlan4={selectedPlan4}
                    chooseOnClick={cardSelectHandler4}
                    ctmQtyOnClick={ctmQtyHandler4}
                    saveQtyOnClick={saveQtyHandler4}
                    resetOnClick={resetHandler4}
                    isCustomInput={isCustomQtyInput4}
                    onChange={inputHandler4}
                    // darkTheme={true}
                    // which ad types to show in card
                    card4={true}
                    setQtyAdTypesCard2={setQtyAdTypesCard2}
                    setQtyAdTypesCard3={setQtyAdTypesCard3}
                    setQtyAdTypesCard4={setQtyAdTypesCard4}
                    // adtype data
                    setCodeOfPamph={setCodeOfPamph}
                    pammphletSize={adTypeData?.pamphletSize}
                    pammphletSide={adTypeData?.pamphletSide}
                    pammphletQlty={adTypeData?.pamphletQuality}
                    footerSize={adTypeData?.footerSize}
                    displayAdSize={adTypeData?.displayAdSize}
                    labelAdSize={adTypeData?.labelAdSize}
                    // to get qty
                    setPamphQty={setPamphQty}
                    setFtrQty={setFtrQty}
                    setDispQty={setDispQty}
                    setLabelQty={setLabelQty}
                    // to get budget
                    setQtyBasedBgt={setQtyBasedBgt}
                    // validation
                    minBgtError={minBgtError4}
                    maxBgtError={maxBgtError4}
                    // comingSoon={true}
                  />
                </>
              )}

              {/* Budget Based Cards */}
              {campaignType === "Budget" && (
                <>
                  <BusinessCheckboxCard
                    campaignType={campaignType}
                    selectedRecomendedBgt={recBudget.plan2}
                    monthPlan={monthPlans?.[0]}
                    // benefitsList={benefitsListBusinessPlans.plan4.list}
                    selectedPlan={selectedPlan2}
                    selectedPlan2={selectedPlan2}
                    chooseOnClick={cardSelectHandler2}
                    ctmBgtOnClick={ctmBgtHandler2}
                    saveBgtOnClick={saveBgtHandler2}
                    resetOnClick={resetHandler2}
                    isCustomInput={isCustomInput2}
                    customBgt={customBgt2}
                    setCustomBgt={setCustomBgt2}
                    onChange={inputHandler2}
                    isCtmBgt={isCustomInput2 && customBgt2 > 999}
                    saveBgt={saveBgt2}
                    // darkTheme={true}
                    // which ad types to show in card
                    setAdTypesCard2={setAdTypesCard2}
                    setAdTypesCard3={setAdTypesCard3}
                    setAdTypesCard4={setAdTypesCard4}
                    card2={true}
                    // adtype data
                    setCodeOfPamph={setCodeOfPamph}
                    pammphletSize={adTypeData?.pamphletSize}
                    pammphletSide={adTypeData?.pamphletSide}
                    pammphletQlty={adTypeData?.pamphletQuality}
                    footerSize={adTypeData?.footerSize}
                    displayAdSize={adTypeData?.displayAdSize}
                    labelAdSize={adTypeData?.labelAdSize}
                    // to get qty
                    setPamphQty={setPamphQty}
                    setFtrQty={setFtrQty}
                    setDispQty={setDispQty}
                    setLabelQty={setLabelQty}
                    // validation
                    minBgtError={minBgtError2}
                    maxBgtError={maxBgtError2}
                    // comingSoon={true}
                  />
                  <BusinessCheckboxCard
                    campaignType={campaignType}
                    selectedRecomendedBgt={recBudget.plan3}
                    monthPlan={monthPlans?.[1]}
                    // benefitsList={benefitsListBusinessPlans.plan4.list}
                    selectedPlan={selectedPlan3}
                    selectedPlan3={selectedPlan3}
                    chooseOnClick={cardSelectHandler3}
                    ctmBgtOnClick={ctmBgtHandler3}
                    saveBgtOnClick={saveBgtHandler3}
                    resetOnClick={resetHandler3}
                    isCustomInput={isCustomInput3}
                    customBgt={customBgt3}
                    setCustomBgt={setCustomBgt3}
                    onChange={inputHandler3}
                    isCtmBgt={isCustomInput3 && customBgt3 > 999}
                    saveBgt={saveBgt3}
                    // darkTheme={true}
                    // which ad types to show in card
                    card3={true}
                    setAdTypesCard2={setAdTypesCard2}
                    setAdTypesCard3={setAdTypesCard3}
                    setAdTypesCard4={setAdTypesCard4}
                    // adtype data
                    setCodeOfPamph={setCodeOfPamph}
                    pammphletSize={adTypeData?.pamphletSize}
                    pammphletSide={adTypeData?.pamphletSide}
                    pammphletQlty={adTypeData?.pamphletQuality}
                    footerSize={adTypeData?.footerSize}
                    displayAdSize={adTypeData?.displayAdSize}
                    labelAdSize={adTypeData?.labelAdSize}
                    // to get qty
                    setPamphQty={setPamphQty}
                    setFtrQty={setFtrQty}
                    setDispQty={setDispQty}
                    setLabelQty={setLabelQty}
                    // validation
                    minBgtError={minBgtError3}
                    maxBgtError={maxBgtError3}
                    // comingSoon={true}
                  />
                  <BusinessCheckboxCard
                    campaignType={campaignType}
                    selectedRecomendedBgt={recBudget.plan4}
                    monthPlan={monthPlans?.[2]}
                    // benefitsList={benefitsListBusinessPlans.plan4.list}
                    selectedPlan={selectedPlan4}
                    selectedPlan4={selectedPlan4}
                    chooseOnClick={cardSelectHandler4}
                    ctmBgtOnClick={ctmBgtHandler4}
                    saveBgtOnClick={saveBgtHandler4}
                    resetOnClick={resetHandler4}
                    isCustomInput={isCustomInput4}
                    customBgt={customBgt4}
                    setCustomBgt={setCustomBgt4}
                    onChange={inputHandler4}
                    isCtmBgt={isCustomInput4 && customBgt4 > 999}
                    saveBgt={saveBgt4}
                    // darkTheme={true}
                    // which ad types to show in card
                    card4={true}
                    setAdTypesCard2={setAdTypesCard2}
                    setAdTypesCard3={setAdTypesCard3}
                    setAdTypesCard4={setAdTypesCard4}
                    // adtype data
                    setCodeOfPamph={setCodeOfPamph}
                    pammphletSize={adTypeData?.pamphletSize}
                    pammphletSide={adTypeData?.pamphletSide}
                    pammphletQlty={adTypeData?.pamphletQuality}
                    footerSize={adTypeData?.footerSize}
                    displayAdSize={adTypeData?.displayAdSize}
                    labelAdSize={adTypeData?.labelAdSize}
                    // to get qty
                    setPamphQty={setPamphQty}
                    setFtrQty={setFtrQty}
                    setDispQty={setDispQty}
                    setLabelQty={setLabelQty}
                    // validation
                    minBgtError={minBgtError4}
                    maxBgtError={maxBgtError4}
                    // comingSoon={true}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_fix_div_wrapper" id="bottom_btns">
        <div className="bottom_fixed_div">
          <button
            className="new_back_btn"
            name="Back"
            onClick={() => {
              history.goBack();
            }}
          >
            <span>
              <img src={arrowLeftNew} alt="arrowLeftNew" />
            </span>
            &nbsp; Back
          </button>
          <button
            className="new_continue_btn"
            onClick={() => {
              if (campaignType === "Budget") {
                if (
                  (selectedPlan2 || selectedPlan3 || selectedPlan4) &&
                  (adTypesCard2?.[0] ||
                    adTypesCard2?.[1] ||
                    adTypesCard3?.[0] ||
                    adTypesCard3?.[1] ||
                    adTypesCard4?.[0] ||
                    adTypesCard4?.[1])
                ) {
                  pamphletDetailsSubmitHandler();
                }
                if (
                  (selectedPlan2 || selectedPlan3 || selectedPlan4) &&
                  (adTypesCard2?.[2] || adTypesCard3?.[2] || adTypesCard4?.[2])
                ) {
                  footerDetailsSubmitHandler();
                }
                if (
                  (selectedPlan2 || selectedPlan3 || selectedPlan4) &&
                  (adTypesCard2?.[3] || adTypesCard3?.[3] || adTypesCard4?.[3])
                ) {
                  displayAdDetailSubmitHandler();
                }
                if (
                  (selectedPlan2 || selectedPlan3 || selectedPlan4) &&
                  (adTypesCard2?.[4] || adTypesCard3?.[4] || adTypesCard4?.[4])
                ) {
                  labelAdDetailSubmitHandler();
                }

                //
                if (
                  (selectedPlan2 &&
                    !selectedPlan3 &&
                    !selectedPlan4 &&
                    (adTypesCard2?.[0] ||
                      adTypesCard2?.[1] ||
                      adTypesCard2?.[2] ||
                      adTypesCard2?.[3] ||
                      adTypesCard2?.[4])) ||
                  (!selectedPlan2 &&
                    selectedPlan3 &&
                    !selectedPlan4 &&
                    (adTypesCard3?.[0] ||
                      adTypesCard3?.[1] ||
                      adTypesCard3?.[2] ||
                      adTypesCard3?.[3] ||
                      adTypesCard3?.[4])) ||
                  (!selectedPlan2 &&
                    !selectedPlan3 &&
                    selectedPlan4 &&
                    (adTypesCard4?.[0] ||
                      adTypesCard4?.[1] ||
                      adTypesCard4?.[2] ||
                      adTypesCard4?.[3] ||
                      adTypesCard4?.[4]))
                ) {
                  history.push({
                    pathname: "/dashboard/business-plans-detail-desktop",
                    state: {
                      codeOfPamph: codeOfPamph,
                      pamphSize: adTypeData?.pamphletSize,
                      pamphQlty: adTypeData?.pamphletQuality,
                      isDuplex:
                        adTypeData?.pamphletSide === "Double" ? true : false,
                      ftrSize: adTypeData?.footerSize,
                      displayAdSize: adTypeData?.displayAdSize,
                      labelAdSize: adTypeData?.labelAdSize,
                      pamphQty: pamphQty,
                      ftrQty: ftrQty,
                      dispQty: dispQty,
                      labelQty: labelQty,
                      isQr:
                        adTypesCard2?.[1] ||
                        adTypesCard3?.[1] ||
                        adTypesCard4?.[1] ||
                        ((adTypesCard2?.[0] ||
                          adTypesCard3?.[0] ||
                          adTypesCard4?.[0]) &&
                          adTypeData?.pamphletSize === "A4" &&
                          adTypeData?.pamphletSide === "Double") ||
                        ((adTypesCard2?.[2] ||
                          adTypesCard3?.[2] ||
                          adTypesCard4?.[2]) &&
                          adTypeData?.footerSize === "2cm")
                          ? true
                          : false,
                      adTypesCard: [adTypesCard2, adTypesCard3, adTypesCard4],
                      recBgt:
                        (selectedPlan1 && recBudget.plan1) ||
                        (selectedPlan2 && recBudget.plan2) ||
                        (selectedPlan3 && recBudget.plan3) ||
                        (selectedPlan4 && recBudget.plan4),
                      isCtmBgt: isCtmBgt,
                      customBgt:
                        (selectedPlan1 && customBgt1) ||
                        (selectedPlan2 && customBgt2) ||
                        (selectedPlan3 && customBgt3) ||
                        (selectedPlan4 && customBgt4),
                      // isVoucher: (selectedPlan3 || selectedPlan4) && true,
                      // isLocation: selectedPlan4 && true,
                    },
                  });
                } else {
                  alert("Please Select Plan First");
                }
              }

              if (campaignType === "Quantity") {
                if (
                  (selectedPlan2 || selectedPlan3 || selectedPlan4) &&
                  (qtyAdTypesCard2?.[0] ||
                    qtyAdTypesCard2?.[1] ||
                    qtyAdTypesCard3?.[0] ||
                    qtyAdTypesCard3?.[1] ||
                    qtyAdTypesCard4?.[0] ||
                    qtyAdTypesCard4?.[1])
                ) {
                  pamphletDetailsSubmitHandler();
                }
                if (
                  (selectedPlan2 || selectedPlan3 || selectedPlan4) &&
                  (qtyAdTypesCard2?.[2] ||
                    qtyAdTypesCard3?.[2] ||
                    qtyAdTypesCard4?.[2])
                ) {
                  footerDetailsSubmitHandler();
                }
                if (
                  (selectedPlan2 || selectedPlan3 || selectedPlan4) &&
                  (qtyAdTypesCard2?.[3] ||
                    qtyAdTypesCard3?.[3] ||
                    qtyAdTypesCard4?.[3])
                ) {
                  displayAdDetailSubmitHandler();
                }
                if (
                  (selectedPlan2 || selectedPlan3 || selectedPlan4) &&
                  (qtyAdTypesCard2?.[4] ||
                    qtyAdTypesCard3?.[4] ||
                    qtyAdTypesCard4?.[4])
                ) {
                  labelAdDetailSubmitHandler();
                }

                //
                if (
                  (selectedPlan2 &&
                    !selectedPlan3 &&
                    !selectedPlan4 &&
                    !isCustomQtyInput2 &&
                    (qtyAdTypesCard2?.[0] ||
                      qtyAdTypesCard2?.[1] ||
                      qtyAdTypesCard2?.[2] ||
                      qtyAdTypesCard2?.[3] ||
                      qtyAdTypesCard2?.[4])) ||
                  (!selectedPlan2 &&
                    selectedPlan3 &&
                    !selectedPlan4 &&
                    !isCustomQtyInput3 &&
                    (qtyAdTypesCard3?.[0] ||
                      qtyAdTypesCard3?.[1] ||
                      qtyAdTypesCard3?.[2] ||
                      qtyAdTypesCard3?.[3] ||
                      qtyAdTypesCard3?.[4])) ||
                  (!selectedPlan2 &&
                    !selectedPlan3 &&
                    selectedPlan4 &&
                    !isCustomQtyInput4 &&
                    (qtyAdTypesCard4?.[0] ||
                      qtyAdTypesCard4?.[1] ||
                      qtyAdTypesCard4?.[2] ||
                      qtyAdTypesCard4?.[3] ||
                      qtyAdTypesCard4?.[4]))
                ) {
                  history.push({
                    pathname: "/dashboard/business-plans-detail-desktop",
                    state: {
                      campaignType: campaignType,
                      codeOfPamph: codeOfPamph,
                      pamphSize: adTypeData?.pamphletSize,
                      pamphQlty: adTypeData?.pamphletQuality,
                      isDuplex:
                        adTypeData?.pamphletSide === "Double" ? true : false,
                      ftrSize: adTypeData?.footerSize,
                      displayAdSize: adTypeData?.displayAdSize,
                      labelAdSize: adTypeData?.labelAdSize,
                      pamphQty: pamphQty,
                      ftrQty: ftrQty,
                      dispQty: dispQty,
                      labelQty: labelQty,
                      isQr:
                        qtyAdTypesCard2?.[1] ||
                        qtyAdTypesCard3?.[1] ||
                        qtyAdTypesCard4?.[1] ||
                        ((qtyAdTypesCard2?.[0] ||
                          qtyAdTypesCard3?.[0] ||
                          qtyAdTypesCard4?.[0]) &&
                          adTypeData?.pamphletSize === "A4" &&
                          adTypeData?.pamphletSide === "Double") ||
                        ((qtyAdTypesCard2?.[2] ||
                          qtyAdTypesCard3?.[2] ||
                          qtyAdTypesCard4?.[2]) &&
                          adTypeData?.footerSize === "2cm")
                          ? true
                          : false,
                      qtyAdTypesCard: [
                        qtyAdTypesCard2,
                        qtyAdTypesCard3,
                        qtyAdTypesCard4,
                      ],
                      recBgt: qtyBasedBgt,
                      isCtmBgt: isCtmBgt,
                      customBgt:
                        (selectedPlan1 && customBgt1) ||
                        (selectedPlan2 && customBgt2) ||
                        (selectedPlan3 && customBgt3) ||
                        (selectedPlan4 && customBgt4),
                      // isVoucher: (selectedPlan3 || selectedPlan4) && true,
                      // isLocation: selectedPlan4 && true,
                    },
                  });
                } else {
                  alert("Please Select Plan First");
                }
              }
            }}
          >
            Proceed
            <span>
              <img src={whiteRightArrow} alt="whiteRightArrow" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};
export default BusinessPlansPage;
