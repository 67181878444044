import React from "react";

import "./Promoter.css";
import phone2 from "./phone2.png";
import phone3 from "./phone3.png";

import star from "./star.svg";
import googlePlaySvg from "./Google-play.svg";
import ewayadz from "./Ewayprint.png";

function Promoter() {
  let details = navigator.userAgent;

  /* Creating a regular expression 
  containing some mobile devices keywords 
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(details);
  return (
    <>
      <div className="promoter-main-div">
        <div className="promoter-c1">
          <div>
            <img className="eway-logo-img-size" src={ewayadz} alt="abc" />
          </div>
          <div className="star-grp">
            <div style={{ width: "2em" }}>
              <img src={star} alt="star"></img>
            </div>
            <div style={{ width: "2em" }}>
              <img src={star} alt="star"></img>
            </div>
            <div style={{ width: "2em" }}>
              <img src={star} alt="star"></img>
            </div>
            <div style={{ width: "2em" }}>
              <img src={star} alt="star"></img>
            </div>
            <div style={{ width: "2em" }}>
              <img src={star} alt="star"></img>
            </div>
          </div>
          <div className="promoter-text-flex">
            <div className="promoter-text">Install the App & Register for</div>

            <div className="promoter-text">
              <span style={{ color: "rgb(15 159 65)", marginBottom: "1rem" }}>
                FREE PRINTS !
              </span>
            </div>
          </div>
          <div
            style={{ width: "80%", marginTop: "2rem" }}
            className="promoter-text-flex"
          >
            <a
              onClick="return gtag_report_conversion('http://ewayprint.com/home');"
              href="http://ewayprint.com/home"
            >
              <a
                href={
                  isMobileDevice
                    ? "https://ewayprint.page.link/eeow"
                    : "https://play.google.com/store/apps/details?id=com.iotexcel.ewayprint"
                }
                target="blank"
              >
                <img src={googlePlaySvg} alt="download" />
              </a>
            </a>
          </div>
        </div>
        <div className="promoter-c2">
          <img className="promoter-c2-img" src={phone3} alt="mob"></img>
        </div>
      </div>
    </>
  );
}

export default Promoter;
