import React from "react";
import CampaignHeader from "./Header/CampaignHeader.jsx";
import "./CampaignLayout.css";

const CampaignLayout = (props) => {
  return (
    <div>
      <CampaignHeader />
      <div className="body__div w_100">
        <div
          className="w_100 flex flex_column align_center justify-start"
          style={{ paddingBottom: "5rem" }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default CampaignLayout;
