import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import "./ToggleButtonsCard.css";
import { useState } from "react";
import QtyCard from "../QtyCard/QtyCard";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../../store/campaign-slice";
import { useEffect } from "react";
import { infoIcon } from "../../../assets";

const ToggleButtonsCard = (props) => {
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = useState("A5");
  const [selectedSide, setSelectedSide] = useState("Single");
  const [selectedQuality, setSelectedQuality] = useState("90GSM");
  const [footerSize, setFooterSize] = useState("1CM");

  const [pamphletQty, setPamphletQty] = useState(props.quantityArr?.[0]);

  let inputObj = {
    selectedSize: selectedSize,
    selectedSide: selectedSide,
    selectedQuality: selectedQuality,
    pamphletQty: pamphletQty,
  };
  useEffect(() => {
    dispatch(campaignActions.setPamphletData({ pamphletData: inputObj }));
  }, [selectedSize, selectedSide, selectedQuality, pamphletQty]);

  // const handleSizeChange = (event, size) => {
  //   setSelectedSize(size);
  // };

  // const handleSideChange = (event, side) => {
  //   setSelectedSide(side);
  // };

  // const footerSizeChange = (event, size) => {
  //   setFooterSize(size);
  // };

  // const handleQualityChange = (event, quality) => {
  //   setSelectedQuality(quality);
  // };
  return (
    <>
      <div className="toggle_btn_card_main_div">
        <div className="toggle_btn_card_sub_div1">
          <div className="sub_title">Pamphlet Details</div>
          <div className="size_side_toggle_wrapper">
            <div className="size_toggle_wrapper m_t_1">
              <div className="sub_title_child">Pamphlet Size</div>
              <ToggleButtonGroup
                value={selectedSize}
                exclusive
                // onChange={handleSizeChange}
                aria-label="Platform"
                sx={{
                  color: "rgba(25, 150, 71, 1)",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "8px",
                  padding: "3px",
                }}
                className="toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    selectedSize === "A5"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="A5"
                  sx={{
                    fontWeight: 700,
                    fontSize: "15px",
                    border: "none",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedSize("A5");
                  }}
                >
                  A5
                </ToggleButton>
                <ToggleButton
                  className={
                    selectedSize === "A4"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="A4"
                  sx={{
                    fontWeight: 700,
                    fontSize: "15px",
                    border: "none",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedSize("A4");
                  }}
                >
                  A4
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div className="size_toggle_wrapper m_t_1">
              <div className="sub_title_child">Printing Side</div>
              <ToggleButtonGroup
                color="primary"
                value={selectedSide}
                exclusive
                // onChange={handleSideChange}
                aria-label="Platform"
                sx={{
                  color: "rgba(25, 150, 71, 1)",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "8px",
                  padding: "3px",
                }}
                className="toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    selectedSide === "Single"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="Single"
                  sx={{
                    width: 120,
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedSide("Single");
                  }}
                >
                  Single
                </ToggleButton>
                <ToggleButton
                  className={
                    selectedSide === "Double"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="Double"
                  sx={{
                    width: 120,
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedSide("Double");
                  }}
                >
                  Double
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>

          {props.paperQlty && (
            <div className="size_toggle_wrapper m_t_1 m_b_1">
              <div className="sub_title_child">Paper Quality</div>
              <ToggleButtonGroup
                color="primary"
                value={selectedQuality}
                exclusive
                // onChange={handleQualityChange}
                aria-label="Platform"
                sx={{
                  color: "rgba(25, 150, 71, 1)",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "8px",
                  padding: "3px",
                }}
                className="toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    selectedQuality === "90GSM"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="90GSM"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedQuality("90GSM");
                  }}
                >
                  90 GSM
                </ToggleButton>
                <ToggleButton
                  className={
                    selectedQuality === "130GSM"
                      ? "selected-toggle"
                      : "unselected-toggle"
                  }
                  value="130GSM"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedQuality("130GSM");
                  }}
                >
                  130GSM
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
        </div>
        {props.qtyCard && (
          <QtyCard
            setPamphletQty={setPamphletQty}
            quantityArr={props.quantityArr}
            amountToMultiply={props.amountToMultiply}
          />
        )}
        {props.isFooter && (
          <div className="toggle_btn_card_sub_div2 m_t_0_5">
            <div className="sub_title">Footer Details</div>
            <div className="sub_title_child">Size</div>
            <div className="size_toggle_wrapper">
              <ToggleButtonGroup
                value={footerSize}
                exclusive
                // onChange={footerSizeChange}
                aria-label="Platform"
                sx={{
                  width: 140,
                  color: "rgba(25, 150, 71, 1)",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "4px",
                }}
              >
                <ToggleButton
                  className={footerSize === "1CM" ? "selected-toggle" : ""}
                  value="1CM"
                  sx={{
                    width: 70,
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    setFooterSize("1CM");
                  }}
                >
                  1CM
                </ToggleButton>
                <ToggleButton
                  className={footerSize === "2CM" ? "selected-toggle" : ""}
                  value="2CM"
                  sx={{
                    width: 70,
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    setFooterSize("2CM");
                  }}
                >
                  2CM
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        )}
        {props.isPrePrinted && (
          <div className="pre_printed_info_div m_t_1">
            <div>
              <img src={infoIcon} alt="infoIcon" />
            </div>
            <div className="pre_printed_info_text ">
              Please note that, pamphlet details will not make any changes in
              pricing. These details are just for verification & reference
              purpose.
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ToggleButtonsCard;
