import "./EsteemedClients.css";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../../constant/apiURL";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const EsteemedClients = () => {
  const [clientLogo, setClientLogo] = useState([]);

  useEffect(() => {
    const fetchClientLogos = async () => {
      try {
        const response = await fetch(API_URL + "portal/client-logo-list/");
        if (response.ok) {
          const data = await response.json();
          setClientLogo(data);
        } else {
          console.error("Failed to fetch client logos");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchClientLogos();
  }, []);

  return (
    <div className="esteemed_clients_main_div">
      <div
        className="ads__new_heading"
        style={{ marginTop: "80px", textAlign: "center" }}
      >
        Some Of Our Esteemed Clients
      </div>

      <div className="carousel_wrapper">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          swipeable={true}
          emulateTouch={true}
          infiniteLoop={true}
          centerMode={true}
          centerSlidePercentage={33.3}
          autoPlay={true}
          interval={5000} // Adjust interval as needed
          stopOnHover={true}
        >
          {clientLogo.map((logo) => (
            <div className="client_logo_wrapper" key={logo.id}>
              <img className="client_logo" src={logo.file} alt="Client Logo" />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default EsteemedClients;
