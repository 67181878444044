import React from 'react';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';

import classes from './AuthLayout.module.css';

function AuthLayout(props) {
    return (
        <Box className={classes.box}>
            <Paper variant='outlined' elevation={0} className={classes.paper}>
                {props.children}
            </Paper>
        </Box>
    )
}

export default AuthLayout
