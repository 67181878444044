import React, { useState, useRef, useEffect } from "react";
import "./Carousel.css";

const Carousel = ({ items, autoplayInterval = 3000 }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [draggingDelta, setDraggingDelta] = useState(0);

  const carouselRef = useRef(null);

  const handleNext = () => {
    if (currentPosition === -(items.length - 1)) {
      setCurrentPosition(0);
    } else {
      setCurrentPosition(currentPosition - 1);
    }
  };

  const handlePrev = () => {
    if (currentPosition === 0) {
      setCurrentPosition(-(items.length - 1));
    } else {
      setCurrentPosition(currentPosition + 1);
    }
  };

  const handleDragStart = (event) => {
    setIsDragging(true);
    setStartX(event.pageX || event.touches[0].pageX);
  };

  const handleDragMove = (event) => {
    if (!isDragging) return;
    const currentX = event.pageX || event.touches[0].pageX;
    setDraggingDelta(currentX - startX);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    if (draggingDelta > 50) {
      handlePrev();
    } else if (draggingDelta < -50) {
      handleNext();
    }
    setDraggingDelta(0);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, autoplayInterval);
    return () => clearInterval(intervalId);
  }, [currentPosition]);

  return (
    <div
      className="carousel"
      ref={carouselRef}
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
      onMouseMove={handleDragMove}
      onTouchMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onTouchEnd={handleDragEnd}
      onMouseLeave={() => setIsDragging(false)}
      onTouchCancel={() => setIsDragging(false)}
    >
      <ul
        className="carousel__list"
        style={{ transform: `translateX(${currentPosition * 100}%)` }}
      >
        {items.map((item, index) => (
          <>
            <li key={index} className="carousel__item">
              {item.name}&nbsp;
              {item.review}
            </li>
          </>
        ))}
        {/* <li key={items.length} className="carousel__item">
          {items[0].name}
        </li> */}
      </ul>
      {/* <div className="prev_next_btn_wrapper">
        <button
          className="carousel__button carousel__button--prev"
          onClick={handlePrev}
        >
          Prev
        </button>
        <button
          className="carousel__button carousel__button--next"
          onClick={handleNext}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default Carousel;
