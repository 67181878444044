import "./QrFeatureCard.css";
const QrFeatureCard = (props) => {
  return (
    <div className="qr_feature_card_main_div">
      <div>
        <img src={props.img} alt="Img" />
      </div>

      <div className="qr_feature_card_heading m_t_0_5">{props.heading}</div>
      <div className="qr_feature_text m_t_0_5">{props.text}</div>
    </div>
  );
};
export default QrFeatureCard;
