import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { checkCircleWhite, expandArrow2, infoIcon } from "../../../assets";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import HorizontalDivider from "../../../components/UI/Dividers/HorizontalDivider";
import Button from "../../../components/UI/GreenBtn/Button";
import Input from "../../../components/UI/Inputs/Input";
import SelectDropdown from "../../../components/UI/SelectDropdown/SelectDropdown";
import { API_URL } from "../../../constant/apiURL";
import CreateQrCode from "../CreateQrCode/CreateQrCode";
import PopUp from "../../../components/UI/PopUp/PopUp";

import "./Awareness.css";
import infoIcon2 from "./info.svg";
import {
  campaignActions,
  getCampaignById,
} from "../../../store/campaign-slice";
import useHttp from "../../../Hooks/useHttp";
import QrCodeCreater from "../QrCodeCreater/QrCodeCreater";

const Awareness = (props) => {
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const dispatch = useDispatch();
  const qrCodeId = useSelector((state) => state.campaigns.qrCodeId);
  const [recentQrId, setRecentQrId] = useState();
  const [qrFile, setQrFile] = useState();

  const { id = 0 } = useParams();

  const location = useLocation();

  const [selectAdType, setSelectAdType] = useState(false);
  const [inputData, setInputData] = useState({
    campaignName: "",
    startOn: "",
    pamphlet: "",
    footer: "",
    displayAd: "",
    industry: null,
  });
  const setData = (dataKey, dataVal) => {
    setInputData((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };

  //popup
  const [showPopup, setShowPopup] = useState(false);
  function togglePopup() {
    setShowPopup(!showPopup);
  }

  //states for validation
  const [isQrVisible, setIsQrVisible] = useState(false);
  // console.log(isQrVisible, "isQrVisible");
  const [qrCodeCreatedCard, setQrCodeCreatedCard] = useState(false);
  const [selectedCardName, setSelectedCardName] = useState(null);

  const [isCampaignNameEmpty, setIsCampaignNameEmpty] = useState(false);
  const [isStartOnEmpty, setIsStartOnEmpty] = useState(false);
  const [isIndustryEmpty, setIsIndustryEmpty] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [qrCreated, setQrCreate] = useState();
  const [isCheckedUrlEmpty, setIsCheckedUrlEmpty] = useState(false);
  // console.log(qrCreated);
  const [isChecked, setIsChecked] = useState(false);
  const [url, setUrl] = useState(false);

  // const isChecked_IsUrl = isChecked && url;
  // console.log(url, isChecked);
  // console.log(isChecked_IsUrl);

  //Date select Validation
  const [minDate, setMinDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 5);
    return date.toISOString().slice(0, 10);
  });

  const [industryList, setIndustryList] = useState("");

  const getSelected = (item) => {
    props.setIndustry(item);
    setData("industry", item);
  };
  // console.log(inputData);

  useEffect(() => {
    if (token) {
      (async () => {
        // Fetching Industry List
        const resInd = await fetch(API_URL + "industries/list/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const dataInd = await resInd.json();
        // console.log(dataInd);
        setIndustryList(dataInd);
      })();

      if (id) {
        const currentCampaign = campaigns.find(
          (campaign) => campaign.id.toString() === id
        );
        // console.log(currentCampaign);
      }
    }
  }, [token]);
  campaignActions.getCampaignById(83);

  const [
    locationList: data,
    locationError: error,
    isLocationLoading: isLoading,
  ] = useHttp(API_URL + "campaign/locations-list/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });

  let pinCodesCSV =
    locationList &&
    locationList.map((l) => l.pin_code !== "All" && l.pin_code).join(" ,");

  const submitQrCodeCampaign = (id) => {
    setRecentQrId(id);
    const formData = new FormData();
    formData.append("name", inputData.campaignName);
    formData.append("start_on", inputData.startOn);
    formData.append("sub_industry", inputData.industry);
    formData.append("qr_code_id", id ?? qrCodeId);
    formData.append("pin_codes", pinCodesCSV);
    fetch(API_URL + "campaign/create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        // setIsLoading(false);

        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);

            setCampaignId(data.id);
            // console.log(data.id, "campaignId");
            dispatch(campaignActions.setCampaignId({ campaignId: data.id }));
            dispatch(campaignActions.updateCampaignList({ campaign: data }));
            // console.log("got data.id  " + data.id);
            // idOFVoucher.push({
            //   voucherId: data.id,
            // });
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const updateQrCodeHandler = () => {
    const formData = new FormData();
    formData.append("file", qrFile);
    fetch(API_URL + `campaign/qr-code-update/${recentQrId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        // setIsLoading(false);

        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            // console.log("pamphlet updated");
            // history.goBack();
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />
      {!isQrVisible && (
        <div className="brand_recall_main_div ">
          <div className="brand_recall_main__sub_container">
            <div
              style={{ marginTop: "1rem" }}
              className="create_campaign heading"
            >
              Create Your Pamphlet Campaign
            </div>
            <div className=" m_t_1 m_t_1 campaign_name_div ">
              <div className=" " id="Campaign_name_Input">
                <div className="campaign_name_wrapper">
                  <div className="sub_title">Campaign Name</div>
                </div>
                <Input
                  onChange={(e) => {
                    setData("campaignName", e.target.value);
                    if (e.target.value.length === 0) {
                      setIsCampaignNameEmpty(true);
                    } else {
                      setIsCampaignNameEmpty(false);
                    }
                  }}
                  placeholder="Enter Campaign Name"
                ></Input>
              </div>
              {isCampaignNameEmpty && (
                <p style={{ color: "red" }}>Please enter campaign name</p>
              )}
            </div>
            <div className="date_picker_div">
              <div className="campaign_name_wrapper">
                <label className="sub_title" htmlFor="datepicker">
                  Distribution Start Date
                </label>
              </div>
              <input
                onChange={(e) => {
                  setData("startOn", e.target.value);
                }}
                type="date"
                min={minDate}
                name="date"
                // id="datepicker"
                className="date_picker"
              />

              {isStartOnEmpty && (
                <p style={{ color: "red" }}>Please select date</p>
              )}
            </div>

            <div className="industry_wrapper">
              <div className="industry  ">
                <SelectDropdown
                  optionsArray={industryList}
                  getSelected={getSelected}
                  label="Select Your Business Category"
                  className="m_b_1"
                />
                {isIndustryEmpty && (
                  <p style={{ color: "red" }}>Please select industry</p>
                )}
              </div>
            </div>

            {/* <div className="ad_types_card ">
            <div className="sub_title ad_types">
              Ad types
              <span className="info_span">
                <img src={infoIcon} alt="info" />
              </span>
            </div>
            <div
              className="choose_ad_type"
              // onClick={() => console.log(props.isChecked)}
            >
              Choose Atleast one Ad type
            </div>
            <div className="checkbox_group">
              <div className="checkbox_div1">
                <input
                  type="checkbox"
                  value="pamphlet"
                  id="pamphlet"
                  checked={props.isChecked.pamphletIsChecked}
                  onChange={(e) => {
                    props.setIsChecked((state) => ({
                      ...state,
                      pamphletIsChecked: e.target.checked,
                    }));
                  }}
                />
                &nbsp;&nbsp;
                <label htmlFor="pamphlet">Pamphlet</label>
              </div>
              <div className="checkbox_div2">
                <input
                  type="checkbox"
                  value="footer"
                  id="footer"
                  checked={props.isChecked.footerIsChecked}
                  onChange={(e) => {
                    props.setIsChecked((state) => ({
                      ...state,
                      footerIsChecked: e.target.checked,
                    }));
                  }}
                />
                &nbsp;&nbsp;
                <label htmlFor="footer">Footer</label>
              </div>
              <div className="checkbox_div3">
                <input
                  type="checkbox"
                  value="display_ad"
                  checked={props.isChecked.displayAdIsChecked}
                  id="display_ad"
                  onChange={(e) => {
                    props.setIsChecked((state) => ({
                      ...state,
                      displayAdIsChecked: e.target.checked,
                    }));
                  }}
                />
                &nbsp;&nbsp;
                <label htmlFor="display_ad">Display Ad</label>
              </div>
              {selectAdType ? (
                <p style={{ color: "red" }}>Please Select Atleast 1 Ad Type</p>
              ) : null}
            </div>
          </div> */}

            {!qrCodeCreatedCard && (
              <div className="qr_checkbox_wrapper">
                {/* <input
              type="checkbox"
              value="showQrCard"
              id="showQrCard"
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
            /> */}
                &nbsp;&nbsp;
                <div
                  htmlFor="showQrCard"
                  className="create_qr_btn sub_title"
                  onClick={() => {
                    if (inputData.campaignName === "") {
                      setIsCampaignNameEmpty(true);
                    }
                    if (inputData.startOn === "") {
                      setIsStartOnEmpty(true);
                    }
                    if (inputData.industry === null) {
                      setIsIndustryEmpty(true);
                    }
                    // console.log(inputData);

                    let isValid =
                      inputData.campaignName !== "" &&
                      inputData.startOn !== "" &&
                      // inputData.footer !== "" &&
                      // inputData.displayAd !== "" &&
                      inputData.industry !== null;
                    if (isValid) {
                      setIsQrVisible(true);
                    }
                  }}
                >
                  + Create QR code{" "}
                  <span style={{ color: "#929292" }}>(optional)</span>
                </div>
                <div style={{ color: "#929292" }}>
                  When this QR is scanned, potential customers can be directed
                  to your website, social media accounts, or even a promotional
                  video.
                </div>
              </div>
            )}

            {qrCodeCreatedCard && (
              <div className="qr_code_card">
                <CreateQrCode
                  setQrFile={setQrFile}
                  setUrl={setUrl}
                  campaignId={campaignId}
                  setQrCreate={setQrCreate}
                  isCheckedUrlEmpty={isCheckedUrlEmpty}
                  selectedCardName={selectedCardName}
                />
              </div>
            )}

            <div style={{ marginBottom: "10rem" }}></div>
            {/* <div className="qr_code_main_div ">
            <div className="create_heading sub_title">Create QR Code</div>
            <div className="qr_code_details">
              QR codes are a great way to encourage people to visit your
              website, Social media page or contact you via whatsApp. Just
              Create a QR code and attach it to your ads. It's that easy!
            </div>

            <div className="create_qr_code_button">
              {props.qrCreated ? (
                <div className="qr_created_div">
                  <div className="qr_created">
                    QR Code Created &nbsp;
                    <img
                      className="checkCircleWhite"
                      src={checkCircleWhite}
                      alt="checkCircleWhite"
                    ></img>
                  </div>
                </div>
              ) : null}
              <button
                onClick={() =>
                  history.push({
                    pathname: "/ads/campaign/create/new/create-qr-code",
                    state: location.state,
                  })
                }
                className="edit_qr_code_button"
              >
                Create Qr Code
                <span>
                  <img src={expandArrow2} alt="expand arrow"></img>
                </span>
              </button>
            </div>
          </div> */}

            <div className="wrapper ">
              <div className="divider_brand_recall">
                <HorizontalDivider />
              </div>
              <div className="button_wrapper">
                <Button
                  className="white"
                  onClick={() => history.goBack()}
                  btnTxtClassName="sub_title text_black"
                  name="Back"
                />
                <Button
                  type="submit"
                  name="Next"
                  onClick={() => {
                    if (qrCodeCreatedCard) {
                      updateQrCodeHandler();
                      togglePopup();
                    } else {
                      if (inputData.campaignName === "") {
                        setIsCampaignNameEmpty(true);
                      }
                      if (inputData.startOn === "") {
                        setIsStartOnEmpty(true);
                      }
                      if (inputData.industry === null) {
                        setIsIndustryEmpty(true);
                      }
                      // console.log(inputData);

                      let isValid =
                        inputData.campaignName !== "" &&
                        inputData.startOn !== "" &&
                        // inputData.footer !== "" &&
                        // inputData.displayAd !== "" &&
                        inputData.industry !== null;

                      if (isChecked) {
                        if (isChecked && url === false) {
                          setIsCheckedUrlEmpty(true);
                          // submitVoucherDetails();
                          // submitCampaignName();
                          // console.log(inputData);
                          // submitVoucherDetails();
                          // console.log(industryList);
                          // submitCampaignName();
                          // console.log(submitVoucherDetails);
                          // console.log(submitCampaignName);
                        }
                      } else {
                        if (isValid) {
                          history.replace({
                            pathname: "/ads/campaign/create/new/stepper",
                            state: {
                              ...inputData,
                              ...props.isChecked,
                              ...location.state,
                            },
                          });
                          // submitVoucherDetails();
                          // submitCampaignName();
                          // console.log(inputData);
                          // submitVoucherDetails();
                          // console.log(industryList);
                          // submitCampaignName();
                          // console.log(submitVoucherDetails);
                          // console.log(submitCampaignName);
                        }
                      }

                      if (
                        !props.isChecked.pamphletIsChecked &&
                        !props.isChecked.footerIsChecked &&
                        !props.isChecked.displayAdIsChecked
                      ) {
                        setSelectAdType(true);
                      }

                      if (props.campaignName === "") {
                        setIsCampaignNameEmpty(true);
                      }
                    }
                  }}
                />
              </div>
            </div>
            {showPopup && (
              <PopUp onClose={togglePopup}>
                <div className="info_icon_wrapper_campaign_modal">
                  <img src={infoIcon2} alt="infoIcon2" />
                </div>

                <div style={{ textAlign: "center" }} className="sub_title">
                  Add QR Code in your pamphlet and come back to create your
                  campaign
                </div>
                {/* <div className="sub_text_campaign_modal">
                You can Resume creating your campaign from campaign list
              </div> */}
                <hr></hr>
                <div
                  className="save_add_btn_campaign_modal"
                  onClick={() => {
                    // submitQrCodeCampaign();
                    history.push("/ads/campaign-list");
                  }}
                >
                  Save QR code & Continue Later
                </div>
                <p
                  style={{ textAlign: "center", fontSize: "15px" }}
                  className="sub_title_child"
                >
                  Choosing this option will save your campaign and allow you to
                  add QR code in your pamphlet. You can resume creating campaign
                  from campaign list.
                </p>
                <hr></hr>
                <div
                  className="continue_btn_campaign_modal"
                  onClick={() => {
                    togglePopup();
                    if (inputData.campaignName === "") {
                      setIsCampaignNameEmpty(true);
                    }
                    if (inputData.startOn === "") {
                      setIsStartOnEmpty(true);
                    }
                    if (inputData.industry === null) {
                      setIsIndustryEmpty(true);
                    }
                    // console.log(inputData);
                    let isValid =
                      inputData.campaignName !== "" &&
                      inputData.startOn !== "" &&
                      // inputData.footer !== "" &&
                      // inputData.displayAd !== "" &&
                      inputData.industry !== null;

                    if (isValid) {
                      history.replace({
                        pathname:
                          "/ads/campaign/create/new/stepper/" + campaignId,
                        state: {
                          ...inputData,
                          ...props.isChecked,
                          ...location.state,
                          pamphletIsCheckedfromList: true,
                        },
                      });
                      // submitVoucherDetails();
                      // submitCampaignName();
                      // console.log(inputData);
                      // submitVoucherDetails();
                      // console.log(industryList);
                      // submitCampaignName();
                      // console.log(submitVoucherDetails);
                      // console.log(submitCampaignName);
                    }
                    if (
                      !props.isChecked.pamphletIsChecked &&
                      !props.isChecked.footerIsChecked &&
                      !props.isChecked.displayAdIsChecked
                    ) {
                      setSelectAdType(true);
                    }

                    if (props.campaignName === "") {
                      setIsCampaignNameEmpty(true);
                    }
                  }}
                >
                  Continue Now
                </div>
              </PopUp>
            )}
          </div>
        </div>
      )}
      {isQrVisible && (
        <QrCodeCreater
          qrFile={qrFile}
          setIsQrVisible={setIsQrVisible}
          setQrCodeCreatedCard={setQrCodeCreatedCard}
          submitQrCodeCampaign={submitQrCodeCampaign}
          campaignId={campaignId}
          setSelectedCardName={setSelectedCardName}
        />
      )}
    </>
  );
};
export default Awareness;
