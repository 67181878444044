import "./EwayprintAppDetail.css";
import AppPlayStoreImg from "./image (1).png";
import appLogo from "./logo shape 1 (1).png";
import printConnect from "./print_connect (1).svg";
import googlePlayImg from "./Google Play.svg";
import { downloadIconBlack } from "../../../../../assets";
function EwayprintAppDetail() {
  const redirectHandler = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.iotexcel.ewayprint&hl=en_IN&gl=US";
  };
  return (
    <>
      <div className="ewayprint_app_detail_main_div">
        {window.innerWidth > 1000 && (
          <div className="ewayprint_app_detail_first_div">
            <img width="400px" src={AppPlayStoreImg} alt="AppPlayStoreImg" />
          </div>
        )}

        <div className="ewayprint_app_detail_second_div">
          <div>
            <img className="ewayprint_app_logo" src={appLogo} alt="appLogo" />
          </div>
          <div className="printed_and_downloads_detail_wrapper">
            <div className="number_text_wrapper">
              <div className="sub_title dark_green_text">14,22,100+</div>
              <div className="sub_title_child ">
                <span>
                  <img src={printConnect} alt="printConnect" />
                </span>
                Pages printed
              </div>
            </div>
            {/* <div className="number_text_wrapper">
              <div className="sub_title dark_green_text">20,000+</div>
              <div className="sub_title_child ">
                <span>
                  <img src={downloadIconBlack} alt="downloadIconBlack" />
                </span>
                App downloads
              </div>
            </div> */}
            <div className="pointer" onClick={redirectHandler}>
              <img src={googlePlayImg} alt="googlePlayImg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EwayprintAppDetail;
