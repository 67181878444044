import { Button, Divider, Grid, Modal, Typography } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import classes from "./PaymentModel.module.css";
import "./PaymentModel.css";
import {
  confirmIcon,
  horizentalShortBar,
  paymentDetailsImg,
} from "../../assets";
import { API_URL } from "../../constant/apiURL";
import PopUp from "../UI/PopUp/PopUp";
import { useHistory } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import HorizontalDivider from "../UI/Dividers/HorizontalDivider";
import HztlBarDotted from "../UI/HztlBarDotted/HztlBarDotted";
import { campaignActions } from "../../store/campaign-slice";

const PaymentModel = forwardRef((props, ref) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const GST = 0.05;
  useImperativeHandle(ref, () => ({
    openModal() {
      handleOpenPaymetModel();
    },

    paymentSubmit(createdCampaignId) {
      localStorage.setItem("createdCampaignId", createdCampaignId);
      // console.log(createdCampaignId+ 'Form PaymentModel');
      document.querySelector("#paymentForm").submit();
    },
  }));
  const [showPopup, setShowPopup] = useState(false);
  function togglePopup() {
    setShowPopup(!showPopup);
  }

  const [isPartial, setIsPartial] = useState(false);
  function partialToggle() {
    setIsPartial(!isPartial);
  }
  const partOnCloseHandler = () => {
    setIsPartial(false);
  };

  const [rechargeAmount, setRechargeAmount] = useState(0);

  const [estAmount, setEstAmount] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);

  const [openPaymetModel, setOpenPaymetModel] = useState(false);
  const handleOpenPaymetModel = () => setOpenPaymetModel(true);
  const handleClosePaymetModel = () => setOpenPaymetModel(false);

  const phoneNo = useSelector((state) => state.auth.phone);

  const [partAmount, setPartAmount] = useState();
  const [isExpand, setIsExpand] = useState(false);
  const [isPartPayValid, setIsPartPayValid] = useState(true);
  const [isPartPaymentBtnDisabled, setIsPartPaymentBtnDisabled] =
    useState(true);
  const validAmount = Math.round((props.amount / 100) * 10);

  const expandHandler = () => {
    setIsExpand(!isExpand);
  };
  //For updating amount
  useEffect(() => {
    // const _totalAmount = parseFloat(props.amount) >= parseFloat(props.wallet) ? (parseFloat(props.amount) - parseFloat(props.wallet)).toFixed(2) : 0.00;
    // setRechargeAmount(_totalAmount);
    const _totalAmount = parseFloat(props.budget)
      // - parseFloat(props.discount)
      .toFixed(2);
    setRechargeAmount(_totalAmount | 0);
  }, [props.amount, props.discount]);

  //For updating reacharge amount
  useEffect(() => {
    // const _estAmount = (parseFloat(rechargeAmount) - parseFloat(props.discount)).toFixed(2);
    const _totalPayableAmount = (
      parseFloat(+rechargeAmount) + parseFloat(+props.payableTaxAmount)
    ).toFixed(2);
    // setEstAmount(_estAmount);
    setTotalPayableAmount(
      _totalPayableAmount - parseFloat(+props.discount).toFixed(2)
    );
  }, [props.discount, props.payableTaxAmount, rechargeAmount]);

  // const rechargeAmountHandler = (e) => {
  //     const _rechargeAmount = parseFloat(e.target.value).toFixed(2);
  //     setRechargeAmount(_rechargeAmount);
  // }

  const paymentHandler = () => {
    dispatch(campaignActions.setPamphletId({ pamphletId: null }));
    dispatch(campaignActions.setFooterId({ footerId: null }));
    dispatch(campaignActions.setDisplayAdId({ displayAdId: null }));
    dispatch(campaignActions.setLabelAdId({ labelAdId: null }));
    localStorage.setItem("createdCampaignId", props.createdCampaignId);
    localStorage.setItem("amountPaid", partAmount ? partAmount : props.amount);
    document.querySelector("#paymentForm").submit();
  };

  const handleApiCall = () => {
    localStorage.setItem("createdCampaignId", props.createdCampaignId);
    localStorage.setItem("amountPaid", partAmount ? partAmount : props.amount);

    document.querySelector("#partPaymentForm").submit();
  };
  return (
    <>
      {!isPartial && (
        <Modal
          open={openPaymetModel}
          onClose={handleClosePaymetModel}
          aria-labelledby="modal-pay-title"
          aria-describedby="modal-payment-description"
        >
          <Box
            className={classes.model}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: "98vw",
              minWidth: "350px",
              bgcolor: "#fff",
              boxShadow: 24,
              p: 1,
              borderRadius: "10px",
            }}
          >
            {/* <Box sx={{borderTop:'1px solid #ccc'}} p={2}>
                <Typography sx={{fontWeight:'500'}}>
                    Amount to Add
                </Typography>
                <RedditTextField
                    label="Amount"
                    defaultValue={rechargeAmount}
                    id="amount-input"
                    variant="filled"
                    fullWidth
                    style={{ marginTop: 11 }} 
                    onChange={rechargeAmountHandler}
                    type='number'
                />
            </Box> */}
            <Box p={2}>
              {/* <Typography sx={{ fontWeight: "500" }}>Amount to Pay</Typography> */}
              {/* <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Grid
                  container
                  justifyContent="right"
                  textAlign="right"
                  sx={{ maxWidth: "400px" }}
                >
                  <Grid item xs={6}>
                    Amount
                  </Grid>
                  <Grid item xs={6} sx={{ fontWeight: "600" }}>
                    ₹ {rechargeAmount}
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    Discount Amount
                  </Grid>
                  <Grid item xs={6} mt={1} sx={{ fontWeight: "600" }}>
                    ₹ {props.discount ? props.discount : 0}
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    Estimate Tax (5% Tax)
                  </Grid>
                  <Grid item xs={6} mt={1} sx={{ fontWeight: "600" }}>
                    ₹ {props.payableTaxAmount}
                  </Grid>

                  <Grid item xs={12} py={1}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    Total Amount to Pay
                  </Grid>
                  <Grid item xs={6} sx={{ fontWeight: "600" }}>
                    ₹ {props.amount}
                  </Grid>
                </Grid>
              </Box> */}
              {/* <form method='POST' action='http://192.168.0.125:8000/api/payments/initiate/'>
                
                <input type='submit' value='SUBMIT' />
            </form> */}

              <div className="new_payment_details_wrapper">
                {/* <img src={horizentalShortBar} alt="horizentalShortBar" /> */}
                <h4 className="sub_title ">Payment Details</h4>
                <HorizontalDivider
                  style={{ backgroundColor: "#81A180", height: "3px" }}
                />
                <div className="payment_img_detail_wrapper m_t_1">
                  <div className="payment_img_wrapper">
                    <img
                      width="100%"
                      src={paymentDetailsImg}
                      alt="paymentDetailsImg"
                    />
                  </div>
                  <div className="amounts_detail_wrapper">
                    <div className="payment_summary_detail_wrapper m_t_0_5">
                      <div className="sub_title_child m_r_2">Price</div>
                      <div className="text_start"> ₹ {rechargeAmount}</div>
                    </div>
                    <div className="payment_summary_detail_wrapper m_t_0_5">
                      <div className="sub_title_child m_r_2">GST (5%)</div>
                      <div className="text_start">
                        {" "}
                        ₹ {props.payableTaxAmount}
                      </div>
                    </div>
                    <div className="payment_summary_detail_wrapper m_t_0_5">
                      <div className="sub_title_child m_r_2">Discount</div>
                      <div
                        className={
                          props.discount
                            ? "green_text text_start"
                            : "text_start"
                        }
                      >
                        {" "}
                        ₹ {props.discount ? props.discount : 0}
                      </div>
                    </div>
                    <HztlBarDotted style={{ margin: "0.5rem 0rem" }} />
                    <div className="payment_summary_detail_wrapper m_t_0_5">
                      <div className="sub_title_child m_r_2">Total Amount</div>
                      <div className="text_start"> ₹ {props.amount}</div>
                    </div>
                  </div>
                </div>
              </div>
              <form
                id="paymentForm"
                method="POST"
                action={API_URL + "payments/initiate/"}
              >
                <input name="phone" type="hidden" value={phoneNo} />
                <input name="amount" type="hidden" value={+props.amount} />
                {/* <input name='amount' type='hidden' value='1' /> */}
                <div
                  className={
                    props.noSaveBtn ? classes.single_btn : "btns_wrapper"
                  }
                  style={{ marginTop: "1rem" }}
                >
                  {!props.noSaveBtn && (
                    <Button
                      type="button"
                      onClick={() => {
                        // setOpenPaymetModel(false);
                        // togglePopup();
                        dispatch(
                          campaignActions.setPamphletId({ pamphletId: null })
                        );
                        dispatch(
                          campaignActions.setFooterId({ footerId: null })
                        );
                        dispatch(
                          campaignActions.setDisplayAdId({ displayAdId: null })
                        );
                        dispatch(
                          campaignActions.setLabelAdId({ labelAdId: null })
                        );
                        history.push("/dashboard");
                      }}
                      variant=""
                      sx={{
                        // minWidth: "130px",
                        backgroundColor: "transperent",
                        color: "#3CAC4E",
                        // border: "2px solid #000",
                        borderRadius: "20px",
                        margin: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      Save & Pay Later
                    </Button>
                  )}

                  <Button
                    type="button"
                    onClick={paymentHandler}
                    variant="contained"
                    sx={{
                      // minWidth: "130px",
                      backgroundColor: "#3CAC4E",
                      borderRadius: "10px",
                      margin: "10px",
                    }}
                  >
                    {`Pay ₹${props.amount}`}
                  </Button>
                </div>
                <div
                  className="more_options_pymt_handler pointer"
                  onClick={expandHandler}
                >
                  {isExpand ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </div>
                {isExpand && (
                  <div className="pay_part_btn_wrapper">
                    <Button
                      type="button"
                      onClick={partialToggle}
                      variant=""
                      sx={{
                        minWidth: "130px",

                        backgroundColor: "transperent",
                        color: "#3CAC4E",
                        border: "2px solid #3CAC4E",
                        borderRadius: "20px",
                        margin: "10px",
                      }}
                      className="pay_part_btn"
                    >
                      Pay Partial
                    </Button>
                  </div>
                )}
              </form>
            </Box>
          </Box>
        </Modal>
      )}

      {showPopup && (
        <PopUp>
          <div className={classes.popup_wrapper}>
            <div>
              <img
                className={classes.confirm_icon}
                src={confirmIcon}
                alt="greenCheck"
              />
            </div>
            <div className={classes.created_text}>
              Campaign Created Successfully!
            </div>
            <div className={classes.created_detail_text}>
              Your campaign will start running as soon as you make the payment.
              You can review campaign & make payment from campaign list.
            </div>
            <div>
              <button
                className={classes.go_to_dashboard_btn}
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </PopUp>
      )}
      {isPartial && (
        <>
          <PopUp
            onClose={() => {
              partOnCloseHandler();
            }}
          >
            <div className="part_pymt_popup_main_div">
              <div className="f_w_700">Partial Payment </div>
              <input
                className="part_pay_input_field"
                type="number"
                name=""
                id=""
                placeholder="Enter amount"
                onChange={(e) => {
                  if (+e.target.value < +validAmount) {
                    setIsPartPayValid(false);
                    setIsPartPaymentBtnDisabled(true);
                  } else {
                    setIsPartPayValid(true);
                    setPartAmount(+e.target.value);
                    setIsPartPaymentBtnDisabled(false);
                  }
                }}
              />
              {!isPartPayValid && (
                <p className="small_error_text">
                  Minimum partial payment should be 10% of total amount
                </p>
              )}
              <div className="f_w_700 m_t_1">Note: </div>
              <div className="flex m_t_0_5">
                <span>
                  <BsDot />
                </span>
                Your campaign will be created , but it will begin once the full
                payment is completed
              </div>
              <div className="flex">
                <span>
                  <BsDot />
                </span>
                You can pay remaining amount later from the campaign details
                page
              </div>

              <form
                id="partPaymentForm"
                method="POST"
                action={API_URL + "payments/initiate/"}
              >
                <input name="phone" type="hidden" value={phoneNo} />
                <input name="amount" type="hidden" value={+partAmount} />
                {/* <input name='amount' type='hidden' value='1' /> */}
                <button
                  className="part_pay_btn m_t_1"
                  disabled={isPartPaymentBtnDisabled}
                  onClick={() => {
                    if (validAmount) {
                      handleApiCall();
                    }
                  }}
                >
                  Pay Now
                </button>
              </form>
            </div>
          </PopUp>
        </>
      )}
    </>
  );
});

export default PaymentModel;
