const fetchData = async (url, options = {}) => {
  let data = null;
  let error = null;
  let isLoading = true;

  try {
    const response = await fetch(url, options);
    const responseData = await response.json();
    data = responseData;
  } catch (err) {
    error = err;
  }

  isLoading = false;

  return [data, error, isLoading];
};

export default fetchData;
