import { greenCheck } from "../../../assets";
import "./CouponAppliedCard.css";
function CouponAppliedCard(props) {
  return (
    <>
      <div className="coupon_code_applied_main_div m_t_1">
        <div className="green_check_div">
          <img src={greenCheck} alt="greenCheck" />
        </div>
        <div className="coupon_code_info_div">
          <div>
            <span className="sub_title">{props.couponCodeFromCampaign}</span>{" "}
            applied
          </div>
          <div className="sub_title_child">-₹{props.discount} off </div>
        </div>
      </div>
    </>
  );
}
export default CouponAppliedCard;
