import {
  captivePortal,
  link,
  businessPage,
  quiz,
  socialMedia,
  images,
  video,
  coupons,
  formIcon,
  locationIcon,
  shoppingCartIcon,
} from "../../../assets/UrlTypeIcons/index";

import {
  captivePortalColor,
  linkColor,
  businessPageColor,
  quizColor,
  socialMediaColor,
  imagesColor,
  videoColor,
  couponsColor,
  locationColorIcon,
} from "../../../assets/UrlTypeColorIcons/index";

export const urlTypesDetails1 = [
  {
    id: "website",
    name: "Website",
    icon: captivePortal,
    colorIcon: captivePortalColor,
    default: true,
  },
  {
    id: "s_m_account",
    name: "Social Media",
    icon: socialMedia,
    colorIcon: socialMediaColor,
  },
  {
    id: "location",
    name: "Location",
    icon: locationIcon,
    colorIcon: locationColorIcon,
  },
  {
    id: "business_page",
    name: "Business Page",
    icon: businessPage,
    colorIcon: businessPageColor,
    comingSoon: false,
  },
];

export const urlTypesDetails2 = [
  {
    id: "multiple_links",
    name: "Multiple Links",
    icon: link,
    colorIcon: linkColor,
  },
  {
    id: "product_display",
    name: "Product Display",
    icon: images,
    colorIcon: imagesColor,
    comingSoon: true,
  },
  {
    id: "video",
    name: "Video",
    icon: video,
    colorIcon: videoColor,
    comingSoon: true,
  },

  {
    id: "coupons",
    name: "Coupons",
    icon: coupons,
    colorIcon: coupons,
    comingSoon: true,
  },
];

export const urlTypesDetails3 = [
  {
    id: "form",
    name: "Form",
    icon: formIcon,
    colorIcon: formIcon,
    comingSoon: true,
  },
  {
    id: "quiz",
    name: "Quiz",
    icon: quiz,
    colorIcon: quiz,
    comingSoon: true,
  },

  {
    id: "product_purchase",
    name: "Product Purchase",
    icon: shoppingCartIcon,
    colorIcon: shoppingCartIcon,
    comingSoon: true,
  },
];
