import { createSlice } from "@reduxjs/toolkit";

const initialToken = localStorage.getItem("token");
const initialPhone = localStorage.getItem("phone");
const initialClientId = localStorage.getItem("clientId");
const initialUserId = localStorage.getItem("userId");
const initialBusinessName = localStorage.getItem("businessName");
const initialSelectedIndustry = localStorage.getItem("selectedIndustry");

const initialState = {
  token: initialToken,
  isLoggedIn: !!initialToken,
  phone: initialPhone,
  clientId: initialClientId,
  userId: initialUserId,
  businessName: initialBusinessName,
  selectedIndustry: initialSelectedIndustry,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      localStorage.setItem("token", action.payload.token);
      state.isLoggedIn = true;
      state.phone = action.payload.phone;
      localStorage.setItem("phone", action.payload.phone);
      state.clientId = action.payload.clientId;
      localStorage.setItem("clientId", action.payload.clientId);
      state.userId = action.payload.userId;
      localStorage.setItem("userId", action.payload.userId);
      state.businessName = action.payload.userId;
      localStorage.setItem("businessName", action.payload.businessName);
      state.businessName = action.payload.selectedIndustry;
      localStorage.setItem("selectedIndustry", action.payload.selectedIndustry);
    },
    logout(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("clientId");
      localStorage.removeItem("userId");
      localStorage.removeItem("businessName");
      localStorage.removeItem("selectedIndustry");
      state.token = "";
      state.phone = "";
      state.userId = "";
      state.clientId = "";
      state.businessName = "";
      state.selectedIndustry = "";
      state.isLoggedIn = false;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
