import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import newLoginBanner from "./Frame 118.png";
import logo from "./logo.png";
import CampaignLayout from "../../components/CampaignLayout/CampaignLayout";
import Button from "../../components/UI/GreenBtn/Button";
import Input from "../../components/UI/Inputs/Input";
import { API_URL } from "../../constant/apiURL";
import { authActions } from "../../store/auth-slice";
import "./Login.css";
import CampaignHeader from "../../components/CampaignLayout/Header/CampaignHeader";

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoginPage = location.pathname === "/login";
  const isOtpVerifyPage = location.pathname === "/verify";
  const isRegistrationPage = location.pathname === "/register";

  const [errorNumber, setErrorNumber] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordError2, setPasswordError2] = useState(false);
  const [businessError, setBusinessError] = useState(false);
  const [wrongPassword, setWrongPassword] = useState();
  const [userId, setUserId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [dataId, setDataId] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const [createACCIsLoading, setCreateACCIsLoading] = useState(false);
  const [loginDisable, setLoginDisable] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  // console.log(isChecked);

  const handleUserIdChange = (e) => {
    //Allowing only number
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setUserId(e.target.value);
    setErrorNumber(e.target.value.length !== 10);
  };
  const handlePassword1Change = (e) => {
    setPassword1(e.target.value);
    setPasswordError(e.target.value === "");
    setPasswordError2(e.target.value !== password2);
  };
  const handlePassword2Change = (e) => {
    setPassword2(e.target.value);
    setPasswordError2(e.target.value !== password1);
  };
  const handleBusinessNameChange = (e) => {
    setBusinessName(e.target.value);
    setBusinessError(e.target.value === "");
  };
  const handleOtpChange = (e) => {
    setOtp(+e.target.value);
  };

  const loginHandler = (event) => {
    event.preventDefault();

    let clientId2 = "";
    let userId2 = "";
    let selectedIndustry = "";
    let businessName = "";

    setLoginIsLoading(true);
    // ye fetch karega default method GET se
    const fetchClient = (loginData) => {
      fetch(API_URL + "clients/list/")
        // return karega ek promise jisko apneko handle karna padega .then() se
        // .then me pehlese hi wo response ka object aaega  jispe hume sare operations perform karne hain
        .then((res) => {
          // ager response ok hai to age ka kaam karenge
          if (res.ok) {
            // ab ander res.json() karna padta jo ki ek naya promise return karta isko bhi apneko handle karna padega .then() se
            res
              .json()
              // is wale .then me hume data milta jo ki real data rehta hai as it is on server
              .then((data) => {
                // console.log("fetchClient", data);
                // yaha pe hume data milgaya ab jo bhi karne ka hai data ke saath operation wo hum perform karsakte
                let arr = data.filter(checkClientId);
                // console.log(arr);
                function checkClientId(client) {
                  return +client.phone === +userId;
                }
                clientId2 = arr[0].id;
                userId2 = arr[0].user;
                selectedIndustry = arr[0].industry;
                businessName = arr[0].name;

                setLoginIsLoading(false);
                dispatch(
                  authActions.login({
                    token: loginData["token"],
                    phone: userId,
                    clientId: clientId2,
                    userId: userId2,
                    selectedIndustry: selectedIndustry,
                    businessName: businessName,
                  })
                );
                history.replace("/dashboard");
              });
          }
        });
    };

    fetch(API_URL + "accounts/login/", {
      method: "POST",
      body: JSON.stringify({
        phone: userId,
        password: password1,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          console.log("Everything is OK");
          return res.json();
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Authentication Failed!";
            for (let key in data) {
              errorMsg = data[key];
            }
            throw new Error(errorMsg);
          });
        }
      })
      .then((data) => {
        // console.log("", data);
        fetchClient(data);
      })
      .catch((err) => {
        setWrongPassword(err.message);
        // alert(err.message);
        setLoginIsLoading(false);
      });
  };
  const registrationHandler = (event) => {
    event.preventDefault();
    setCreateACCIsLoading(true);
    fetch(API_URL + "accounts/register/", {
      method: "POST",
      body: JSON.stringify({
        phone: userId,
        password: password1,
        password2: password2,
        businessName: businessName,
        // customer_type: "CL",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res.status);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMsg = "Authentication Failed!";
            for (let key in data) {
              errorMsg = data[key];
            }
            throw new Error(errorMsg);
          });
        }
      })
      .then((data) => {
        setCreateACCIsLoading(false);
        // console.log("dataId " + data.data_id);
        let dataId = data.data_id;
        // console.log("form login : " + password1);
        history.push({
          pathname: "/verify",
          state: {
            userId: +userId,
            password1: password1,
            businessName: businessName,
            dataId: dataId,
          },
        });
      })
      .catch((err) => {
        alert(err.message);
        setCreateACCIsLoading(false);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setIsLoading(true);

    fetch(API_URL + "accounts/register/verify/", {
      method: "POST",
      body: JSON.stringify({
        phone: userId,
        password: password1,
        password2: password1,
        pin_code: "411048",
        name: businessName,
        otp: otp,
        data_id: dataId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        return res.json().then((data) => {
          dispatch(authActions.login({ token: data["token"], phone: userId }));
          history.replace("/dashboard");
        });
      } else {
        return res.json().then((data) => {
          // console.log(data);
          let errorMsg = "Authentication Failed!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(key + ":" + data[key]);
          }
          alert(errorMsg);
        });
      }
    });
  };

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />
      <div className="auth_page_main_div">
        <div className="login_main_container_wrapper">
          {isLoginPage && (
            <div className="logo_login_page_wrapper">
              <Link to="/">
                <img className="logo_login_page" src={logo} alt="logo"></img>
              </Link>
            </div>
          )}
          <div className=" login_main_container">
            {/* <div className="login_banner_image_container">
          <img
            className="login_banner_image"
            src={loginBanner}
            alt="loginPng"
          />
        </div> */}
            <div className="login_banner_form_container_wrapper">
              <div className="login_banner_form_container">
                <div className="loginPage__Title">
                  {isOtpVerifyPage
                    ? "OTP Verification"
                    : isLoginPage
                    ? "Sign In"
                    : isRegistrationPage
                    ? "Let’s get started"
                    : ""}
                </div>
                {!isOtpVerifyPage && (
                  <>
                    <Input
                      pattern="^[0-9]*$"
                      maxLength="10"
                      placeholder="Phone no"
                      className=""
                      onChange={handleUserIdChange}
                      value={userId ? userId : ""}
                    />
                    {errorNumber && (
                      <p style={{ color: "red" }}>
                        Phone Number must contain 10 digits
                      </p>
                    )}
                  </>
                )}
                {isOtpVerifyPage && (
                  <div className="sub_title text_center">
                    {userId}
                    {/* Enter the 6 digit OTP sent to
            {` ${userId.includes("@") ? "Email" : "Mobile No"}. ${
              userId ? userId : "jhdgfjshfjsdghb"
            }`} */}
                  </div>
                )}

                {isRegistrationPage && (
                  <>
                    <Input
                      placeholder="Business Name"
                      className=""
                      value={businessName}
                      type="text"
                      onChange={handleBusinessNameChange}
                    />
                    {businessError && (
                      <p style={{ color: "red" }}>Business Name is required </p>
                    )}
                  </>
                )}
                {(isLoginPage || isRegistrationPage) && (
                  <>
                    <Input
                      placeholder="Password"
                      className=""
                      value={password1}
                      type="password"
                      onChange={handlePassword1Change}
                    />
                    {passwordError && (
                      <p style={{ color: "red" }}>Password is required </p>
                    )}
                    {wrongPassword && (
                      <p style={{ color: "red" }}>{wrongPassword}</p>
                    )}
                  </>
                )}

                {isRegistrationPage && (
                  <>
                    <Input
                      placeholder="Confirm Password"
                      className=""
                      value={password2}
                      type="password"
                      onChange={handlePassword2Change}
                    />
                    {passwordError2 && (
                      <p style={{ color: "red" }}>Password does not match </p>
                    )}
                  </>
                )}
                {isOtpVerifyPage && (
                  <Input
                    placeholder="Enter your 6 digit OTP"
                    className=""
                    value={otp}
                    type="number"
                    onChange={handleOtpChange}
                  />
                )}

                {!isOtpVerifyPage && !isLoginPage && (
                  <>
                    <div className="terms_conditions_text">
                      <label htmlFor="agree">
                        <input
                          type="checkbox"
                          value="agree"
                          id="agree"
                          checked={isChecked}
                          onChange={(e) => {
                            setIsChecked(e.target.checked);
                          }}
                        />
                        &nbsp; I agree to the
                        <a href="/terms-and-conditions" target="_blank">
                          <span style={{ color: "green" }}>
                            &nbsp;Terms and Conditions
                          </span>
                        </a>
                      </label>
                      {/* {agreeError && (
                  <p style={{ color: "red" }}>please agree to the T & C</p>
                )} */}
                    </div>
                  </>
                )}

                {isLoginPage && (
                  <>
                    <div className="">
                      <Button
                        style={{ borderRadius: "30px" }}
                        className="Login_btn_"
                        name={loginIsLoading ? "Loading..." : "Login"}
                        onClick={(e) => {
                          if (userId.length !== 10 || password1 === "") {
                            setErrorNumber(userId.length !== 10);
                            setPasswordError(password1 === "");
                            return;
                          }

                          loginHandler(e);

                          // if (
                          //   userId.length > 9 ||
                          //   userId.endsWith("@gmail.com") ||
                          //   userId.endsWith("@yahoo.com") ||
                          //   userId.endsWith("@outlook.com")
                          // ) {
                          // } else {
                          //   // alert("Please Give a valid phone no.");
                          //   setErrorNumber(true);
                          // }
                        }}
                      />
                    </div>
                    <div
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={(e) => {
                        history.push("/forget-password");
                      }}
                    >
                      Forget Password!
                    </div>
                    <div className="w_100" style={{ padding: "8px 0" }}>
                      Don’t have an account?
                    </div>
                    <div>
                      <Link to="/register">
                        <Button
                          style={{ borderRadius: "30px" }}
                          className="createAcc_btn_"
                          btnTextClasses="createAcc_btn_text"
                          name={
                            createACCIsLoading ? "Loading..." : "Create Account"
                          }
                        ></Button>
                      </Link>
                    </div>
                  </>
                )}
                {isRegistrationPage && (
                  <>
                    <div>
                      <Button
                        style={{ borderRadius: "30px" }}
                        isChecked={isChecked}
                        className="Login_btn_"
                        name={
                          createACCIsLoading ? "Loading..." : "Create Account"
                        }
                        onClick={(e) => {
                          if (!isChecked) {
                            setAgreeError(true);
                          }

                          if (
                            userId.length !== 10 ||
                            password1 === "" ||
                            password2 === "" ||
                            businessName === ""
                          ) {
                            setErrorNumber(userId.length !== 10);
                            setPasswordError(password1 === "");
                            setPasswordError2(password1 !== password2);
                            setBusinessError(businessName === "");
                            return;
                          }
                          if (!passwordError2 && isChecked) {
                            registrationHandler(e);
                          }
                        }}
                      />
                    </div>

                    <div className="w_100" style={{ padding: "8px 0" }}>
                      Already have an account?
                    </div>
                    <div className="">
                      <Link to="/login">
                        <Button
                          style={{ borderRadius: "30px" }}
                          className="createAcc_btn_"
                          btnTextClasses="createAcc_btn_text"
                          name={loginIsLoading ? "Loading..." : "Login"}
                        />
                      </Link>
                    </div>
                  </>
                )}
                {isOtpVerifyPage && (
                  <>
                    <div>
                      <Button
                        className="Login_btn_"
                        name={isLoading ? "Loading..." : "Verify OTP"}
                        disabled={isLoading}
                        onClick={submitHandler}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="login_banner_image_container">
          <img
            className="login_banner_image"
            src={newLoginBanner}
            alt="loginPng"
          />
        </div>
      </div>
    </>
  );
};

export default Login;
