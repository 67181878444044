import { useState } from "react";
import jsPDF from "jspdf";
import "./CreateQrCode.css";
import QRCode from "react-qr-code";
import { useEffect } from "react";
import { API_URL } from "../../../constant/apiURL";
import { useDispatch, useSelector } from "react-redux";
import { campaignActions } from "../../../store/campaign-slice";
import infoSvg from "./info.svg";
import { downloadIconGreen } from "../../../assets";

const CreateQrCode = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [qrCreated, setQrCreated] = useState(false);

  // state for validation
  const [urlIsNotValid, setUrlIsNotValid] = useState(false);
  const [isEmptyUrl, setIsEmptyUrl] = useState(false);
  const [qrCreatedCounter, setQrCreatedCounter] = useState(0);
  const [prevEnteredUrl, setPrevEnteredUrl] = useState("");
  const [prevSelectedUrlType, setPrevSelectedUrlType] = useState(0);
  const [invalidUrl, setInvalidUrl] = useState(false);

  const isLiveUrl = API_URL === "https://service.ewayadz.com/api/";
  // console.log(isLiveUrl);

  // url type list
  // let qrUrlType = [
  //   { id: "video", name: "Video" },
  //   { id: "website", name: "Website" },
  //   { id: "s_m_account", name: "Social Media Account" },
  //   { id: "google_form", name: "Google Form" },
  //   { id: "other", name: "Other" },
  // ];

  const onImageDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      // console.log(downloadLink.href);
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const [formState, setFormState] = useState({
    enteredQRLink: "",
    url_type: "video",
  });

  const setData = (dataKey, dataVal) => {
    setFormState((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };
  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   setData("enteredQRLink", value);
  //   if (value.length > 0) {
  //     setIsEmptyUrl(false);
  //     props.setUrl(true);
  //   }
  //   if (value.length > 0) {
  //     setUrlIsNotValid(false);
  //   }
  //   setPrevEnteredUrl(formState.enteredQRLink);
  //   if (value.length === 0) {
  //     setQrCreated(false);
  //     props.setUrl(false);
  //   }
  // };

  // const callQrCreate = (formData) => {
  //   fetch(API_URL + "campaign/qr-code-create/", {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Token ${token}`,
  //     },
  //     body: formData,
  //   })
  //     .then(async (res) => {
  //       if (res.ok) {
  //         setInvalidUrl(true);
  //         const data = await res.json();
  //         // dispatch(campaignActions.setQrCodeId({ qrCodeId: data.id }));
  //         if (props.campaignId === "") {
  //           props.submitQrCodeCampaign(data.id);
  //         }
  //       } else {
  //         setQrCreated(false);
  //         setInvalidUrl(true);
  //         const data = res.json();
  //         let errorMsg = "Something went wrong!";
  //         for (let key in data) {
  //           errorMsg = data[key];
  //         }
  //         throw new Error(errorMsg);
  //       }
  //     })
  //     .catch((err) => {
  //       // alert(err.message);
  //     });
  // };
  // useEffect(() => {

  //   const svg = document.getElementById("QRCode");
  //   const svgData = new XMLSerializer().serializeToString(svg);
  //   const svgBlob = new Blob([svgData], { type: "image/svg+xml" });
  //   const file = new File([svgBlob], "qrcode.svg", { type: "image/svg+xml" });
  //   props.setQrFile(file);
  // }, [props.campaignId]);

  useEffect(() => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const blob = dataURLtoBlob(pngFile);
      const file = new File([blob], "qrcode.png", { type: "image/png" });
      props.setQrFile(file);
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }, [props.campaignId]);

  // Function to convert data URL to Blob
  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  return (
    <>
      <div className=" ">
        <div className=" ">
          {/* <div className="create_qr_code_heading heading m_t_2">
            Create QR Code
          </div> */}
          {/* <span style={{ marginBottom: "1rem" }} className="sub_title_child">
            (optional)
          </span> */}
          {/* <div style={{ marginTop: "2rem" }} className="radio_input_div1">
            <label className="sub_title" htmlFor="html">
              Enter URL
            </label>
            <br></br>
            <div>
              You Can Enter URL Of Your Website, Social media ,Lead Form,
              WhatsApp etc. This Link Will Open When Scanned.
            </div>
            <div>Example - https://www.abc.com</div>
            <div className="qr_inputs_wrapper">
              <select
                name=""
                id=""
                className="qr_url_type_dropdown"
                onChange={(e) => {
                  setPrevSelectedUrlType(formState.url_type);
                  setData("url_type", e.target.value);
                }}
              >
                {qrUrlType.map((type) => {
                  return (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
              <input
                onChange={handleChange}
                placeholder="Enter URL to Create QR Code"
                type="text"
                className="url_input"
              />
              {isEmptyUrl && (
                <p
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginBottom: "0rem",
                  }}
                >
                  Please Enter URL First
                </p>
              )}
              {props.isCheckedUrlEmpty && (
                <p
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginBottom: "0rem",
                  }}
                >
                  Please Enter URL First
                </p>
              )}
              {/* {invalidUrl && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  Invalid URL
                  <span style={{ color: "black" }}>
                    - Must Contain https://www.url.com
                  </span>
                </p>
              )} */}

          {/* <button
                style={{ width: "100%", marginTop: "1rem" }}
                className="url_btn_clicked"
                onClick={() => {
                  if (formState.enteredQRLink === "") {
                    setIsEmptyUrl(true);
                  }
                  if (formState.enteredQRLink.length > 0 && !isEmptyUrl) {
                    props.setQrCreate(true);
                    setQrCreated(true);
                    setQrCreatedCounter((state) => state + 1);
                  }
                }}
              >
                Create
              </button>
            </div> */}

          {/* {urlIsNotValid && (
              <p style={{ color: "red" }}>Please Enter Valid URL</p>
            )}
          </div> */}
          {/* {props.campaignId  && ( */}
          <div className="">
            <div className="qr_download_btn_wrapper">
              <div className="">
                <div className="">
                  {/* 192.168.0.136:3000 */}
                  {/* ewayprint.com */}
                  <QRCode
                    size={140}
                    id="QRCode"
                    title="Open"
                    value={
                      isLiveUrl
                        ? "https://ads.ewayprint.com/ads/client-site/" +
                          props.campaignId
                        : "http://192.168.0.51:3000/ads/client-site/" +
                          props.campaignId
                    }
                  />
                </div>
              </div>
              <div>
                <div>
                  <div className="sub_title" style={{ textAlign: "center" }}>
                    {props.selectedCardName === null
                      ? "Website"
                      : props.selectedCardName}
                    &nbsp; QR Code
                  </div>
                  <button
                    className="download_qr_code"
                    onClick={onImageDownload}
                  >
                    <span>
                      <img src={downloadIconGreen} alt="downloadIconGreen" />
                    </span>
                    &nbsp; Download QR Code
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};
export default CreateQrCode;
