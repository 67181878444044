import { Chip, Grid } from "@mui/material";
import { padding } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  deletedIcon,
  deleteIcon,
  arrowBack,
  infoIcon,
  downloadIcon,
  visibilityBlackIcon,
  checkCircle,
  deleteIconRed,
  arrowLeftNew,
} from "../../../assets";
import previewIcon from "./share.png";
import { API_URL } from "../../../constant/apiURL";
import { fetchCampaignData } from "../../../store/campaign-slice";
import HorizontalDivider from "../../UI/Dividers/HorizontalDivider";
import "./CampaignListDetail.css";
import Button from "../../UI/GreenBtn/Button";
import { useRef } from "react";
import PaymentModel from "../../Accounts/PaymentModel";
import PopUp from "../../UI/PopUp/PopUp";
import ButtonNew from "../../UI/ButtonNew/ButtonNew";
import CouponAppliedCard from "../../UI/CouponAppliedCard/CouponAppliedCard";

let isInitial = true;
const CampaignListDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const createdCampaignId = params.campaignId;
  const [campaign, setCampaign] = useState(null);

  const [pamphlet, setPamphlet] = useState(null);

  const isDuplex = campaign?.pamphlet_id?.is_duplex;
  const [isLoading, setIsLoading] = useState(false);
  const [noSaveBtn, setNoSaveBtn] = useState(true);
  const [isAttachFile, setIsAttachFile] = useState(false);
  const [pamphletFile1, setPamphletFile1] = useState("");
  const [pamphletFile2, setPamphletFile2] = useState("");
  const [newFtrFile, setNewFtrFile] = useState("");
  const [newDisplFile, setNewDisplFile] = useState("");
  const [newLblAdFile, setNewLblAdFile] = useState("");
  const [fileUpdatedToggle, setFileUpdatedToggle] = useState(false);

  const [frontIsPDFFile, setFrontIsPDFFile] = useState(false);
  const [backIsPDFFile, setBackIsPDFFile] = useState(false);
  const [footerIsPDFFile, setFooterIsPDFFile] = useState(false);
  const [dsplyIsPDFFile, setDsplyPDFFile] = useState(false);
  const [lblIsPDFFile, setLblIsPDFFile] = useState(false);

  const attachFileHandler = () => {
    setIsAttachFile(true);
  };

  // converting date to dd/mm/yy format
  const creationDate = campaign?.created_on.substring(0, 10);
  const date = new Date(creationDate);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
  const year = date.getFullYear().toString(); // Get the last two digits of the year
  // Create the formatted date string
  const formattedDate = `${day}/${month}/${year}`;

  const qrCodeId = campaign?.qr_code_id?.id;
  const qrCodeType = campaign?.qr_code_id?.url_type;
  const qrCodeFile = campaign?.qr_code_id?.file;

  let qrType =
    (qrCodeType === "s_m_account" && "Social Media Account") ||
    (qrCodeType === "website" && "Website") ||
    (qrCodeType === "location" && "Location") ||
    (qrCodeType === "business_page" && "Business Page") ||
    (qrCodeType === "multiple_links" && "Multiple Links");

  let displayAdSize =
    campaign?.display_ad_id?.screen_size === "l1" && "Large Screen";

  let labelAdSize = campaign?.label_ad_id?.size === "1" && "Standard Size";

  // const [campaignStatus, setCampaignStatus] = useState();

  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const token = useSelector((state) => state.auth.token);
  const clientId = useSelector((state) => state.auth.clientId);

  const overAllData = useSelector((state) => state.auth.overAllData);

  const tax = 5;
  const selectedBudget = campaign?.budget_amount;
  const amountWithoutCoupon = +campaign?.amount;
  let payableTax;
  let finalTotalAmountPayable =
    Math.floor(amountWithoutCoupon * tax * 0.01) + amountWithoutCoupon;

  const totalAmount = useSelector(
    (state) => state?.campaigns?.overAllData?.totalAmount
  );

  const dispatch = useDispatch();
  const paymentModalRef = useRef();
  const submitHandler = (event) => {
    // event.preventDefault();

    // if (+totalAmountToPay > 0) {

    paymentModalRef.current.openModal();
    // } else {
    //   // createCampaign(false);
    //   deductAmount(campaignId);
    // }
  };

  // Coupon Code
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [coupon, setCoupon] = useState("");
  const [couponId, setCouponId] = useState();
  // console.log(couponId);

  const [couponError, setCouponError] = useState(false);
  const [couponMsg, setCouponMsg] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const isCouponApplicable =
    campaign?.coupon?.coupon_code === "ABCDE" ? true : false;
  const couponCodeFromCampaign = campaign?.coupon?.coupon_code;

  if (!isCouponApplied) {
    payableTax = amountWithoutCoupon * tax * 0.01;
  } else {
    payableTax = (amountWithoutCoupon - discount) * tax * 0.01;
  }
  let total = amountWithoutCoupon - discount + payableTax;
  const remainingPayment = total - campaign?.amount_paid;
  // Delete PopUp
  const [showPopup, setShowPopup] = useState(false);
  function togglePopup() {
    setShowPopup(!showPopup);
  }
  const [showImgPopup1, setShowImgPopup1] = useState(false);
  function togglePopupImg1() {
    setShowImgPopup1(!showImgPopup1);
  }

  const [showImgPopup2, setShowImgPopup2] = useState(false);
  function togglePopupImg2() {
    setShowImgPopup2(!showImgPopup2);
  }

  const [showImgPopup3, setShowImgPopup3] = useState(false);
  function togglePopupImg3() {
    setShowImgPopup3(!showImgPopup3);
  }

  const [showImgPopup4, setShowImgPopup4] = useState(false);
  function togglePopupImg4() {
    setShowImgPopup4(!showImgPopup4);
  }

  const [showImgPopup5, setShowImgPopup5] = useState(false);
  function togglePopupImg5() {
    setShowImgPopup5(!showImgPopup5);
  }

  const backDropHandler = () => {
    if (
      showImgPopup1 ||
      showImgPopup2 ||
      showImgPopup3 ||
      showImgPopup4 ||
      showImgPopup5
    ) {
      setShowImgPopup4(false);
      setShowImgPopup3(false);
      setShowImgPopup2(false);
      setShowImgPopup1(false);
      setShowImgPopup5(false);
    }
  };

  // Qr Code File Download
  const handleDownload = async () => {
    try {
      // Replace the URL with the actual file URL
      const fileURL = qrCodeFile;
      const response = await fetch(fileURL);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element and trigger a click to download the file
      const link = document.createElement("a");
      link.href = url;
      link.download = "qrcode.png"; // Specify the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const [deletedPopUp, setDeletedPopUp] = useState(false);
  function togglePopUp2() {
    setDeletedPopUp(!deletedPopUp);
  }

  const updatePamphlet = () => {
    const formData = new FormData();
    formData.append("pamphlet_file1", pamphletFile1 ? pamphletFile1 : "");
    if (pamphlet?.is_duplex) {
      formData.append("pamphlet_file2", pamphletFile2 ? pamphletFile2 : "");
    }
    formData.append("pamphlet_type", "UP");
    formData.append("client", clientId);
    formData.append("is_duplex", isDuplex);

    fetch(
      `${API_URL}campaign/pamphlet-update/${pamphlet?.id && pamphlet?.id}/`,
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setFileUpdatedToggle(true);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";

            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const updateFooter = () => {
    const formData = new FormData();
    formData.append("footer_file", newFtrFile ? newFtrFile : "");
    formData.append("client", clientId);
    fetch(`${API_URL}campaign/footer-update/${campaign?.footer_id?.id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setFileUpdatedToggle(true);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const updateDisplayAd = () => {
    const formData = new FormData();
    formData.append("display_ad_file", newDisplFile ? newDisplFile : "");
    formData.append("client", clientId);
    fetch(
      `${API_URL}campaign/display-ad-update/${campaign?.display_ad_id?.id}/`,
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setFileUpdatedToggle(true);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // update label ad

  const updateLabelAd = () => {
    const formData = new FormData();
    formData.append("file", newLblAdFile ? newLblAdFile : "");
    formData.append("client", clientId);
    fetch(`${API_URL}campaign/label-ad-update/${campaign?.label_ad_id?.id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
            setFileUpdatedToggle(true);
          });
        } else {
          return res.json().then((data) => {
            // console.log(data);
            let errorMsg = "Something went wrong!";
            for (let key in data) {
              errorMsg = data[key];
              // console.log(`${key}: ${errorMsg}`);
            }
            throw new Error(errorMsg);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    const _campaign = campaigns.find(
      (campaign) => campaign.id === parseInt(createdCampaignId)
    );

    if (_campaign) {
      setCampaign(_campaign);
    } else if (isInitial) {
      isInitial = false;
      dispatch(fetchCampaignData(token));
    }
    if (_campaign && _campaign?.pamphlet_id?.id) {
      (async () => {
        const resPam = await fetch(
          API_URL + `campaign/pamphlet-detail/${_campaign.pamphlet_id?.id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        const dataPam = await resPam.json();
        setPamphlet(dataPam);
      })();
    }
  }, [
    campaigns,
    dispatch,
    token,
    createdCampaignId,
    pamphletFile1,
    pamphletFile2,
  ]);
  // console.log(campaign?.id);

  // DELETE request using fetch with async/await
  const deletePost = () => {
    fetch(API_URL + `campaign/delete/${campaign?.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then(async (res) => {
        // setIsLoading(false);

        if (res.ok) {
          const data = await JSON.parse(res);
          // console.log(data);

          // history.push("/ads/pamphlet-list");
        } else {
          const data_1 = await res.json();
          // console.log(data_1);
          let errorMsg = "Something went wrong!";
          for (let key in data_1) {
            errorMsg = data_1[key];
            console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  };

  const applyCoupon = async () => {
    // console.log(coupon)
    const formData = new FormData();
    formData.append("coupon_code", coupon?.toUpperCase());
    // formData.append("quantity", roundedQty);
    const res = await fetch(API_URL + "campaign/apply-coupen-code/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    });
    // console.log(res);
    if (res.ok) {
      const data = await res.json();
      // console.log(res);
      setCouponId(data.id);
      if (data.lumpsum_discount === 0) {
        const discountPercent = data.discount;

        const discountAmount = (
          (amountWithoutCoupon * discountPercent) /
          100
        ).toFixed(0);
        setDiscount(discountAmount);
      } else {
        if (amountWithoutCoupon > data.lumpsum_discount) {
          setDiscount(data.lumpsum_discount);
        }
      }
    }
    if (res.ok) {
      setCouponMsg("Coupon Code Applied Successfully");
      setIsCouponApplied(true);
      setCouponError(false);
    } else if (!res.ok) {
      setCouponError(true);
      setIsCouponApplied(false);
      setCouponMsg("Coupon Code Invalid");
    }
    // console.log(couponMsg);
  };

  const updateCampaignApi = () => {
    const formData = new FormData();
    if (isCouponApplied) {
      formData.append("coupon", couponId);
    } else {
      formData.append("coupon", 1);
    }

    formData.append("amount", selectedBudget - discount);
    fetch(API_URL + `campaign/update/${campaign?.id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },

      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            // console.log(data);
          });
        } else {
          const data = res.json();

          let errorMsg = "Something went wrong!";
          for (let key in data) {
            errorMsg = data[key];
            console.log(`${key}: ${errorMsg}`);
          }
          throw new Error(errorMsg);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // campaign status
  let campaignStatus;
  if (campaign?.is_paid) {
    campaignStatus = "Paid";
    if (campaign?.is_approved) {
      campaignStatus = "Pending";
    } else {
      campaignStatus = "Paid";
    }
    if (campaign?.is_approved && campaign?.is_active) {
      campaignStatus = "Running";
    }
    if (campaign?.is_approved && !campaign?.is_active) {
      campaignStatus = "Paused";
    }
  } else {
    campaignStatus = "Not Paid";
  }

  let paymentStatus;
  if (campaign?.is_paid) {
    paymentStatus = "Paid";
  } else if (campaign?.is_part_paid) {
    paymentStatus = "Partially Paid";
  } else {
    paymentStatus = "Not Paid";
  }
  // files of adtypes
  // pamphlet File
  const file1 = campaign?.pamphlet_id?.pamphlet_file1;
  const fileName1 = file1?.slice(59);
  const file2 = campaign?.pamphlet_id?.pamphlet_file2;
  const fileName2 = file2?.slice(59);

  // footer file
  const footerFile = campaign?.footer_id?.footer_file;
  const footerFileName = footerFile?.slice(57);

  const displayAdFile = campaign?.display_ad_id?.display_ad_file;
  const displayAdFileName = displayAdFile?.slice(61);

  const labelAdFile = campaign?.label_ad_id?.file;
  const labelAdFileName = labelAdFile?.slice(57);

  useEffect(() => {
    if (file1) {
      if (file1?.endsWith(".pdf")) {
        setFrontIsPDFFile(true);
      }
    }
    if (file2) {
      if (file2.endsWith(".pdf")) {
        setBackIsPDFFile(true);
      }
    }
    if (footerFile) {
      if (footerFile.endsWith(".pdf")) {
        setFooterIsPDFFile(true);
      }
    }
    if (displayAdFile) {
      if (displayAdFile.endsWith(".pdf")) {
        setDsplyPDFFile(true);
      }
    }
    if (labelAdFile) {
      if (labelAdFile.endsWith(".pdf")) {
        setLblIsPDFFile(true);
      }
    }
  }, [file1, file2, footerFile, displayAdFile, labelAdFile]);

  return (
    <>
      <div
        className="flex_grow_1 dashboard__main_div"
        onClick={backDropHandler}
      >
        <div className="campaign_list_detail_main_page_div">
          <div className="back_delete_btn_wrapper">
            <div
              className="campaign_list_back_button pointer"
              onClick={() => history.goBack()}
            >
              <span>
                <img
                  src={arrowLeftNew}
                  alt="arrowLeftNew"
                  className="arrow_back"
                ></img>
              </span>
              Back
            </div>

            {/* {!campaign?.is_paid && (
              <button
                className="delete_btn"
                onClick={() => {
                  togglePopup();
                  // deletePost();
                  // history.push("/ads/campaign-list");
                }}
              >
                <span style={{ marginTop: "-3px" }}>
                  <img src={deleteIconRed} alt="deleteIconRed" />
                </span>
                &nbsp; Delete Campaign
              </button>
            )} */}
          </div>
          <div className="campaign_payment_detail_wrapper">
            <div className="campaign_detail_table_main_div container">
              <div className="campaign_detail_table_header">
                <div>
                  <div
                    style={{ fontSize: "24px" }}
                    className="truncate campaign_name_title sub_title"
                  >
                    {campaign?.name}
                  </div>
                  <div style={{ fontSize: "16px" }} className="  ">
                    Created on : {formattedDate}
                  </div>
                  <div className="campaign_status_wrapper m_t_2">
                    <div className="" style={{ fontWeight: "600" }}>
                      Campaign status : &nbsp;&nbsp;
                    </div>
                    <div
                      style={{
                        color: "#fff",
                        padding: "0px 12px",
                        borderRadius: "15px",
                        backgroundColor:
                          campaign?.status === "Not Paid"
                            ? "red"
                            : "" || campaign?.status === "Pending"
                            ? "orange"
                            : "" || campaign?.status === "Paused"
                            ? "purple"
                            : "" || campaign?.status === "Running"
                            ? "#31b963"
                            : "" || campaign?.status === "Finished"
                            ? "blue"
                            : "" || campaign?.status === "Partially Paid"
                            ? "#e59400"
                            : "",
                      }}
                    >
                      {/* &nbsp; &nbsp; {campaignStatus} */}
                      {campaign?.status}
                    </div>
                  </div>
                  {qrCodeId && (
                    <div className="qrCode_content_wrapper m_t_2">
                      <div className="m_r_2 f_w_500">
                        QR Code :{" "}
                        <span
                          style={{
                            color: "rgba(24, 119, 242, 1)",
                          }}
                          onClick={handleDownload}
                        >
                          Download &nbsp;
                          <img src={downloadIcon} alt="downloadIcon" />
                        </span>
                      </div>

                      <div style={{ fontWeight: "500" }} className="">
                        QR Code Type : &nbsp; <span>{qrType}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="pamphlet_detail_columns">
                  <div className="first_div1">
                    <div className="f_s_16 f_w_500">Ad Files :</div>

                    <div className="file_icon_wrapper ">
                      <>
                        {!file1 && campaign?.pamphlet_id?.id && (
                          <div className="ad_file_input_wrapper m_t_0_5">
                            <div className="sub_title_child">Pamphlet File</div>
                            <input
                              className="attach_file_campaign_details"
                              type="file"
                              name=""
                              id=""
                              onChange={(e) => {
                                setPamphletFile1(e.target.files[0]);
                              }}
                            />
                          </div>
                        )}
                      </>

                      {pamphlet?.is_duplex &&
                        !file2 &&
                        campaign?.pamphlet_id?.id && (
                          <div className="ad_file_input_wrapper m_t_0_5">
                            <div className="sub_title_child">
                              Pamphlet File 2
                            </div>
                            <input
                              className="attach_file_campaign_details"
                              type="file"
                              name=""
                              id=""
                              onChange={(e) => {
                                setPamphletFile2(e.target.files[0]);
                              }}
                            />
                          </div>
                        )}
                      {!footerFile && campaign?.footer_id?.id && (
                        <div className="ad_file_input_wrapper m_t_0_5">
                          <div className="sub_title_child">Footer File</div>
                          <input
                            className="attach_file_campaign_details"
                            type="file"
                            name=""
                            id=""
                            onChange={(e) => {
                              setNewFtrFile(e.target.files[0]);
                            }}
                          />
                        </div>
                      )}

                      {!displayAdFile && campaign?.display_ad_id?.id && (
                        <div className="ad_file_input_wrapper m_t_0_5">
                          <div className="sub_title_child">Display Ad File</div>
                          <input
                            className="attach_file_campaign_details"
                            type="file"
                            name=""
                            id=""
                            onChange={(e) => {
                              setNewDisplFile(e.target.files[0]);
                            }}
                          />
                        </div>
                      )}
                      {!labelAdFile && campaign?.label_ad_id?.id && (
                        <div className="ad_file_input_wrapper m_t_0_5">
                          <div className="sub_title_child">Label Ad File</div>
                          <input
                            className="attach_file_campaign_details"
                            type="file"
                            name=""
                            id=""
                            onChange={(e) => {
                              setNewLblAdFile(e.target.files[0]);
                            }}
                          />
                        </div>
                      )}
                      {((campaign?.pamphlet_id?.id && !file1) ||
                        (campaign?.footer_id?.id && !footerFile) ||
                        (campaign?.display_ad_id?.id && !displayAdFile) ||
                        (campaign?.label_ad_id?.id && !labelAdFile)) && (
                        <div className="cancel_update_btn_wrapper">
                          {/* <ButtonNew
              className="new_back_btn"
              name="Cancel"
              onClick={() => {
                setIsAttachFile(false);
                setPamphletFile1("");
                setPamphletFile2("");
              }}
            /> */}
                          <ButtonNew
                            className="file_update_btn"
                            name="Update Files"
                            onClick={() => {
                              if (
                                !isDuplex &&
                                pamphletFile1 !== "" &&
                                !file1 &&
                                campaign?.pamphlet_id?.id
                              ) {
                                updatePamphlet();
                              }
                              if (
                                isDuplex &&
                                pamphletFile1 !== "" &&
                                pamphletFile2 !== "" &&
                                !file1 &&
                                !file2 &&
                                campaign?.pamphlet_id?.id
                              ) {
                                updatePamphlet();
                              }

                              if (
                                newFtrFile !== "" &&
                                !footerFile &&
                                campaign?.footer_id?.id
                              ) {
                                updateFooter();
                              }
                              if (
                                newDisplFile !== "" &&
                                !displayAdFile &&
                                campaign?.display_ad_id?.id
                              ) {
                                updateDisplayAd();
                              }
                              if (
                                newLblAdFile !== "" &&
                                !labelAdFile &&
                                campaign?.label_ad_id?.id
                              ) {
                                updateLabelAd();
                              }
                            }}
                          />
                        </div>
                      )}
                      {fileName1 && (
                        <>
                          <div className="file_icon_sub_wrapper">
                            <div className="pamphlet_text_view_file_icon_wrapper">
                              <div className="f_s_16 f_w_400 ">
                                Pamphlet File :&nbsp;
                                <span onClick={togglePopupImg1}>
                                  <img
                                    src={visibilityBlackIcon}
                                    alt="visibilityIcon"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {fileName2 && (
                        <>
                          <div className="file_icon_sub_wrapper">
                            <div className="pamphlet_text_view_file_icon_wrapper">
                              <div className="f_s_16 f_w_400 ">
                                Pamphlet File 2 :&nbsp;
                                <span onClick={togglePopupImg2}>
                                  <img
                                    src={visibilityBlackIcon}
                                    alt="visibilityIcon"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {footerFileName && (
                        <>
                          <div className="file_icon_sub_wrapper">
                            <div className="pamphlet_text_view_file_icon_wrapper">
                              <div className="f_s_16 f_w_400 ">
                                Footer :&nbsp;
                                <span onClick={togglePopupImg3}>
                                  <img
                                    src={visibilityBlackIcon}
                                    alt="visibilityIcon"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {displayAdFile && (
                        <>
                          <div className="file_icon_sub_wrapper">
                            <div className="pamphlet_text_view_file_icon_wrapper">
                              <div className="f_s_16 f_w_400 ">
                                DisplayAd :&nbsp;
                                <span onClick={togglePopupImg4}>
                                  <img
                                    src={visibilityBlackIcon}
                                    alt="visibilityIcon"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {labelAdFile && (
                        <>
                          <div className="file_icon_sub_wrapper">
                            <div className="pamphlet_text_view_file_icon_wrapper">
                              <div className="f_s_16 f_w_400">
                                Front Page :&nbsp;
                                <span onClick={togglePopupImg5}>
                                  <img
                                    src={visibilityBlackIcon}
                                    alt="visibilityIcon"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <table className="table ">
                  <thead>
                    <tr className="t_h_row">
                      <th className="th">Ad Type</th>
                      <th className="th">Ad Details</th>
                      <th className="th">Ad Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaign?.pamphlet_id && (
                      <tr>
                        <td className="td sub_title_child">Pamphlet</td>
                        <td className="td sub_title_child">
                          {campaign?.pamphlet_id?.size}{" "}
                          {isDuplex ? "Double Side" : "Single Side"}
                        </td>
                        <td className="td sub_title_child">
                          {campaign?.qty_for_pamphlet}
                        </td>
                      </tr>
                    )}
                    {campaign?.footer_id && (
                      <tr>
                        <td className="td sub_title_child">Footer</td>
                        <td className="td sub_title_child">
                          {campaign?.footer_id?.height}
                        </td>
                        <td className="td sub_title_child">
                          {campaign?.qty_for_footer}
                        </td>
                      </tr>
                    )}
                    {campaign?.display_ad_id && (
                      <tr>
                        <td className="td sub_title_child">Display Ad</td>
                        <td className="td sub_title_child">{displayAdSize}</td>
                        <td className="td sub_title_child">
                          {campaign?.qty_for_display_ad}
                        </td>
                      </tr>
                    )}
                    {campaign?.label_ad_id && (
                      <tr>
                        <td className="td sub_title_child">Front Page</td>
                        <td className="td sub_title_child">{labelAdSize}</td>
                        <td className="td sub_title_child">
                          {campaign?.qty_for_label_ad}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="payment_details_pay_btn_wrapper">
                <div className="payment_detail_table_main_div">
                  <div className=" f_w_700">Payment Summary :</div>
                  <div className="campaign_detail_table_content_text">
                    <div
                      style={{ fontWeight: "500" }}
                      className="payment_list_firstChild "
                    >
                      Amount
                    </div>
                    <div className="">
                      ₹{" "}
                      {!isCouponApplicable && selectedBudget
                        ? selectedBudget
                        : amountWithoutCoupon}
                    </div>
                  </div>

                  {(isCouponApplied || !isCouponApplicable) &&
                    selectedBudget && (
                      <div className="campaign_detail_table_content_text">
                        <div
                          style={{ fontWeight: "500" }}
                          className="payment_list_firstChild "
                        >
                          Discount
                        </div>
                        <div className="">
                          ₹
                          {!isCouponApplicable
                            ? selectedBudget - amountWithoutCoupon
                            : discount}
                        </div>
                      </div>
                    )}

                  <div className="campaign_detail_table_content_text">
                    <div
                      style={{ fontWeight: "500" }}
                      className="payment_list_firstChild "
                    >
                      tax
                    </div>
                    <div className="">₹{payableTax.toFixed(2)}</div>
                  </div>
                  <hr />
                  <div className="campaign_detail_table_content_text">
                    <div
                      style={{ fontWeight: "500" }}
                      className="payment_list_firstChild "
                    >
                      Total
                    </div>
                    <div className="">₹{total.toFixed(2)}</div>
                  </div>
                  <hr />
                  {campaign?.is_part_paid && (
                    <div className="campaign_detail_table_content_text">
                      <div
                        style={{ fontWeight: "500" }}
                        className="payment_list_firstChild "
                      >
                        Partial Payment
                      </div>
                      <div className="">₹{campaign?.amount_paid}</div>
                    </div>
                  )}
                  {campaign?.is_part_paid && (
                    <div className="campaign_detail_table_content_text">
                      <div
                        style={{ fontWeight: "500" }}
                        className="payment_list_firstChild "
                      >
                        Remaining Payment
                      </div>
                      <div className="">₹{remainingPayment.toFixed(2)}</div>
                    </div>
                  )}
                  <div className="campaign_detail_table_content_text">
                    <div
                      style={{ fontWeight: "500" }}
                      className="payment_list_firstChild "
                    >
                      Payment Status
                    </div>
                    <div
                      className={`f_w_500 ${paymentStatus === "Paid" && "paid"}
                        ${
                          paymentStatus === "Partially Paid" && "partially_paid"
                        }
                        ${paymentStatus === "Not Paid" && "not_paid"}`}
                    >
                      {paymentStatus}
                    </div>
                  </div>
                </div>
                <div className="coupon_pay_btn_wrapper">
                  {!campaign?.is_paid && isCouponApplicable && (
                    <div className="p_1">
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        &nbsp; I have a coupon code
                      </label>
                    </div>
                  )}

                  {isChecked && (
                    <>
                      <div className="coupon_input_btn_wrapper m_l_1 m_t_1">
                        <div className="custom_input_wrapper">
                          <input
                            className="ctm_bgt_input "
                            type="text"
                            value={props.coupon}
                            onChange={(e) => {
                              setCoupon(e.target.value);
                            }}
                          />
                        </div>
                        <div>
                          <button
                            className="cpn_code_apply_btn"
                            onClick={() => {
                              if (isCouponApplied) {
                                setDiscount(0);
                                setCoupon("");
                                setIsCouponApplied(false);
                                // updateCampaignApi();
                              } else {
                                applyCoupon();
                              }
                            }}
                          >
                            {isCouponApplied ? "Remove" : "Apply"}
                          </button>
                        </div>
                      </div>

                      {isCouponApplied && (
                        <div
                          className="m_l_1"
                          style={{
                            color: "#43ba56",
                            fontWeight: "500",
                            marginTop: "0.5rem",
                          }}
                        >
                          {couponMsg}
                        </div>
                      )}

                      {couponError && (
                        <div
                          style={{
                            color: "red",
                            fontWeight: "600",
                            marginTop: "0.5rem",
                            marginLeft: "1rem",
                          }}
                        >
                          Invalid Coupon Code
                        </div>
                      )}
                    </>
                  )}
                  {!isCouponApplicable && selectedBudget && (
                    <CouponAppliedCard
                      couponCodeFromCampaign={couponCodeFromCampaign}
                      discount={selectedBudget - amountWithoutCoupon}
                    />
                  )}

                  {!campaign?.is_paid && (
                    <div style={{ padding: "1rem" }}>
                      <button
                        className="pay_btn f_w_700"
                        onClick={() => {
                          submitHandler();
                          // history.push("/ads/campaign/create/new/review");
                          if (isCouponApplicable && isCouponApplied) {
                            updateCampaignApi();
                          }
                        }}
                      >
                        {/* {`Pay ₹${totalAmountToPay}`} */}
                        Process Payment
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* {!campaign?.is_paid && (
                <div className="pay_delete_btn_wrapper">
                  {campaign?.is_paid === false && !campaign?.is_part_paid && (
                    <div className="campaign_not_working_wrapper m_t_1">
                      <div className="campaign_not_working_text">
                        <span className="info_icon_div">
                          <img src={infoIcon} alt="infoIcon" />
                        </span>
                        Your campaign is currently not running, Kindly make
                        payment to start your campaign
                      </div>
                    </div>
                  )}
                  {campaign?.is_part_paid && (
                    <div className="campaign_not_working_wrapper m_t_1">
                      <div className="make_payment_text_msg">
                        <span className="info_icon_div">
                          <img src={infoIcon} alt="infoIcon" />
                        </span>
                        Your campaign is not running, Kindly make full payment
                        to start your campaign
                      </div>
                    </div>
                  )}
                </div>
              )} */}
            </div>
          </div>
          <Grid item>
            <PaymentModel
              noSaveBtn={noSaveBtn}
              budget={
                !isCouponApplicable ? selectedBudget : amountWithoutCoupon
              }
              payableTaxAmount={payableTax.toFixed(2)}
              ref={paymentModalRef}
              amount={remainingPayment.toFixed(2)}
              discount={
                !isCouponApplicable && selectedBudget
                  ? selectedBudget - amountWithoutCoupon
                  : discount
              }
              createdCampaignId={campaign?.id}
              // createCampaign={createCampaign}
              isCreated={false}
              campaignId={null}
            />
          </Grid>
          {showPopup && (
            <PopUp>
              <div className="delete_confirmation_wrapper">
                <div className="delete_confirmation_sub_wrapper">
                  <div className="delete_icon_wrapper">
                    <img src={deleteIcon} alt="deleteIcon" />
                  </div>
                  <div
                    style={{ textAlign: "center", fontWeight: "700" }}
                    className="sub_title_child"
                  >
                    Are You Sure You Want to Delete This Campaign?
                  </div>
                </div>
                <div className="cancel_delete_btn_wrapper">
                  <div>
                    <button
                      className="popup_delete_btn"
                      onClick={() => {
                        deletePost();
                        setShowPopup(false);
                        togglePopUp2();
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  <div>
                    <button className="popup_cancel_btn" onClick={togglePopup}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </PopUp>
          )}
          {deletedPopUp && (
            <>
              <PopUp>
                <>
                  <div className="campaign_deleted_wrapper">
                    <div className="deleted_icon_text">
                      <div className="deleted_icon">
                        <img src={deletedIcon} alt="deletedIcon" />
                      </div>
                      <div className="campaign_deleted_text">
                        Campaign Deleted!
                      </div>
                    </div>
                    <div className="deleted_btn_wrapper">
                      <button
                        className="deleted_btn"
                        onClick={() => {
                          history.replace("/campaign-list");

                          // window.location.href = "/ads/campaign-list";
                        }}
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                </>
              </PopUp>
            </>
          )}
          {showImgPopup1 && file1 && (
            <PopUp filePreview={true}>
              <>
                {!frontIsPDFFile ? (
                  <div className="pamhlet_file_preview_img_div">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      className="obj_fit_contain"
                      src={file1}
                      alt="productDisplayPreview"
                    />
                  </div>
                ) : (
                  <div className="pamhlet_file_preview_img_div">
                    <a
                      className="previewLink"
                      href={file1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className=""
                        src={previewIcon}
                        alt="previewIcon"
                        style={{ margin: "auto", width: "150px" }}
                      ></img>
                      <span style={{ marginTop: "20px" }}>Show Preview</span>
                    </a>
                  </div>
                )}
              </>
            </PopUp>
          )}
          {showImgPopup2 && file2 && (
            <PopUp filePreview={true}>
              <>
                {!backIsPDFFile ? (
                  <div className="pamhlet_file_preview_img_div">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      className="obj_fit_contain"
                      src={file2}
                      alt="productDisplayPreview"
                    />
                  </div>
                ) : (
                  <div className="pamhlet_file_preview_img_div">
                    <a
                      className="previewLink"
                      href={file2}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className=""
                        src={previewIcon}
                        alt="previewIcon"
                        style={{ margin: "auto", width: "150px" }}
                      ></img>
                      <span style={{ marginTop: "20px" }}>Show Preview</span>
                    </a>
                  </div>
                )}
              </>
            </PopUp>
          )}

          {showImgPopup3 && footerFile && (
            <PopUp filePreview={true}>
              <>
                {!footerIsPDFFile ? (
                  <div className="pamhlet_file_preview_img_div">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      className="obj_fit_contain"
                      src={footerFile}
                      alt="productDisplayPreview"
                    />
                  </div>
                ) : (
                  <div className="pamhlet_file_preview_img_div">
                    <a
                      className="previewLink"
                      href={footerFile}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className=""
                        src={previewIcon}
                        alt="previewIcon"
                        style={{ margin: "auto", width: "150px" }}
                      ></img>
                      <span style={{ marginTop: "20px" }}>Show Preview</span>
                    </a>
                  </div>
                )}
              </>
            </PopUp>
          )}

          {showImgPopup4 && displayAdFile && (
            <PopUp filePreview={true}>
              <>
                {!dsplyIsPDFFile ? (
                  <div className="pamhlet_file_preview_img_div">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      className="obj_fit_contain"
                      src={displayAdFile}
                      alt="productDisplayPreview"
                    />
                  </div>
                ) : (
                  <div className="pamhlet_file_preview_img_div">
                    <a
                      className="previewLink"
                      href={displayAdFile}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className=""
                        src={previewIcon}
                        alt="previewIcon"
                        style={{ margin: "auto", width: "150px" }}
                      ></img>
                      <span style={{ marginTop: "20px" }}>Show Preview</span>
                    </a>
                  </div>
                )}
              </>
            </PopUp>
          )}
          {showImgPopup5 && labelAdFile && (
            <PopUp filePreview={true}>
              <>
                {!lblIsPDFFile ? (
                  <div className="pamhlet_file_preview_img_div">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      className="obj_fit_contain"
                      src={labelAdFile}
                      alt="productDisplayPreview"
                    />
                  </div>
                ) : (
                  <div className="pamhlet_file_preview_img_div">
                    <a
                      className="previewLink"
                      href={labelAdFile}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className=""
                        src={previewIcon}
                        alt="previewIcon"
                        style={{ margin: "auto", width: "150px" }}
                      ></img>
                      <span style={{ marginTop: "20px" }}>Show Preview</span>
                    </a>
                  </div>
                )}
              </>
            </PopUp>
          )}
          {fileUpdatedToggle && (
            <>
              <PopUp>
                <>
                  <div className="campaign_deleted_wrapper">
                    <div className="deleted_icon_text">
                      <div className="deleted_icon">
                        <img src={checkCircle} alt="deletedIcon" />
                      </div>
                      <div className="campaign_deleted_text">
                        Campaign Updated!
                      </div>
                    </div>
                    <div className="deleted_btn_wrapper">
                      <button
                        className="deleted_btn"
                        onClick={() => {
                          history.replace("/campaign-list");

                          // window.location.href = "/ads/campaign-list";
                        }}
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                </>
              </PopUp>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CampaignListDetail;
