import React from "react";
import logo from "./logo.png";
import "./DashboardHeader.css";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../UI/GreenBtn/Button";
import { useSelector } from "react-redux";
import { menu, profile } from "../../../assets";
import { useState } from "react";
import { useEffect } from "react";
import { API_URL } from "../../../constant/apiURL";

const DashboardHeader = (props) => {
  const location = useLocation();
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  // const phoneNo = localStorage.getItem("phone");
  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const reduxSelectedCampaignId = useSelector(
    (state) => state.campaigns.selectedCampaign
  );

  // const businessName = useSelector((state) => state.auth.businessName);
  const [businessName, setBusinessName] = useState("");

  const phone = useSelector((state) => state.auth.phone);

  const derivedSelectedCampaign = campaigns.filter(
    (campaign) => campaign.id === reduxSelectedCampaignId
  );
  const selectedCampaign = derivedSelectedCampaign[0];
  // console.log(selectedCampaign);
  const isDashboardPage =
    location.pathname === "/dashboard" ||
    location.pathname === "/ads" ||
    location.pathname === "/ads/";
  const isCampaignListPage = location.pathname === "/campaign-list";
  const isPamphletListListPage = location.pathname === "/pamphlet-list";

  useEffect(() => {
    fetch(API_URL + "accounts/profile/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setBusinessName(data.name);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [token]);
  return (
    <>
      <div className="dashboard-header-compo ">
        <div className="header_ham_menu" onClick={props.toggleSideBar}>
          <img src={menu} alt="menu.svg" />
        </div>
        <div>
          <img className="logo" src={logo} alt="logo"></img>
        </div>
        <div className="title dashboard-nav-text">
          {isDashboardPage ? "Dashboard" : ""}

          {isCampaignListPage && "Campaign List"}
          {isPamphletListListPage && "Pamphlet List"}
        </div>
        <div
          style={{ marginRight: "2rem" }}
          className="dashboard-campaign-create-btn"
        >
          <button
            className="create_campaign_btn"
            onClick={() => {
              if (campaigns.length > 0) {
                history.push("/dashboard/business-plans-page");
              } else {
                history.push("/dashboard/business-plans-page");
              }
            }}
          >
            + Create Campaign
          </button>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
