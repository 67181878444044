import { useState } from "react";
import "./CheckBoxPrePrinted.css";
function CheckBoxPrePrinted(props) {
  const [isChecked, setIsChecked] = useState(false);

  // Handle checkbox change events
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div
        className={
          isChecked
            ? "pre_printed_checkbox_checked_main_div"
            : "pre_printed_checkbox_main_div"
        }
      >
        <div className="pre_printed_checkbox_sub_div_div">
          <div className="pre_printed_checkbox">
            <label className="sub_title_child f_s_15 custom-checkbox">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />{" "}
              &nbsp; I want to distribute my pre-
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;printed pamphlets
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
export default CheckBoxPrePrinted;
