import { Ellipse, check, storeFront } from "../../../assets";
import "./PlanSelectionCard.css";
const PlanSelectionCard = (props) => {
  return (
    <>
      <div
        className={
          props.isSelected
            ? "plan_selection_card_selected_main_div"
            : "plan_selection_card_main_div"
        }
        onClick={props.onClick}
      >
        <div className="plan_selection_card_sub_div">
          <div className="plan_selection_card_icon_wrapper">
            <img src={props.dataArray?.[0].logo} alt="storeFront" />
          </div>
          <div className="plan_name_wrapper">
            <div className="sub_title">{props.planName}</div>
          </div>
          <div className="benefits_list_wrapper">
            {props.dataArray.map((element, i) => {
              return (
                <div key={i} className="icon_text_wrapper">
                  <img
                    className="benefits_list_icon_wrapper"
                    src={element.icon}
                    alt="Ellipse"
                  />

                  <div className="benefits_text_wrapper">{element.text}</div>
                </div>
              );
            })}
          </div>
          <div className="select_goal_wrapper">
            <button
              className={
                props.isSelected ? "selected_goal_btn" : "select_goal_btn"
              }
            >
              {props.isSelected && !props.comingSoon ? (
                <img className="selected_goal_btn" src={check} alt="check" />
              ) : props.comingSoon ? (
                "Coming Soon"
              ) : (
                props.btnName
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default PlanSelectionCard;
