import { useState } from "react";
import "./CampaignReview.css";
import CreateQrCode from "../../NewFlowOfCampaign/CreateQrCode/CreateQrCode";
import { API_URL } from "../../../constant/apiURL";
import { useSelector } from "react-redux";
import CampaignModal from "../../../components/CampaignComponents/CampaignModal/CampaignModal";
import { overViewIcon } from "../../../assets";
const CampaignReview = (props) => {
  const token = useSelector((state) => state.auth.token);

  const [isChecked, setIsChecked] = useState(false);

  let totalAmountWithDiscount = props.budget - props.discount;

  const tax = 5;
  let payableTaxAmount = Math.floor(+totalAmountWithDiscount * tax * 0.01);
  // let totalAmountToPay = Math.floor(+props.budget + +payableTaxAmount);

  let total = props.budget - props.discount + payableTaxAmount;

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  let distributionDate = props.campaignDetails?.startOn;

  const date = new Date(distributionDate);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear().toString();

  let convertedDate = `${day}/${month}/${year}`;

  let displayAdSize = props.displayAdSize === "l1" && "Large Screen";
  let labelAdSize = props.labelAdSize === "1" && "Large Size";
  return (
    <>
      <div className="stepper_input_main_div_review">
        <div className="campaign_review_sub_div_1">
          <h4 className="m_b_2">
            <span>
              <img width="35px" src={overViewIcon} alt="overViewIcon" />
            </span>
            &nbsp; Campaign Overview
          </h4>
          <div className="plan_details_div m_t_1">
            <div className="sub_title_child m_r_2">Campaign Name :</div>
            <div className="sub_title_child">
              {props.campaignDetails.campaignName}
            </div>
          </div>
          <div className="plan_details_div">
            <div className="sub_title_child m_r_2">
              Distribution Start Date :
            </div>
            <div className="sub_title_child">{convertedDate}</div>
          </div>
          <table className="table m_t_1">
            <thead>
              <tr className="t_h_row">
                <th className="th">Ad Type</th>
                <th className="th">Ad Details</th>
                <th className="th">Ad Qty</th>
              </tr>
            </thead>
            <tbody>
              {props.pamphletId && (
                <tr>
                  <td className="td sub_title_child">Pamphlet</td>
                  <td className="td sub_title_child">
                    {props.sizeOfPamphlet} {props.side}
                  </td>
                  <td className="td sub_title_child">{props.pamphQty}</td>
                </tr>
              )}
              {props.footerId && (
                <tr>
                  <td className="td sub_title_child">Footer</td>
                  <td className="td sub_title_child">{props.ftrSize}</td>
                  <td className="td sub_title_child">{props.ftrQty}</td>
                </tr>
              )}
              {props.displayAdId && (
                <tr>
                  <td className="td sub_title_child">Display Ad</td>
                  <td className="td sub_title_child">{displayAdSize}</td>
                  <td className="td sub_title_child">{props.dispQty}</td>
                </tr>
              )}
              {props.labelAdId && (
                <tr>
                  <td className="td sub_title_child">Front Page</td>
                  <td className="td sub_title_child">{labelAdSize}</td>
                  <td className="td sub_title_child">{props.labelQty}</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* <div className="plan_details_div">
            <div className="sub_title_child m_r_2">Industry</div>
            <div>{props.campaignDetails.industry}</div>
          </div> */}
          {/* {(props.pamphletFile1 || props.footerFile || props.displayAdFile) &&
            props.selectedOption === "yes" && (
              <div className="plan_details_text m_t_1">Ad Types Files</div>
            )}
          {props.pamphletFile1 && props.selectedOption === "yes" && (
            <>
              <div className="plan_details_div">
                <div className="sub_title_child m_r_2">Pamphlet File </div>
                <div className="ad_type_file_name">
                  {props.pamphletFile1?.name}
                </div>
              </div>
              {props.isDuplex && (
                <div className="plan_details_div">
                  <div className="sub_title_child m_r_2">Pamphlet File 2</div>
                  <div className="ad_type_file_name">
                    {props.pamphletFile2?.name}
                  </div>
                </div>
              )}
            </>
          )}
          {props.footerFile && props.selectedOption === "yes" && (
            <div className="plan_details_div">
              <div className="sub_title_child m_r_2">Footer File</div>
              <div className="ad_type_file_name">{props.footerFile?.name}</div>
            </div>
          )}
          {props.displayAdFile && props.selectedOption === "yes" && (
            <div className="plan_details_div">
              <div className="sub_title_child m_r_2">Display Ad File</div>
              <div className="ad_type_file_name">
                {props.displayAdFile?.name}
              </div>
            </div>
          )}
          {props.labelAdFile && props.selectedOption === "yes" && (
            <div className="plan_details_div">
              <div className="sub_title_child m_r_2">Label Ad File</div>
              <div className="ad_type_file_name">{props.labelAdFile?.name}</div>
            </div>
          )}
          {props.voucherFile && props.selectedOption === "yes" && (
            <div className="plan_details_div">
              <div className="sub_title_child m_r_2">Voucher File</div>
              <div className="ad_type_file_name">{props.labelAdFile?.name}</div>
            </div>
          )} */}
        </div>
        <div className="campaign_review_sub_div_2">
          <div className="plan_details_div">
            <div className="sub_title m_r_2">Budget</div>
            <div className="sub_title text_underline">{props.budget}/-</div>
          </div>
          <div className="payment_summary_sub_div m_t_2">
            <h5>Payment Summary :</h5>

            <div className="payment_summary_detail_wrapper m_t_1">
              <div className="sub_title_child m_r_2">Price</div>
              <div className="sub_title_child">₹{props.budget}</div>
            </div>

            {props.isCouponApplied && (
              <div className="payment_summary_detail_wrapper m_t_1">
                <div className="sub_title_child m_r_2">Coupon Discount</div>
                <div className="green_text sub_title_child">
                  ₹{props.discount}
                </div>
              </div>
            )}

            <div className="payment_summary_detail_wrapper m_t_1">
              <div className="sub_title_child m_r_2">GST (5%)</div>
              <div className="sub_title_child">₹{payableTaxAmount}</div>
            </div>
            <div className="m_t_1">
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                &nbsp; I have a coupon code
              </label>
            </div>

            {isChecked && (
              <>
                <div className="coupon_input_btn_wrapper">
                  <div className="custom_input_wrapper">
                    <input
                      className="ctm_bgt_input"
                      type="text"
                      value={props.coupon}
                      onChange={(e) => {
                        props.setCoupon(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      className="cpn_code_apply_btn"
                      onClick={() => {
                        if (props.isCouponApplied) {
                          props.setDiscount(0);
                          props.setCoupon("");
                          props.setIsCouponApplied(false);
                        } else {
                          props.applyCoupon();
                        }
                      }}
                    >
                      {props.isCouponApplied ? "Remove coupon" : "Apply Coupon"}
                    </button>
                  </div>
                </div>

                {props.isCouponApplied && (
                  <div
                    style={{
                      color: "#43ba56",
                      fontWeight: "600",
                      marginTop: "0.5rem",
                    }}
                  >
                    {props.couponMsg}
                  </div>
                )}

                {props.couponError && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "600",
                      marginTop: "0.5rem",
                    }}
                  >
                    Invalid Coupon Code
                  </div>
                )}
              </>
            )}
            <hr />
            <div className="payment_summary_detail_wrapper m_t_1">
              <div className="sub_title_child m_r_2">Total</div>
              <div className="sub_title_child">₹{total}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CampaignReview;
