import { useState } from "react";
import BudgetCard from "../../../../components/UI/BugetCard/BudgetCard";
import CustomBudgetCard from "../../../../components/UI/CustomBudgetCard/CustomBudgetCard";

const QuantityInCards = (props) => {
  const [minQtyError, setMinQtyError] = useState(false);
  const [maxQtyReached, setMaxQtyReached] = useState(false);
  return (
    <>
      <div>
        <br></br>
        <input
          style={{ marginRight: "1rem" }}
          type="radio"
          id="recommended plan"
          name="fav_language"
          value="recommended plan"
          onClick={() => {
            props.setSelectedPlan("recommended plan");
          }}
        />
        <label
          className="sub_title"
          htmlFor="recommended plan"
          onClick={() => {
            props.setSelectedPlan("recommended plan");
          }}
        >
          Recommended Plan
        </label>
        <BudgetCard
          Quantity={props.recommendedPlanQty}
          price={props.recommendedPlanPrice}
          side={props.pamphletSide}
          size={props.pamphletSize}
        />

        <br></br>

        <input
          style={{ marginRight: "1rem" }}
          type="radio"
          id="custom quantity"
          name="fav_language"
          value="custom quantity"
          onClick={() => {
            props.setSelectedPlan("custom quantity");
          }}
        />
        <label
          className="sub_title"
          htmlFor="custom quantity"
          onClick={() => {
            props.setSelectedPlan("custom quantity");
          }}
        >
          Custom Quantity
        </label>
        <CustomBudgetCard
          setPrice={(e) => {
            if (e.target.value < 1000) {
              setMinQtyError(true);
            }
            if (e.target.value >= 1000) {
              setMinQtyError(false);
            }
            if (e.target.value > 50000) {
              setMaxQtyReached(true);
            } else {
              setMaxQtyReached(false);
            }
            props.setEnteredQuantity(e.target.value);
          }}
          side={props.pamphletSide}
          size={props.pamphletSize}
          minQty={1000}
          maxQty={50000}
          qtyAlert={minQtyError}
          price={props.customAmountCard}
          maxQtyReached={maxQtyReached}
        />
      </div>
    </>
  );
};
export default QuantityInCards;
