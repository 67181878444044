import React from "react";
import { useState } from "react";

import down from "./down.png";
import "./SelectDropdown.css";

const SelectDropdown = (props) => {
  const classes = `${
    props.className !== undefined ? props.className : " "
  } w_100`;

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("Select Industry");
  const handleClick = () => {
    setOpen((open) => !open);
  };
  const handleSelectClick = (item) => {
    setSelected(item);
  };

  return (
    <div className={classes}>
      <span className="Eway_input_compo_text" style={{ marginBottom: "8px" }}>
        {props.label ? props.label : ""}
      </span>
      <div
        name="cars"
        onClick={handleClick}
        style={{ border: "1px solid #E1E3E6" }}
        className={`select_dropdown pointer ${open ? "is_Selected" : ""}`}
      >
        <div className="selected_value_div">{selected}</div>
        <img className={`${open ? "rotate_180" : ""}`} src={down} alt="down" />
        {open && (
          <div className="select_modal">
            {props.optionsArray.map((item) => {
              return (
                <li
                  value={item.name}
                  onClick={() => {
                    handleSelectClick(item.name);
                    props.getSelected(item.id);
                  }}
                  key={item.id}
                  className="pointer sun_title_child"
                >
                  <span>{item.name}</span>
                </li>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectDropdown;
