import { Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { campaignList, campaignListActive, trash } from "../../../assets";
import { API_URL } from "../../../constant/apiURL";
import CampaignModal from "../../CampaignComponents/CampaignModal/CampaignModal";
import DashBoardCardWrapper from "../../Dashboard/DashBoardCardWrapper";
import SideBarButton from "../../DashboardLayout/DashboardHeader/SideBarButtons/SideBarButton";
import Button from "../../UI/GreenBtn/Button";
import "./PamphletList.css";
import "../../../index.css";

const IndividualPamphletCard = (props) => {
  const token = useSelector((state) => state.auth.token);
  const history = useHistory();
  const fileName = getFIleName(props.pamphlet.pamphlet_file1);
  const [isLoading, setIsLoading] = useState(false);
  // /api/campaign/pamphlet-delete/<id_number>/

  function getFIleName(fullStr) {
    return fullStr.substring(57, fullStr.length);
  }
  // const deletePamphlet = (pamphlet_id) => {
  //   setIsLoading(true);
  //   fetch(`${API_URL}campaign/pamphlet-delete/${pamphlet_id}/`, {
  //     method: "DELETE",
  //     headers: {
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then(async (res) => {
  //       // setIsLoading(false);

  //       if (res.ok) {
  //         const data = await JSON.parse(res);
  //         // console.log(data);
  //         setIsLoading(false);
  //         //Created Successfully
  //         // history.push("/ads/pamphlet-list");
  //       } else {
  //         const data_1 = await res.json();
  //         // console.log(data_1);
  //         let errorMsg = "Something went wrong!";
  //         for (let key in data_1) {
  //           errorMsg = data_1[key];
  //           console.log(`${key}: ${errorMsg}`);
  //         }
  //         throw new Error(errorMsg);
  //       }
  //     })
  //     .catch((err) => {
  //       alert(err.message);
  //     });
  // };
  return (
    <div className="pamphlet_card_flex">
      <div className=" pamphlet_list_img_flex">
        <img
          className="w_100 elevate_10px"
          src={props.img}
          alt="pamphlet_image"
          // backImg={campaignListActive}
        />
      </div>
      <div
        style={{ margin: "11px", width: "42%" }}
        className="pamphlet__child_2"
      >
        <div className="w_100">
          <div className="pamphlet_title_text">File Name : </div>
          <div className="w_80 pamphlet_sub_title truncate">
            {fileName.substring(2, fileName.length)}
          </div>
        </div>
        <div>
          <div className="pamphlet_title_text">Side : </div>
          <div className="pamphlet_sub_title ">{props.side}</div>
        </div>
        <div className="pamphlet_title_text">
          Start campaign with this pamphlet
        </div>
        <div className="pamphlet__btns_grp">
          <div>
            <Button
              name=" + Start Campaign"
              btnTextClasses="pamphletList__body_text"
              style={{ height: "40px" }}
              onClick={() => {
                history.push({
                  pathname: "/ads/campaign/create/new",
                  state: { ...props.pamphlet },
                });
              }}
            />
          </div>
          {/* for deleting pamphlet */}
          {/* <div>
            <SideBarButton
              btnName={isLoading ? "Deleting..." : "Delete"}
              btnTextClasses="pamphletList__body_text"
              className="pamphlet_delete_btn"
              btnImg={trash}
              onClick={() => deletePamphlet(props.pamphlet_id)}
              style={{ height: "40px", border: "2px solid black" }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

const PamphletList = (props) => {
  const [pamphletList, setPamphletList] = useState([]);
  const [isPamphletLoading, setIsPamphletIsLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    if (token) {
      (async () => {
        setIsPamphletIsLoading(true);
        // Fetching Pamphlet List
        const resPam = await fetch(API_URL + "campaign/pamphlet-list/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const dataPam = await resPam.json();

        setPamphletList(dataPam);
        setIsPamphletIsLoading(false);
      })();
    }
  }, [token]);
  useEffect(() => {
    props.setSelectedDashPage(2);
  }, []);
  // console.log(pamphletList);
  return (
    <div className=" pamphletList__main_div ">
      {props.isVisible && (
        <>
          {/* <Grid item align="right" xs={12} sx={{ height: "fit-content" }}>
            <Button
              className="add_pamphlet_btn"
              btnTextClasses="add_pamphlet_btn_text"
              name=" + Add New Pamphlet"
            />
          </Grid> */}
          <div className="w_100 pamphlet_list_grp">
            {pamphletList.map((pamphlet) => (
              <div className=" card_12px elevate_16px" key={pamphlet.id}>
                <DashBoardCardWrapper style={{ backgroundColor: "white" }}>
                  {isPamphletLoading && (
                    <p style={{ textAlign: "center", color: "black" }}>
                      Loading...
                    </p>
                  )}
                  <IndividualPamphletCard
                    img={pamphlet.pamphlet_file1}
                    side={pamphlet.is_duplex ? "Double Side" : "Single Side"}
                    pamphlet_id={pamphlet.id}
                    pamphlet={pamphlet}
                  />
                </DashBoardCardWrapper>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PamphletList;
