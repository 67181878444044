import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import UrlTypeCard from "../../../components/UI/UrlTypeCard/UrlTypeCard";
import "./QrCodeCreater.css";

import { useState } from "react";
import {
  urlTypesDetails1,
  urlTypesDetails2,
  urlTypesDetails3,
} from "./constant";
import WebsiteDetails from "./DetailComponents/WebsiteDetails/WebsiteDetails";
import { useDispatch, useSelector } from "react-redux";

// Url Types preview
import {
  businessPagePreviewImg,
  multiUrlPreviewImg,
  productDisplayPreview,
  socialMediaPreview,
  videoPreviewImg,
  websitePreview,
  LocationPreviewImg,
} from "../../../assets/PreviewImages";
import MultipleUrlDetails from "./DetailComponents/MultipleUrlDetails/MultipleUrlDetails";
import BusinessPageDetails from "./DetailComponents/BusinessPageDetails/BusinessPageDetails";
import SocialMediaDetails from "./DetailComponents/SocialMediaDetails/SocialMediaDetails";
import VideoDetails from "./DetailComponents/VideoDetails/VideoDetails";
import ProductDisplay from "./DetailComponents/ProductDisplay/ProductDisplay";
import BackNextBtn from "./BackNextBtn/BackNextBtn";
import { campaignActions } from "../../../store/campaign-slice";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import PopUp from "../../../components/UI/PopUp/PopUp";
import { API_URL } from "../../../constant/apiURL";
import LocationDetails from "./DetailComponents/LocationDetails/LocationDetails";

// material ui
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { visibilityIcon } from "../../../assets";
import { useEffect } from "react";

const QrCodeCreater = (props) => {
  // Getting width of screen
  const screenWidth = window.innerWidth;

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardName, setSelectedCardName] = useState(null);

  useEffect(() => {
    props.setSelectedCardName(selectedCardName);
  }, [selectedCardName]);

  // Data of url types
  const websiteDetails = useSelector((state) => state.campaigns.websiteDetails);
  const [multipleUrlDetails, setMultipleUrlDetails] = useState(null);
  const [businessPageDetails, setBusinessPageDetails] = useState(null);
  const socialMediaDetails = useSelector(
    (state) => state.campaigns.socialMediaDetails
  );
  const videoDetails = useSelector((state) => state.campaigns.videoDetails);
  const locationDetails = useSelector(
    (state) => state.campaigns.locationDetails
  );

  const [productDisplayDetails, setProductDisplayDetails] = useState(null);

  const handleCardClick = (cardId) => {
    setSelectedCard(cardId);
  };

  // Validation Error Messages

  // for url type website
  const [isWebsiteUrlEmpty, setIsWebsiteUrlEmpty] = useState(false);

  // for url type multiple_links
  const [
    isMultipleLinksBusinessNameEmpty,
    setIsMultipleLinksBusinessNameEmpty,
  ] = useState(false);
  const [isMultipleLinksLogoEmpty, setIsMultipleLinksLogoEmpty] =
    useState(false);
  const [isMultipleLinksUrlsEmpty, setIsMultipleLinksUrlsEmpty] =
    useState(false);

  // for business page
  const [isBusinessPageBusinessNameEmpty, setIsBusinessPageBusinessNameEmpty] =
    useState(false);
  const [isBusinessPageImgEmpty, setIsBusinessPageImgEmpty] = useState(false);
  const [isBusinessPageDescriptionEmpty, setIsBusinessPageDescriptionEmpty] =
    useState(false);
  const [isBusinessPagePhoneNoEmpty, setIsBusinessPagePhoneNoEmpty] =
    useState(false);
  const [isBusinessPageStoreAddressEmpty, setIsBusinessPageStoreAddressEmpty] =
    useState(false);

  // for social media page
  const [isSocialMediaBusinessNameEmpty, setIsSocialMediaBusinessNameEmpty] =
    useState(false);
  const [isSocialMediaUrlsEmpty, setIsSocialMediaUrlsEmpty] = useState(false);

  // for video page
  const [isVideoPageBusinessNameEmpty, setIsVideoPageBusinessNameEmpty] =
    useState(false);
  const [isVideoPageDescriptionEmpty, setIsVideoPageDescriptionEmpty] =
    useState(false);
  const [isVideoPageVideoTitleEmpty, setIsVideoPageVideoTitleEmpty] =
    useState(false);
  const [isVideoPageVideoUrlEmpty, setIsVideoPageVideoUrlEmpty] =
    useState(false);

  // for location
  const [isLocationUrlEmpty, setIsLocationUrlEmpty] = useState(false);

  // for product display
  const [isProductDetailsEmpty, setIsProductDetailsEmpty] = useState(false);

  // validation states for url
  const [isUrlValid, setIsUrlValid] = useState(true);

  //popup
  const [showPopup, setShowPopup] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);
  function togglePopup() {
    setShowPopup(!showPopup);
  }

  const qrCodeCreateApi = () => {
    // data to send in api
    const dataToSend =
      ((selectedCard === null || selectedCard === "website") &&
        websiteDetails) ||
      (selectedCard === "multiple_links" && multipleUrlDetails) ||
      (selectedCard === "business_page" && businessPageDetails) ||
      (selectedCard === "s_m_account" && socialMediaDetails) ||
      (selectedCard === "video" && videoDetails) ||
      (selectedCard === "product_display" && productDisplayDetails) ||
      (selectedCard === "location" && locationDetails);

    const qrCodeCreateFormData = new FormData();
    qrCodeCreateFormData.append("urls_id", JSON.stringify(dataToSend));
    qrCodeCreateFormData.append("size", props.codeOfPamph);
    qrCodeCreateFormData.append(
      "url_type",
      selectedCard === null ? "website" : selectedCard
    );
    qrCodeCreateFormData.append(
      "business_images",
      (selectedCard === "multiple_links" && multipleUrlDetails?.logo) ||
        (selectedCard === "business_page" &&
          businessPageDetails?.imgOfBusinessPage) ||
        (selectedCard === "product_display" &&
          productDisplayDetails?.productImg) ||
        ""
    );
    // qrCodeCreateFormData.append("want_retarget", isChecked);

    fetch(API_URL + "campaign/qr-code-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: qrCodeCreateFormData,
    }).then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          const parseData = JSON.parse(data.urls_id);
          dispatch(campaignActions.setQrCodeId({ qrCodeId: data.id }));

          props.setQrCodeId2(data.id);
          props.setIsQrVisible(false);
          props.setQrCodeCreatedCard(true);
          props.submitCampaignCreate(data.id);
          // console.log(data.id);
        });
      }
    });
  };

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />

      <div className="qr_creater_main_div">
        <div className="qr_creater_sub_div">
          <div
            className={
              screenWidth > 900 ? "url_types_div" : "url_types_div_for_mobile"
            }
          >
            <div
              className={
                screenWidth > 900
                  ? "url_types_heading"
                  : "url_types_heading_for_mobile"
              }
            >
              Choose a QR Code Type
            </div>
            {screenWidth < 900 && (
              <div className="accordian_wrapper">
                <Accordion>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div className="url_type_cards_for_mobile">
                        {urlTypesDetails1.map((card, index) => {
                          return (
                            <UrlTypeCard
                              key={card.id}
                              onClick={() => {
                                handleCardClick(card.id);
                                setSelectedCardName(card.name);
                              }}
                              cardId={card.id}
                              selectedCard={selectedCard}
                              name={card.name}
                              icon={card.icon}
                              colorIcon={card.colorIcon}
                              default={card.default}
                            />
                          );
                        })}
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="url_type_cards_for_mobile">
                      {urlTypesDetails2.map((card, index) => {
                        return (
                          <UrlTypeCard
                            key={card.id}
                            onClick={() => {
                              handleCardClick(card.id);
                              setSelectedCardName(card.name);
                            }}
                            cardId={card.id}
                            selectedCard={selectedCard}
                            name={card.name}
                            icon={card.icon}
                            colorIcon={card.colorIcon}
                            comingSoon={card.comingSoon}
                          />
                        );
                      })}
                    </div>
                    <div className="url_type_cards_for_mobile">
                      {urlTypesDetails3.map((card, index) => {
                        return (
                          <UrlTypeCard
                            key={card.id}
                            onClick={() => {
                              handleCardClick(card.id);
                              setSelectedCardName(card.name);
                            }}
                            cardId={card.id}
                            selectedCard={selectedCard}
                            name={card.name}
                            icon={card.icon}
                            colorIcon={card.colorIcon}
                            comingSoon={card.comingSoon}
                          />
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            {screenWidth > 900 && (
              <div className="url_type_cards">
                {urlTypesDetails1.map((card, index) => {
                  return (
                    <UrlTypeCard
                      key={card.id}
                      onClick={() => {
                        handleCardClick(card.id);
                        setSelectedCardName(card.name);
                      }}
                      cardId={card.id}
                      selectedCard={selectedCard}
                      name={card.name}
                      icon={card.icon}
                      colorIcon={card.colorIcon}
                      default={card.default}
                    />
                  );
                })}
              </div>
            )}
            {screenWidth > 900 && (
              <div className="url_type_cards2">
                {urlTypesDetails2.map((card, index) => {
                  return (
                    <UrlTypeCard
                      key={card.id}
                      onClick={() => {
                        handleCardClick(card.id);
                        setSelectedCardName(card.name);
                      }}
                      cardId={card.id}
                      selectedCard={selectedCard}
                      name={card.name}
                      icon={card.icon}
                      colorIcon={card.colorIcon}
                      comingSoon={card.comingSoon}
                    />
                  );
                })}
              </div>
            )}
            {screenWidth > 900 && (
              <div className="url_type_cards3">
                {urlTypesDetails3.map((card, index) => {
                  return (
                    <UrlTypeCard
                      key={card.id}
                      onClick={() => {
                        handleCardClick(card.id);
                        setSelectedCardName(card.name);
                      }}
                      cardId={card.id}
                      selectedCard={selectedCard}
                      name={card.name}
                      icon={card.icon}
                      colorIcon={card.colorIcon}
                      comingSoon={card.comingSoon}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className="url_details_div">
            {screenWidth < 1200 && (
              <div className="show_preview_btn_wrapper" onClick={togglePopup}>
                <div className="show_preview_btn">
                  <span>
                    <img src={visibilityIcon} alt="" />
                  </span>
                  &nbsp; Show Preview
                </div>
              </div>
            )}
            {(selectedCard === "website" || selectedCard === null) && (
              <WebsiteDetails
                isWebsiteUrlEmpty={isWebsiteUrlEmpty}
                setIsUrlValid={setIsUrlValid}
              />
            )}
            {selectedCard === "multiple_links" && (
              <MultipleUrlDetails
                setMultipleUrlDetails={setMultipleUrlDetails}
                isMultipleLinksBusinessNameEmpty={
                  isMultipleLinksBusinessNameEmpty
                }
                isMultipleLinksLogoEmpty={isMultipleLinksLogoEmpty}
                isMultipleLinksUrlsEmpty={isMultipleLinksUrlsEmpty}
                setIsUrlValid={setIsUrlValid}
              />
            )}
            {selectedCard === "business_page" && (
              <BusinessPageDetails
                setBusinessPageDetails={setBusinessPageDetails}
                isBusinessPageBusinessNameEmpty={
                  isBusinessPageBusinessNameEmpty
                }
                isBusinessPageImgEmpty={isBusinessPageImgEmpty}
                isBusinessPageDescriptionEmpty={isBusinessPageDescriptionEmpty}
                isBusinessPagePhoneNoEmpty={isBusinessPagePhoneNoEmpty}
                isBusinessPageStoreAddressEmpty={
                  isBusinessPageStoreAddressEmpty
                }
                setIsUrlValid={setIsUrlValid}
              />
            )}
            {selectedCard === "s_m_account" && (
              <SocialMediaDetails
                isSocialMediaBusinessNameEmpty={isSocialMediaBusinessNameEmpty}
                isSocialMediaUrlsEmpty={isSocialMediaUrlsEmpty}
                setIsUrlValid={setIsUrlValid}
              />
            )}

            {selectedCard === "video" && (
              <VideoDetails
                isVideoPageBusinessNameEmpty={isVideoPageBusinessNameEmpty}
                isVideoPageDescriptionEmpty={isVideoPageDescriptionEmpty}
                isVideoPageVideoTitleEmpty={isVideoPageVideoTitleEmpty}
                isVideoPageVideoUrlEmpty={isVideoPageVideoUrlEmpty}
                setIsUrlValid={setIsUrlValid}
              />
            )}
            {selectedCard === "product_display" && (
              <ProductDisplay
                setProductDisplayDetails={setProductDisplayDetails}
                isProductDetailsEmpty={isProductDetailsEmpty}
                setIsProductDetailsEmpty={setIsProductDetailsEmpty}
                setIsUrlValid={setIsUrlValid}
              />
            )}
            {selectedCard === "location" && (
              <LocationDetails
                isLocationUrlEmpty={isLocationUrlEmpty}
                setIsUrlValid={setIsUrlValid}
              />
            )}
            <BackNextBtn
              back={() => {
                props.setIsQrVisible(false);
              }}
              createOnClick={() => {
                //for url type website
                if (
                  (selectedCard === null || selectedCard === "website") &&
                  websiteDetails?.url === ""
                ) {
                  setIsWebsiteUrlEmpty(true);
                }
                if (
                  (selectedCard === null || selectedCard === "website") &&
                  websiteDetails?.url !== "" &&
                  isUrlValid
                ) {
                  // togglePopup();
                  qrCodeCreateApi();
                }

                // for url type multiple_links
                if (
                  selectedCard === "multiple_links" &&
                  multipleUrlDetails?.businessName === ""
                ) {
                  setIsMultipleLinksBusinessNameEmpty(true);
                }
                if (
                  selectedCard === "multiple_links" &&
                  multipleUrlDetails?.logo === null
                ) {
                  setIsMultipleLinksLogoEmpty(true);
                }
                if (
                  selectedCard === "multiple_links" &&
                  multipleUrlDetails?.urls === ""
                ) {
                  setIsMultipleLinksUrlsEmpty(true);
                }

                if (
                  selectedCard === "multiple_links" &&
                  multipleUrlDetails?.businessName !== "" &&
                  multipleUrlDetails?.logo !== null &&
                  multipleUrlDetails?.urls !== "" &&
                  isUrlValid
                ) {
                  // togglePopup();
                  qrCodeCreateApi();
                }

                // for url type business_page
                if (
                  selectedCard === "business_page" &&
                  businessPageDetails?.businessName === ""
                ) {
                  setIsBusinessPageBusinessNameEmpty(true);
                }
                if (
                  selectedCard === "business_page" &&
                  businessPageDetails?.imgOfBusinessPage === null
                ) {
                  setIsBusinessPageImgEmpty(true);
                }
                if (
                  selectedCard === "business_page" &&
                  businessPageDetails?.description === ""
                ) {
                  setIsBusinessPageDescriptionEmpty(true);
                }
                if (
                  selectedCard === "business_page" &&
                  businessPageDetails?.phoneNo === ""
                ) {
                  setIsBusinessPagePhoneNoEmpty(true);
                }
                if (
                  selectedCard === "business_page" &&
                  businessPageDetails?.storeAddress === ""
                ) {
                  setIsBusinessPageStoreAddressEmpty(true);
                }
                if (
                  selectedCard === "business_page" &&
                  businessPageDetails?.businessName !== "" &&
                  businessPageDetails?.imgOfBusinessPage !== null &&
                  businessPageDetails?.description !== "" &&
                  businessPageDetails?.phoneNo !== "" &&
                  businessPageDetails?.storeAddress !== "" &&
                  isUrlValid
                ) {
                  // togglePopup();
                  qrCodeCreateApi();
                }

                // for social media details
                if (
                  selectedCard === "s_m_account" &&
                  socialMediaDetails?.businessName === ""
                ) {
                  setIsSocialMediaBusinessNameEmpty(true);
                }
                let isSocialMediaUrlsEmpty =
                  socialMediaDetails?.facebookLink !== "" ||
                  socialMediaDetails?.instagramLink !== "" ||
                  socialMediaDetails?.linkedInLink !== "" ||
                  socialMediaDetails?.twitterLink !== "" ||
                  socialMediaDetails?.youTubeLink !== "";

                if (
                  (selectedCard === "s_m_account" &&
                    socialMediaDetails?.facebookLink === "") ||
                  socialMediaDetails?.instagramLink === "" ||
                  socialMediaDetails?.linkedInLink === "" ||
                  socialMediaDetails?.twitterLink === "" ||
                  socialMediaDetails?.youTubeLink === ""
                ) {
                  setIsSocialMediaUrlsEmpty(true);
                }

                if (
                  selectedCard === "s_m_account" &&
                  socialMediaDetails?.businessName !== "" &&
                  isSocialMediaUrlsEmpty &&
                  isUrlValid
                ) {
                  // togglePopup();
                  qrCodeCreateApi();
                }

                // for url type video

                if (
                  selectedCard === "video" &&
                  videoDetails?.businessName === ""
                ) {
                  setIsVideoPageBusinessNameEmpty(true);
                }
                if (
                  selectedCard === "video" &&
                  videoDetails?.description === ""
                ) {
                  setIsVideoPageDescriptionEmpty(true);
                }
                if (
                  selectedCard === "video" &&
                  videoDetails?.videoTitle === ""
                ) {
                  setIsVideoPageVideoTitleEmpty(true);
                }
                if (selectedCard === "video" && videoDetails?.videoUrl === "") {
                  setIsVideoPageVideoUrlEmpty(true);
                }
                if (
                  selectedCard === "video" &&
                  videoDetails?.businessName !== "" &&
                  videoDetails?.description !== "" &&
                  videoDetails?.videoTitle !== "" &&
                  videoDetails?.videoUrl !== "" &&
                  isUrlValid
                ) {
                  // togglePopup();
                  qrCodeCreateApi();
                }

                // for url type location
                if (
                  selectedCard === "location" &&
                  locationDetails?.url === ""
                ) {
                  setIsLocationUrlEmpty(true);
                }
                if (
                  selectedCard === "location" &&
                  locationDetails?.url !== "" &&
                  isUrlValid
                ) {
                  qrCodeCreateApi();
                }

                // for url type product display
                if (
                  selectedCard === "product_display" &&
                  productDisplayDetails?.phoneNo === "" &&
                  productDisplayDetails?.actualPrice === "" &&
                  productDisplayDetails?.priceAtStore === "" &&
                  productDisplayDetails?.productName === "" &&
                  productDisplayDetails?.storeAddress === "" &&
                  productDisplayDetails?.productDetails === "" &&
                  productDisplayDetails?.productImg === ""
                ) {
                  setIsProductDetailsEmpty(true);
                }
                if (
                  selectedCard === "product_display" &&
                  productDisplayDetails?.phoneNo !== "" &&
                  productDisplayDetails?.actualPrice !== "" &&
                  productDisplayDetails?.priceAtStore !== "" &&
                  productDisplayDetails?.productName !== "" &&
                  productDisplayDetails?.storeAddress !== "" &&
                  productDisplayDetails?.productDetails !== "" &&
                  productDisplayDetails?.productImg !== ""
                ) {
                  console.log("Qr Code Created Of Product Display");
                  qrCodeCreateApi();
                }
              }}
            ></BackNextBtn>
          </div>
          {screenWidth > 1200 && (
            <div className="url_preview_div">
              {(selectedCard === "website" || selectedCard === null) && (
                <>
                  <img
                    className="img_preview"
                    src={websitePreview}
                    alt="websitePreview"
                  />
                </>
              )}
              {selectedCard === "multiple_links" && (
                <>
                  <img
                    className="img_preview"
                    src={multiUrlPreviewImg}
                    alt="multiUrlPreviewImg"
                  />
                </>
              )}
              {selectedCard === "business_page" && (
                <>
                  <img
                    className="img_preview"
                    src={businessPagePreviewImg}
                    alt="businessPagePreviewImg"
                  />
                </>
              )}
              {selectedCard === "s_m_account" && (
                <>
                  <img
                    className="img_preview"
                    src={socialMediaPreview}
                    alt="socialMediaPreview"
                  />
                </>
              )}
              {selectedCard === "video" && (
                <>
                  <img
                    className="img_preview"
                    src={videoPreviewImg}
                    alt="videoPreviewImg"
                  />
                </>
              )}
              {selectedCard === "product_display" && (
                <>
                  <img
                    className="img_preview"
                    src={productDisplayPreview}
                    alt="productDisplayPreview"
                  />
                </>
              )}
              {selectedCard === "location" && (
                <>
                  <img
                    className="img_preview"
                    src={LocationPreviewImg}
                    alt="productDisplayPreview"
                  />
                </>
              )}
              {selectedCard === "website" || selectedCard === null ? (
                <div className="sub_title txt_center">Preview</div>
              ) : (
                <div className="sub_title txt_center">
                  Your Page will look like this
                </div>
              )}
            </div>
          )}
        </div>
        {showPopup && screenWidth < 1200 && (
          <PopUp onClose={togglePopup}>
            <div className="url_preview_div">
              {(selectedCard === "website" || selectedCard === null) && (
                <>
                  <img
                    className="img_preview"
                    src={websitePreview}
                    alt="websitePreview"
                  />
                </>
              )}
              {selectedCard === "multiple_links" && (
                <>
                  <img
                    className="img_preview"
                    src={multiUrlPreviewImg}
                    alt="multiUrlPreviewImg"
                  />
                </>
              )}
              {selectedCard === "business_page" && (
                <>
                  <img
                    className="img_preview"
                    src={businessPagePreviewImg}
                    alt="businessPagePreviewImg"
                  />
                </>
              )}
              {selectedCard === "s_m_account" && (
                <>
                  <img
                    className="img_preview"
                    src={socialMediaPreview}
                    alt="socialMediaPreview"
                  />
                </>
              )}
              {selectedCard === "video" && (
                <>
                  <img
                    className="img_preview"
                    src={videoPreviewImg}
                    alt="videoPreviewImg"
                  />
                </>
              )}
              {selectedCard === "product_display" && (
                <>
                  <img
                    className="img_preview"
                    src={productDisplayPreview}
                    alt="productDisplayPreview"
                  />
                </>
              )}
              {selectedCard === "location" && (
                <>
                  <img
                    className="img_preview"
                    src={LocationPreviewImg}
                    alt="productDisplayPreview"
                  />
                </>
              )}
              {selectedCard === "website" || selectedCard === null ? (
                <div className="sub_title txt_center">Preview</div>
              ) : (
                <div className="sub_title txt_center">
                  Your Page will look like this
                </div>
              )}
            </div>
          </PopUp>
        )}
      </div>
    </>
  );
};

export default QrCodeCreater;
