import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Hidden, TextField } from "@mui/material";
// import ProgressCard from "../components/Dashboard/ProgressCard";
// import TotalPrintCard from "../components/Dashboard/TotalPrintCard";
// import CampaignRunningTile from "../components/Dashboard/CampaignRunningTile";
// import AccountBalanceTile from "../components/Dashboard/AccountBalanceTile";
import ChartSection from "../components/Dashboard/ChartSection";
import CampaignTable from "../components/Dashboard/CampaignTable";
import { fetchCampaignData } from "../store/campaign-slice";
import { API_URL } from "../constant/apiURL";
import "./Dashboard.css";
import "./Dashboard.scss";
import CampaignDetail from "../components/CampaignDetail/CampaignDetail";
import { useHistory } from "react-router-dom";
import Button from "../components/UI/GreenBtn/Button";
import QrCodeDetail from "../components/Dashboard/QrCodeDetail";
import DistributionPlatform from "../components/Dashboard/DistributionPlatform/DistributionPlatform";

function Dashboard(props) {
  const history = useHistory();
  const [adType, setAdType] = useState({ all: true });
  // console.log(adType, "adType");

  // console.log(adType.pamphlet);

  // for all records
  const [allDatesArr, setAllDatesArr] = useState([]);
  const [allRecordsArr, setAllRecordsArr] = useState([]);

  // for only pamphlet records
  const [pamphletDatesArr, setPamphletDatesArr] = useState([]);
  const [pamphletRecordsArr, setPamphletRecordsArr] = useState([]);

  // for only footer records
  const [footerDatesArr, setFooterDatesArr] = useState([]);
  const [footerRecordsArr, setFooterRecordsArr] = useState([]);

  // for only display Ad records
  const [displayAdDatesArr, setDisplayAdDatesArr] = useState([]);
  const [displayAdRecordsArr, setDisplayAdRecordsArr] = useState([]);

  // for only label Ad records
  const [labelAdDatesArr, setLabelAdDatesArr] = useState([]);
  const [labelAdRecordsArr, setLabelAdRecordsArr] = useState([]);

  //  pamphlet details
  const [pamphletSide, setPamphletSide] = useState();
  const [pamphletSize, setPamphletSize] = useState();

  // footer details
  const [footerHeight, setFooterHeight] = useState();

  // displayAd Details
  const [displayAdURl, setDisplayAdURL] = useState();

  const provideDatesArray = () => {
    if (filteredDatesArr.length > 0) {
      return filteredDatesArr;
    } else if (adType.pamphlet) {
      return pamphletDatesArr;
    } else if (adType.footer) {
      return footerDatesArr;
    } else if (adType.display) {
      return displayAdDatesArr;
    } else if (adType.label) {
      return labelAdDatesArr;
    } else if (adType.all) {
      return allDatesArr;
    }
  };
  const provideRecordsArray = () => {
    if (filteredDatesArr.length > 0) {
      return filteredDatesArr;
    } else if (adType.pamphlet) {
      return pamphletRecordsArr;
    } else if (adType.footer) {
      return footerRecordsArr;
    } else if (adType.display) {
      return displayAdRecordsArr;
    } else if (adType.label) {
      return labelAdRecordsArr;
    } else if (adType.all) {
      return allRecordsArr;
    }
  };
  const [filteredDatesArr, setFilteredDatesArr] = useState([]);
  const [filteredRecordsArr, setFilteredRecordsArr] = useState([]);
  const [startDate, setStartDate] = useState(
    allDatesArr.length > 0 ? allDatesArr[0] : null
  );
  const [endDate, setEndDate] = useState(
    allDatesArr.length > 0 ? allDatesArr[allDatesArr.length - 1] : null
  );
  const token = useSelector((state) => state.auth.token);

  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const reduxSelectedCampaignId = useSelector(
    (state) => state.campaigns.selectedCampaign
  );

  const derivedSelectedCampaign = campaigns.filter(
    (campaign) => campaign.id === reduxSelectedCampaignId
  );
  const selectedCampaign = derivedSelectedCampaign[0];
  // console.log(selectedCampaign);
  // const pamphletId = selectedCampaign.pamphlet_id;
  // const footerId = selectedCampaign.footer_id;
  // const displayAdId = selectedCampaign.display_ad_id;

  const dispatch = useDispatch();

  // const pamphletId = useSelector((state) => state.campaigns.pamphletId);
  // const footerId = useSelector((state) => state.campaigns.footerId);
  // const displayAdId = useSelector((state) => state.campaigns.displayAdId);

  useEffect(() => {
    dispatch(fetchCampaignData(token));
  }, [dispatch, token]);

  const buildDateObj = (dateStr) => {
    return new Date(
      +dateStr.substring(0, 4),
      +dateStr.substring(5, 7) - 1,
      +dateStr.substring(8, 10) + 1
    );
  };
  let formData = new FormData();
  formData.append("campaign-id", reduxSelectedCampaignId);

  // for getting the records data
  useEffect(() => {
    if (campaigns.length > 0 && reduxSelectedCampaignId) {
      fetch(
        API_URL +
          `campaign/distribution-records/?campaign-id=${reduxSelectedCampaignId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      ).then((res) => {
        res.json().then((res) => {
          // console.log(res, "data");
          // const seenKeys = new Set();

          // for (const key in res.pamphlet_records) {
          //   if (res.pamphlet_records.hasOwnProperty(key)) {
          //     if (seenKeys.has(key)) {
          //       // return true; // Duplicate key found
          //       console.log("duplicate");
          //     } else {
          //       seenKeys.add(key);
          //       console.log("not found");
          //     }
          //   }
          // }
          // for all records
          let allRecordsArr2 = [];
          let allDatesArr2 = [];
          // for only pamphlet records
          let pamphletRecordsArr2 = [];
          let pamphletDatesArr2 = [];
          // for only footer records
          let footerRecordsArr2 = [];
          let footerDatesArr2 = [];
          // for displayAd
          let displayAdRecordsArr2 = [];
          let displayAdDatesArr2 = [];

          // for labelAd
          let labelAdRecordsArr2 = [];
          let labelAdDatesArr2 = [];
          // for all records
          for (const key in res.all_records) {
            allRecordsArr2.push(res.all_records[key]);
            allDatesArr2.push(buildDateObj(key));
          }
          // for only pamphlet records
          for (const key in res.pamphlet_records) {
            // pamphletRecordsArr2.push(res.pamphlet_records[key]);
            pamphletRecordsArr2.push(res.pamphlet_records[key]);
            pamphletDatesArr2.push(buildDateObj(key));
          }
          // for only footer records
          for (const key in res.footer_records) {
            footerRecordsArr2.push(res.footer_records[key]);
            footerDatesArr2.push(buildDateObj(key));
          }
          // for displayAd
          for (const key in res.display_ad_records) {
            displayAdRecordsArr2.push(res.display_ad_records[key]);
            displayAdDatesArr2.push(buildDateObj(key));
          }
          // for labelAd
          for (const key in res.label_ad_records) {
            labelAdRecordsArr2.push(res.label_ad_records[key]);
            labelAdDatesArr2.push(buildDateObj(key));
          }
          // for all records
          setAllRecordsArr([...allRecordsArr2]);
          setAllDatesArr([...allDatesArr2]);
          // for only pamphlet records
          setPamphletRecordsArr([...pamphletRecordsArr2]);
          setPamphletDatesArr([...pamphletDatesArr2]);
          // for only  footer records
          setFooterRecordsArr([...footerRecordsArr2]);
          setFooterDatesArr([...footerDatesArr2]);
          // for only  footer records
          setDisplayAdRecordsArr([...displayAdRecordsArr2]);
          setDisplayAdDatesArr([...displayAdDatesArr2]);

          // for label ad only
          setLabelAdRecordsArr([...labelAdRecordsArr2]);
          setLabelAdDatesArr([...labelAdDatesArr2]);
        });
      });
    }
  }, [campaigns.length, reduxSelectedCampaignId, token]);

  // for getting the filtered dates
  useEffect(() => {
    if (allDatesArr.length > 0 && startDate != null && endDate != null) {
      const filterDates = (item) => {
        return item >= buildDateObj(startDate) && item <= buildDateObj(endDate);
      };
      setFilteredDatesArr([...allDatesArr.filter(filterDates)]);
    }
  }, [startDate, endDate, allDatesArr]);

  // for getting the records array
  useEffect(() => {
    if (allDatesArr.length > 0 && startDate != null && endDate != null) {
      let filteredRecords = allRecordsArr.slice(
        allDatesArr.indexOf(filteredDatesArr[0]),
        allDatesArr.indexOf(filteredDatesArr[filteredDatesArr.length - 1]) + 1
      );
      setFilteredRecordsArr([...filteredRecords]);
    }
  }, [startDate, endDate, filteredDatesArr, allDatesArr, allRecordsArr]);

  const totalQty = () => {
    if (adType.pamphlet) {
      return selectedCampaign?.qty_for_pamphlet;
    } else if (adType.footer) {
      return selectedCampaign?.qty_for_footer;
    } else if (adType.display) {
      return selectedCampaign?.qty_for_display_ad;
    } else if (adType.label) {
      return selectedCampaign?.qty_for_label_ad;
    } else if (adType.all) {
      return (
        selectedCampaign?.qty_for_pamphlet +
        selectedCampaign?.qty_for_footer +
        selectedCampaign?.qty_for_display_ad +
        selectedCampaign?.qty_for_label_ad
      );
    }
  };
  const distributedQty = () => {
    if (adType.pamphlet) {
      return selectedCampaign?.dist_qty_for_pamphlet;
    } else if (adType.footer) {
      return selectedCampaign?.dist_qty_for_footer;
    } else if (adType.display) {
      return selectedCampaign?.dist_qty_for_display_ad;
    } else if (adType.label) {
      return selectedCampaign?.dist_qty_for_label_ad;
    } else if (adType.all) {
      return (
        selectedCampaign?.dist_qty_for_pamphlet +
        selectedCampaign?.dist_qty_for_footer +
        selectedCampaign?.dist_qty_for_display_ad +
        selectedCampaign?.dist_qty_for_label_ad
      );
    }
  };

  const balanceQty = () => {
    if (adType.pamphlet) {
      return selectedCampaign?.bal_qty_for_pamphlet;
    } else if (adType.footer) {
      return selectedCampaign?.bal_qty_for_footer;
    } else if (adType.display) {
      return selectedCampaign?.bal_qty_for_display_ad;
    } else if (adType.label) {
      return selectedCampaign?.bal_qty_for_label_ad;
    } else if (adType.all) {
      return (
        selectedCampaign?.bal_qty_for_pamphlet +
        selectedCampaign?.bal_qty_for_footer +
        selectedCampaign?.bal_qty_for_display_ad +
        selectedCampaign?.bal_qty_for_label_ad
      );
    }
  };

  const adTypesDetails = () => {
    if (adType.pamphlet) {
      return selectedCampaign?.pamphlet_id?.size;
    } else if (adType.pamphlet) {
      return selectedCampaign?.pamphlet_id?.is_duplex;
    } else if (adType.footer) {
      return selectedCampaign?.footer_id?.height;
    } else if (adType.display) {
      return (
        selectedCampaign?.display_ad_id?.screen_size === "l1" && "Large Size"
      );
    } else if (adType.label) {
      return selectedCampaign?.label_ad_id?.size === "1" && "Standard Size";
    } else if (adType.all) {
      return (
        selectedCampaign?.dist_qty_for_pamphlet +
        selectedCampaign?.dist_qty_for_footer +
        selectedCampaign?.dist_qty_for_display_ad +
        selectedCampaign?.dist_qty_for_label_ad
      );
    }
  };
  const distributionStartDate = selectedCampaign?.start_on;
  const isDuplex = selectedCampaign?.pamphlet_id?.is_duplex;
  return (
    <Grid container className="flex_grow_1 dashboard__main_div">
      {props.isVisible && (
        <>
          {/* <Grid item xs={12}>
            <div className="title dashboard_heading">Overview</div>
          </Grid> */}
          <Grid
            item
            xs={12}
            className="dashboard__adType_btnGrp_create_cmpgn_wrapper"
          >
            <div className="dashboard__adType_btnGrp">
              <button
                className={`multi_addType_btn ${
                  adType.all && "selected_multi_ad_btn"
                }`}
                onClick={() => setAdType({ all: true })}
              >
                All
              </button>
              {selectedCampaign?.pamphlet_id?.id && (
                <button
                  className={`multi_addType_btn ${
                    adType.pamphlet && "selected_multi_ad_btn"
                  }`}
                  onClick={() => setAdType({ pamphlet: true })}
                >
                  Pamphlet
                </button>
              )}
              {selectedCampaign?.footer_id?.id && (
                <button
                  className={`multi_addType_btn  ${
                    adType.footer && "selected_multi_ad_btn"
                  }`}
                  onClick={() => setAdType({ footer: true })}
                >
                  Footer
                </button>
              )}
              {selectedCampaign?.display_ad_id?.id && (
                <button
                  className={`multi_addType_btn ${
                    adType.display && "selected_multi_ad_btn"
                  }`}
                  onClick={() => setAdType({ display: true })}
                >
                  Display Ad
                </button>
              )}
              {selectedCampaign?.label_ad_id?.id && (
                <button
                  className={`multi_addType_btn ${
                    adType.label && "selected_multi_ad_btn"
                  }`}
                  onClick={() => setAdType({ label: true })}
                >
                  Label Ad
                </button>
              )}
            </div>
            <div className="dashboard-campaign-create-btn">
              <button
                className="create_campaign_btn"
                onClick={() => {
                  if (campaigns.length > 0) {
                    history.push("/dashboard/business-plans-page");
                  } else {
                    history.push("/dashboard/business-plans-page");
                  }
                }}
              >
                + Create Campaign
              </button>
            </div>
          </Grid>
          <div className="detail_components_wrapper">
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CampaignDetail
                className="card_12px elevate_16px"
                name={selectedCampaign?.name || "No Campaigns"}
                percentage={() => (distributedQty() / totalQty()) * 100}
                date={selectedCampaign?.created_on?.substring(0, 10)}
                side={
                  selectedCampaign
                    ? selectedCampaign?.pamphlet_id?.is_duplex
                      ? "Double"
                      : "Single"
                    : "----"
                }
                size={selectedCampaign?.pamphlet_id?.size}
                distributedQty={distributedQty}
                totalQty={totalQty}
                balQtyPercentage={() => (balanceQty() / totalQty()) * 100}
                balanceQty={balanceQty}
                adTypeAll={adType.all}
                adTypePamphlet={adType.pamphlet}
                adTypeFooter={adType.footer}
                adTypeDisplayAd={adType.display}
                adTypeLabelAd={adType.label}
                adTypesDetails={adTypesDetails}
                isDuplex={isDuplex}
                distributionStartDate={distributionStartDate}
                selectedCampaign={selectedCampaign}
                isQrCodeId={selectedCampaign?.qr_code_id?.id ? true : false}
              />
            </Grid>

            {/* {selectedCampaign?.qr_code_id?.id && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={{ display: "flex" }}
              >
                <QrCodeDetail
                  className="card_12px elevate_16px"
                  selectedCampaign={selectedCampaign}
                />
              </Grid>
            )} */}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              sx={{ display: "flex" }}
            >
              <CampaignTable />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              sx={{ display: "flex" }}
            >
              <DistributionPlatform distributedQty={distributedQty} />
            </Grid>
          </div>
          <Grid item xs={12}>
            <div
              className="card_12px elevate_16px dashboard_graph"
              style={{
                backgroundColor: "#ffff",
                margin: "12px",
                // padding: "12px",
              }}
            >
              {campaigns.length === 0 && (
                <div className="blur_backdrop">
                  <button
                    className="create_campaign_btn"
                    onClick={() => {
                      if (campaigns.length > 0) {
                        history.push("/dashboard/business-plans-page");
                      } else {
                        history.push("/dashboard/business-plans-page");
                      }
                    }}
                  >
                    + Create Campaign
                  </button>
                </div>
              )}

              <Grid container>
                {/* <Grid item xs={6} align="center">
                  <h2>Start Date</h2>
                  {allDatesArr.length > 0 && (
                    <input
                      type="date"
                      min={allDatesArr[0].toISOString().substring(0, 10)}
                      value={
                        startDate
                          ? startDate
                          : allDatesArr[0].toISOString().substring(0, 10)
                      }
                      max={allDatesArr[allDatesArr.length - 1]
                        .toISOString()
                        .substring(0, 10)}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    ></input>
                  )}
                </Grid>
                <Grid item xs={6} align="center">
                  <h2>End Date</h2>
                  {allDatesArr.length > 0 && (
                    <input
                      type="date"
                      min={allDatesArr[0].toISOString().substring(0, 10)}
                      value={
                        endDate
                          ? endDate
                          : allDatesArr[allDatesArr.length - 1]
                              .toISOString()
                              .substring(0, 10)
                      }
                      max={allDatesArr[allDatesArr.length - 1]
                        .toISOString()
                        .substring(0, 10)}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    ></input>
                  )}
                </Grid> */}
              </Grid>
              <ChartSection
                className="chart_main_div"
                dates={provideDatesArray}
                records={provideRecordsArray}
                // records={[1, 5]}
                allData={{
                  pamphlet: {
                    pamphletDatesArr: pamphletDatesArr,
                    pamphletRecordsArr: pamphletRecordsArr,
                  },
                  footer: {
                    footerDatesArr: footerDatesArr,
                    footerRecordsArr: footerRecordsArr,
                  },
                  displayAd: {
                    displayAdDatesArr: displayAdDatesArr,
                    displayAdRecordsArr: displayAdRecordsArr,
                  },
                  labelAd: {
                    labelAdDatesArr: labelAdDatesArr,
                    labelAdRecordsArr: labelAdRecordsArr,
                  },
                }}
                adType={adType}
              />
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Dashboard;
