import React, { useState } from "react";
import "./MultiUrlPreviewPage.css";
import rightArrow from "./right_arrow.svg";
import { API_URL } from "../../../../../constant/apiURL";
// import { API_URL } from "../../../../../constant/apiURL";
function MultiUrlPreviewPage(props) {
  const [previewUrl, setPreviewUrl] = useState("");

  const isLiveUrl = API_URL === "https://service.ewayadz.com/api/";

  const isLivePath = isLiveUrl
    ? "https://service.ewayadz.com/"
    : "http://192.168.0.48:7070/";
  const path = isLivePath + "media/";
  // console.log(path, "path");

  // console.log(props.response);

  let array2;
  // console.log(typeof props.response?.qr_code_url);
  if (typeof props.response?.qr_code_url === "string") {
    array2 = JSON.parse(props.response?.qr_code_url);
  } else {
    array2 = props.response?.qr_code_url;
  }
  const businessName = array2?.businessName;
  const logo = path + props.response?.qr_code_business_images;
  // console.log(array2);
  // console.log(logo, "logo");
  let array = array2?.urls;

  return (
    <>
      <div className="multi_url_preview_main_div">
        <div className="multi_url_preview_sub_div">
          {/* <div className="green_background"></div> */}
          <div style={{ marginTop: "2rem" }}>
            <img
              className="multi_url_logo_preview"
              src={logo}
              alt="ImagePreview"
            />
          </div>
          <div className="multi_url_business_name_wrapper">
            <div className="multi_url_business_name">{businessName}</div>
          </div>

          <div className="multi_urls_tabs_card">
            <div className="multi_urls_tabs_card_sub_div">
              {/* <div className="multi_url_preview_sub_heading">
                Explore {businessName}
                `s
              </div>
              <div className="multi_url_preview_sub_heading2">
                Online Presence
              </div>
              <div className="multi_url_preview_guide_text">
                click on the buttons to visit
              </div> */}
              <div className="multi_url_preview_btns_wrapper">
                {array?.map((links, index) => {
                  return (
                    <div
                      className="multi_url_preview_btns_sub_wrapper"
                      onClick={() => {
                        window.location.href = links.url;
                      }}
                    >
                      <div className="multi_url_preview_btn" key={index}>
                        {links.title}
                      </div>
                      <div style={{ marginLeft: "42px" }}>
                        <img src={rightArrow} alt="rightArrow" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MultiUrlPreviewPage;
