import "./QrCodeSection.css";
import QrFeatureCard from "./QrFeatureCard/QrFeatureCard";
import qrFeaturesImg from "./qr_features_image.png";
import trackableScansImg from "./QrFeatureCard/trackableScansImg.png";
import DgtlEnsmtLogo from "./QrFeatureCard/DgtlEnsmtLogo.png";
import imgVersatileInfo from "./QrFeatureCard/img-Versatile info 1.png";
function QrCodeSection(props) {
  const qrFeatureObj = [
    {
      img: imgVersatileInfo,
      heading: "Versatile Information",
      text: "Link to videos, locations, boost social media engagement, drive app downloads, or guide users to a unique landing page – all through QR Codes.",
    },
    {
      img: trackableScansImg,
      heading: "Trackable Scans",
      text: "The ability of QR codes to track their QR code scans allows users to understand their QR marketing campaign.",
    },
    {
      img: DgtlEnsmtLogo,
      heading: "Digital Enhancement",
      text: "Elevate print materials with QR codes, leading users to a webpage for deeper insights.",
    },
  ];
  return (
    <>
      <div className="qr_code_section_main_div">
        <div className="qr_code_section_sub_div">
          <div className="qr_features_img_div">
            <img
              className="qr_features_img"
              src={qrFeaturesImg}
              alt="qrFeaturesImg"
            />
          </div>

          <div className="child1">
            <div className="content__heading  text-center dark_green_text m_t_2">
              Enhance Your Direct Mail with QR Code
            </div>
            <div className="sub_title_child text-center m_t_0_5">
              The great thing about direct mail marketing is that you can have
              the best of both worlds <br /> i.e print media & digital you can
              include QR codes, websites, social media links and so on.
            </div>
          </div>

          <div className="qr_features_cards_wrapper m_t_2">
            {qrFeatureObj?.map((item, index) => {
              return (
                <div className="m_b_1" key={index}>
                  <QrFeatureCard
                    img={item.img}
                    heading={item.heading}
                    text={item.text}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
export default QrCodeSection;
