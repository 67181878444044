import React from "react";
import ReactDOM from "react-dom";
import Button from "../UI/GreenBtn/Button";

import "./CustomModal.css";

const Modal = (props) => {
  return (
    <div className="custom_modal_backdrop">
      <div className="custom_modal_modal">
        <div style={{ paddingBottom: "20px" }}>{props.children}</div>
        {props.BtnGrpNeeded && (
          <div className="custom_modal_btn_grp">
            <Button
              style={{ color: "#43ba56" }}
              className="custom_modal_btn_grp_closebtn"
              btnTextClasses="custom_modal_btn_grp_closebtn_text "
              name={props.closeBtnName ? props.closeBtnName : "Close"}
              onClick={props.onClose}
            />
            <Button
              style={{ height: "43px", borderRadius: "10px" }}
              name={props.confirmBtnName ? props.confirmBtnName : "Okay"}
              onClick={props.onConfirm}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const CustomModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Modal
          onClose={props.onClose}
          onConfirm={props.onConfirm}
          closeBtnName={props.closeBtnName}
          confirmBtnName={props.confirmBtnName}
          BtnGrpNeeded={props.BtnGrpNeeded}
        >
          {props.children}
        </Modal>,
        document.getElementById("modal")
      )}
    </>
  );
};

export default CustomModal;
