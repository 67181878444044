import React from "react";
import "./ProductPreviewPage.css";
import ImageSlider from "../../../../../components/UI/ImageSlider/ImageSlider";
import img1 from "./2499504-cover.jpg";
import img2 from "./G03.jpg";
import img3 from "./pngimg.com - iphone_14_PNG20.png";
function ProductPreviewPage(props) {
  console.log(typeof props.response?.qr_code_url);
  const data = JSON.parse(props.response?.qr_code_url);
  const discountedPrice = +data?.discountedPrice;
  const actualPrice = +data?.actualPrice;
  console.log(data);
  //price
  const frmtDiscountedPrice = discountedPrice.toLocaleString("en-US");
  const frmtActualPrice = actualPrice.toLocaleString("en-US");

  //about product
  const aboutProduct = data?.productDetails;

  // product availability
  const availableAt = data?.storeAddress;
  let images = [img1, img2, img3];

  return (
    <>
      <div className="product_preview_main_div">
        <div className="product_preview_sub_div">
          <div className="image_slider_div">
            <ImageSlider images={images} />
          </div>
          <div className="product_preview_details_div">
            <div className="f_w_500 f_s_18">{data.productName}</div>
            <div className="f_w_700 f_s_18">
              ₹ {frmtDiscountedPrice}
              <span className="f_w_700 f_s_14 opaque text_line_through m_l_1">
                ₹ {frmtActualPrice}
              </span>
            </div>

            <div className="opaque m_t_1">colors</div>
            <div className="colors_wrapper">
              <div className="color_div">Black</div>
              <div className="color_div">Blue</div>
              <div className="color_div">Green</div>
            </div>

            <div className="opaque m_t_1">About the product</div>
            <div>{aboutProduct}</div>

            <div className="opaque m_t_1">Available at</div>
            <div>{availableAt}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPreviewPage;
