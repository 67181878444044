import React, { useState } from "react";
import "./FAQ.scss";
import chevron from "./down.png";
const listData = [
  {
    query: "What is ewayprint ads & what does it offer?",
    selected: false,
    info: "Ewayprint is a leading pamphlet distribution company that provides a personalized & customized advertising solutions for businesses. We help companies reach their target audience through the distribution of business flyers in a secure &efficient manner.",
  },
  {
    query: "How is ewayprint ads different from social media advertising?",
    selected: false,
    info: "Ewayprint ads provides a unique approach to advertising by delivering promotional flyers directly to your target audience's doorstep.",
  },
  {
    query: "How can I track the progress of my campaigns?",
    selected: false,
    info: "With our real-time monitoring system, you can track the progress of your campaign & see the number of pamphlets distributed at any given time.",
  },
  {
    query: "Can I set how often my pamphlet is distributed to same person?",
    selected: false,
    info: "Yes, you can choose to have your pamphlets delivered to the same person one-time or after a gap of days. This gives you complete control over your advertising campaigns.",
  },
  {
    query:
      "How can I trust that my pamphlets are being delivered & reaching to the target audience?",
    selected: false,
    info: "Ewayprint ads uses a tracking system to ensure that each pamphlet is delivered & received by the target audience. You can check the results of your investment through our real-time monitoring dashboard.",
  },
  {
    query: "Can I see a proof of my pamphlet before it goes into distribution?",
    selected: false,
    info: "Yes, you can view a proof of your pamphlet before it goes into distribution. Our expert design team will work with you to create a design that meets your needs. And you will have an opportunity to review & approve the proof before it goes into production.",
  },
  {
    query: "Do you have a dedicated support team?",
    selected: false,
    info: "Yes, at ewayprint we have dedicated support team that provides personalized support & ensures that your advertising campaign is a success.",
  },
];

const ListItemHelper = (props) => {
  return (
    <div className="list_item ">
      <div className="list_item__heading">
        <div>{props.query}</div>
        <div>
          <img
            src={chevron}
            alt="chevron"
            title="chevron"
            className={`${props.selected && "rotate-180-cw"}`}
            onClick={props.onClick}
          ></img>
        </div>
      </div>
      {props.selected && (
        <div className="list_item__content">
          <p>{props.info}</p>
        </div>
      )}
    </div>
  );
};
const FAQ = (props) => {
  const [list, setList] = useState([...listData]);
  const setSelected = (arr, index) => {
    const needsUpdate = arr[index].selected;
    const recArray = [...arr];
    if (needsUpdate) {
      recArray[index].selected = !recArray[index].selected;
      setList(recArray);
      return;
    } else {
      recArray.map((item) => (item.selected = false));
      recArray[index].selected = !recArray[index].selected;
      // debugger;
      setList(recArray);
      return;
    }
  };
  return (
    <div className="FAQ_" id="FAQ">
      <div className="FAQ__heading text-center">Frequently asked questions</div>
      <div className="FAQ__faq_list">
        {list.map((item, index) => (
          <ListItemHelper
            key={index}
            selected={item.selected}
            query={item.query}
            info={item.info}
            onClick={() => setSelected(list, index)}
          />
        ))}
      </div>
      <div className="FAQ__footer">
        Couldn’t find your query? Contact us & our team will get back to you
        soon.
      </div>
    </div>
  );
};

export default FAQ;
