import "./VideoDetails.css";
import { videoColor } from "../../../../../assets/UrlTypeColorIcons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../../../../store/campaign-slice";

const VideoDetails = (props) => {
  const dispatch = useDispatch();
  const [videoDetails, setVideoDetails] = useState({
    businessName: "",
    description: "",
    videoTitle: "",
    videoUrl: "",
  });

  const setData = (dataKey, dataValue) => {
    setVideoDetails((state) => ({ ...state, [`${dataKey}`]: dataValue }));
  };
  dispatch(campaignActions.setVideoDetails({ videoDetails: videoDetails }));

  // States for Validation
  const [url, setUrl] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(true);

  const validateUrl = (url) => {
    const validSchemes = ["http://", "https://", "ftp://"];
    const containsScheme = validSchemes.some((scheme) => url.includes(scheme));
    // const containsWww = url.includes("www");
    // const containsDotCom = url.includes(".com");
    return containsScheme;
  };

  return (
    <>
      <div className="social_media_main_details">
        <div className="multiple_url_details_sub_div">
          <div className="sub_title url_type_heading">
            <span className="url_heading_icon">
              <img src={videoColor} alt="videoColor" />
            </span>
            Video
          </div>
          <div className="url_detail_section_wrapper">
            <div style={{ marginBottom: "0.5rem" }} className="sub_title_child">
              Business Name
            </div>
            <div>
              <input
                className="website_url_input"
                type="text"
                placeholder="Your Business Name"
                onChange={(e) => {
                  setData("businessName", e.target.value);
                }}
              />
              {props.isVideoPageBusinessNameEmpty && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  Please Enter Business Name
                </div>
              )}
            </div>
          </div>
          <div className="img_detail_section_wrapper">
            <div className="sub_title_child">Description</div>
            <div>
              <textarea
                className="description_input"
                style={{ padding: "10px" }}
                name=""
                id=""
                cols=""
                rows="3"
                placeholder="What Your Video Is About"
                onChange={(e) => {
                  setData("description", e.target.value);
                }}
              ></textarea>
              {props.isVideoPageDescriptionEmpty && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  Please Enter Description
                </div>
              )}
            </div>
          </div>
          <div className="url_detail_section_wrapper2">
            <div style={{ marginBottom: "0.5rem" }} className="sub_title_child">
              Video Title
            </div>
            <div>
              <input
                className="website_url_input"
                type="text"
                placeholder="Enter Your Video Title"
                onChange={(e) => {
                  setData("videoTitle", e.target.value);
                }}
              />
              {props.isVideoPageVideoTitleEmpty && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  Please Enter Video Title
                </div>
              )}
            </div>
          </div>
          <div className="url_detail_section_wrapper2">
            <div style={{ marginBottom: "0.5rem" }} className="sub_title_child">
              Video URL
            </div>
            <div>
              <input
                className="website_url_input"
                type="text"
                placeholder="Enter Your Video URL"
                onChange={(e) => {
                  setData("videoUrl", e.target.value);
                  setUrl(e.target.value);
                  setIsValidUrl(validateUrl(e.target.value));
                  props.setIsUrlValid(validateUrl(e.target.value));
                }}
              />
              {!isValidUrl && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  Please Enter Valid URL
                </p>
              )}
              {props.isVideoPageVideoUrlEmpty && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  Please Enter Video URL
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VideoDetails;
