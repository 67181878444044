import React from "react";
import CardWrapper from "../CardWrapper/CardWrapper";

import check from "./check.svg";
import "./BudgetCard.css";

const BudgetCard = (props) => {
  return (
    <CardWrapper
      className={`step_2_card_1 ${
        props.isChecked ? "custom_card_comp_selected" : ""
      }`}
      onClick={props.onClick}
    >
      <div className="step_2_card_1_div1">
        <div className="step_2_sub_title txt_center">Pamphlet Quantity</div>
        <div className="large_num step_2_card_number">{props.Quantity}</div>
      </div>
      <div className="step_2_price_recommended">
        <div className="div2">
          <div className="step_2_sub_title">Price : ₹ &nbsp;{props.price}</div>
          <div className="step_2_sub_title">
            {props.size} - {props.side}
          </div>
        </div>
        <div className="div3">
          <div className="step_3_img_flex">
            {props.isChecked ? <img src={check} alt="check"></img> : ""}
          </div>
          <div
            className="step_2_sub_title div3_text class"
            style={{ color: "#31B963" }}
          >
            {props.isRecommended ? "Recommended Plan" : ""}
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default BudgetCard;
