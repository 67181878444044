import { useState } from "react";
import "./CampaignInput.css";
import SelectDropdown from "../../../components/UI/SelectDropdown/SelectDropdown";
import Multiselect from "multiselect-react-dropdown";

import { useEffect } from "react";
import { API_URL } from "../../../constant/apiURL";
import { useDispatch, useSelector } from "react-redux";
import { campaignActions } from "../../../store/campaign-slice";
import { useRef } from "react";
import { campiagnCreateIcon } from "../../../assets";
const CampaignInput = (props) => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const multiSelectRef = useRef();

  const [industryList, setIndustryList] = useState("");

  const [inputData, setInputData] = useState({
    campaignName: "",
    startOn: "",
    industry: "",
    pinCodes: null,
    voucherName: "",

    tagLine: "",
    contact: null,
  });

  const handleInputClick = () => {
    // Programmatically trigger the click event on the input element
    document.getElementById("datepicker").click();
  };

  useEffect(() => {
    dispatch(
      campaignActions.setCampaignDetails({ campaignDetails: inputData })
    );
  });

  const setData = (dataKey, dataVal) => {
    setInputData((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };

  const [minDate, setMinDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate());
    return date.toISOString().slice(0, 10);
  });
  const getSelected = (item) => {
    setData("industry", item);
  };
  const getSelectedPinCode = (item) => {
    setData("pinCodes", item);
  };

  useEffect(() => {
    if (token) {
      (async () => {
        // Fetching Industry List
        const resInd = await fetch(API_URL + "industries/list/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const dataInd = await resInd.json();
        // console.log(dataInd);
        setIndustryList(dataInd);
      })();
    }
  }, [token]);

  let pinCodesList = [];
  pinCodesList.push(props.locationList);
  return (
    <>
      <div className="stepper_input_main_div">
        <div className="campaign_input_sub_div">
          <h4 className="m_b_2">
            <span>
              <img src={campiagnCreateIcon} alt="campiagnCreateIcon" />
            </span>
            &nbsp; Create Campaign
          </h4>
          <div className="campaign_name_date_wrapper">
            <div className="campaign_label_input_wrapper">
              <div className="sub_title_child">Campaign Name</div>
              <input
                autoFocus
                style={{ paddingLeft: "20px" }}
                className="campaign_name_user_input"
                type="text"
                // placeholder="Enter Campaign Name"
                onChange={(e) => {
                  setData("campaignName", e.target.value);
                }}
                placeholder="Enter Campaign Name"
              />
            </div>

            <div className="campaign_label_input_wrapper">
              <div className="sub_title_child ">Distribution Start Date</div>
              <input
                onChange={(e) => {
                  setData("startOn", e.target.value);
                }}
                type="date"
                min={minDate}
                name="date"
                // id="datepicker"
                className="date_picker date_picker_new"
                id="datepicker"
                onClick={handleInputClick}
              />
            </div>
          </div>

          <div className="sub_title_child m_t_1">
            Select Your Business Industry
          </div>
          <SelectDropdown
            optionsArray={industryList && industryList}
            getSelected={getSelected}
            className=""
          />
          {props.campaignDetailsError && (
            <div className="error_text_child">All fields are required *</div>
          )}
        </div>
      </div>
      {props.isLocation && (
        <div className="campaign_input_main_div">
          <div className="sub_title_child m_t_1 m_b_1">
            Select Distribution Location
          </div>
          <div className="campaign_input_sub_div">
            <div className="">
              <Multiselect
                className="multiselect_div"
                options={pinCodesList[0]}
                displayValue="pin_code"
                onSelect={getSelectedPinCode}
                ref={multiSelectRef}
                selectedValues={inputData.pinCodes}
              />
              {props.isPinCodeEmpty && (
                <p style={{ color: "red" }}>please select pin code</p>
              )}
            </div>
          </div>
        </div>
      )}
      {props.isVoucher && (
        <div className="campaign_input_main_div">
          <div className="campaign_input_sub_div">
            <div className="sub_title_child">Voucher Name</div>
            <div className="m_t_0_5">
              <input
                className="campaign_name_user_input"
                type="text"
                // placeholder="Enter Voucher Name"
                onChange={(e) => {
                  setData("voucherName", e.target.value);
                }}
              />
            </div>
            <div className="sub_title_child m_t_0_5">Logo</div>
            <div className="m_t_0_5">
              <input
                className="campaign_name_user_input"
                type="file"
                // placeholder="Enter Voucher Name"
                onChange={(e) => {
                  props.setVoucherFile(e.target.files[0]);
                }}
              />
            </div>
            <div className="sub_title_child m_t_0_5">Tag Line</div>
            <div className="m_t_0_5">
              <input
                className="campaign_name_user_input"
                type="text"
                // placeholder="Enter Voucher Name"
                onChange={(e) => {
                  setData("tagLine", e.target.value);
                }}
              />
            </div>
            <div className="sub_title_child m_t_0_5">Contact Number</div>
            <div className="m_t_0_5">
              <input
                className="campaign_name_user_input"
                type="number"
                // placeholder="Enter Voucher Name"
                onChange={(e) => {
                  setData("contact", e.target.value);
                }}
              />
            </div>
            {(props.voucherDetailsError || props.locationDetailsError) && (
              <div className="error_text_child">All fields are required*</div>
            )}
            {props.isContactNoValid && (
              <div className="error_text_child">
                Contact No Must Contain 10 Digits
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default CampaignInput;
