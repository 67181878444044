import React from "react";

import "./WhoCanUse.css";
import WCUbg from "./WCU.png";

function WhoCanUse() {
  return (
    <>
      <div className="WCU-main-div">
        <div className="WCU-c0">
          <div className="WCU-text">Who Can Use</div>
          <div className="WCU-text">
            <span style={{ color: "green" }}>Ewayprint</span> App
          </div>
        </div>
        <div className="sec2-flex">
          <div className="WCU-c1">
            <div className="WCU-list">
              <li>Students</li>
              <li> Teachers</li>
              <li> Professionals</li>
              <li> Businessman</li>
            </div>
          </div>
          <div className="WCU-c2">
            <img className="WCU-image" src={WCUbg} alt="mobile"></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoCanUse;
