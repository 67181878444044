import { useState } from "react";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";
import NewBudgetCard from "../../../components/UI/NewBudgetCard/NewBudgetCard";
import "./BasicPlanNew.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { campaignActions } from "../../../store/campaign-slice";
import { API_URL } from "../../../constant/apiURL";
import { useEffect } from "react";
import BudgetCard2 from "./BudgetCard2/BudgetCard2";
import ToggleButtonsCard from "../../../components/UI/ToggleButtonsCard/ToggleButtonsCard";
import { redInfoIcon } from "../../../assets";
const BasicPlanNew = () => {
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [ratesList, setRatesList] = useState(null);

  const [toPrint, setToPrint] = useState(true);
  const [isPrePrinted, setIsPrePrinted] = useState(false);
  const [selectedPlan1, setSelectedPlan1] = useState(false);
  const [selectedPlan2, setSelectedPlan2] = useState(false);
  const [confirmPlanError, setConfirmPlanError] = useState(false);

  const [prePrintedQty, setPrePrintedQty] = useState(false);
  const [prePrintedBgt, setPrePrintedBgt] = useState(false);

  const pamphletData = useSelector((state) => state.campaigns.pamphletData);
  const quantityArr = [
    1000, 2000, 4000, 8000, 16000, 24000, 32000, 48000, 56000, 96000, 104000,
  ];

  const chooseOnClick1 = () => {
    setSelectedPlan1(true);
    setSelectedPlan2(false);
  };

  const chooseOnClick2 = () => {
    setSelectedPlan2(true);
    setSelectedPlan1(false);
  };
  const printingDistributionHandler = () => {
    setIsPrePrinted(false);
    setToPrint(true);
  };
  const distributionHandler = () => {
    setToPrint(false);
    setIsPrePrinted(true);
  };

  useEffect(() => {
    // Rate list api
    fetch(API_URL + "pamphlet-quantity-rate/list/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRatesList(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Rate Calculation as per size, side & quality

  // For 90GSM
  const isA5S90GSM =
    pamphletData?.selectedSize === "A5" &&
    pamphletData?.selectedSide === "Single" &&
    pamphletData?.selectedQuality === "90GSM";

  const isA5D90GSM =
    pamphletData?.selectedSize === "A5" &&
    pamphletData?.selectedSide === "Double" &&
    pamphletData?.selectedQuality === "90GSM";

  const isA4S90GSM =
    pamphletData?.selectedSize === "A4" &&
    pamphletData?.selectedSide === "Single" &&
    pamphletData?.selectedQuality === "90GSM";

  const isA4D90GSM =
    pamphletData?.selectedSize === "A4" &&
    pamphletData?.selectedSide === "Double" &&
    pamphletData?.selectedQuality === "90GSM";

  // For 130GSM
  const isA5S130GSM =
    pamphletData?.selectedSize === "A5" &&
    pamphletData?.selectedSide === "Single" &&
    pamphletData?.selectedQuality === "130GSM";

  const isA5D130GSM =
    pamphletData?.selectedSize === "A5" &&
    pamphletData?.selectedSide === "Double" &&
    pamphletData?.selectedQuality === "130GSM";

  const isA4S130GSM =
    pamphletData?.selectedSize === "A4" &&
    pamphletData?.selectedSide === "Single" &&
    pamphletData?.selectedQuality === "130GSM";

  const isA4D130GSM =
    pamphletData?.selectedSize === "A4" &&
    pamphletData?.selectedSide === "Double" &&
    pamphletData?.selectedQuality === "130GSM";

  // Rate of A5 Duplex 90GSM
  const a5d90GSMRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    pamphletData?.pamphletQty < 7999
      ? ratesList?.[0]?.rate_points?.[0]?.price
      : ratesList?.[0]?.rate_points?.[1]?.price;

  // Rate of A5 Single 90GSM
  const a5s90GSMRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    pamphletData?.pamphletQty < 7999
      ? ratesList?.[1]?.rate_points?.[0]?.price
      : ratesList?.[1]?.rate_points?.[1]?.price;

  // Rate of A4 Duplex 90GSM
  const a4d90GSMRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    pamphletData?.pamphletQty < 7999
      ? ratesList?.[2]?.rate_points?.[0]?.price
      : ratesList?.[2]?.rate_points?.[1]?.price;

  // Rate of A4 Single 90GSM
  const a4s90GSMRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    pamphletData?.pamphletQty < 7999
      ? ratesList?.[3]?.rate_points?.[0]?.price
      : ratesList?.[3]?.rate_points?.[1]?.price;

  // Rate for 130 GSM
  const a5d130GSMRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    pamphletData?.pamphletQty < 7999
      ? ratesList?.[4]?.rate_points?.[0]?.price
      : ratesList?.[4]?.rate_points?.[1]?.price;

  // Rate of A5 Single 90GSM
  const a5s130GSMRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    pamphletData?.pamphletQty < 7999
      ? ratesList?.[5]?.rate_points?.[0]?.price
      : ratesList?.[5]?.rate_points?.[1]?.price;

  // Rate of A4 Duplex 90GSM
  const a4d130GSMRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    pamphletData?.pamphletQty < 7999
      ? ratesList?.[6]?.rate_points?.[0]?.price
      : ratesList?.[6]?.rate_points?.[1]?.price;

  // Rate of A4 Single 90GSM
  const a4s130GSMRate =
    ratesList != null &&
    ratesList.length >= 0 &&
    pamphletData?.pamphletQty < 7999
      ? ratesList?.[7]?.rate_points?.[0]?.price
      : ratesList?.[7]?.rate_points?.[1]?.price;

  let amountToMultiply =
    (isA5D90GSM && a5d90GSMRate) ||
    (isA5S90GSM && a5s90GSMRate) ||
    (isA4D90GSM && a4d90GSMRate) ||
    (isA4S90GSM && a4s90GSMRate) ||
    (isA5D130GSM && a5d130GSMRate) ||
    (isA5S130GSM && a5s130GSMRate) ||
    (isA4D130GSM && a4d130GSMRate) ||
    (isA4S130GSM && a4s130GSMRate);

  const pamphletDetailsSubmitHandler = () => {
    const pamphletFormData = new FormData();
    pamphletFormData.append("pamphlet_type", isPrePrinted ? "PP" : "UP");
    pamphletFormData.append(
      "is_duplex",
      pamphletData?.selectedSide === "Single" ? false : true
    );
    pamphletFormData.append("size", pamphletData?.selectedSize);
    pamphletFormData.append("paper_quality", pamphletData?.selectedQuality);

    fetch(API_URL + "campaign/pamphlet-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: pamphletFormData,
    }).then(async (res) => {
      const data = await res.json();
      dispatch(campaignActions.setPamphletId({ pamphletId: data.id }));

      history.push({
        pathname: "/dashboard/basic-plan-detail",
        state: {
          pamphlet_id: data.id,
          qty: isPrePrinted ? +prePrintedQty : +pamphletData.pamphletQty,
          budget: isPrePrinted
            ? +prePrintedBgt
            : pamphletData.pamphletQty * amountToMultiply,
        },
      });
    });
  };

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />

      <div className="budget_selection_page_main_div">
        <div className="budget_selection_page_sub_div">
          <div className="sub_title heading2">Choose Your Plan</div>
          <div className="printing_distribution_toggle_card">
            <div
              className={toPrint ? "selected_text " : "unselected_text"}
              onClick={printingDistributionHandler}
            >
              Printing & Distribution Plan
            </div>
            <div
              className={isPrePrinted ? "selected_text " : "unselected_text"}
              onClick={distributionHandler}
            >
              Only Distribution Plan
            </div>
          </div>
          <div className="basic_plan_cards_wrapper">
            <ToggleButtonsCard
              qtyCard={toPrint ? true : false}
              paperQlty={true}
              quantityArr={quantityArr}
              amountToMultiply={amountToMultiply}
              isPrePrinted={isPrePrinted}
              selectedPlan1={selectedPlan1}
              selectedPlan2={selectedPlan2}
            />
            <BudgetCard2
              side={pamphletData?.selectedSide}
              size={pamphletData?.selectedSize}
              quantity={pamphletData?.pamphletQty}
              amountToMultiply={amountToMultiply}
              isPrePrinted={isPrePrinted}
              setPrePrintedBgt={setPrePrintedBgt}
              setPrePrintedQty={setPrePrintedQty}
              chooseOnClick1={chooseOnClick1}
              selectedPlan1={selectedPlan1}
              chooseOnClick2={chooseOnClick2}
              selectedPlan2={selectedPlan2}
              isBenefits={true}
            />
          </div>
          {/* {confirmPlanError && (
            <div className="error_text error_text_info_icon_wrapper">
              <span>
                <img src={redInfoIcon} alt="" />
              </span>
              &nbsp; Please confirm your plan to continue
            </div>
          )} */}
        </div>
      </div>
      <div className="bottom_fixed_div">
        <ButtonNew
          className="new_back_btn"
          name="Back"
          onClick={() => {
            history.goBack();
          }}
        />
        <ButtonNew
          className="new_continue_btn"
          name="Continue"
          onClick={() => {
            if (selectedPlan1 && !isPrePrinted) {
              pamphletDetailsSubmitHandler();
            }
            if (!selectedPlan1 && !isPrePrinted) {
              alert("Please Confirm Your Plan First");
              // setConfirmPlanError(true);
            }

            if (selectedPlan2 && isPrePrinted) {
              pamphletDetailsSubmitHandler();
            }
            if (!selectedPlan2 && isPrePrinted) {
              alert("Please Confirm Your Plan");
              // setConfirmPlanError(true);
            }
          }}
        />
      </div>
    </>
  );
};
export default BasicPlanNew;
