import { useState, useEffect } from "react";
import {
  facebookLogo,
  instaLogo,
  linkedInLogo,
  twitterLogo,
  youTubeLogo,
} from "../../../../../assets";
import { socialMediaColor } from "../../../../../assets/UrlTypeColorIcons";
import "./SocialMediaDetails.css";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../../../../store/campaign-slice";
const SocialMediaDetails = (props) => {
  const dispatch = useDispatch();
  const [socialMediaDetails, setSocialMediaDetails] = useState({
    businessName: "",
    instagramLink: "",
    facebookLink: "",
    twitterLink: "",
    linkedInLink: "",
    youTubeLink: "",
  });
  const setData = (dataKey, dataValue) => {
    setSocialMediaDetails((state) => ({ ...state, [`${dataKey}`]: dataValue }));
  };

  // States for Validation
  const [url, setUrl] = useState("");
  const [isValidUrl1, setIsValidUrl1] = useState(true);
  const [isValidUrl2, setIsValidUrl2] = useState(true);
  const [isValidUrl3, setIsValidUrl3] = useState(true);
  const [isValidUrl4, setIsValidUrl4] = useState(true);
  const [isValidUrl5, setIsValidUrl5] = useState(true);

  const validateUrl = (url) => {
    const validSchemes = ["http://", "https://", "ftp://"];
    const containsScheme = validSchemes.some((scheme) => url.includes(scheme));
    // const containsWww = url.includes("www");
    // const containsDotCom = url.includes(".com");
    return containsScheme;
  };

  useEffect(() => {
    dispatch(
      campaignActions.setSocialMediaDetails({
        socialMediaDetails: socialMediaDetails,
      })
    );
  }, [socialMediaDetails, dispatch]);

  return (
    <>
      <div className="social_media_main_details">
        <div className="multiple_url_details_sub_div">
          <div className="sub_title url_type_heading">
            <span className="url_heading_icon">
              <img src={socialMediaColor} alt="socialMediaColor" />
            </span>
            Social Media
          </div>
          <div className="url_detail_section_wrapper">
            <div style={{ marginBottom: "0.5rem" }} className="sub_title_child">
              Business Name
            </div>
            <div>
              <input
                className="website_url_input"
                type="text"
                placeholder="Your Business Name"
                onChange={(e) => {
                  setData("businessName", e.target.value);
                }}
              />
              {props.isSocialMediaBusinessNameEmpty && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  Please Enter BusinessName
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: "1rem" }} className="url_input_wrapper">
            <div
              style={{ marginRight: "1rem", width: "100%", marginTop: "2rem" }}
            >
              <div className="sub_title_child">
                Social Media Links{" "}
                <span style={{ color: "#929292" }}>(optional)</span>
              </div>
              <div
                style={{ marginTop: "0.5rem" }}
                className="logo_input_wrapper"
              >
                <span style={{ marginRight: "1rem" }}>
                  <img src={instaLogo} alt="instaLogo" />
                </span>
                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  placeholder="Instagram Profile Link"
                  type="text"
                  onChange={(e) => {
                    setData("instagramLink", e.target.value);
                    setUrl(e.target.value);
                    setIsValidUrl1(validateUrl(e.target.value));
                    props.setIsUrlValid(validateUrl(e.target.value));
                  }}
                />
              </div>
              {!isValidUrl1 && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  Please Enter Valid URL
                </p>
              )}
              <div style={{ marginTop: "1rem" }} className="logo_input_wrapper">
                <span style={{ marginRight: "1rem" }}>
                  <img src={facebookLogo} alt="facebookLogo" />
                </span>

                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  placeholder="Facebook Profile Link"
                  type="text"
                  onChange={(e) => {
                    setData("facebookLink", e.target.value);
                    setUrl(e.target.value);
                    setIsValidUrl2(validateUrl(e.target.value));
                    props.setIsUrlValid(validateUrl(e.target.value));
                  }}
                />
              </div>
              {!isValidUrl2 && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  Please Enter Valid URL
                </p>
              )}
              <div style={{ marginTop: "1rem" }} className="logo_input_wrapper">
                <span style={{ marginRight: "1rem" }}>
                  <img src={twitterLogo} alt="twitterLogo" />
                </span>

                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  placeholder="Twitter Profile Link"
                  type="text"
                  onChange={(e) => {
                    setData("twitterLink", e.target.value);
                    setUrl(e.target.value);
                    setIsValidUrl3(validateUrl(e.target.value));
                    props.setIsUrlValid(validateUrl(e.target.value));
                  }}
                />
              </div>
              {!isValidUrl3 && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  Please Enter Valid URL
                </p>
              )}
              <div style={{ marginTop: "1rem" }} className="logo_input_wrapper">
                <span style={{ marginRight: "1rem" }}>
                  <img src={linkedInLogo} alt="linkedInLogo" />
                </span>

                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  placeholder="LinkedIn Profile Link"
                  type="text"
                  onChange={(e) => {
                    setData("linkedInLink", e.target.value);
                    setUrl(e.target.value);
                    setIsValidUrl4(validateUrl(e.target.value));
                    props.setIsUrlValid(validateUrl(e.target.value));
                  }}
                />
              </div>
              {!isValidUrl4 && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  Please Enter Valid URL
                </p>
              )}
              <div style={{ marginTop: "1rem" }} className="logo_input_wrapper">
                <span style={{ marginRight: "1rem" }}>
                  <img src={youTubeLogo} alt="youTubeLogo" />
                </span>

                <input
                  style={{ width: "100%" }}
                  className="website_url_input"
                  placeholder="YouTube Channel Link"
                  type="text"
                  onChange={(e) => {
                    setData("youTubeLink", e.target.value);
                    setUrl(e.target.value);
                    setIsValidUrl5(validateUrl(e.target.value));
                    props.setIsUrlValid(validateUrl(e.target.value));
                  }}
                />
              </div>
              {!isValidUrl5 && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  Please Enter Valid URL
                </p>
              )}
              {props.isSocialMediaUrlsEmpty && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  Please Enter Atleast One URL
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SocialMediaDetails;
