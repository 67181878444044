import React from "react";
import WhatsAppFloatIcon from "../WhatsappFloatIcon/WhatsAppFloatIcon";
import FormSec from "./FormSec/FormSec";
import Git from "./GetInTouchSection/Git";
import Nav from "../Nav/Nav";
import ContactUs from "../ContactUs/ContactUs";

const ContactUsPage = () => {
  return (
    <>
      <WhatsAppFloatIcon></WhatsAppFloatIcon>
      <Nav />
      <Git />
      <FormSec />
      <ContactUs page={"/ads/contact"} />
    </>
  );
};

export default ContactUsPage;
