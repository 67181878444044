import { useEffect, useState } from "react";
import "./PamphletFileInput.css";
import FileInput from "../../../components/UI/FileInput/FileInput";
import FileInputForVoucher from "../../../components/UI/FileInputForVoucher/FileInputForVoucher";
import { fileUploadIcon } from "../../../assets";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
const PamphletFileInput = (props) => {
  const [selectedOption, setSelectedOption] = useState("yes");
  useEffect(() => {
    props.setSelectedOption(selectedOption);
  }, [selectedOption]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  let isHalfFold =
    props.qtyAdtypes?.[0]?.[0] ||
    props.qtyAdtypes?.[1]?.[0] ||
    props.qtyAdtypes?.[2]?.[0] ||
    props.bgtAdtypes?.[0]?.[0] ||
    props.bgtAdtypes?.[1]?.[0] ||
    props.bgtAdtypes?.[2]?.[0];

  let isTriFold =
    props.qtyAdtypes?.[0]?.[1] ||
    props.qtyAdtypes?.[1]?.[1] ||
    props.qtyAdtypes?.[2]?.[1] ||
    props.bgtAdtypes?.[0]?.[1] ||
    props.bgtAdtypes?.[1]?.[1] ||
    props.bgtAdtypes?.[2]?.[1];

  return (
    <>
      <div className="stepper_input_main_div">
        <div className="pamphlet_file_input_sub_div">
          <div className="file_upload_text_wrapper">
            <h4 className="m_b_2">
              <span>
                <img src={fileUploadIcon} alt="fileUploadIcon" />
              </span>
              &nbsp; File Upload
            </h4>
            <div className="text_center f_s_18">
              Would you like to upload files for your Ads now?
            </div>

            <div className="file_upload_toggle_wrapper">
              <h5>Upload Now</h5>
              <ToggleButtonGroup
                color="primary"
                value={selectedOption}
                exclusive
                // onChange={handleQualityChange}
                aria-label="Platform"
                sx={{
                  // width: "180px",
                  color: "#43ba56",
                  border: "2px solid rgba(217, 217, 217, 1)",
                  borderRadius: "30px",
                  // padding: "3px",
                  margin: "0.5rem",
                }}
                className="file_toggle_btn_grp"
              >
                <ToggleButton
                  className={
                    selectedOption === "yes"
                      ? "file-selected-toggle"
                      : "file-unselected-toggle"
                  }
                  value="yes"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedOption("yes");
                  }}
                ></ToggleButton>

                <ToggleButton
                  className={
                    selectedOption === "no"
                      ? "file-selected-toggle_2"
                      : "file-unselected-toggle"
                  }
                  value="no"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    border: "none",
                    // backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                  onClick={() => {
                    setSelectedOption("no");
                  }}
                ></ToggleButton>
              </ToggleButtonGroup>
              <h5>Upload Later</h5>
            </div>
          </div>
          <div className="file_upload_wrapper">
            {selectedOption === "yes" ? (
              <>
                {props.pamphletId && (
                  <div className="file_label_input_wrapper">
                    <div className="sub_title_child">
                      {props.pamphSize === "A4" &&
                        props.isDuplex &&
                        isHalfFold &&
                        !isTriFold &&
                        "Half-Fold Side1"}
                      {props.pamphSize === "A4" &&
                        props.isDuplex &&
                        isTriFold &&
                        !isHalfFold &&
                        "Tri-Fold Side1"}

                      {props.pamphSize === "A5" &&
                        props.isDuplex &&
                        "Standard Side1"}
                      {props.pamphSize === "A5" &&
                        !props.isDuplex &&
                        "Standard File"}
                    </div>
                    <input
                      className="ad_type_file"
                      type="file"
                      name="pamphlet_file"
                      id=""
                      onChange={(e) => {
                        props.setPamphletFile1(e.target.files[0]);
                      }}
                    />
                  </div>
                )}
                {props.isDuplex && props.pamphletId && (
                  <div className="file_label_input_wrapper">
                    <div className="sub_title_child">
                      {props.pamphSize === "A4" &&
                        props.isDuplex &&
                        isHalfFold &&
                        !isTriFold &&
                        "Half-Fold Side2"}
                      {props.pamphSize === "A4" &&
                        props.isDuplex &&
                        isTriFold &&
                        !isHalfFold &&
                        "Tri-Fold Side2"}
                      {props.pamphSize === "A5" &&
                        props.isDuplex &&
                        "Standard Side2"}
                    </div>
                    <input
                      className="ad_type_file"
                      type="file"
                      name="pamphlet_file"
                      id=""
                      onChange={(e) => {
                        props.setPamphletFile2(e.target.files[0]);
                      }}
                    />
                  </div>
                )}{" "}
                {props.footerId && (
                  <div className="file_label_input_wrapper">
                    <div className="sub_title_child">Footer File</div>
                    <input
                      className="ad_type_file"
                      type="file"
                      name="footer_file"
                      id=""
                      onChange={(e) => {
                        props.setFooterFile(e.target.files[0]);
                      }}
                    />
                  </div>
                )}
                {props.displayAdId && (
                  <div className="file_label_input_wrapper">
                    <div className="sub_title_child">Display File</div>
                    <input
                      className="ad_type_file"
                      type="file"
                      name="footer_file"
                      id=""
                      onChange={(e) => {
                        props.setDisplayAdFile(e.target.files[0]);
                      }}
                    />
                  </div>
                )}
                {props.labelAdId && (
                  <div className="file_label_input_wrapper">
                    <div className="sub_title_child">Front Page File</div>
                    <input
                      className="ad_type_file"
                      type="file"
                      name="footer_file"
                      id=""
                      onChange={(e) => {
                        props.setLabelAdFile(e.target.files[0]);
                      }}
                    />
                  </div>
                )}
              </>
            ) : null}{" "}
          </div>
          {props.pamphletFileError && (
            <div className="error_text_child">Please Attach File</div>
          )}
        </div>
      </div>
    </>
  );
};
export default PamphletFileInput;
