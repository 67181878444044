import { callIcon, helpFaq, mailIcon, rightArrowGreen } from "../../../assets";
import FaqAccordian from "./FaqAccordian/FaqAccordian";
import "./HelpFaqPage.css";
const HelpFaqPage = (props) => {
  const handleEmailClick = () => {
    // window.location.href = "mailto:support@ewayprint.com";
  };
  return (
    <>
      <div className="flex_grow_1 dashboard__main_div">
        <div className="help_faq_main_div">
          <div className="help_faq_sub_div">
            <div className="help_faq_img_wrapper">
              <img className="help_faq_img" src={helpFaq} alt="" />
            </div>
            <div className="two_divs_wrapper">
              <div className="faq_accordian_main_div">
                <FaqAccordian />
              </div>
              <div className="contact_detail_div">
                <div className="timing_info_div">
                  <div className="sub_title">
                    Reach Out To Our Support Team For Assistance
                  </div>
                  <div>
                    We are here to assist you with any inquiries you may have
                    from <b>10:00 AM to 7:00 PM.</b> Feel free to contact us via
                    email, and we will respond to you promptly.
                  </div>
                </div>

                <div className="contact_number_div m_t_1">
                  <div className="sub_title green_text">Call us on</div>
                  <div className="number_arrrow_wrapper">
                    <div className=" pointer">
                      <span>
                        <img
                          style={{ width: "21px", marginRight: "0.5rem" }}
                          src={callIcon}
                          alt="callIcon"
                        />
                      </span>
                      <a href="tel:+91 777 0010 066">+91 777 0010 066</a>
                    </div>
                    <div>
                      <img src={rightArrowGreen} alt="rightArrowGreen" />
                    </div>
                  </div>
                </div>

                <div className="contact_number_div m_t_1">
                  <div className="sub_title green_text">E-mail</div>
                  <div className="number_arrrow_wrapper">
                    <div className=" pointer" onClick={handleEmailClick}>
                      <span>
                        <img
                          style={{ width: "21px", marginRight: "0.5rem" }}
                          src={mailIcon}
                          alt="mailIcon"
                        />
                      </span>
                      <a href="mailto:support@ewayprint.com">
                        support@ewayprint.com
                      </a>
                    </div>
                    <div>
                      <img src={rightArrowGreen} alt="rightArrowGreen" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HelpFaqPage;
