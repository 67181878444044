import "./MultipleUrlDetails.css";
import linkColor from "./../../../../../assets/UrlTypeColorIcons/link (1).svg";
import { useState } from "react";
import MultipleInputFields from "./MultipleInputFields/MultipleInputFields";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../../../../store/campaign-slice";
import { useEffect } from "react";
const MultipleUrlDetails = (props) => {
  const [previewUrl, setPreviewUrl] = useState("");
  const dispatch = useDispatch();

  const [multiUrlDetails, setMultiUrlDetails] = useState({
    businessName: "",
    logo: null,
    urls: "",
  });
  useEffect(() => {
    props.setMultipleUrlDetails(multiUrlDetails);
  }, [multiUrlDetails]);

  // console.log(multiUrlDetails, "multiUrlDetails");
  // console.log(multiUrlDetails.urls, "multiUrlDetails.urls");
  const setData = (dataKey, dataVal) => {
    setMultiUrlDetails((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };
  // dispatch(
  //   campaignActions.multiUrlDetails({ multiUrlDetails: multiUrlDetails })
  // );
  // setData("urls", urls);

  const multiUrlImgHandler = (e) => {
    const file = e.target.files[0];
    setData("logo", file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <div className="multiple_url_details_main_div">
        <div className="multiple_url_details_sub_div">
          <div className="sub_title url_type_heading">
            <span className="url_heading_icon">
              <img src={linkColor} alt="linkColor" />
            </span>
            Multiple Links
          </div>

          <div className="url_detail_section_wrapper">
            <div className="sub_title_child">Business Name</div>
            <div>
              <input
                className="website_url_input"
                type="text"
                placeholder="Your Business Name"
                onChange={(e) => {
                  setData("businessName", e.target.value);
                }}
              />
              {props.isMultipleLinksBusinessNameEmpty && (
                <div style={{ color: "red" }}>Please Enter Business Name</div>
              )}
            </div>
          </div>

          <div className="img_detail_section_wrapper">
            <div className="sub_title_child">Image / Logo</div>
            <div>
              <input
                className="img_input"
                type="file"
                name=""
                id=""
                onChange={multiUrlImgHandler}
              />
              {props.isMultipleLinksLogoEmpty && (
                <div style={{ color: "red" }}>Please Attach Logo / image</div>
              )}
            </div>
          </div>
          <div className="logo_preview_wrapper">
            {previewUrl && (
              <img
                className="logo_preview"
                src={previewUrl}
                alt="ImagePreview"
              />
            )}
          </div>
          <div
            style={{ marginTop: "2rem", marginBottom: "1rem" }}
            className="sub_title "
          >
            Links{" "}
            <span style={{ color: "#929292", fontSize: "17px" }}>
              (Maximum Limit is 5)
            </span>
          </div>
          <div className="">
            <MultipleInputFields
              setMultiUrlDetails={setMultiUrlDetails}
              isMultipleLinksUrlsEmpty={props.isMultipleLinksUrlsEmpty}
              setIsUrlValid={props.setIsUrlValid}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default MultipleUrlDetails;
