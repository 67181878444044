import CardNewFlow from "../../components/UI/CardNewFlow/CardNewFlow";
import "./CampaignCreateNew.css";
import {
  remarketing1,
  remarketing2,
  remarketing3,
  expandArrow,
} from "./../../assets";
import CampaignHeader from "../../components/CampaignLayout/Header/CampaignHeader";
import Button from "../../components/UI/GreenBtn/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import HorizontalDivider from "../../components/UI/Dividers/HorizontalDivider";
import { useState } from "react";
import PopUp from "../../components/UI/PopUp/PopUp";

const CampaignCreateNew = (props) => {
  const [selectedPlan1, setSelectedPlan1] = useState(false);
  const [selectedPlan2, setSelectedPlan2] = useState(false);
  const [selectedPlan3, setSelectedPlan3] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  function togglePopup() {
    setShowPopup(!showPopup);
  }
  // const [selectedPlan, setSelectedPlan] = useState({
  //   brandRecall: null,
  //   reachNewAudience: null,
  //   awareness: null,
  // });
  // const setData = (dataKey, dataVal) => {
  //   setSelectedPlan((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  // };

  const history = useHistory();
  const location = useLocation();

  // const selectionHandler0 = (selectionType) => {
  //   setData("brandRecall", selectionType);
  // };
  // const selectionHandler1 = (selectionType) => {
  //   setData("reachNewAudience", selectionType);
  // };
  // const selectionHandler2 = (selectionType) => {
  //   setData("awareness", selectionType);
  // };
  // console.log(selectedPlan.brandRecall);
  // console.log(selectedPlan.reachNewAudience);
  // console.log(selectedPlan.awareness);

  const cardHeadings = [
    { goalName: "Brand Remembrance" },
    { goalName: "Target New Customers" },
    { goalName: "Increase Brand Awareness" },
  ];

  const cardInfo = [
    {
      details:
        "Increase the recall value of your brand among customers through exclusive vouchers",
    },
    {
      details:
        "Acquire new customers by targeting specific locations through exclusive vouchers",
    },
    {
      details:
        "Advertise pamphlets in random locations to increase brand awareness ",
    },
  ];

  const img1 = (
    <img style={{ width: "5rem" }} src={remarketing1} alt="remarketing1"></img>
  );
  const img2 = (
    <img style={{ width: "5rem" }} src={remarketing2} alt="remarketing2"></img>
  );
  const img3 = (
    <img style={{ width: "5rem" }} src={remarketing3} alt="remarketing3"></img>
  );
  const expand = <img src={expandArrow} alt="expand"></img>;

  const cardSelectHandler1 = () => {
    setSelectedPlan1(true);
    setSelectedPlan2(false);
    setSelectedPlan3(false);
  };
  const cardSelectHandler2 = () => {
    setSelectedPlan1(false);
    setSelectedPlan2(true);
    setSelectedPlan3(false);
  };
  const cardSelectHandler3 = () => {
    setSelectedPlan1(false);
    setSelectedPlan2(false);
    setSelectedPlan3(true);
  };
  // let myString = "17";
  // console.log(typeof myString);
  // let convert = +myString;
  // console.log(typeof convert);

  return (
    <>
      <CampaignHeader />
      <div className="main_container ">
        <div className="main__sub_container">
          <div className="heading ">Select your advertising goal</div>
          {/* <div className="txt_center body_text_xs voucher_details">
            please select your advertising goal
          </div> */}
          <div className="cards_wrapper  ">
            <CardNewFlow
              onClick={() => {
                cardSelectHandler3();
              }}
              isSelected={selectedPlan3}
              Goal={cardHeadings[2].goalName}
              Image={img3}
              Details={cardInfo[2].details}
              Button="LearnMore"
              ButtonIcon={expand}
            />
            <CardNewFlow
              onClick={() => {
                cardSelectHandler1();
              }}
              isSelected={selectedPlan1}
              Goal={cardHeadings[0].goalName}
              Image={img1}
              Details={cardInfo[0].details}
              Button="Learn More"
              ButtonIcon={expand}
              comingSoon="Coming Soon"
            />
            <CardNewFlow
              onClick={() => {
                cardSelectHandler2();
              }}
              isSelected={selectedPlan2}
              Goal={cardHeadings[1].goalName}
              Image={img2}
              Details={cardInfo[1].details}
              Button="LearnMore"
              ButtonIcon={expand}
              comingSoon="Coming Soon"
            />

            {showPopup && (
              <PopUp onClose={togglePopup}>
                <p style={{ color: "red" }}>Please Select Advertising Goal</p>
              </PopUp>
            )}
          </div>

          <div className="wrapper">
            <div className="divider_brand_recall ">
              <HorizontalDivider />
            </div>
            <div className="button_wrapper">
              <Button
                className="white"
                onClick={() => history.push("/dashboard")}
                btnTxtClassName=" sub_title text_black"
                name="Back"
              />
              <Button
                type="submit"
                name="Next"
                onClick={() => {
                  // if (selectedPlan1 === true) {
                  //   history.push({
                  //     pathname: "/campaign/create/new/brand-recall",
                  //     state: { brandRecall: selectedPlan1 },
                  //   });
                  // }
                  // if (selectedPlan2 === true) {
                  //   history.push({
                  //     pathname: "/ads/campaign/create/new/reach-new-audience",
                  //     state: { reachNewAudience: selectedPlan2 },
                  //   });
                  // }
                  if (selectedPlan3 === true) {
                    history.push({
                      pathname: "/ads/campaign/create/new/awareness",
                      state: { awareness: selectedPlan3 },
                    });
                  }
                  // if (
                  //   selectedPlan1 === false &&
                  //   selectedPlan2 === false &&
                  //   selectedPlan3 === false
                  // ) {
                  //   togglePopup();
                  // }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CampaignCreateNew;
