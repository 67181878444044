import { TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import CardWrapper from "../CardWrapper/CardWrapper";

import "./FileInputForVoucher.css";
import icon from "./Icon.svg";
import x from "./x.svg";
import img from "./photograph.svg";

const FileInputForVoucher = (props) => {
  const [fileName, setFileName] = useState("");
  const classes = `w_100   ${props.className}`;
  return (
    <div className={classes}>
      <label className=" sub_title w_100" htmlFor={`img${props.idHelper}`}>
        <CardWrapper id="for_pamphlet" className="fileInput_main2 w_100">
          <TextField
            onChange={(e) => {
              props.onChange(e);
              props.setFileName(e.target.files[0].name);
            }}
            type="file"
            inputProps={{
              accept:
                "application/x-photoshop, application/octet-stream, image/vnd.adobe.photoshop, application/x-coreldraw, image/*, application/pdf, .cdr",
            }}
            id={`img${props.idHelper}`}
            variant="outlined"
            className="w_100"
            name={`img${props.idHelper}`}
            sx={{ display: "none" }}
          />
          {props.fileName === "" && (
            <>
              <div>
                <img src={icon} alt="plusIcon"></img>
              </div>
              <div className="sub_title opaque">Attach file </div>
            </>
          )}
          {props.fileName !== "" && (
            <>
              <div>
                <img src={img} alt="imgIcon"></img>
              </div>
              <div className="sub_title truncate" style={{ flex: "1" }}>
                {props.fileName}
              </div>
              <div>
                <img src={x} alt="xIcon"></img>
              </div>
            </>
          )}
        </CardWrapper>
      </label>
    </div>
  );
};

export default FileInputForVoucher;
