import "./UrlTypeCard.css";
const UrlTypeCard = (props) => {
  return (
    <div
      onClick={!props.comingSoon ? props.onClick : undefined}
      className={
        props.comingSoon
          ? "url_type_disabled_card_main_div"
          : props.selectedCard === props.cardId ||
            (props.default &&
              (props.selectedCard === "website" || props.selectedCard === null))
          ? "url_type_selected_card_main_div"
          : "url_type_unselected_card_main_div"
      }
    >
      <div className="url_type_icon_wrapper">
        <img
          src={
            props.selectedCard === props.cardId ? props.colorIcon : props.icon
          }
          alt="Icon"
        />
      </div>
      <div className="url_type_name">{props.name}</div>
    </div>
  );
};
export default UrlTypeCard;
