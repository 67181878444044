import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../constant/apiURL";
import useHttp from "../../../Hooks/useHttp";
import { campaignActions } from "../../../store/campaign-slice";
import "./TableWithRadio.css";

const data = [
  // {
  //   quantity: 500,
  //   amount: 0,
  //   discount: 0,
  //   discPer: 0.0,
  //   total: 0,
  // },
  {
    quantity: 2000,
    amount: 0,
    discount: 5,
    discPer: 0.05,
    total: 0,
  },
  {
    quantity: 5000,
    amount: 0,
    discount: 10,
    discPer: 0.1,
    total: 0,
  },
  {
    quantity: 10000,
    amount: 0,
    discount: 15,
    discPer: 0.15,
    total: 0,
  },
  {
    quantity: 20000,
    amount: 0,
    discount: 20,
    discPer: 0.2,
    total: 0,
  },
  {
    quantity: "Custom Quantity",
    amount: 0,
    discount: 0,
    discPer: 0,
    total: 0,
  },
];

const TableWithRadio = (props) => {
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState({});
  const [selectedPlan, setSelectedPlan] = useState();
  // console.log(selectedPlan);
  const [rowData, setRowData] = useState([...data]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const token = useSelector((state) => state.auth.token);

  dispatch(
    campaignActions.setRatesTableRowData({ ratesTableRowData: selectedPlan })
  );
  // console.log(selectedPlan);
  const handleRowClick = (index) => {
    setData("Index", index);
    setData("rowData", rowData[index]);
  };
  const setData = (dataKey, dataVal) => {
    setSelectedRow((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };

  let amountToMultiply =
    props.rateOfA5Single ||
    props.rateOfA5Duplex ||
    props.rateOfA4Single ||
    props.rateOfA4Duplex ||
    0;

  // console.log(amountToMultiply);
  //   console.log(i++);

  //   console.log(props.rateOfA5Single);
  //   console.log(+data[0].quantity);

  // console.log(props.rateOfA5Single);
  // console.log(props.rateOfA5Duplex);
  // console.log(props.rateOfA4Single);

  useEffect(() => {
    // setRowData(data.map((ele) => {
    //   {ele.amount = ele.quantity * amountToMultiply, ...ele  }
    // )
    // );

    for (let i = 0; i <= rowData?.length - 1; i++) {
      if (rowData[i].quantity === "Custom Quantity") {
        rowData[i] = {
          ...rowData[i],
          amount: 0,
          total: 0,
        };
      } else {
        rowData[i] = {
          ...rowData[i],
          amount: rowData[i].quantity * amountToMultiply,
          total:
            rowData[i]?.quantity * amountToMultiply -
            rowData[i].quantity * amountToMultiply * rowData[i].discPer,
        };
      }
    }
    setRowData(rowData);
  }, [amountToMultiply]);

  return (
    <div className="table_wrapper">
      <table className="table_div">
        <thead>
          <tr>
            <th className="table_data">Quantity</th>
            <th className="table_data">Amount</th>
            <th className="table_data">Discount</th>
            <th className="table_data">Total</th>
          </tr>
        </thead>
        {rowData && (
          <tbody>
            {rowData.map((row, index) => (
              <>
                <tr
                  key={index}
                  onClick={() => {
                    setSelectedPlan(rowData[index]);
                    handleRowClick(index);
                    props.setAmountWithDisc(row.total);
                  }}
                >
                  <td className="qty_radio_wrapper table_data">
                    <input
                      className="table_radio"
                      type="radio"
                      name="selectedRow"
                      value={index}
                      checked={selectedRow.Index === index}
                      onChange={() => {}}
                    />
                    {row.quantity}
                  </td>

                  <td className="table_data">
                    {row.quantity === "Custom Quantity" ? "" : row.amount}
                  </td>
                  <td className="table_data">
                    {row.quantity === "Custom Quantity"
                      ? ""
                      : `${row.discount}%`}
                  </td>
                  <td className="table_data">
                    {row.quantity === "Custom Quantity" ? "" : row.total}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default TableWithRadio;
