import { useState } from "react";
import "./ReferCodeInput.css";

const ReferCodeInput = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <div className="code_input_main_div">
        <div>
          <input
            type="checkbox"
            value="showQrCard"
            id="showPlanCodeInput"
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
              props.setIsChecked(e.target.checked);
            }}
          />
          &nbsp;&nbsp;
          <label htmlFor="showPlanCodeInput" className="sub_title">
            {props.label}
          </label>
        </div>

        {isChecked && (
          <>
            <div>
              <input
                onChange={props.onChange}
                className="code_input"
                type="text"
                placeholder={props.placeholder}
              />
            </div>
            <div>
              <button className="apply_btn" onClick={props.onClick}>
                {props.name}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ReferCodeInput;
