import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CampaignModal from "../../../components/CampaignComponents/CampaignModal/CampaignModal";
import CampaignHeader from "../../../components/CampaignLayout/Header/CampaignHeader";
import ReferCodeInput from "../../../components/UI/CampaignCodeInput/ReferCodeInput";
import HorizontalDivider from "../../../components/UI/Dividers/HorizontalDivider";
import FileInput from "../../../components/UI/FileInput/FileInput";
import Button from "../../../components/UI/GreenBtn/Button";
import Input from "../../../components/UI/Inputs/Input";
import PamphletDetailsCard from "../../../components/UI/PamphletDetailsCard/PamphletDetailsCard";
import { API_URL } from "../../../constant/apiURL";
import fetchData from "../../../Hooks/fetchData";
import { campaignActions } from "../../../store/campaign-slice";
import "./CampaignNewStepper.css";
import PopUp from "../../../components/UI/PopUp/PopUp";
import axios from "axios";

const CampaignNewStepper = (props) => {
  const dispatch = useDispatch();
  const [pamphletDetails, setPamphletDetails] = useState({});
  const setPamphletData = (dataKey, dataVal) => {
    setPamphletDetails((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };

  const [displayAdDetails, setDisplayAdDetails] = useState({});
  const setDisplayAdData = (dataKey, dataVal) => {
    setDisplayAdDetails((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };
  const [footerDetails, setFooterDetails] = useState({});
  const setFooterData = (dataKey, dataVal) => {
    setFooterDetails((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };
  const history = useHistory();
  const location = useLocation();
  // console.log(location.state);
  const { id = 0 } = useParams();

  const token = useSelector((state) => state.auth.token);
  const clientId = useSelector((state) => state.auth.clientId);
  const voucherId = useSelector((state) => state.campaigns.voucherId);
  const userId = useSelector((state) => state.auth.userId);
  const pamphletId = useSelector((state) => state.campaigns.pamphletId);
  const campaigns = useSelector((state) => state.campaigns.campaigns);

  //state for currentCampaign
  const [isCurrentCampaign, setIsCurrentCampaign] = useState();

  // console.log(voucherId);
  let adTypeIds = [];

  adTypeIds.push(location.state);
  // console.log({
  //   ...location.state,
  //   pamphletDetails: pamphletDetails,
  //   footerDetails: footerDetails,
  //   displayAdDetails: displayAdDetails,
  // });
  // console.log(location?.state?.objectfromList?.pamphletIsCheckedfromList);

  useEffect(() => {
    const types = [];
    // if(location.state.pamphletIsChecked)
    if (
      location?.state?.pamphletIsChecked ||
      location?.state?.pamphletIsCheckedfromList
    ) {
      types.push({ id: "pamphlet", name: "Pamphlet" });
    }
    if (location?.state?.footerIsChecked) {
      types.push({ id: "footer", name: "Footer" });
    }
    if (location?.state?.displayAdIsChecked) {
      types.push({ id: "displayAd", name: "Display Ad" });
    }

    setPamphletTypes(types);
    if (id) {
      const currentCampaign = campaigns.find(
        (campaign) => campaign.id.toString() === id
      );
      setIsCurrentCampaign(currentCampaign);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pamphletTypes, setPamphletTypes] = useState([]);
  const [previewSide, setPreviewSide] = useState("none");
  const [currStep, setCurrStep] = useState(0);
  const [isDoubleSide, setIsDoubleSide] = useState(false);
  const [frontImgInput, setFrontImgInput] = useState("");
  const [backImgInput, setBackImgInput] = useState("");
  const [frontImg, setFrontImg] = useState(null);
  const [frontFileName, setFrontFileName] = useState("");
  const [footerFrontFileName, setFooterFrontFileName] = useState("");
  const [displayAdFrontFileName, setDisplayAdFrontFileName] = useState("");
  const [backFileName, setBackFileName] = useState("");
  const [selectedSide, setSelectedSide] = useState("");
  const [selectedDesign, setSelectedDesign] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [backImg, setBackImg] = useState(null);
  const [footerFrontImg, setFooterFrontImg] = useState(null);
  const [footerFrontImgInput, setFooterFrontImgInput] = useState(null);
  const [footerHeight, setFooterHeight] = useState(null);
  const [displayAdImg, setDisplayAdImg] = useState(null);
  const [displayAdImgInput, setDisplayAdImgInput] = useState(null);
  const [enteredURL, setEnteredUrl] = useState();

  // states for design by us
  const [designByUsInput, setDesignByUsInput] = useState({});

  // states for validation
  const [codeErrorMsg, setCodeErrorMsg] = useState(false);
  const [hidePamphletDetailCard, setHidePamphletDetailCard] = useState(false);
  const [selectedPamphletType1, setSelectedPamphletType1] = useState(true);
  const [selectedPamphletType2, setSelectedPamphletType2] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  function togglePopup() {
    setShowPopup(!showPopup);
  }

  const selectedCard1 = () => {
    setSelectedPamphletType1(true);
    setSelectedPamphletType2(false);
    setHidePamphletDetailCard(false);
  };
  const selectedCard2 = () => {
    setSelectedPamphletType1(false);
    setSelectedPamphletType2(true);
    setIsCodeValid(false);
    setIsChecked(false);
    setHidePamphletDetailCard(true);
    setCodeErrorMsg(true);
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isSizeSelected, setIsSizeSelected] = useState(false);
  const [isSideSelected, setIsSideSelected] = useState(false);
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [isFrontFileAttached, setIsFrontFileAttached] = useState(false);
  const [isBackFileAttached, setIsBackFileAttached] = useState(false);
  const [isDoubleValidation, setIsDoubleValidation] = useState(false);

  // states for referCode List
  const [referCodeList, setReferCodeList] = useState();
  const [enteredCode, setEnteredCode] = useState("");
  const enteredPlanCode = enteredCode.toUpperCase();
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [inValidCode, setInValidCode] = useState();

  const setData = (dataKey, dataVal) => {
    setDesignByUsInput((state) => ({ ...state, [`${dataKey}`]: dataVal }));
  };

  const [isUpload, setIsUpload] = useState(true);
  const [isDesignByUs, setIsDesignByUs] = useState(false);

  const clientUpdate = (id, clientData) => {
    let clientID = localStorage.getItem("clientId");
    // console.log(clientID);
    let phone = localStorage.getItem("phone");
    let options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        contact_number: phone,
        user: userId,
        shop_name: designByUsInput.ShopName,
        address: designByUsInput.shopAddress1,
        website: designByUsInput.websiteLink,
        owner_name: designByUsInput.ownerName,
        description: designByUsInput.description,
      }),
    };
    fetchData(API_URL + `clients/update/${clientID}/`, options);
  };

  const handleSideChange = (event) => {
    setSelectedSide(event.target.value);
  };
  const handlePamphletDesign = (event) => {
    setSelectedDesign(event.target.value);
  };
  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };
  const handleFooterHeightChange = (e) => {
    setFooterHeight(e.target.value);
  };
  const doubleSideSelected = () => {
    setIsDoubleSide(true);
  };
  const singleSideSelected = () => {
    setIsDoubleSide(false);
  };
  const frontFileHandler = (e) => {
    onImageChange(e, setFrontImg);
    setFrontImgInput(e.target.files[0]);
    setPreviewSide((state) => (state = "front"));
  };
  const footerFrontFileHandler = (e) => {
    onImageChange(e, setFooterFrontImg);
    setFooterFrontImgInput(e.target.files[0]);
  };
  const displayAdFrontFileHandler = (e) => {
    onImageChange(e, setDisplayAdImg);
    setDisplayAdImgInput(e.target.files[0]);
  };

  const handleBackImg = (e) => {
    setBackImgInput(e.target.files[0]);
    onImageChange(e, setBackImg);
    setPreviewSide((state) => (state = "back"));
  };
  const handleFrontFileName = (val) => {
    setFrontFileName(val);
  };

  const handleBackFileName = (val) => {
    setBackFileName(val);
  };
  const onImageChange = (event, setState) => {
    if (event.target.files && event.target.files[0]) {
      setState(URL.createObjectURL(event.target.files[0]));
    }
  };

  // Refer Code List Api
  useEffect(() => {
    if (token) {
      (async () => {
        const resInd = await fetch(API_URL + "campaign/pamphlet-plans/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const dataInd = await resInd.json();
        // console.log(dataInd);
        setReferCodeList(dataInd);
      })();
    }
  }, [token]);

  // Client detail api
  const [clientDetails, setClientDetails] = useState();
  // console.log(clientDetails);
  useEffect(() => {
    if (token) {
      (async () => {
        const response = await fetch(`${API_URL}clients/detail/${clientId}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const dataInd = await response.json();
        setClientDetails(dataInd);
      })();
    }
  }, [token]);

  // pamphlet post api
  const pamphletDetailsSubmitHandler = () => {
    const pamphletFormData = new FormData();
    // formData.append("is_duplex", )
    pamphletFormData.append(
      "pamphlet_type",
      selectedPamphletType2 ? "PP" : selectedDesign
    );
    pamphletFormData.append("pamphlet_file1", frontImgInput);
    if (isDoubleSide) {
      pamphletFormData.append("pamphlet_file2", backImgInput);
    }

    pamphletFormData.append(
      "is_duplex",
      isCodeValid ? pamphletDetails.sideUsingCode : isDoubleSide
    );
    pamphletFormData.append(
      "size",
      isCodeValid ? pamphletDetails.sizeUsingCode : selectedSize
    );

    fetch(API_URL + "campaign/pamphlet-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: pamphletFormData,
    }).then(async (res) => {
      const data = await res.json();
      // props.setPamphletId(data.id);
      // console.log(data);
      dispatch(campaignActions.setPamphletId({ pamphletId: data.id }));
      // adTypeIds.push({
      //   type: "pamphlet",
      //   id: data.id,
      //   size: data.size,
      //   side: data.is_duplex,
      //   pamphlet_file1: data.pamphlet_file1,
      //   pamphlet_file2: data.pamphlet_file2,
      // });
      // console.log(adTypeIds);
    });
  };

  // footer post api
  const footerDetailsSubmitHandler = (e) => {
    const footerFormData = new FormData();
    footerFormData.append("footer_name", "-----");
    footerFormData.append("height", footerHeight);
    footerFormData.append("footer_file", footerFrontImgInput);

    fetch(API_URL + "campaign/footer-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: footerFormData,
    }).then(async (res) => {
      const data = await res.json();
      props.setFooterId(data.id);
      dispatch(campaignActions.setFooterId({ footerId: data.id }));
      adTypeIds.push({
        type: "footer",
        id: data.id,
        sizeOfFooter: data.height,
        fileOfFooter: data.footer_file,
      });
    });
  };

  // Display Ad Post Api
  const displayAdDetailSubmitHandler = () => {
    const displayAdFormData = new FormData();
    displayAdFormData.append("display_ad_name", frontFileName);
    displayAdFormData.append("display_ad_file", displayAdImgInput);
    displayAdFormData.append("ad_url", enteredURL);
    displayAdFormData.append("ad_url_type", displayAdDetails.adUrlType);

    fetch(API_URL + "campaign/display-ad-create/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: displayAdFormData,
    }).then(async (res) => {
      const data = await res.json();
      // console.log(data);
      props.setDisplayAdId(data.id);
      dispatch(campaignActions.setDisplayAdId({ displayAdId: data.id }));
      adTypeIds.push({
        type: "displayAd",
        id: data.id,
        fileOfDisplayAd: data.display_ad_file,
      });
    });
  };

  // Arrays for setBudget Dropdowns
  let countArray = [
    { id: 1, name: "1 Time" },
    { id: 2, name: "2 Times" },
    { id: 3, name: "3 Times" },
  ];

  let repeatRange = [
    { id: 10, name: "After 10 Days" },
    { id: 20, name: "After 20 Days" },
    { id: 30, name: "After 30 Days" },
  ];
  // states for dropdowns
  const [isRepeatRange, setIsRepeatRange] = useState(false);

  // dropdown list for ad_url_type
  let adUrlType = [
    { id: "video", name: "Video" },
    { id: "website", name: "Website" },
    { id: "s_m_account", name: "Social Media Account" },
    { id: "google_form", name: "Google Form" },
    { id: "other", name: "Other" },
  ];

  // functions for dropdowns
  const getCountSelected = (e) => {
    props.setRepeatCount(+e.target.value);
    if (+e.target.value === 2 || +e.target.value === 3) {
      setIsRepeatRange(true);
    }
    if (+e.target.value === 1) {
      setIsRepeatRange(false);
    }
  };

  const getRepeatSelected = (e) => {
    props.setRepeatRange(+e.target.value);
  };

  const pamphletForm = (
    <div className="pamphlet_details_card  m_t_3">
      <div className="pamphlet_details_div1">
        <div className="detail_child_div1">
          {/* <div
          className="sub_title m_t_1"
          onClick={() => {
            // pamphletDetailsSubmitHandler();
          }}
        >
          Pamphlet Details
        </div> */}
          {/* <div className="location_div">
          <div className="location m_t_1">Location -</div>
          <div className="pune m_t_1">Pune</div>
        </div> */}
          {selectedPamphletType1 && (
            <div>
              <ReferCodeInput
                onChange={(e) => {
                  setEnteredCode(e.target.value);
                  if (e.target.value.length === 0) {
                    setIsCodeValid(false);
                    setInValidCode(false);
                  }
                }}
                label="I Have a Plan Code ?"
                name={isCodeValid ? "Applied" : "Apply"}
                placeholder="Enter Plan Code"
                setIsChecked={setIsChecked}
                onClick={(e) => {
                  var i;
                  for (i = 0; i <= referCodeList?.length - 1; i++) {
                    if (referCodeList[i]?.plan_code !== enteredPlanCode) {
                      setInValidCode(true);
                      setCodeErrorMsg(false);
                    }
                    if (referCodeList[i]?.plan_code === enteredPlanCode) {
                      setInValidCode(false);

                      setPamphletData("sizeUsingCode", referCodeList[i]?.size);
                      setPamphletData(
                        "sideUsingCode",
                        referCodeList[i]?.is_duplex
                      );
                      setPamphletData("planCodeId", referCodeList[i]?.id);
                      setPamphletData(
                        "quantityUsingCode",
                        referCodeList[i]?.quantity
                      );
                      setPamphletData(
                        "amountUsingCode",
                        referCodeList[i]?.amount
                      );
                      setPamphletData(
                        "discountUsingCode",
                        referCodeList[i]?.discount
                      );

                      setIsCodeValid(true);
                    }
                  }
                }}
              />
            </div>
          )}
          {selectedPamphletType1 && (
            <div style={{ marginTop: "1rem" }}>
              <HorizontalDivider />
            </div>
          )}
          {isCodeValid &&
            isChecked &&
            !selectedPamphletType2 &&
            !hidePamphletDetailCard &&
            referCodeList
              ?.filter((code) => {
                return enteredPlanCode === ""
                  ? code
                  : code.plan_code.includes(enteredPlanCode);
              })
              .map((code) => {
                return (
                  <PamphletDetailsCard
                    key={code.id}
                    size={code.size}
                    side={code.is_duplex}
                    quantity={code.quantity}
                    amount={code.amount}
                  />
                );
              })}
          {inValidCode &&
            !isCodeValid &&
            !selectedPamphletType2 &&
            !codeErrorMsg && (
              <p style={{ color: "red", marginTop: "0.5rem" }}>
                Plan Code Is Not Valid!
              </p>
            )}

          {((selectedPamphletType1 && !isCodeValid && !isChecked) ||
            selectedPamphletType2) && (
            <div className="size_main_div m_t_1">
              <div className="size_text sub_title">Pamphlet Size</div>

              <Grid item xs={6}>
                <RadioGroup
                  row
                  defaultValue="A5"
                  aria-label="pamphlet-size"
                  name="pamphlet-size"
                  onChange={(event) => {
                    handleSizeChange(event);
                    setPamphletData("pamphletSize", event.target.value);
                  }}
                  value={selectedSize}
                >
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 18,
                      },
                    }}
                    value="A5"
                    control={
                      <Radio
                        required
                        sx={{
                          color: grey[400],
                          "&.Mui-checked": {
                            color: green[500],
                          },
                        }}
                      />
                    }
                    label="A5 (148 x 210 mm)"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 18,
                      },
                    }}
                    value="A4"
                    control={
                      <Radio
                        required
                        sx={{
                          color: grey[400],
                          "&.Mui-checked": {
                            color: green[500],
                          },
                        }}
                      />
                    }
                    label="A4 (210 x 297 mm)"
                  />
                </RadioGroup>
              </Grid>
              {isSizeSelected && (
                <p style={{ color: "red" }}>Please Select Size</p>
              )}
            </div>
          )}
          {((selectedPamphletType1 && !isCodeValid && !isChecked) ||
            selectedPamphletType2) && (
            <div className="side_main_div m_t_1">
              <div className="side_text sub_title">Printing Side</div>
              <Grid item xs={6}>
                <RadioGroup
                  row
                  defaultValue="Single Side"
                  // onChange={handleSideChange}
                  onChange={(event) => {
                    handleSideChange(event);
                    setPamphletData("pamphletSide", event.target.value);
                  }}
                  value={selectedSide}
                >
                  <FormControlLabel
                    onClick={singleSideSelected}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 18,
                      },
                    }}
                    value="Single Side"
                    control={
                      <Radio
                        required
                        sx={{
                          color: grey[400],
                          "&.Mui-checked": {
                            color: green[500],
                          },
                        }}
                      />
                    }
                    label="Single Side"
                  />
                  <FormControlLabel
                    onClick={() => {
                      setIsDoubleValidation(true);
                      doubleSideSelected();
                    }}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 18,
                      },
                    }}
                    value="Double Side"
                    control={
                      <Radio
                        required
                        sx={{
                          color: grey[400],
                          "&.Mui-checked": {
                            color: green[500],
                          },
                        }}
                      />
                    }
                    label="Double Side"
                  />
                </RadioGroup>
              </Grid>
              {isSideSelected && (
                <p style={{ color: "red" }}>Please Select Side</p>
              )}
            </div>
          )}
          {selectedPamphletType1 && (
            <div className="side_main_div m_t_1">
              <div className="side_text sub_title">
                Upload or design pamphlet
              </div>
              <Grid item xs={6}>
                <RadioGroup
                  row
                  defaultValue="I Have a Pamphlet Design"
                  onChange={(event) => {
                    handlePamphletDesign(event);
                    setPamphletData("pamphletDesign", event.target.value);
                  }}
                  value={selectedDesign}
                >
                  <FormControlLabel
                    value="UP"
                    onClick={() => {
                      setIsUpload(true);
                      setIsDesignByUs(false);
                    }}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 18,
                      },
                    }}
                    control={
                      <Radio
                        required
                        sx={{
                          color: grey[400],
                          "&.Mui-checked": {
                            color: green[500],
                          },
                        }}
                      />
                    }
                    label="I Have a Pamphlet Design"
                  />
                  <FormControlLabel
                    value="DS"
                    onClick={() => {
                      setIsDesignByUs(true);
                      setIsUpload(false);
                    }}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: 18,
                      },
                    }}
                    control={
                      <Radio
                        required
                        sx={{
                          color: grey[400],
                          "&.Mui-checked": {
                            color: green[500],
                          },
                        }}
                      />
                    }
                    label="Design My Pamphlet"
                  />
                  <br></br>
                  {clientDetails?.client_type === "super_client" && (
                    <FormControlLabel
                      value="PP"
                      onClick={() => {
                        setIsUpload(true);
                        setIsDesignByUs(false);
                      }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: 18,
                        },
                      }}
                      control={
                        <Radio
                          required
                          sx={{
                            color: grey[400],
                            "&.Mui-checked": {
                              color: green[500],
                            },
                          }}
                        />
                      }
                      label="I Have Printed Pamphlets"
                    />
                  )}
                </RadioGroup>
              </Grid>
              {isTypeSelected && (
                <p style={{ color: "red" }}>Please Select Pamphlet Type</p>
              )}
            </div>
          )}
          <div className="attach_file_main_div m_t_1">
            <div className="attach_file_text side_text sub_title">
              Upload Front Side
            </div>
            <div className="file_input_div m_t_1 w_100">
              <FileInput
                idHelper="front"
                fileName={frontFileName}
                // onChange={frontFileHandler}
                onChange={(e) => {
                  frontFileHandler(e);
                  setPamphletData("frontFileName", e.target.files[0]?.name);
                }}
                setFileName={handleFrontFileName}
              />
              {isFrontFileAttached && (
                <p style={{ color: "red" }}>Please Attach File </p>
              )}
            </div>
            {isDoubleSide || pamphletDetails.sideUsingCode === true ? (
              <>
                <div className="attach_file_text m_t_1 side_text sub_title">
                  Upload Back Side
                </div>
                <div className="file_input_div m_t_1 w_100">
                  <FileInput
                    idHelper="back"
                    fileName={backFileName}
                    setFileName={handleBackFileName}
                    // onChange={handleBackImg}
                    onChange={(event) => {
                      handleBackImg(event);
                      setPamphletData(
                        "backFileName",
                        event.target.files[0]?.name
                      );
                    }}
                  />
                  {isBackFileAttached && (
                    <p style={{ color: "red" }}>Please Attach File </p>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="detail_child_div2">
          {isUpload && (
            <div className="campaign_modal">
              <CampaignModal
                className="preview_modal"
                previewSide={previewSide}
                frontImgInput={frontImgInput}
                frontImg={frontImg}
                backImgInput={backImgInput}
                backImg={backImg}
                isDuplex={selectedSide === "Double Side"}
                isDuplexForPlanCode={pamphletDetails.sideUsingCode}
                setPreviewSide={setPreviewSide}
              />
            </div>
          )}
          {isDesignByUs && (
            <div className="design_by_us_main_div">
              <div className="business_name_main_div">
                <Input
                  onChange={(e) => {
                    setData("ShopName", e.target.value);
                  }}
                  label="Shop Name"
                  placeholder="Enter Business Name"
                ></Input>
              </div>
              <div className="shop_address_main_div">
                <Input
                  onChange={(e) => {
                    setData("shopAddress1", e.target.value);
                  }}
                  label="Shop Address"
                  placeholder="Enter Shop Address"
                ></Input>
              </div>

              <div className="website_main-div">
                <Input
                  onChange={(e) => {
                    setData("websiteLink", e.target.value);
                  }}
                  label="Website Link"
                  placeholder="Enter website link"
                ></Input>
              </div>

              <div className="owner_name_main_div">
                <Input
                  onChange={(e) => {
                    setData("ownerName", e.target.value);
                  }}
                  label="Owner Name"
                  placeholder="Enter Owner Name"
                ></Input>
              </div>

              <div className="description_main_div">
                <label
                  className="sub_title"
                  onClick={() => {
                    // console.log(designByUsInput);
                  }}
                >
                  {" "}
                  Description
                </label>
                <br></br>
                <textarea
                  onChange={(e) => {
                    setData("description", e.target.value);
                  }}
                  className="textarea"
                  // placeholder="Enter description to be added in your pamphlet"
                ></textarea>
              </div>

              {/* <div className="file_input_main_div">
                <label className="sub_title">Attach File</label>
                <FileInput
                  idHelper="front"
                  fileName={frontFileNameDU}
                  setFileName={(name) => setFrontFileNameDU(name)}
                  onChange={(e) => {
                    setData("fileInput1", e.target.files[0]);
                  }}
                />
                <FileInput
                  idHelper="back"
                  fileName={frontFileName2DU}
                  setFileName={(name) => setFrontFileName2DU(name)}
                  onChange={(e) => {
                    setData("fileInput2", e.target.files[0]);
                  }}
                />
              </div> */}
            </div>
          )}
        </div>
      </div>
      <div className="dropdowns_wrapper">
        <div className="campaign_duration_dropdown_main_div">
          <div style={{ textAlign: "center" }} className="sub_title ">
            Repeat Pamphlet Delivery
          </div>
          <div className="months_dropdown_wrapper">
            <select
              // onSelect={getCountSelected}
              // onChange={getCountSelected}
              onChange={(event) => {
                getCountSelected(event);
                setPamphletData("countSelected", +event.target.value);
              }}
              className="dropdown_months"
            >
              {countArray.map((count) => {
                return (
                  <option key={count.id} value={count.id}>
                    {count.name}
                  </option>
                );
              })}
            </select>
            <div
              style={{ marginTop: "0.5rem", textAlign: "center" }}
              className=""
            >
              Select how many times you want the pamphlet to be delivered to the
              same person
            </div>
          </div>
        </div>

        {isRepeatRange && (
          <div className="repeat_range_main_div">
            <div
              style={{ textAlign: "center", marginTop: "1rem" }}
              className="sub_title "
            >
              Repeat Range
            </div>
            <div className="repeat_range_wrapper">
              <select
                // onChange={getRepeatSelected}
                className="repeat_range_dropdown"
                onChange={(event) => {
                  getRepeatSelected(event);
                  setPamphletData("repeatRangeSelected", +event.target.value);
                }}
              >
                {repeatRange.map((repeat) => {
                  return (
                    <option key={repeat.id} value={repeat.id}>
                      {repeat.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
      </div>
      {showPopup && (
        <PopUp onClose={togglePopup}>
          <p style={{ color: "red" }}>All fields are required!</p>
        </PopUp>
      )}
    </div>
  );

  const footerForm = (
    <div className="footer_card_div ">
      <div className="footer_details_wrapper">
        <div className="footer_details_div1">
          <div
            className="footer_details_heading sub_title"
            // onClick={footerDetailsSubmitHandler}
          >
            Footer Details
          </div>
          <div className="size_main_div m_t_1">
            <div className="size_text">Footer Height</div>

            <Grid item xs={6}>
              <RadioGroup
                row
                defaultChecked={"2cm"}
                aria-label="pamphlet-size"
                name="pamphlet-size"
                onChange={(event) => {
                  handleFooterHeightChange(event);
                  setFooterData("footerHeight", event.target.value);
                }}
                value={footerHeight}
              >
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: 18,
                    },
                  }}
                  value="2cm"
                  control={
                    <Radio
                      required
                      sx={{
                        color: grey[400],
                        "&.Mui-checked": {
                          color: green[500],
                        },
                      }}
                    />
                  }
                  label="2cm  &nbsp;(recommended 2cm)"
                />
                <FormControlLabel
                  value="1cm"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: 18,
                    },
                  }}
                  control={
                    <Radio
                      required
                      sx={{
                        color: grey[400],
                        "&.Mui-checked": {
                          color: green[500],
                        },
                      }}
                    />
                  }
                  label="1cm"
                />
              </RadioGroup>
            </Grid>
          </div>
          <div className="file_input_wrapper">
            <div className="detail_footer">
              <div className="attach_file_label m_b_1 m_t_1">
                Attach Downloaded QR Code Here
              </div>
              <FileInput
                idHelper="front"
                fileName={footerFrontFileName}
                // onChange={footerFrontFileHandler}
                onChange={(event) => {
                  footerFrontFileHandler(event);
                  setFooterData("footerFileName", event.target.files[0].name);
                }}
                setFileName={(name) => setFooterFrontFileName(name)}
              />
            </div>
            <div className="campaign_modal_footer m_t_1">
              <CampaignModal
                previewSide={"none"}
                frontImgInput={footerFrontImgInput}
                frontImg={footerFrontImg}
                setPreviewSide={setPreviewSide}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const displayAdForm = (
    <div className="footer_card_div ">
      <div className="footer_details_wrapper">
        <div className="footer_details_div1">
          <div
            className="footer_details_heading sub_title"
            // onClick={displayAdDetailSubmitHandler}
          >
            Display Ad Details
          </div>
          <div className="enter_url_main_div">
            <div className="m_t_1 sub_title_child">Enter URL</div>
            <div className="enter_url_input_div">
              <input
                className="enter_url_input"
                onChange={(e) => {
                  setEnteredUrl(e.target.value);
                  setDisplayAdData("displayAdURL", e.target.value);
                }}
                placeholder="Enter URL"
              />
            </div>
            <div className="m_t_0_5">
              This link will open when image is tapped.
            </div>
            <div>
              You can enter url of your website,<br></br> social media etc.
            </div>
          </div>

          <div className="ad_url_type_main_div">
            <label className="sub_title_child">Ad URL Type</label>
            <select
              onChange={(e) => {
                setDisplayAdData("adUrlType", e.target.value);
              }}
              className="ad_url_type_dropdown"
            >
              {adUrlType.map((urlType) => {
                return (
                  <option key={urlType.id} value={urlType.id}>
                    {urlType.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="file_input_wrapper">
            <div className="detail_footer">
              <div className="attach_file_label  m_t_1 sub_title_child">
                Attach File
              </div>
              <FileInput
                idHelper="front"
                fileName={displayAdFrontFileName}
                // onChange={displayAdFrontFileHandler}
                onChange={(event) => {
                  displayAdFrontFileHandler(event);
                  setDisplayAdData(
                    "displayAdFileName",
                    event.target.files[0].name
                  );
                }}
                setFileName={(name) => setDisplayAdFrontFileName(name)}
              />
            </div>
            <div className="campaign_modal_footer m_t_1">
              <CampaignModal
                previewSide={"none"}
                frontImgInput={displayAdImgInput}
                frontImg={displayAdImg}
                setPreviewSide={setPreviewSide}
              />
            </div>
          </div>
        </div>
        <div className="footer_details_div2"></div>
      </div>
    </div>
  );

  return (
    <>
      <CampaignHeader className="campaign_create_new_fixed_header" />
      <div className="stepper_main_div">
        <div className="stepper__main_sub_container">
          {pamphletTypes.length && (
            <>
              <div className="heading">Select Your Pamphlet Requirements </div>
              <div className="with_or_without_pre_printed_wrapper">
                <div
                  className={
                    selectedPamphletType1
                      ? "selected_pamphlet_type_div"
                      : "pamphlet_type_div"
                  }
                  htmlFor="non_printed"
                  onClick={selectedCard1}
                >
                  <label
                    htmlFor="non_printed"
                    className="sub_title"
                    // onClick={}
                  >
                    Print & Distribute My Pamphlets
                  </label>
                </div>
                <div
                  className={
                    selectedPamphletType2
                      ? "selected_pamphlet_type_div"
                      : "pamphlet_type_div"
                  }
                  htmlFor="printed"
                  onClick={() => {
                    selectedCard2();
                  }}
                >
                  <label
                    htmlFor="printed"
                    className="sub_title"
                    // onClick={}
                  >
                    Distribute My Printed Pamphlets
                  </label>
                </div>
              </div>
              {/* <div style={{ width: "100%" }}>
                <div className="fill_ad_details_heading heading m_top_3">
                  Fill Ad Details {currStep + 1} of &nbsp;
                  {pamphletTypes.length}
                </div>
                <div className="tabs_group  m_t_2">
                  {pamphletTypes.map((type, i) => (
                    <div
                      key={type.id}
                      className={`${
                        currStep === i ? "focused_tab" : "normal_tab"
                      }`}
                    >
                      {type.name}
                    </div>
                  ))}
                </div>
              </div> */}

              {pamphletTypes.map((type, i) => {
                if (type.id === "pamphlet" && currStep === i) {
                  return (
                    <div
                      key={type.id}
                      className="pamphlet_main_div "
                      style={{ width: "100%" }}
                    >
                      {pamphletForm}
                    </div>
                  );
                } else if (type.id === "footer" && currStep === i) {
                  return (
                    <div key={type.id} className="footer_main_div">
                      {footerForm}
                    </div>
                  );
                } else if (type.id === "displayAd" && currStep === i) {
                  return (
                    <div key={type.id} className="display_ad_main_div">
                      {displayAdForm}
                    </div>
                  );
                } else {
                  return <div key={i}></div>;
                }
              })}
            </>
          )}

          <div className="center_div">
            <div className="wrapper">
              <div className="divider_brand_recall">
                <HorizontalDivider />
              </div>
              <div className="button_wrapper">
                <Button
                  className="white"
                  onClick={() => {
                    if (currStep === 0) {
                      history.goBack();
                      return;
                    } else {
                      setCurrStep((prevState) => prevState - 1);
                    }
                  }}
                  btnTxtClassName="sub_title text_black"
                  name="Back"
                />
                <Button
                  type="submit"
                  name="Continue"
                  onClick={() => {
                    if (isDesignByUs) {
                      clientUpdate();
                    }
                    if (currStep !== pamphletTypes.length - 1) {
                      setCurrStep((prevState) => prevState + 1);
                    } else {
                      // if (props.isChecked.pamphletIsChecked) {

                      // }
                      // if (props.isChecked.footerIsChecked) {
                      //   footerDetailsSubmitHandler();
                      // }
                      // if (props.isChecked.displayAdIsChecked) {
                      //   displayAdDetailSubmitHandler();
                      // }
                      let isFieldEmpty =
                        selectedSize === "" ||
                        selectedSide === "" ||
                        frontImgInput === null;

                      let isBackImgEmpty =
                        selectedSide === "Double Side" && backImgInput === null;
                      let backImgNotEmpty =
                        selectedSide === "Double Side" && backImgInput !== null;

                      // console.log(isFieldEmpty);
                      // console.log(isBackImgEmpty);
                      // console.log(
                      //   selectedSide === "Double Side" && isBackImgEmpty
                      // );

                      // if (isFieldEmpty) {
                      //   togglePopup();
                      // }
                      // if (isBackImgEmpty) {
                      //   togglePopup();
                      // }
                      if (selectedSize === "") {
                        setIsSizeSelected(true);
                      }
                      if (selectedSide === "") {
                        setIsSideSelected(true);
                      }
                      if (selectedDesign === "") {
                        setIsTypeSelected(true);
                      }
                      // if (frontImgInput === null) {
                      //   setIsFrontFileAttached(true);
                      // }
                      // if (
                      //   selectedSide === "Double Side" &&
                      //   backImgInput === null
                      // ) {
                      //   setIsBackFileAttached(true);
                      // }
                      if (isDoubleValidation && selectedPamphletType1) {
                        if (
                          (selectedSize === "A5" ||
                            selectedSize === "A4" ||
                            pamphletDetails.sizeUsingCode === "A5" ||
                            pamphletDetails.sizeUsingCode === "A4") &&
                          (selectedSide === "Single Side" ||
                            selectedSide === "Double Side" ||
                            pamphletDetails.sideUsingCode === false ||
                            pamphletDetails.sideUsingCode === true) &&
                          selectedDesign !== ""
                          // frontImgInput !== null &&
                          // backImgInput !== null
                        ) {
                          pamphletDetailsSubmitHandler();
                          history.replace({
                            pathname: "/ads/campaign/create/new/budget",
                            state: {
                              currentCampaign: isCurrentCampaign,
                              isCodeValid: isCodeValid,
                              brandRecallPageState: location.state,
                              pamphletDetails: pamphletDetails,
                              designByUsInput: designByUsInput,
                              footerDetails: footerDetails,
                              displayAdDetails: displayAdDetails,
                              isPrePrinted:
                                selectedDesign === "" ? true : false,
                            },
                          });
                        }
                      } else {
                        if (
                          (selectedSize === "A5" ||
                            selectedSize === "A4" ||
                            pamphletDetails.sizeUsingCode === "A5" ||
                            pamphletDetails.sizeUsingCode === "A4") &&
                          (selectedSide === "Single Side" ||
                            selectedSide === "Double Side" ||
                            pamphletDetails.sideUsingCode === false ||
                            pamphletDetails.sideUsingCode === true)
                          // selectedDesign !== "" &&
                          // frontImgInput !== null
                        ) {
                          pamphletDetailsSubmitHandler();
                          history.replace({
                            pathname: "/ads/campaign/create/new/budget",
                            state: {
                              currentCampaign: isCurrentCampaign,
                              isCodeValid: isCodeValid,
                              brandRecallPageState: location.state,
                              pamphletDetails: pamphletDetails,
                              designByUsInput: designByUsInput,
                              footerDetails: footerDetails,
                              displayAdDetails: displayAdDetails,
                              isPrePrinted:
                                selectedDesign === "" ? true : false,
                            },
                          });
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CampaignNewStepper;
